import { useState, useCallback } from 'react';

import { Auth } from 'aws-amplify';
import { Hub } from "@aws-amplify/core";
import { AuthState } from '@aws-amplify/ui-components';

import { format } from 'date-fns';

import PortalAPI from './PortalAPI';

//  --------------------------------------------------------------------------------------------------

export default class Utils {

   static isBlank(value) {
      return (value.trim() === '');
   }

   static isNotBlank(value) {
      return !Utils.isBlank(value);
   }

   static isVariantCode(value) {
      return VARIANT_CODE_PATTERN.test(value);
   }

   static isNotVariantCode(value) {
      return !Utils.isVariantCode(value);
   }

   static isEmail(value) {
      return EMAIL_PATTERN.test(value);
   }

   static isNotEmail(value) {
      return !Utils.isEmail(value);
   }

   static isOptionalEmail(value) {
      return (Utils.isBlank(value) || Utils.isEmail(value));
   }

   static isNotOptionalEmail(value) {
      return !Utils.isOptionalEmail(value);
   }

   static isMLEmail(value) {
      return EMAIL_PATTERN_ML.test(value);
   }

   static isNotMLEmail(value) {
      return !Utils.isMLEmail(value);
   }

   static isOptionalMLEmail(value) {
      return (Utils.isBlank(value) || Utils.isMLEmail(value));
   }

   static isNotOptionalMLEmail(value) {
      return !Utils.isOptionalMLEmail(value);
   }

   static isColour(value) {
      return COLOUR_PATTERN.test(value);
   }

   static isNotColour(value) {
      return !Utils.isColour(value);
   }

   static isInteger(value) {
      return INTEGER_PATTERN.test(value);
   }

   static isNotInteger(value) {
      return !Utils.isInteger(value);
   }

   static isZeroPlusInteger(value) {
      return ZERO_PLUS_INTEGER_PATTERN.test(value);
   }

   static isNotZeroPlusInteger(value) {
      return !Utils.isZeroPlusInteger(value);
   }

   static isOnePlusInteger(value) {
      return ONE_PLUS_INTEGER_PATTERN.test(value);
   }

   static isNotOnePlusInteger(value) {
      return !Utils.isOnePlusInteger(value);
   }

   static isZeroPlusNumber(value) {
      return ZERO_PLUS_NUMBER_PATTERN.test(value);
   }

   static isNotZeroPlusNumber(value) {
      return !Utils.isZeroPlusNumber(value);
   }

   static isPrice(value) {
      return PRICE_PATTERN.test(value);
   }

   static isNotPrice(value) {
      return !Utils.isPrice(value);
   }

   //  -----------------------------------------------------------------------------------------------
   //  For both these functions, input = <UUID> + ':' + <TEXT>

   //  Returns just the leading <UUID>
   static takeUUIDPrefix(input) {
      return input.substring(0, 36);
   }

   //  Returns just the tailing <TEXT>
   static dropUUIDPrefix(input) {
      return input.substring(37);
   }

   //  -----------------------------------------------------------------------------------------------

   //  Format a name for display
   static formatName(name) {
      return name.surnameFirst ? (name.surname + ' ' + name.forename) : (name.forename + ' ' + name.surname);
   }

   //  -----------------------------------------------------------------------------------------------

   //  Format a UTC ISO-8601 timestamp into something slightly more friendly in the local time
   static formatTimestamp(timestamp) {
      return format(new Date(timestamp), 'yyyy-MM-dd @ HH:mm:ss');
   }

   //  Convert the specified Date into a UTC ISO-8601 timestamp string at the start of the day (local time)
   static formatStartDate(date) {
      const start = new Date(date.getTime());
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      return start.toISOString();
   }

   //  Convert the secified Date into a UTC ISO-8601 timestamp string at the end of the day (local time)
   static formatEndDate(date) {
      const end = new Date(date.getTime());
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(999);
      return end.toISOString();
   }

   //  -----------------------------------------------------------------------------------------------

   static uploadFile(config, role, file, onSuccess) {
      config.showMessage('Initiating Upload');
      PortalAPI.uploadStart(file.name, role).then((response) => {
         const id = response.id;
         const setProgress = config.showMessage("Uploading", true);
         return PortalAPI.uploadFile(file, response.url, response.contentType, response.contentDisposition, setProgress).then((response) => {
            config.showMessage("Processing");
            return PortalAPI.uploadComplete(id).then((response) => {
               config.hideMessage();
               onSuccess(id, response);
            });
         });
      }).catch(config.reportAPIError);
   }

   //  -----------------------------------------------------------------------------------------------

   static orderStatusText(status) {
      switch ( status ) {
         case 'PROGRESS'     : return 'In Progress';
         case 'ISSUES'       : return 'Issues';
         case 'PRODUCTION'   : return 'In Production';
         case 'DISPATCHABLE' : return 'Ready for Dispatch';
         case 'DISPATCHED'   : return 'Dispatched';
         case 'DELIVERED'    : return 'Delivered';
         case 'CANCELLED'    : return 'Cancelled';
         default             : return status;
      }
   }

   //  -----------------------------------------------------------------------------------------------

   static signOut() {
      //  Invoking Auth.signOut() does not update the authentication component state (it used to but doesn't now), and despite it being reported ages ago the issue(s)
      //  were apparently just closed and nothing was ever done.  This work-around was taken from the the comments of the following issue report and seems to do the job.
      //  https://github.com/aws-amplify/amplify-js/issues/4643
      Auth.signOut().then(() => {
         Hub.dispatch('UI Auth', {
            event   : 'AuthStateChange',
            message : AuthState.SignedOut
         });
      });
   }

}

//  --------------------------------------------------------------------------------------------------

const VARIANT_CODE_PATTERN = /^[a-zA-Z0-9]+$/;

const EMAIL_PATTERN_BASE = '((([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))';

const EMAIL_PATTERN = new RegExp('^\\s*' + EMAIL_PATTERN_BASE + '\\s*$');

const EMAIL_PATTERN_ML = new RegExp('^\\s*' + EMAIL_PATTERN_BASE + '(\\s+' + EMAIL_PATTERN_BASE + ')*\\s*$');

const COLOUR_PATTERN = /^#[0-9a-fA-F]{6}$/;

const INTEGER_PATTERN = /^(?:0|-?[1-9][0-9]*)$/;

const ZERO_PLUS_INTEGER_PATTERN = /^(?:0|[1-9][0-9]*)$/;

const ONE_PLUS_INTEGER_PATTERN = /^[1-9][0-9]*$/;

const ZERO_PLUS_NUMBER_PATTERN = /^(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/;

const PRICE_PATTERN = /^(?:0|[1-9][0-9]*)\.[0-9]{2}$/;

//  --------------------------------------------------------------------------------------------------

export const GLOBAL_ID = '00000000-0000-0000-0000-000000000000';

//  --------------------------------------------------------------------------------------------------

export const TAB_STYLE = { textTransform : 'none' }

//  --------------------------------------------------------------------------------------------------

export const ORDER_STATUS_OPTIONS = [
   { id : 'PROGRESS',     name : Utils.orderStatusText('PROGRESS')     },
   { id : 'ISSUES',       name : Utils.orderStatusText('ISSUES')       },
   { id : 'PRODUCTION',   name : Utils.orderStatusText('PRODUCTION')   },
   { id : 'DISPATCHABLE', name : Utils.orderStatusText('DISPATCHABLE') },
   { id : 'DISPATCHED',   name : Utils.orderStatusText('DISPATCHED')   },
   { id : 'DELIVERED',    name : Utils.orderStatusText('DELIVERED')    },
   { id : 'CANCELLED',    name : Utils.orderStatusText('CANCELLED')    }
];

//  --------------------------------------------------------------------------------------------------

export function useToggle() {
   const [ state, setState ] = useState(false);
   return [ state, useCallback(() => setState((s) => !s), [ ]) ];
}

//  --------------------------------------------------------------------------------------------------

