import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

//  --------------------------------------------------------------------------------------------------

export default function TextInputDialog(props) {

   return (
      <Dialog open disableBackdropClick onClose={props.onClose} fullWidth>
         <DialogTitle>Downloads</DialogTitle>
         <DialogContent dividers={true}>
            <Table>
               <TableBody>{
                  props.files.map((f) => (
                     <TableRow key={f.id}>
                        <TableCell><img src={'/icons/' + f.icon + '.svg'} alt="File Icon" height={48}/></TableCell>
                        <TableCell>
                           <Typography variant="h6"><Link href={f.url}>{f.name}</Link></Typography>
                           <Typography variant="body1">{f.description}</Typography>
                        </TableCell>
                     </TableRow>
                  ))
               }</TableBody>
            </Table>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.onClose} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


