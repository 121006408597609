import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FilterIcon from '@material-ui/icons/FilterList';

import PortalAPI from '../../PortalAPI';
import Utils, { ORDER_STATUS_OPTIONS } from '../../Utils';

import FilterDialog from './FilterDialog';
import OrderDialog from './OrderDialog';

//  --------------------------------------------------------------------------------------------------

export default function ProductList(props) {

   const { config, orders, user, branch, client, vendor, view, onReload } = props;

   const classes = useStyles(config.theme);

   const [ filter, setFilter ] = useState(() => initFilterState(orders));

   const [ dialog, setDialog ] = useState(false);

   const filterHandler = () => {
      const closeHandler = (f) => {
         setDialog(false);
         setFilter(f);
      };
      setDialog(<FilterDialog orders={orders} user={user} branch={branch} client={client} vendor={vendor} initial={filter} onClose={closeHandler}/>);
   };

   const viewOrderHandler = (id) => {
      config.showMessage('Loading');
      PortalAPI.getOrder(id, view).then((response) => {
         config.hideMessage();
         let idx = -1;
         response.paid = response.hasOwnProperty('paid') ? response.paid ? 'true' : 'false' : '';
         response.sections.forEach((s) => s.items.forEach((i) => {
            if ( i.id ) {
               i.idx = ++idx;
            }
         }));
         const closeHandler = (reload) => {
            setDialog(false);
            if ( reload ) {
               onReload();
            }
         };
         setDialog(<OrderDialog config={config} order={response} reorder={(view === 'client')} onClose={closeHandler}/>);
      }).catch(config.reportAPIError);
   };

   return (orders.length > 0) ? (
      <>
         <InputLabel shrink>Filter</InputLabel>
         <Typography variant="h6"><IconButton onClick={filterHandler}><FilterIcon/></IconButton>Showing {filter.result.length} out of {orders.length} order(s).</Typography>
         <Paper className={classes.root} elevation={1}>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell>Order / Date</TableCell>
                     <TableCell>
                        {user && 'User'}
                        {user && (branch || client || vendor) && ' / '}
                        {branch && 'For Branch'}
                        {branch && (client || vendor) && ' / '}
                        {client && 'Via Portal'}
                        {client && vendor && ' / '}
                        {vendor && 'From Vendor'}
                     </TableCell>
                     <TableCell>Status</TableCell>
                     <TableCell className={classes.itemsColumn}>Items</TableCell>
                     <TableCell className={classes.valueColumn}>Value</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>{
                  filter.result.map(order => (
                     <TableRow key={order.id}>
                        <TableCell>
                           <Link color="inherit" href="#" onClick={() => viewOrderHandler(order.id)}>{order.id}</Link>
                           <br/>
                           { Utils.formatTimestamp(order.timestamp) }
                        </TableCell>
                        <TableCell>
                           {user && order.user.name}
                           {user && (branch || client || vendor) && <br/>}
                           {branch && order.branch.name}
                           {branch && (client || vendor) && <br/>}
                           {client && order.client.name}
                           {client && vendor && <br/>}
                           {vendor && order.vendor.name}
                        </TableCell>
                        <TableCell>
                           {
                              order.hasOwnProperty('paid') && (
                                 <React.Fragment key="paid">
                                    { order.paid ? <span className={classes.paid}>PAID</span> : <span className={classes.unpaid}>UNPAID</span> }
                                    <br/>
                                 </React.Fragment>
                              )
                           }
                           {
                              order.status.map((s, i) => (
                                 <React.Fragment key={i}>{(i > 0) && <br/>}{Utils.orderStatusText(s.status)}: {s.count}</React.Fragment>
                              ))
                           }
                           </TableCell>
                        <TableCell className={classes.itemsColumn}>{order.items}</TableCell>
                        <TableCell className={classes.valueColumn}>{order.total}</TableCell>
                     </TableRow>
                  ))
               }</TableBody>
            </Table>
         </Paper>
         {dialog}
      </>
   ) : (
      <Typography variant="h6">No matching orders found.</Typography>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root              : (theme) => ({
      ...theme.mixins.gutters(),
      marginTop      : theme.spacing(1),
      paddingTop     : theme.spacing(2),
      paddingBottom  : theme.spacing(2)
   }),
   unpaid            : {
      color          : 'red',
      fontWeight     : 'bold'
   },
   paid              : {
      color          : 'green',
      fontWeight     : 'bold'
   },
   itemsColumn       : {
      textAlign      : 'right'
   },
   valueColumn       : {
      textAlign      : 'right'
   }
});

//  --------------------------------------------------------------------------------------------------

function initFilterState(orders) {
   //  Collate all the searchable entities
   const vendors   = new Map();
   const clients   = new Map();
   const branches  = new Map();
   const users     = new Map();
   const available = new Set();
   orders.forEach((o) => {
      collate(vendors,  o.vendor);
      collate(clients,  o.client);
      collate(branches, o.branch);
      collate(users,    o.user);
      if ( o.hasOwnProperty('paid') ) {
         available.add(o.paid);
      }
      o.status.forEach((s) => available.add(s.status));
   });
   vendors.forEach((v, k) => available.add(k));
   clients.forEach((v, k) => available.add(k));
   branches.forEach((v, k) => available.add(k));
   users.forEach((v, k) => available.add(k));
   //  Construct the filter state
   return {
      vendors   : transform(vendors),
      vendor    : null,
      clients   : transform(clients),
      client    : null,
      branches  : transform(branches),
      branch    : null,
      users     : transform(users),
      user      : null,
      payments  : [
         { id : true,  name : 'PAID'   },
         { id : false, name : 'UNPAID' }
      ],
      payment   : null,
      statuses  : ORDER_STATUS_OPTIONS,
      status    : null,
      available : available,
      result    : orders
   };
}

function collate(map, entry) {
   if ( map.has(entry.id) ) {
      map.get(entry.id).add(entry.name);
   } else {
      map.set(entry.id, new Set([ entry.name ]));
   }
}

function transform(map) {
   const entries = [ ];
   map.forEach((v, k) => entries.push({ id : k, name : Array.from(v).sort().join(' / ')}));
   return entries.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0);
}

//  --------------------------------------------------------------------------------------------------



