import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//  --------------------------------------------------------------------------------------------------

export default function AudienceDialog(props) {

   return (
      <Dialog open disableBackdropClick onClose={props.onClose} aria-labelledby="destination-dialog-title" aria-describedby="destination-dialog-description">
         <DialogTitle id="destination-dialog-title">Confirm Audience</DialogTitle>
         <DialogContent dividers={true}>
            <DialogContentText id="destination-dialog-description">Please select the intended audience for this notice.</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.onSelected} color="primary">Selected Client</Button>
            <Button onClick={props.onGlobal} color="primary">All Clients</Button>
            <Button onClick={props.onClose} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


