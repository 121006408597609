import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';

import PortalAPI from '../../PortalAPI';

import SelectionDialog from '../../components/SelectionDialog';
import Selector, { findOption } from '../../components/Selector';

//  --------------------------------------------------------------------------------------------------

export default function UserBranchDialog(props) {

   const { config, level, details, onClose } = props;

   const [ state, setState ] = useState(() => initState(config, details));

   useEffect(() => loadClients(config, setState), [ config, setState ]);

   useEffect(() => loadBranches(config, setState, state.clients, state.fields.clientId), [ config, setState, state.clients, state.fields.clientId ]);

   const clientFieldHandler = (e, value) => setState((state) => produce(state, (draft) => {
      draft.client               = value;
      draft.branches             = null;
      draft.fields.clientId      = (value === null) ? false : value.id;
      draft.fields.branches      = new Set();
      draft.fields.branchId      = false;
      draft.fields.authorisation = (value === null) ? false : value.authorisation;
      draft.errors.clientId      = (value === null);
      draft.errors.branchId      = true;
   }));

   const addHandler = (id) => {
      const saveHandler = (id) => setState((state) => produce(state, (draft) => {
         draft.fields.branches.add(id);
         draft.dialog = false;
      }));
      const cancelHandler = (id) => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         const branches = state.branches.filter((b) => !state.fields.branches.has(b.id));
         draft.dialog = <SelectionDialog title="Add Branch" label="Branch" button="Add" options={branches} value={branches[0].id} onSave={saveHandler} onCancel={cancelHandler}/>;
      }));
   };

   const removeHandler = (id) => setState((state) => produce(state, (draft) => {
      draft.fields.branches.delete(id);
      if ( id === draft.fields.branchId ) {
         draft.fields.branchId = false;
         draft.errors.branchId = true;
      }
   }));

   const selectHandler = (id) => setState((state) => produce(state, (draft) => {
      draft.fields.branchId = id;
      draft.errors.branchId = (id === false);
   }));

   const saveButtonHandler = () => onClose({
      clientId      : state.fields.clientId,
      branches      : Array.from(state.fields.branches),
      branchId      : state.fields.branchId,
      authorisation : state.fields.authorisation
   });

   const cancelButtonHandler = () => onClose(details);

   const disableAdd = state.branches && ((state.fields.branches.size === state.branches.length) || (state.fields.branches.size === 1 && level !== 'B'));

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs" fullWidth>
         <DialogTitle>{(config.user.level === 'S') ? 'Client & Branch Selection' : 'Branch Selection'}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               {
                  (config.user.level === 'S') && (
                     <Grid item xs={12}>
                        <Selector options={state.clients} value={state.client} onChange={clientFieldHandler} label="Client" required={true} error={state.errors.clientId}/>
                     </Grid>
                  )
               }
               {
                  state.branches && (
                     <Grid item xs={12}>
                        <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
                           state.branches.filter((b) => state.fields.branches.has(b.id)).map((b) => {
                              const isSelected = (b.id === state.fields.branchId);
                              return (
                                 <CustomListItem key={b.id} style={ isSelected ? { background : 'rgb(255, 0, 0)' } : { } }>
                                    <ListItemText>{b.name}</ListItemText>
                                    <ListItemSecondaryAction>
                                       <IconButton onClick={() => removeHandler(b.id)}><DeleteIcon/></IconButton>
                                       {
                                          isSelected ? <IconButton onClick={() => selectHandler(false)}><RemoveIcon/></IconButton> :
                                                       <IconButton onClick={() => selectHandler(b.id)}><AddIcon/></IconButton>
                                       }
                                    </ListItemSecondaryAction>
                                 </CustomListItem>
                              );
                           })
                        }</List>
                        <div style={{ textAlign : 'center' }}>
                           <IconButton color="primary" onClick={() => addHandler()} disabled={disableAdd}><AddCircleIcon/></IconButton>
                        </div>
                     </Grid>
                  )
               }
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={(state.errors.clientId || state.errors.branchId)}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const CustomListItem = withStyles({
   secondaryAction: {
      paddingRight: 104
   }
})(ListItem);

//  --------------------------------------------------------------------------------------------------

function initState(config, details) {
   return {
      fields : {
         clientId      : details.clientId,
         branches      : new Set(details.branches),
         branchId      : details.branchId,
         authorisation : details.authorisation
      },
      errors : {
         clientId : (details.clientId === false),
         branchId : (details.branchId === false)
      },
      clients  : null,
      client   : null,
      branches : (config.user.level === 'B') ? config.user.branches : null,
      dialog   : false
   };
}

//  --------------------------------------------------------------------------------------------------

function loadClients(config, setState) {
   if ( config.user.level === 'S' ) {
      PortalAPI.getClients('auth').then((response) => setState((state) => produce(state, (draft) => {
         draft.clients = response;
         draft.client = findOption(response, draft.fields.clientId);
         if ( draft.client !== null ) {
            draft.fields.clientId      = draft.client.id;
            draft.fields.authorisation = draft.client.authorisation;
            draft.errors.clientId      = false;
         } else {
            draft.fields.clientId      = false;
            draft.fields.authorisation = false;
            draft.errors.clientId      = true;
         }
      }))).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

function loadBranches(config, setState, clients, clientId) {
   if ( clientId && ((config.user.level === 'S' && clients) || config.user.level === 'C') ) {
      PortalAPI.getBranches(clientId).then((response) => setState((state) => produce(state, (draft) => {
         draft.branches = response;
         const branch = findOption(response, draft.fields.branchId);
         if ( branch !== null ) {
            draft.fields.branchId = branch.id;
            draft.errors.branchId = false;
         } else if ( response.length === 1 ) {
            draft.fields.branches.add(response[0].id);
            draft.fields.branchId = response[0].id;
         } else {
            draft.fields.branchId = false;
            draft.errors.branchId = true;
         }
      }))).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

