import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import Utils from '../../Utils';

import SurchargeDialog, { newSurchargeDetails, testSurchargeDetails } from './SurchargeDialog';

//  --------------------------------------------------------------------------------------------------

export default function SurchargeZoneDialog(props) {

   const { vatRates, postcodes, postcode, value, onSave, onCancel } = props;

   const [ state, setState ] = useState(() => initState(postcode, value));

   const handlers = useMemo(() => createHandlers(vatRates, postcodes, setState), [ vatRates, postcodes, setState ]);

   const saveHandler = () => {
      const surcharges = { };
      if ( state.fields.saver ) {
         surcharges.saver = state.fields.saver;
      }
      if ( state.fields.standard ) {
         surcharges.standard = state.fields.standard;
      }
      if ( state.fields.express ) {
         surcharges.express = state.fields.express;
      }
      if ( state.fields.nextDay ) {
         surcharges.nextDay = state.fields.nextDay;
      }
      if ( state.fields.sameDay ) {
         surcharges.sameDay = state.fields.sameDay;
      }
      onSave(state.fields.postcode, surcharges);
   };

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Delivery Surcharge Zone</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>

               <Grid item xs={12}>
                  <TextField label="Postcode" value={fields.postcode} error={errors.postcode} onChange={handlers.postcode} required fullWidth/>
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Saver</InputLabel>
                  {
                     fields.saver ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editSaver}>{summarise('SVR', fields.saver)}</Link>
                           <IconButton size="small" onClick={handlers.deleteSaver}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Delivery Not Available <IconButton size="small" onClick={handlers.addSaver}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Standard</InputLabel>
                  {
                     fields.standard ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editStandard}>{summarise('STD', fields.standard)}</Link>
                           <IconButton size="small" onClick={handlers.deleteStandard}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Delivery Not Available <IconButton size="small" onClick={handlers.addStandard}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Express</InputLabel>
                  {
                     fields.express ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editExpress}>{summarise('EXP', fields.express)}</Link>
                           <IconButton size="small" onClick={handlers.deleteExpress}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Delivery Not Available <IconButton size="small" onClick={handlers.addExpress}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Next Working Day</InputLabel>
                  {
                     fields.nextDay ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editNextDay}>{summarise('NXT', fields.nextDay)}</Link>
                           <IconButton size="small" onClick={handlers.deleteNextDay}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Delivery Not Available <IconButton size="small" onClick={handlers.addNextDay}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

{/*
               <Grid item xs={12}>
 D   I     P      <InputLabel shrink>Same Working Day</InputLabel>
 I T S T   O E    {
 S H S H   T X       fields.sameDay ? (
 A E U I   E P          <Typography>
 B R E S A N E             <Link color="inherit" href="#" onClick={handlers.editSameDay}>{summarise('SAM', fields.sameDay)}</Link>
 L E S   R T N             <IconButton size="small" onClick={handlers.deleteSameDay}><DeleteIcon/></IconButton>
 E     T E I S          </Typography>
 D A W H   A I       ) : (
   R I A   L V           <Typography>Delivery Not Available <IconButton size="small" onClick={handlers.addSameDay}><AddCircleIcon/></IconButton></Typography>
 A E T T   L E       )
 S   H     Y      }
               </Grid>
*/}

            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>
            <Button onClick={saveHandler} color="primary" disabled={errors.postcode || errors.saver   || errors.standard ||
                                                                    errors.express  || errors.nextDay || errors.sameDay  }>Save</Button>
            <Button onClick={onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function summarise(style, details) {
   return `£${details.price}${summariseSurcharge(style, details)}`;
}

function summariseSurcharge(style, details) {
   return (style === 'SVR' || style === 'STD' || style === 'EXP') ? ` - ${summariseDays(details)}` : '';
}

function summariseDays(details) {
   return (details.extension ? 'Adds ' : '') +
          ((details.daysMin === details.daysMax) ? (details.daysMin === 1) ? '1 working day' : `${details.daysMin} working days` : `${details.daysMin} to ${details.daysMax} working days`);
}

//  --------------------------------------------------------------------------------------------------

function initState(postcode, value) {
   const state = {
      fields : {
         postcode : postcode,
      },
      errors : {
         postcode : Utils.isBlank(postcode),
         saver    : value.saver    && testSurchargeDetails('SVR', value.saver),
         standard : value.standard && testSurchargeDetails('STD', value.standard),
         express  : value.express  && testSurchargeDetails('EXP', value.express),
         nextDay  : value.nextDay  && testSurchargeDetails('NXT', value.nextDay),
         sameDay  : value.sameDay  && testSurchargeDetails('SAM', value.sameDay)
      },
      dialog : false
   };
   if ( value.saver ) {
      state.fields.saver = value.saver;
   }
   if ( value.standard ) {
      state.fields.standard = value.standard;
   }
   if ( value.express ) {
      state.fields.express = value.express;
   }
   if ( value.nextDay ) {
      state.fields.nextDay = value.nextDay;
   }
   if ( value.sameDay ) {
      state.fields.sameDay = value.sameDay;
   }
   return state;
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(vatRates, postcodes, setState) {

   const postcodeHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.postcode = value;
         draft.errors.postcode = Utils.isBlank(value) || postcodes.has(value);
      }));
   };

   const dialogCloseHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));

   const addHandler = (style, field) => {
      const saveHandler = (value) => setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         draft.errors[field] = testSurchargeDetails(style, value);
         draft.dialog        = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <SurchargeDialog vatRates={vatRates} style={style} details={newSurchargeDetails(style)} onSave={saveHandler} onCancel={dialogCloseHandler}/>
      }));
   };

   const editHandler = (style, field) => {
      const saveHandler = (value) => setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         draft.errors[field] = testSurchargeDetails(style, value);
         draft.dialog        = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <SurchargeDialog vatRates={vatRates} style={style} details={state.fields[field]} onSave={saveHandler} onCancel={dialogCloseHandler}/>
      }));
   };

   const deleteHandler = (field) => {
      const confirmationHandler = (value) => setState((state) => produce(state, (draft) => {
         delete draft.fields[field];
         draft.dialog      = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <ConfirmationDialog message="Delete this option ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={dialogCloseHandler}/>;
      }));
   };

   return {
      postcode       : postcodeHandler,
      addSaver       : () => addHandler('SVR', 'saver'),
      editSaver      : () => editHandler('SVR', 'saver'),
      deleteSaver    : () => deleteHandler('saver'),
      addStandard    : () => addHandler('STD', 'standard'),
      editStandard   : () => editHandler('STD', 'standard'),
      deleteStandard : () => deleteHandler('standard'),
      addExpress     : () => addHandler('EXP', 'express'),
      editExpress    : () => editHandler('EXP', 'express'),
      deleteExpress  : () => deleteHandler('express'),
      addNextDay     : () => addHandler('NXT', 'nextDay'),
      editNextDay    : () => editHandler('NXT', 'nextDay'),
      deleteNextDay  : () => deleteHandler('nextDay'),
      addSameDay     : () => addHandler('SAM', 'sameDay'),
      editSameDay    : () => editHandler('SAM', 'sameDay'),
      deleteSameDay  : () => deleteHandler('sameDay')
   };

}

//  --------------------------------------------------------------------------------------------------

export function newSurchargeZoneDetails() {
   return { };
}

//  --------------------------------------------------------------------------------------------------

export function testSurchargeZoneDetails(zone) {
   return ((zone.saver    && testSurchargeDetails('SVR', zone.saver))    ||
           (zone.standard && testSurchargeDetails('STD', zone.standard)) ||
           (zone.express  && testSurchargeDetails('EXP', zone.express))  ||
           (zone.nextDay  && testSurchargeDetails('NXT', zone.nextDay))  ||
           (zone.sameDay  && testSurchargeDetails('SAM', zone.sameDay)));
}

//  --------------------------------------------------------------------------------------------------


