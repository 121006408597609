import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import MessageDialog from '../components/MessageDialog';

import PortalAPI from '../PortalAPI';
import Utils from '../Utils';

import EPDQPaymentDialog from './EPDQPaymentDialog';

//  --------------------------------------------------------------------------------------------------

export default function EPDQDetailsDialog(props) {

   // --- https://support.epdq.co.uk/en/integration-solutions/integrations/hosted-payment-page -----------------------------------------------------------
   //
   // Although strictly taken the PSPID, ORDERID, AMOUNT, CURRENCY and LANGUAGE fields are sufficient, we nevertheless strongly recommend you to also send
   // us the customer name (CN), customer’s e-mail (EMAIL), address (OWNERADDRESS), town/city (OWNERTOWN), postcode/ZIP (OWNERZIP), country (OWNERCTY) and
   // telephone number (OWNERTELNO), as they can be useful tools for fraud prevention.
   //
   // ----------------------------------------------------------------------------------------------------------------------------------------------------

   const { config, authorise, basket, details, onClose } = props;

   const [ state, setState ] = useState(() => initState(config.countries, details));

   const fieldHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name'     :  draft.errors.name     = (value.length > 35 || Utils.isBlank(value));     break;
         case 'address'  :  draft.errors.address  = (value.length > 35);                             break;
         case 'town'     :  draft.errors.town     = (value.length > 40);                             break;
         case 'postcode' :  draft.errors.postcode = (value.length > 10);                             break;
         case 'phone'    :  draft.errors.phone    = (value.length > 30);                             break;
         case 'email'    :  draft.errors.email    = (value.length > 50 || Utils.isNotEmail(value));  break;
         default         :                                                                           break;
      }
      draft.errors.any = (draft.errors.name || draft.errors.address || draft.errors.town || draft.errors.postcode || draft.errors.phone || draft.email);
   }));

   const nextButtonHandler = () => {
      const redirect = authorise ? 'epdqauth' : 'epdq';
      config.showMessage("Processing");
      PortalAPI.postEPDQ(basket, {
         params : [
            [ 'CN',           state.fields.name     ],
            [ 'EMAIL',        state.fields.email    ],
            [ 'OWNERADDRESS', state.fields.address  ],
            [ 'OWNERTOWN',    state.fields.town     ],
            [ 'OWNERZIP',     state.fields.postcode ],
            [ 'OWNERCTY',     state.fields.country  ],   // <----- Send back as-is, we want the raw ISO country code at this point
            [ 'OWNERTELNO',   state.fields.phone    ],
            [ 'ACCEPTURL',    redirect              ],   // <--+-- The back-end constructs the full URL for each of these,
            [ 'DECLINEURL',   redirect              ],   // <--+    just one landing page is used though as the portal
            [ 'EXCEPTIONURL', redirect              ],   // <--+    works off of the ePDQ status code, not the URL.
            [ 'CANCELURL',    redirect              ]    // <--+
         ]
      }).then((response) => {
         config.hideMessage();
         const exitHandler = () => onClose(true);
         const closeHandler = () => setState((state) => produce(state, (draft) => { draft.dialog = false; }));
         switch ( response.status ) {

            case 'OK' :
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <EPDQPaymentDialog config={config} basket={basket} details={response} onClose={closeHandler}/>;
               }));
               break;

            case 'FAILED' :
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <MessageDialog title="Error" message="Unable to lock basket" onClose={exitHandler}/>
               }));
               break;

            case 'ISSUES' :
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <MessageDialog title="Error" message="Your basket has unresolved issues, please resolve and resubmit" onClose={exitHandler}/>
               }));
               break;

            case 'REROUTE' :
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <MessageDialog title="Error" message="The vendor has changed their payment requirements, please resubmit your basket" onClose={exitHandler}/>
               }));
               break;

            default :
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <MessageDialog title="Error" message="An error occurred, please notify support" onClose={exitHandler}/>
               }));
               break;

         }
      }).catch(config.reportAPIError);
   };

   const cancelButtonHandler = () => onClose(false);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>Your Billing Details</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  Please ensure the details shown below are correct for the method of payment you have chosen.
                  The secure payment site will use this information when authorising the transaction.
               </Grid>
               <Grid item xs={12}>
                  <TextField id="name" label="Name" value={fields.name} fullWidth required error={errors.name}
                             helperText="Max length 35 characters" onChange={(e) => fieldHandler('name', e.target.value)}/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="address" label="Address" value={fields.address} fullWidth error={errors.address}
                             helperText="Max length 35 characters" onChange={(e) => fieldHandler('address', e.target.value)}/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="town" label="Town" value={fields.town} fullWidth error={errors.town}
                             helperText="Max length 40 characters" onChange={(e) => fieldHandler('town', e.target.value)}/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="postcode" label="Postcode" value={fields.postcode} fullWidth error={errors.postcode}
                             helperText="Max length 10 characters" onChange={(e) => fieldHandler('postcode', e.target.value)}/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel shrink>Country</InputLabel>
                  <Select variant="standard" value={fields.country} onChange={(e) => fieldHandler('country', e.target.value)} fullWidth>
                     { config.countries.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>) }
                  </Select>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="phone" label="Phone" value={fields.phone} fullWidth error={errors.phone}
                             helperText="Max length 30 characters" onChange={(e) => fieldHandler('phone', e.target.value)}/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="email" label="Email" value={fields.email} fullWidth error={errors.email}
                             helperText="Max length 50 characters" onChange={(e) => fieldHandler('email', e.target.value)}/>
               </Grid>
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={nextButtonHandler} color="primary" disabled={errors.any}>Next</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

function initState(countries, details) {
   const state = {
      fields : {
         name     : details.details.contact,
         address  : details.details.street,
         town     : details.details.town,
         postcode : details.details.postcode,
         country  : details.details.country,
         phone    : details.details.phone,
         email    : details.details.email
      },
      errors : {
         name     : (details.details.contact.length > 35 || Utils.isBlank(details.details.contact)),
         address  : (details.details.street.length > 35),
         town     : (details.details.town.length > 40),
         postcode : (details.details.postcode.length > 10),
         phone    : (details.details.phone.length > 30),
         email    : (details.details.email.length > 50 || Utils.isNotEmail(details.details.email))
      },
      dialog : false
   };
   state.errors.any = (state.errors.name || state.errors.address || state.errors.town || state.errors.postcode || state.errors.phone || state.errors.email);
   return state;
}

//  --------------------------------------------------------------------------------------------------

