import React, { useContext, useState } from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ConfigurationContext } from '../../ConfigurationContext';
import { TAB_STYLE } from '../../Utils';

import VendorTab from './VendorTab';
import SearchTab from './SearchTab';

//  --------------------------------------------------------------------------------------------------

export default function VendorOrders(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ tab, setTab ] = useState(0);

   return (config.user.level === 'S' || (config.user.level === 'C' && config.client.vendorId)) ? (
      <>
         <Tabs className={classes.tabs} value={tab} onChange={(e, v) => setTab(v)} aria-label="tabs">
            <Tab style={TAB_STYLE} label="Orders"/>
            <Tab style={TAB_STYLE} label="Search"/>
         </Tabs>
         <VendorTab config={config} tab={0} selected={tab}/>
         <SearchTab config={config} tab={1} selected={tab} view="vendor"/>
      </>
   ) : (
      <Typography variant="h6">Access Denied.</Typography>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   tabs            : (theme) => ({
      marginBottom : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

