import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import DateRangeSelector from '../../components/DateRangeSelector';
import VendorSelector from '../../components/VendorSelector';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';

import OrderList from './OrderList';

//  --------------------------------------------------------------------------------------------------

export default function VendorTab(props) {

   const { config, tab, selected } = props;

   return (selected === tab) && (
      (config.user.level === 'S') ? (
         <VendorSelector config={config} global={true} render={(v) =>
            <DateRangeSelector render={(d) =>
               <Wrapper config={config} vendor={v} dates={d}/>
            }/>
         }/>
      ) : (config.user.level === 'C') ? (
         config.client.vendorId ? (
            <DateRangeSelector render={(d) =>
               <Wrapper config={config} vendor={config.client.vendorId} dates={d}/>
            }/>
         ) : (
            <Typography variant="h6">Your company is not configured as a vendor.</Typography>
         )
      ) : (
         <Typography variant="h6">Access Denied.</Typography>
      )
   );
}

//  --------------------------------------------------------------------------------------------------

function Wrapper(props) {

   const { config, vendor, dates } = props;

   const [ orders, setOrders ] = useState(false);

   const [ reload, reloadOrders ] = useToggle();

   useEffect(() => {
      setOrders(false);
      PortalAPI.getOrders({ vendor, dates }).then((response) => {
         setOrders(response);
      }).catch(config.reportAPIError);
   }, [ config, vendor, dates, reload ]);

   return orders ? (
      <OrderList config={config} orders={orders} client={true} branch={true} user={true} vendor={false} view="vendor" onReload={reloadOrders}/>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------


