import React from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RemoveIcon from '@material-ui/icons/Remove';

//  --------------------------------------------------------------------------------------------------

export default function WizardQuantities(props) {

   const { quantities, value, onChange } = props;

   const selectionHandler = (qty) => onChange(produce(value, (draft) => {
      if ( value.has(qty) ) {
         draft.delete(qty);
      } else {
         draft.add(qty);
      }
   }));

   const addAllHandler = () => onChange(new Set(quantities));

   const removeAllHandler = () => onChange(new Set());

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <Typography>Choose from the product quantities this supplier offers.</Typography>
         </Grid>
         <Grid item xs={12}>
            <List style={{ height : 480, position : 'relative', overflow : 'auto' }}>{
               quantities.map((q) => {
                  const isSelected = value.has(q);
                  return (
                     <CustomListItem key={'q' + q} style={ isSelected ? { background : 'rgb(255, 0, 0)' } : { } }>
                        <ListItemText>{q}</ListItemText>
                        <ListItemSecondaryAction><IconButton onClick={() => selectionHandler(q)}>{ isSelected ? <RemoveIcon/> : <AddIcon/> }</IconButton></ListItemSecondaryAction>
                     </CustomListItem>
                  );
               })
            }</List>
         </Grid>
         <Grid item xs={12}>
            <div style={{ textAlign : 'center' }}>
               <Tooltip title="Select all quantities"><IconButton color="primary" onClick={addAllHandler}><AddCircleIcon/></IconButton></Tooltip>
               <Tooltip title="Deselect all quantities"><IconButton color="primary" onClick={removeAllHandler}><RemoveCircleIcon/></IconButton></Tooltip>
            </div>
         </Grid>
      </Grid>
   );

}

//  --------------------------------------------------------------------------------------------------

const CustomListItem = withStyles({
   secondaryAction : {
      paddingRight : 52
   }
})(ListItem);

//  --------------------------------------------------------------------------------------------------



