import React  from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//  -----------------------------------------------------------------------------------------------------------
//  Not incorporated into WizardMarkups in order to avoid a potentially messy refactoring of a working control
//  -----------------------------------------------------------------------------------------------------------

export default function WizardRounding(props) {

   const { value, onChange } = props;

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <Typography>Use this to control whether selling prices are rounded up to the next whole pound after the % markup has been applied.</Typography>
         </Grid>
         <Grid item xs={12}>
            <FormControlLabel label="Round Up Selling Prices" control={(<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>)}/>
         </Grid>
      </Grid>   
   );

}

//  -----------------------------------------------------------------------------------------------------------

