import React, { useState, useMemo } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';

import OptionDialog, { newOptionDetails } from './OptionDialog';

//  --------------------------------------------------------------------------------------------------

export default function OptionList(props) {

   const { options, disabled, onChange, selected, onSelection } = props;

   const [ dialog, setDialog ] = useState(false);

   const handlers = useMemo(() => createHandlers(options, disabled, onChange, onSelection, setDialog), [ options, disabled, onChange, onSelection, setDialog ]);

   const CustomListItem = useMemo(() => (withStyles({
      secondaryAction: {
         paddingRight: (onSelection ? 104 : 52)
      }
   })(ListItem)), [onSelection]);

   return (
      <>
         <DragDropContext onDragEnd={handlers.dragEnd}>
            <Droppable droppableId="droppable">{(provided, snapshot) => (
               <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>
                     {
                        options.map((o, i) => {
                           const isSelected = (o.id === selected);
                           return (
                              <Draggable key={i} draggableId={'e'+i} index={i}>{(provided, snapshot) => (
                                 <CustomListItem ContainerComponent="li" ContainerProps={{ ref: provided.innerRef }}
                                                 {...provided.draggableProps} {...provided.dragHandleProps}
                                                 style={getItemStyle(isSelected, snapshot.isDragging, provided.draggableProps.style)}>
                                    <ListItemText><Link color="inherit" href="#" onClick={handlers.editOption[i]}>{o.value}</Link></ListItemText>
                                    {
                                       !snapshot.isDragging && (
                                          <ListItemSecondaryAction>
                                             <IconButton onClick={handlers.deleteOption[i]} disabled={disabled}><DeleteIcon/></IconButton>
                                             {
                                                onSelection && (isSelected ? <IconButton onClick={handlers.deselectOption} disabled={disabled}><RemoveIcon/></IconButton> :
                                                                             <IconButton onClick={handlers.selectOption[i]} disabled={disabled}><AddIcon/></IconButton>)
                                             }
                                          </ListItemSecondaryAction>
                                       )
                                    }
                                 </CustomListItem>
                              )}</Draggable>
                           );
                        })
                     }
                     {provided.placeholder}
                  </List>
               </div>
            )}</Droppable>
         </DragDropContext>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={handlers.addOption} disabled={disabled}><AddCircleIcon/></IconButton>
         </div>
         { dialog }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function createHandlers(options, disabled, onChange, onSelect, setDialog) {

   const dragEndHandler = (result) => {
      if ( result.destination ) {
         onChange(result.source.index, result.destination.index, null);
      }
   };

   const addHandler = () => {
      const closeHandler = (option) => {
         setDialog(false);
         if ( option !== '' ) {
            onChange(options.length, -1, option);
         }
      };
      setDialog(<OptionDialog ids={options.map(o => o.id)} value={newOptionDetails()} disabled={disabled} onClose={closeHandler}/>);
   };

   const editHandler = (idx) => {
      const closeHandler = (option) => {
         setDialog(false);
         onChange(idx, -1, option);
      };
      const current = options[idx];
      setDialog(<OptionDialog ids={options.map(o => o.id).filter(i => (i !== current.id))} value={current} disabled={disabled} onClose={closeHandler}/>);
   };

   const deleteHandler = (idx) => onChange(idx, -1, null);

   return {
      dragEnd        : dragEndHandler,
      addOption      : addHandler,
      editOption     : options.map((o, i) => () => editHandler(i)),
      deleteOption   : options.map((o, i) => () => deleteHandler(i)),
      selectOption   : options.map((o, i) => () => onSelect(o.id)),
      deselectOption : () => onSelect('')
   };

}

//  --------------------------------------------------------------------------------------------------

function getItemStyle(isSelected, isDragging, draggableStyle) {
   const style = { ...draggableStyle };
   if ( isDragging ) {
      style.border     = 'solid 1px black';
      style.background = isSelected ? 'rgb(255, 0, 0)' : 'rgb(245, 245, 245)';
   } else if ( isSelected ) {
      style.background = 'rgb(255, 0, 0)';
   }
   return style;
}

//  --------------------------------------------------------------------------------------------------


