import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';

import { ConfigurationContext } from '../../ConfigurationContext';

import ClientSelector from '../../components/ClientSelector';

import ProductList from './ProductList';

//  --------------------------------------------------------------------------------------------------

export default function Products(props) {
   const config = useContext(ConfigurationContext);
   return (config.user.level === 'S') ? (
      <ClientSelector config={config} render={(c) => (<ProductList config={config} client={c}/>)}/>
   ) : config.client.vendorId ? (
      <ProductList config={config} client={config.client.id}/>
   ) : (
      <Typography variant="h6">Your company is not configured as a vendor.</Typography>
   );
}

//  --------------------------------------------------------------------------------------------------


