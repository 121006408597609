import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

import OptionList from '../../components/OptionList';

//  --------------------------------------------------------------------------------------------------

export default function AttributeDialog(props) {

   const [ state, setState ] = useState(() => initState(props.details));

   const handlers = useMemo(() => createFieldHandlers(props.excluding, setState), [ props.excluding, setState ]);

   const saveButtonHandler = () => props.onClose(state.fields);

   const cancelButtonHandler = () => props.onClose(false);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs" fullWidth>
         <DialogTitle>Attribute Details</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField label="Name" value={fields.name} onChange={handlers.name} error={errors.name} disabled={props.wizard} required={!props.wizard} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel shrink>Field Type</InputLabel>
                  <Select value={fields.field} onChange={handlers.field} disabled={props.wizard} fullWidth>
                     <MenuItem value="TEXT">Text</MenuItem>
                     <MenuItem value="TEXTAREA">Text Area</MenuItem>
                     <MenuItem value="SELECT">Variant Selector</MenuItem>
                  </Select>
               </Grid>
               <Grid item xs={12}>{
                  (fields.field === 'TEXT') ? (
                     <TextField label="Default Value" value={fields.value} onChange={handlers.value} disabled={props.wizard} fullWidth/>
                  ) : (fields.field === 'TEXTAREA') ? (
                     <TextField label="Default Value" value={fields.value} onChange={handlers.value} disabled={props.wizard} fullWidth multiline rows={6}/>
                  ) : (
                     <>
                        <InputLabel error={errors.options} required={!props.wizard} shrink>Options</InputLabel>
                        <OptionList options={fields.options} disabled={props.wizard} onChange={handlers.options} selected={fields.value} onSelection={handlers.select}/>
                        <hr/>
                     </>
                  )
               }</Grid>
               <Grid item xs={6}>
                  <FormControlLabel control={(<Checkbox checked={state.fields.required} onChange={handlers.required}/>)} disabled={props.locked || props.tppp} label="Required"/>
               </Grid>
               <Grid item xs={6}>
                  <FormControlLabel control={(<Checkbox checked={state.fields.readOnly} onChange={handlers.readOnly}/>)} disabled={props.locked || props.tppp} label="Read Only"/>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel component="legend">Visibility:</FormLabel>
                     <RadioGroup aria-label="visibility" value={state.fields.visibility} onChange={handlers.visibility}>
                        <FormControlLabel value="PUBLIC"     control={<Radio color="primary"/>} disabled={props.locked || props.tppp} label="Public"/>
                        <FormControlLabel value="RESTRICTED" control={<Radio color="primary"/>} disabled={props.locked || props.tppp} label="Vendor &amp; Suppliers"/>
                        <FormControlLabel value="PRIVATE"    control={<Radio color="primary"/>} disabled={props.locked} label="Vendor only"/>
                     </RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>{
            props.wizard ? (
               <Button onClick={cancelButtonHandler} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveButtonHandler} color="primary" disabled={errors.name || errors.options}>Save</Button>
                  <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(details) {
   return {
      fields     : {
         ...details
      },
      errors     : {
         name    : Utils.isBlank(details.name),
         options : (details.field === 'SELECT' && details.options.length === 0)
      }
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(excluding, setState) {

   const nameHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.name = value;
         draft.errors.name = Utils.isBlank(value) || excluding.has(value);
      }));
   };

   const fieldHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.field = value;
         if ( value === 'SELECT' ) {
            draft.errors.options = (draft.fields.options.length === 0);
         } else {
            draft.fields.options = [ ];
            draft.errors.options = false;
         }
         draft.fields.value = '';
      }));
   };

   const optionsHandler = (idx1, idx2, ref) => setState((state) => produce(state, (draft) => {
      if ( ref ) {
         if ( draft.fields.value === draft.fields.options[idx1] ) {
            draft.fields.value = ref;
         }
         draft.fields.options[idx1] = ref;
         draft.errors.options = false;
      } else if ( idx2 > -1 ) {
         const [ removed ] = draft.fields.options.splice(idx1, 1);
         draft.fields.options.splice(idx2, 0, removed);
      } else {
         draft.fields.options.splice(idx1, 1);
         draft.errors.options = (draft.fields.options.length === 0);
      }
   }));

   const valueHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.value = value;
   }));

   const visibilityHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.visibility = value;
   }));

   return {
      name       : nameHandler,
      field      : fieldHandler,
      options    : optionsHandler,
      select     : valueHandler,
      value      : (e) => valueHandler(e.target.value),
      required   : (e) => setState((state) => produce(state, (draft) => { draft.fields.required   = !draft.fields.required; })),
      readOnly   : (e) => setState((state) => produce(state, (draft) => { draft.fields.readOnly   = !draft.fields.readOnly; })),
      visibility : (e) => visibilityHandler(e.target.value)
   };

}

//  --------------------------------------------------------------------------------------------------

export function newAttributeDetails(locked, tppp) {
   return {
      name       : '',
      required   : tppp,
      readOnly   : tppp,
      visibility : (locked || tppp) ? 'PRIVATE' : 'PUBLIC',
      field      : 'TEXT',
      options    : [ ],
      value      : ''
   };
}

//  --------------------------------------------------------------------------------------------------

