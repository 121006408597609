import React, {useContext, useMemo, useState} from 'react';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles} from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { ConfigurationContext } from '../ConfigurationContext';

import ConfirmationDialog from './ConfirmationDialog';

import ImageDialog, {newImageDetails} from './ImageDialog';

//  --------------------------------------------------------------------------------------------------

export default function ImageList(props) {

   const { role, wide, reorder, markdown, images, onChange } = props;

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const smUp = useMediaQuery(config.theme.breakpoints.up('sm'));
   const mdUp = useMediaQuery(config.theme.breakpoints.up('md'));

   const [ dialog, setDialog ] = useState(false);

   const handlers = useMemo(() => createHandlers(role, markdown, images, onChange, setDialog), [ role, markdown, images, onChange, setDialog ]);

   return (
      <>
         <GridList className={classes.gallery} cols={ (wide && mdUp) ? 4.5 : smUp ? 3.5 : 2.5 }>{
            images.map((image, idx) => (
               <GridListTile key={image.id}>
                  <img src={image.url} title={image.title} alt={image.alt === '' ? image.title : image.alt}/>
                  <GridListTileBar classes={{ root: classes.tileBars, title: classes.tileButtons }} actionIcon={
                     <>
                        {
                           reorder && (
                              <>
                                 {
                                    (idx > 0) &&
                                    <IconButton className={classes.tileButtons} size="small" aria-label={`move ${image.title} left`} onClick={handlers.left[idx]}><ArrowLeftIcon/></IconButton>
                                 }
                                 {
                                    ((idx + 1) < images.length) &&
                                    <IconButton className={classes.tileButtons} size="small" aria-label={`move ${image.title} right`} onClick={handlers.right[idx]}><ArrowRightIcon/></IconButton>
                                 }
                              </>
                           )
                        }
                        <IconButton className={classes.tileButtons} size="small" aria-label={`edit ${image.title}`} onClick={handlers.edit[idx]}><EditIcon/></IconButton>
                        <IconButton className={classes.tileButtons} size="small" aria-label={`delete ${image.title}`} onClick={handlers.delete[idx]}><DeleteIcon/></IconButton>
                     </>
                  }/>
               </GridListTile>
            ))
         }</GridList>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={handlers.add}><AddCircleIcon/></IconButton>
         </div>
         { dialog }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
   gallery         : {
      flexWrap     : 'nowrap',
      transform    : 'translateZ(0)',
   },
   tileBars : {
      background   : 'linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   tileButtons    : {
      background   : 'white',
      color        : theme.palette.primary,
   },

}));

//  --------------------------------------------------------------------------------------------------

function createHandlers(role, markdown, images, onChange, setDialog) {

   const handleClose = () => setDialog(false);

   const handleUpdate = (idx, details) => {
      setDialog(false);
      onChange(idx, details);
   };

   const addHandler = () => setDialog(<ImageDialog role={role} markdown={markdown} details={newImageDetails()} onSave={(i) => handleUpdate(images.length, i)} onCancel={handleClose}/>);

   const editHandler = (idx) => setDialog(<ImageDialog role={role} markdown={markdown} details={images[idx]} onSave={(i) => handleUpdate(idx, i)} onCancel={handleClose}/>);

   const deleteHandler = (idx) => setDialog(<ConfirmationDialog message="Delete this image ?" confirmationButton="Delete" onConfirmation={() => handleUpdate(idx, false)} onClose={handleClose}/>);

   const editHandlers   = [ ];
   const deleteHandlers = [ ];
   const leftHandlers   = [ ];
   const rightHandlers  = [ ];
   images.forEach((i, idx) => {
      editHandlers[idx]   = () => editHandler(idx);
      deleteHandlers[idx] = () => deleteHandler(idx);
      leftHandlers[idx]   = () => handleUpdate(idx, -1);
      rightHandlers[idx]  = () => handleUpdate(idx, 1);
   });

   return {
      add    : addHandler,
      edit   : editHandlers,
      delete : deleteHandlers,
      left   : leftHandlers,
      right  : rightHandlers,
   };

}

//  --------------------------------------------------------------------------------------------------

