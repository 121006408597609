import React, { useState, useMemo } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import produce from 'immer';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import TextInputDialog from '../../components/TextInputDialog';

//  --------------------------------------------------------------------------------------------------

export default function AttributeGroupsList(props) {

   const { groups, locked, onChange, onSelect } = props;

   const [ dialog, setDialog ] = useState(false);

   const handlers = useMemo(() => createHandlers(groups, onChange, onSelect, setDialog), [ groups, onChange, onSelect, setDialog ]);

   //  Reparenting workaround as the drag & drop component doesn't play well with the swipable views component (I think it doesn't like the transitions)
   const getItem = (provided, snapshot, rubric) => {
      const idx = rubric.source.index;
      return (
         <CustomListItem ContainerComponent="li" ContainerProps={{ ref: provided.innerRef }}
                         {...provided.draggableProps} {...provided.dragHandleProps}
                         style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
            <ListItemText>{(locked && groups[idx].name === 'Wizard') ? 'Wizard' : <Link color="inherit" href="#" onClick={handlers.editGroup[idx]}>{groups[idx].name}</Link>}</ListItemText>
            {
               !snapshot.isDragging && (
                  <ListItemSecondaryAction>
                     <IconButton onClick={handlers.deleteGroup[idx]} disabled={locked && groups[idx].name === 'Wizard'}><DeleteIcon/></IconButton>
                     <IconButton onClick={handlers.selectGroup[idx]}><PlayArrowIcon/></IconButton>
                  </ListItemSecondaryAction>
               )
            }
         </CustomListItem>
      );
   };

   return (
      <div>
         <DragDropContext onDragEnd={handlers.dragEnd}>
            <Droppable droppableId="groupsDroppable" renderClone={getItem}>{(provided, snapshot) => (
               <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>
                     { groups.map((g, i) => <Draggable key={i} draggableId={'e'+i} index={i}>{getItem}</Draggable>) }
                     { provided.placeholder }
                  </List>
               </div>
            )}</Droppable>
         </DragDropContext>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={handlers.addGroup}><AddCircleIcon/></IconButton>
         </div>
         { dialog }
      </div>
   );

}

//  --------------------------------------------------------------------------------------------------

const CustomListItem = withStyles({
  secondaryAction: {
    paddingRight: 104
  }
})(ListItem);

//  --------------------------------------------------------------------------------------------------

function createHandlers(groups, onChange, onSelect, setDialog) {

   const dragEndHandler = (result) => {
      if ( result.destination ) {
         onChange(result.source.index, result.destination.index, null);
      }
   };

   const addHandler = () => {
      const closeHandler = (name) => {
         setDialog(false);
         if ( name !== '' ) {
            onChange(groups.length, -1, {
               name       : name,
               attributes : []
            });
         }
      };
      setDialog(<TextInputDialog title="Group Details" label="Name" required={true} value=""
                                 excluding={new Set(groups.map((g) => g.name))} onClose={closeHandler}/>);
   };

   const editHandler = (idx) => {
      const closeHandler = (name) => {
         setDialog(false);
         onChange(idx, -1, produce(groups[idx], (draft) => {
            draft.name = name;
         }));
      };
      setDialog(<TextInputDialog title="Group Details" label="Name" required={true} value={groups[idx].name}
                                 excluding={new Set(groups.filter((g, i) => (i !== idx)).map((g) => g.name))} onClose={closeHandler}/>);
   };

   const deleteHandler = (idx) => {
      const confirmationHandler = () => {
         setDialog(false);
         onChange(idx, -1, null);
      };
      const rejectionHandler = () => {
         setDialog(false);
      };
      setDialog(<ConfirmationDialog message="Delete this group and its content ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={rejectionHandler}/>);
   };

   return {
      dragEnd     : dragEndHandler,
      addGroup    : addHandler,
      editGroup   : groups.map((o, i) => () => editHandler(i)),
      deleteGroup : groups.map((o, i) => () => deleteHandler(i)),
      selectGroup : groups.map((o, i) => () => onSelect(i))
   };

}

//  --------------------------------------------------------------------------------------------------

function getItemStyle(isDragging, draggableStyle) {
   const style = { ...draggableStyle };
   if ( isDragging ) {
      style.border     = 'solid 1px black';
      style.background = 'rgb(245, 245, 245)';
   }
   return style;
}

//  --------------------------------------------------------------------------------------------------


