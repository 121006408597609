import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Selector from '../../components/Selector';

//  --------------------------------------------------------------------------------------------------

export default function FilterDialog(props) {

   const { orders, user, branch, client, vendor, initial, onClose } = props;

   const [ state, setState ] = useState(initial);

   const evaluate = (draft) => {
      draft.available = new Set();
      draft.result    = orders.filter((o) => {
         const v = o.vendor.id;
         const c = o.client.id;
         const b = o.branch.id;
         const u = o.user.id;
         const p = (draft.vendor  === null || draft.vendor.id === v) && (draft.client === null || draft.client.id === c) &&
                   (draft.branch  === null || draft.branch.id === b) && (draft.user   === null || draft.user.id   === u) &&
                   (draft.payment === null || draft.payment.id === o.paid) &&
                   (draft.status  === null || o.status.find((s) => draft.status.id === s.status));
         if ( p ) {
            draft.available.add(v);
            draft.available.add(c);
            draft.available.add(b);
            draft.available.add(u);
            if ( o.hasOwnProperty('paid') ) {
               draft.available.add(o.paid);
            }
            o.status.forEach((s) => draft.available.add(s.status));
         }
         return p;
      });
   };

   const selectionHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft[field] = value;
      evaluate(draft);
   }));

   const clearButtonHandler = () => setState((state) => produce(state, (draft) => {
      draft.vendor = null;
      draft.client = null;
      draft.branch = null;
      draft.user   = null;
      draft.payment = null;
      draft.status  = null;
      evaluate(draft);
   }));

   const applyButtonHandler = () => onClose(state);

   const closeButtonHandler = () => onClose(initial);

   return (
      <Dialog open disableBackdropClick onClose={closeButtonHandler} scroll="paper" fullWidth>
         <DialogTitle>Filter Orders</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Typography>You can filter the orders by specifying one or more of the criteria shown below.
                              Each additional selection you make may narrow the remaining options that are available.
                              To expand the available options you must cancel one of your selections.</Typography>
               </Grid>
               { vendor && <FilterControl label="Vendor" options={state.vendors}  value={state.vendor} handler={(e, v) => selectionHandler('vendor', v)} available={state.available}/> }
               { client && <FilterControl label="Client" options={state.clients}  value={state.client} handler={(e, v) => selectionHandler('client', v)} available={state.available}/> }
               { branch && <FilterControl label="Branch" options={state.branches} value={state.branch} handler={(e, v) => selectionHandler('branch', v)} available={state.available}/> }
               { user   && <FilterControl label="User"   options={state.users}    value={state.user}   handler={(e, v) => selectionHandler('user', v)}   available={state.available}/> }
               <FilterControl label="Payment" options={state.payments} value={state.payment} handler={(e, v) => selectionHandler('payment', v)} available={state.available}/>
               <FilterControl label="Status"  options={state.statuses} value={state.status}  handler={(e, v) => selectionHandler('status', v)}  available={state.available}/>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={clearButtonHandler} color="primary">Clear</Button>
            <Button onClick={applyButtonHandler} color="primary">Apply</Button>
            <Button onClick={closeButtonHandler} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function FilterControl(props) {
   return (
      <Grid item xs={12}>
         <Selector label={props.label} options={props.options} value={props.value} onChange={props.handler} disableOption={(v) => !props.available.has(v.id)} fullWidth select/>
      </Grid>
   );
}

//  --------------------------------------------------------------------------------------------------

