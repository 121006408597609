import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

//  --------------------------------------------------------------------------------------------------

export default function ConfirmationDialog(props) {

   const { branches, onSwitch, onClose } = props;

   const [ state, setState ] = useState('');

   return (
      <Dialog open disableBackdropClick onClose={onClose} style={{ zIndex : 9999 }}>
         <DialogTitle>Switch To</DialogTitle>
         <DialogContent dividers={true}>
            <RadioGroup value={state} onChange={(e) => setState(e.target.value)}>{
               branches.map((b) => <FormControlLabel key={b.id} value={b.id} label={b.name} control={<Radio color="primary"/>}/>)
            }</RadioGroup>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => onSwitch(state)} color="primary" disabled={state === ''}>Switch</Button>
            <Button onClick={onClose} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


