import React, { useContext, useState } from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

import { ConfigurationContext } from '../../ConfigurationContext';
import { TAB_STYLE } from '../../Utils';

import YourTab   from './YourTab';
import BranchTab from './BranchTab';
import SearchTab from './SearchTab';

//  --------------------------------------------------------------------------------------------------

export default function BranchOrders(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ tab, setTab ] = useState(0);

   return (
      <>
         <Tabs className={classes.tabs} value={tab} onChange={(e, v) => setTab(v)} aria-label="tabs">
            <Tab style={TAB_STYLE} label="Your Orders"/>
            <Tab style={TAB_STYLE} label="Branch Orders"/>
            <Tab style={TAB_STYLE} label="Search"/>
         </Tabs>
         <YourTab   config={config} tab={0} selected={tab}/>
         <BranchTab config={config} tab={1} selected={tab}/>
         <SearchTab config={config} tab={2} selected={tab} view="client"/>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   tabs            : (theme) => ({
      marginBottom : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

