import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import PortalAPI from '../../PortalAPI';
import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import Selector, { findOption } from '../../components/Selector';

import OptionList from '../../components/OptionList';

//  --------------------------------------------------------------------------------------------------

export default function AttributeDialog(props) {

   const config = useContext(ConfigurationContext);

   const [ state, setState ] = useState(() => initState(props.groups, props.details));

   const fieldHandlers = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const saveButtonHandler = () => saveDetails(config, props, state.fields);

   const cancelButtonHandler = () => props.onClose(false);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.attributeId ? "Attribute Details" : "Create Attribute"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Selector options={props.groups} value={state.group} onChange={fieldHandlers.group} label="Group" required={true} error={state.errors.groupId}/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Name" value={state.fields.name} onChange={fieldHandlers.name} error={state.errors.name} fullWidth required/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel shrink>Field Type</InputLabel>
                  <Select value={state.fields.field} onChange={fieldHandlers.field} fullWidth>
                     <MenuItem value="TEXT">Text</MenuItem>
                     <MenuItem value="TEXTAREA">Text Area</MenuItem>
                     <MenuItem value="SELECT">Variant Selector</MenuItem>
                  </Select>
               </Grid>
               {
                  (state.fields.field === 'SELECT') && (
                     <Grid item xs={12}>
                        <InputLabel error={state.errors.options} required shrink>Options</InputLabel>
                        <OptionList options={state.fields.options} onChange={fieldHandlers.options}/>
                     </Grid>
                  )
               }
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={state.errors.groupId || state.errors.name || state.errors.options}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(groups, details) {
   return {
      fields     : {
         ...details
      },
      errors     : {
         groupId : Utils.isBlank(details.groupId),
         name    : Utils.isBlank(details.name),
         options : (details.field === 'SELECT' && details.options.length === 0)
      },
      group      : findOption(groups, details.groupId)
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {
   return {
      group : (e, v) => {
         const value = v;
         setState((state) => produce(state, (draft) => {
            draft.fields.groupId = (value == null) ? '' : value.id;
            draft.errors.groupId = (value == null);
            draft.group          = value;
         }));
      },
      name : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.fields.name = value;
            draft.errors.name = Utils.isBlank(value);
         }));
      },
      field : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.fields.field = value;
            if ( value === 'SELECT' ) {
               draft.errors.options = (draft.fields.options.length === 0);
            } else {
               draft.fields.options = [ ];
               draft.errors.options = false;
            }
         }));
      },
      options : (idx1, idx2, ref) => setState((state) => produce(state, (draft) => {
         if ( ref ) {
            draft.fields.options[idx1] = ref;
            draft.errors.options = false;
         } else if ( idx2 > -1 ) {
            const [ removed ] = draft.fields.options.splice(idx1, 1);
            draft.fields.options.splice(idx2, 0, removed);
         } else {
            draft.fields.options.splice(idx1, 1);
            draft.errors.options = (draft.fields.options.length === 0);
         }
      }))
   };
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields) {
   if ( props.attributeId ) {
      config.showMessage('Updating Attribute');
      PortalAPI.putAttribute(props.attributeId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Attribute');
      PortalAPI.postAttribute(fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

export function newAttributeDetails() {
   return {
      version : 0,
      groupId : '',
      name    : '',
      field   : 'TEXT',
      options : [ ]
   };
}

//  --------------------------------------------------------------------------------------------------

