import React, { useContext, useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import HistoryDialog from '../../components/HistoryDialog';
import Selector from '../../components/Selector';

import BasketStatusIcon from '../../basket/BasketStatusIcon';
import BasketViewerDialog from '../../basket/BasketViewerDialog';

import PortalAPI from '../../PortalAPI';
import Utils, { useToggle } from '../../Utils.js';
import { ConfigurationContext } from '../../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function Authorisation(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ branch, setBranch ] = useState(config.user.authorise[0]);

   const [ baskets, setBaskets ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   const [ refresh, forceRefresh ] = useToggle();

   useEffect(() => {
      setBaskets(false);
      PortalAPI.getBaskets(branch.id).then((response) => {
         setBaskets({
            pending : response.filter((b) => (b.status === 'SUBMITTED')),
            decided : response.filter((b) => (b.status !== 'SUBMITTED'))
         });
      }).catch(config.reportAPIError);
   }, [ config, branch, refresh ]);

   const handleDialogClose = (refresh) => {
      setDialog(false);
      if ( refresh ) {
         forceRefresh();
      }
   };

   const handleView = (basket, payment, canLock) => {
      config.showMessage("Loading");
      PortalAPI.getBasket(basket).then((response) => {
         config.hideMessage();
         setDialog(<BasketViewerDialog config={config} authorisation={true} basketId={basket} basket={response} payment={payment} canLock={canLock} onClose={handleDialogClose}/>);
      }).catch(config.reportAPIError);
   };

   const handleHistory = (basket) => {
      config.showMessage("Loading");
      PortalAPI.getBasketHistory(basket).then((response) => {
         config.hideMessage();
         setDialog(<HistoryDialog title="Basket History" history={response} onClose={() => handleDialogClose(false)}/>);
      }).catch(config.reportAPIError);
   };

   return (
      <>
         {
            (config.user.authorise.length > 1) && (
               <>
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={6}>
                        <Selector options={config.user.authorise} value={branch} onChange={(e, b) => setBranch(b)} label="Select Branch"/>
                     </Grid>
                  </Grid>
                  <br/>
               </>
            )
         }
         {
            baskets ? (
               <>
                  <Typography variant="h6">Awaiting Decision</Typography>
                  <BasketList classes={classes} baskets={baskets.pending} onView={handleView} onHistory={handleHistory}/>
                  <Typography variant="h6">Authorised / Rejected</Typography>
                  <BasketList classes={classes} baskets={baskets.decided} onView={handleView} onHistory={handleHistory}/>
               </>
            ) : (
               <CircularProgress color="secondary"/>
            )
         }
         { dialog }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function BasketList(props) {

   const { classes, baskets, onView, onHistory } = props;

   return (
      <Paper className={classes.root} elevation={1}>
         <Table>
            <TableHead>
               <TableRow>
                  <TableCell>Submitted</TableCell>
                  <TableCell>Basket</TableCell>
                  <TableCell className={classes.statusColumn}>Status</TableCell>
                  <TableCell className={classes.itemsColumn}>Items</TableCell>
                  <TableCell className={classes.valueColumn}>Value</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>{
               (baskets.length > 0) ? (
                  baskets.map((basket) => (
                     <TableRow key={basket.id}>
                        <TableCell>{Utils.formatTimestamp(basket.timestamp)}</TableCell>
                        <TableCell>{
                           basket.canView ? (
                              <Link color="inherit" href="#" onClick={() => onView(basket.id, basket.paymentType, basket.canLock)}>{basket.id}</Link>
                           ) : (
                              basket.id
                           )
                        }</TableCell>
                        <TableCell className={classes.statusColumn}>
                           <IconButton size="small" onClick={() => onHistory(basket.id)}><BasketStatusIcon mode="A" status={basket.status}/></IconButton>
                        </TableCell>
                        <TableCell className={classes.itemsColumn}>{basket.items}</TableCell>
                        <TableCell className={classes.valueColumn}>{basket.total}</TableCell>
                     </TableRow>
                  ))
               ) : (
                  <TableRow key="empty">
                     <TableCell colSpan={5}><Typography variant="h6">No baskets found.</Typography></TableCell>
                  </TableRow>
               )
            }</TableBody>
         </Table>
      </Paper>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      marginTop     : theme.spacing(1),
      marginBottom  : theme.spacing(2),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   }),
   statusColumn       : {
      textAlign      : 'center'
   },
   itemsColumn       : {
      textAlign      : 'right'
   },
   valueColumn       : {
      textAlign      : 'right'
   }
});

//  --------------------------------------------------------------------------------------------------

