import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import DateRangeSelector from '../../components/DateRangeSelector';

import PortalAPI from '../../PortalAPI';
import { GLOBAL_ID, useToggle } from '../../Utils';

import OrderList from './OrderList';

//  --------------------------------------------------------------------------------------------------

export default function YourTab(props) {

   const { config, tab, selected } = props;

   return (selected === tab) && (
      <DateRangeSelector render={(d) => <Wrapper config={config} dates={d}/>}/>
   );

}

//  --------------------------------------------------------------------------------------------------

function Wrapper(props) {

   const { config, dates } = props;

   const [ orders, setOrders ] = useState(false);

   const [ reload, reloadOrders ] = useToggle();

   useEffect(() => {
      setOrders(false);
      PortalAPI.getOrders({ user : GLOBAL_ID, dates }).then((response) => {
         setOrders(response);
      }).catch(config.reportAPIError);
   }, [ config, dates, reload ]);

   return orders ? (
      <OrderList config={config} orders={orders} branch={true} client={true} vendor={true} view="client" onReload={reloadOrders}/>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

