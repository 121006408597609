import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function SurchargeDIalog(props) {

   const { vatRates, style, details, onSave, onCancel } = props;

   const noDays = (style === 'NXT' || style === 'SAM');

   const [ state, setState ] = useState({
      fields : {
         price     : details.price,
         vatId     : details.vatId,
         daysMin   : details.daysMin === -1 ? '' : details.daysMin.toString(),
         daysMax   : details.daysMax === -1 ? '' : details.daysMax.toString(),
         extension : details.extension
      },
      errors : {
         price   : Utils.isNotPrice(details.price),
         vatId   : details.vatId === '',
         daysMin : details.daysMin < 0 || details.daysMin > details.daysMax,
         daysMax : details.daysMax < 0 || details.daysMax < details.daysMin
      }
   });

   const vatHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.vatId = value;
         draft.errors.vatId = false;
      }));
   };

   const priceHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.price = value;
         draft.errors.price = Utils.isNotPrice(value);
      }));
   };

   const daysMinHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.daysMin = value;
         checkMinMaxDays(draft);
      }));
   };

   const daysMaxHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.daysMax = value;
         checkMinMaxDays(draft);
      }));
   };

   const extensionHandler = (e) => {
      const value = e.target.checked;
      setState((state) => produce(state, (draft) => {
         draft.fields.extension = value;
         checkMinMaxDays(draft);
      }));
   };

   const saveHandler = () => onSave({
      price     : state.fields.price,
      vatId     : state.fields.vatId,
      daysMin   : parseInt(state.fields.daysMin),
      daysMax   : parseInt(state.fields.daysMax),
      extension : state.fields.extension
   });

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Delivery Surcharge</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12} sm={6}>
                  <InputLabel shrink required error={errors.vatId}>VAT Rate</InputLabel>
                  <Select value={fields.vatId} onChange={vatHandler} fullWidth>{
                     vatRates.map((v) => <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>)
                  }</Select>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Price" value={fields.price} error={errors.price} onChange={priceHandler} required fullWidth
                             InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Minimum Working Days" value={fields.daysMin} error={errors.daysMin} onChange={daysMinHandler} disabled={noDays} required={!noDays} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Maximum Working Days" value={fields.daysMax} error={errors.daysMax} onChange={daysMaxHandler} disabled={noDays} required={!noDays} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <FormControlLabel label="Delivery time extended by amount shown" control={(<Checkbox checked={state.fields.extension} onChange={extensionHandler} disabled={noDays}/>)}/>
               </Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>
            <Button onClick={saveHandler} color="primary" disabled={errors.vatId || errors.price || errors.daysMin || errors.daysMax}>Save</Button>
            <Button onClick={onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function checkMinMaxDays(draft) {
   const errorMin = Utils.isNotInteger(draft.fields.daysMin);
   const errorMax = Utils.isNotInteger(draft.fields.daysMax);
   if ( errorMin || errorMax ) {
      draft.errors.daysMin = errorMin;
      draft.errors.daysMax = errorMax;
   } else {
      const daysMin = parseInt(draft.fields.daysMin);
      const daysMax = parseInt(draft.fields.daysMax);
      draft.errors.daysMin = daysMin < 0 || daysMin > daysMax;
      draft.errors.daysMax = daysMax < 0 || daysMax < daysMin;
   }
}

//  --------------------------------------------------------------------------------------------------

export function newSurchargeDetails(delivery) {
   return {
      vatId     : '',
      price     : '0.00',
      daysMin   : (delivery === 'NXT') ? 1 : (delivery === 'SAM') ? 0 : -1,
      daysMax   : (delivery === 'NXT') ? 1 : (delivery === 'SAM') ? 0 : -1,
      extension : (delivery === 'SVR' || delivery === 'STD' || delivery === 'EXP')
   };
}

//  --------------------------------------------------------------------------------------------------

export function testSurchargeDetails(style, details) {
   switch ( style ) {
      case 'SAM' : return (details.extension || details.daysMin !== 0 || details.daysMax !== 0);
      case 'NXT' : return (details.extension || details.daysMin !== 1 || details.daysMax !== 1);
      default    : return (details.daysMin < 0 || details.daysMin > details.daysMax);
   }
}

//  --------------------------------------------------------------------------------------------------

