import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterIcon from '@material-ui/icons/FilterList';
import LockIcon from '@material-ui/icons/Lock';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import MessageDialog from '../../components/MessageDialog';
import SelectionDialog from '../../components/SelectionDialog';

import LockedDialog from './LockedDialog';
import TabDialog, { newTabDetails } from './TabDialog';
import PreviewDialog from './PreviewDialog';
import PricingDialog from './PricingDialog';
import ProductDialog from './ProductDialog';
import WizardDialog from './WizardDialog';

//  --------------------------------------------------------------------------------------------------

export default function ProductList(props) {

   const { config, client } = props;

   const classes = useStyles(config.theme);

   const [ refresh, forceRefresh ] = useToggle();

   const [ state, setState ] = useState({ master : false });

   useEffect(() => loadProducts(config, client, setState, refresh, forceRefresh), [ config, client, setState, refresh, forceRefresh ]);

   const { master, handlers, filter, tabs, selected, speedDial, dialog } = state

   const force = (config.user.level === 'S');

   const fullEdit = config.user.pFull;

   return master ? (
      <>
         <Paper className={classes.root} elevation={1}>
            <Grid container spacing={1}>
               <Grid item xs={12} sm={6}>
                  <TextField id="filter" label="Filter" value={filter} onChange={handlers.filter} fullWidth
                             InputProps={{ startAdornment: <InputAdornment position="start"><FilterIcon/></InputAdornment> }}/>
               </Grid>
            </Grid>
            <br/>
            <Table>
               <TableHead>
                  <TableRow>
                     { fullEdit && <TableCell padding="checkbox"><Checkbox checked={selected.all} onChange={handlers.checkbox.all}/></TableCell> }
                     <TableCell>Name</TableCell>
                     <TableCell>Actions</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>{
                  tabs.map((t) => (
                     <React.Fragment key={t.id}>
                        <TableRow className={classes.tabRow}>{
                           (t.id === 'ORPHANED' || t.id === 'WIP' || !fullEdit) ? (
                              <>
                                 <TableCell className={classes.tabCell} colSpan={fullEdit ? 2 : 1}><Typography variant="h6">{t.name}</Typography></TableCell>
                                 <TableCell className={classes.tabCell}/>
                              </>
                           ) : (
                              <>
                                 <TableCell className={classes.tabCell} colSpan="2">
                                    <Typography variant="h6"><Link color="inherit" href="#" onClick={handlers.edit[t.id]}>{t.name}</Link></Typography>
                                 </TableCell>
                                 <TableCell className={classes.tabCell}>{
                                    (t.products.length === 0) && (
                                       <IconButton className={classes.tabIcons} onClick={handlers.delete[t.id]} aria-label="Delete Tab"><DeleteIcon/></IconButton>
                                    )
                                 }</TableCell>
                              </>
                           )
                        }</TableRow>
                        {
                           (t.products.length > 0) ? (
                              t.products.map((p) => (
                                 <TableRow key={p.id}>{
                                    (force || p.vendorId === config.client.vendorId) ? (
                                       <>
                                          { fullEdit && <TableCell padding="checkbox"><Checkbox checked={selected[p.id]} onChange={handlers.checkbox[p.id]}/></TableCell> }
                                          <TableCell><Link color="inherit" href="#" onClick={handlers.edit[p.id]}>{p.name}</Link></TableCell>
                                          <TableCell>
                                             { fullEdit && (
                                                <>
                                                   <Tooltip title="Delete product">
                                                      <IconButton onClick={handlers.delete[p.id]} aria-label="Delete Product"><DeleteIcon/></IconButton>
                                                   </Tooltip>
                                                   <Tooltip title="Change supplier">
                                                      <IconButton onClick={handlers.supplier[p.id]} area-label="Change supplier"><ShoppingCartIcon/></IconButton>
                                                   </Tooltip>
                                                </>
                                             )}
                                             <Tooltip title={p.enabled ? 'Disable' : 'Enable'}>
                                                <IconButton onClick={handlers.status[p.id]} aria-label={p.enabled ? 'Disable' : 'Enable'}>{p.enabled ? <CheckIcon/> : <BlockIcon/>}</IconButton>
                                             </Tooltip>
                                             <Tooltip title="Preview">
                                                <IconButton onClick={handlers.view[p.id]} aria-label="Product preview"><PlayArrowIcon/></IconButton>
                                             </Tooltip>
                                             { p.locked && (
                                                <Tooltip title="Locked product settings">
                                                   <IconButton onClick={handlers.locked[p.id]} aria-label="Locked product settings"><LockIcon/></IconButton>
                                                </Tooltip>
                                             )}
                                          </TableCell>
                                       </>
                                    ) : (
                                       <>
                                          { fullEdit && <TableCell padding="checkbox"><Checkbox checked={selected[p.id]} onChange={handlers.checkbox[p.id]} disabled/></TableCell> }
                                          <TableCell>{p.name}</TableCell>
                                          <TableCell/>
                                       </>
                                    )
                                 }</TableRow>
                              ))
                           ) : (
                              <TableRow key={t.id + '_empty'}>
                                 <TableCell colSpan="3"><Typography variant="h6">No products found.</Typography></TableCell>
                              </TableRow>
                           )
                        }
                     </React.Fragment>
                  ))
               }</TableBody>
            </Table>
         </Paper>
         {
            fullEdit && (
               <SpeedDial direction="up" className={classes.fab} icon={<SpeedDialIcon/>} open={speedDial} onOpen={handlers.openSpeedDial} onClose={handlers.closeSpeedDial} ariaLabel="SpeedDial">
                  <SpeedDialAction icon={<NoteAddIcon/>}         tooltipTitle="New Product"             onClick={handlers.createProduct}/>
                  <SpeedDialAction icon={<FileCopyIcon/>}        tooltipTitle="Clone Selected Products" onClick={handlers.cloneProducts}/>
                  <SpeedDialAction icon={<CreateNewFolderIcon/>} tooltipTitle="New Tab"                 onClick={handlers.createTab}/>
               </SpeedDial>
            )
         }
         { dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root              : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop     : theme.spacing(2),
      paddingBottom  : theme.spacing(2)
   }),
   tabRow            : (theme) => ({
      background     : theme.palette.primary.main
   }),
   tabCell           : (theme) => ({
      color          : theme.palette.primary.contrastText,
      paddingTop     : 0,
      paddingBottom  : 0,
      paddingLeft    : theme.spacing(2),
      paddingRight   : 0,
   }),
   tabIcons          : (theme) => ({
      color          : theme.palette.primary.contrastText,
   }),
   fab               : (theme) => ({
      position       : 'fixed',
      bottom         : theme.spacing(2),
      right          : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

function loadProducts(config, client, setState, refresh, forceRefresh) {
   setState((state) => produce(state, (draft) => {
      draft.master = false;
   }));
   PortalAPI.getProducts(client).then((response) => {

      const handleDialogClose = (saved) => {
         setState((state) => produce(state, (draft) => {
            draft.dialog = false
         }));
         if ( saved ) {
            forceRefresh();
         }
      };

      const handleCreateTab = () => setState((state) => produce(state, (draft) => {
         draft.speedDial = false;
         draft.dialog    = <TabDialog clientId={client} tabId={false} details={newTabDetails()} onClose={handleDialogClose}/>;
      }));

      const handleEditTab = (id) => {
         config.showMessage('Loading');
         PortalAPI.getTab(client, id).then((response) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.dialog = <TabDialog clientId={client} tabId={id} details={response} onClose={handleDialogClose}/>;
            }));
         }).catch(config.reportAPIError);
      };

      const handleDeleteTab = (id, version) => {
         const handleDelete = () => {
            handleDialogClose(false);
            config.showMessage('Deleting');
            PortalAPI.deleteTab(client, id, version).then((response) => {
               config.hideMessage();
               forceRefresh();
            }).catch(config.reportAPIError);
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message="Delete this product tab ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => handleDialogClose(false)}/>;
         }));
      };

      const getTabList = (master) => master.map((t) => ({ id : t.id, name : t.name }));

      const handleCreateProduct = () => {
         setState((state) => produce(state, (draft) => {
            draft.speedDial = false;
            draft.dialog    = <WizardDialog config={config} clientId={client} migration={false} tabs={getTabList(state.master)} onClose={handleDialogClose}/>;
         }));
      };

      const handleEditProduct = (id) => {
         if ( config.user.pFull ) {
            config.showMessage('Loading');
            PortalAPI.getProduct(client, id).then((productInfo) => {
               config.hideMessage();
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <ProductDialog tabs={getTabList(draft.master)} clientId={client} productId={id} productInfo={productInfo} onClose={handleDialogClose}/>;
               }));
            }).catch(config.reportAPIError);
         } else {
            config.showMessage('Loading');
            PortalAPI.getProductPricing(client, id).then((productInfo) => {
               config.hideMessage();
               const saveHandler = (pricing) => {
                  config.showMessage("Updating");
                  PortalAPI.putProductPricing(client, id, { version : productInfo.pricing.version, pricing : pricing }).then((response) => {
                     config.hideMessage();
                     handleDialogClose(true);
                  }).catch(config.reportAPIError);
               };
               const cancelHandler = () => handleDialogClose(false);
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <PricingDialog vatRates={productInfo.vatRates} branches={productInfo.branches} value={productInfo.pricing.pricing} locked={productInfo.locked}
                                                onSave={saveHandler} onCancel={cancelHandler}/>;
               }));
            }).catch(config.reportAPIError);
         }
      };

      const handleDeleteProduct = (id, version) => {
         const handleDelete = () => {
            handleDialogClose(false);
            config.showMessage('Deleting');
            PortalAPI.deleteProduct(client, id, version).then((response) => {
               config.hideMessage();
               forceRefresh();
            }).catch(config.reportAPIError);
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message="Delete this product ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => handleDialogClose(false)}/>;
         }));
      };

      const handleChangeSupplier = (id) => {
         config.showMessage('Loading');
         PortalAPI.getWizardMigration(client, id).then((response) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.dialog = <WizardDialog config={config} clientId={client} migration={response} tabs={getTabList(state.master)} onClose={handleDialogClose}/>;
            }));
         }).catch(config.reportAPIError);
      };

      const handleEnableDisableProduct = (id, version, enabled) => {
         const handleConfirmation = () => {
            handleDialogClose(false);
            config.showMessage('Updating');
            PortalAPI.putProductStatus(client, id, version, enabled).then((response) => {
               config.hideMessage();
               forceRefresh();
            }).catch(config.reportAPIError);
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message={enabled ? "Enable this product ?" : "Disable this product ?"} confirmationButton={enabled ? "Enable" : "Disable"}
                                               onConfirmation={handleConfirmation} onClose={() => handleDialogClose(false)}/>;
         }));
      };

      const handleLockedDetails = (id) => {
         config.showMessage('Loading');
         PortalAPI.getProductLock(client, id).then((productInfo) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.dialog = <LockedDialog config={config} clientId={client} productId={id} productInfo={productInfo} onClose={handleDialogClose}/>
            }));
         }).catch(config.reportAPIError);
      };

      const handleViewProduct = (id) => {
         config.showMessage('Loading');
         PortalAPI.getProduct(client, id).then((productInfo) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.dialog = <PreviewDialog productInfo={productInfo} onClose={() => handleDialogClose(false)}/>;
            }));
         }).catch(config.reportAPIError);
      };

      const handleCloneProducts = () => setState((state) => {
         const selected = [ ];
         state.tabs.forEach((t) => t.products.forEach((p) => {
            if ( state.selected[p.id] ) {
               selected.push(p.id);
            }
         }));
         if ( selected.length === 0 ) {
            return produce(state, (draft) => {
               draft.speedDial = false;
               draft.dialog = <MessageDialog title="Unable to procede" message="No products selected" onClose={() => handleDialogClose(false)}/>;
            });
         }
         const handleClone = (target) => {
            setState((state) => produce(state, (draft) => {
               draft.speedDial = false;
               draft.dialog = false;
            }));
            config.showMessage("Cloning products");
            PortalAPI.postProductClone(client, target, selected).then((response) => {
               config.hideMessage();
               forceRefresh();
            }).catch(config.reportAPIError);
         };
         if ( config.user.level === 'S' ) {
            config.showMessage('Loading');
            PortalAPI.getClients().then((response) => {
               config.hideMessage();
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <SelectionDialog title="Clone to" label="Client" button="Clone" options={response} value={client} onSave={handleClone}
                                                  onCancel={() => handleDialogClose(false)}/>
               }));
            }).catch(config.reportAPIError);
            return produce(state, (draft) => {
               draft.speedDial = false;
            });
         }
         handleClone(client);
      });

      const handleFilter = (e) => {
         const value = e.target.value;
         const search = value.toLowerCase();
         setState((state) => produce(state, (draft) => {
            draft.filter = value;
            if ( value === '' ) {
               const selected = { all : false };
               draft.master.forEach((t) => t.products.forEach((p) => { selected[p.id] = false; }));
               draft.tabs = draft.master;
               draft.selected = selected;
            } else {
               const selected = { all : false };
               const filtered = [ ];
               draft.master.forEach((t) => {
                  const matching = [ ];
                  t.products.forEach((p) => {
                     if ( p.filterName.includes(search) ) {
                        selected[p.id] = false;
                        matching.push(p);
                     }
                  });
                  if ( matching.length > 0 ) {
                     filtered.push({
                        id       : t.id,
                        name     : t.name,
                        products : matching
                     });
                  }
               });
               draft.tabs = filtered;
               draft.selected = selected;
            }
         }));
      };

      const handleCheckbox = { };
      const handleEdit     = { };
      const handleDelete   = { };
      const handleSupplier = { };
      const handleStatus   = { };
      const handleView     = { };
      const handleLocked   = { };
      const selected       = { all : false };

      handleCheckbox.all = (e) => {
         const value = e.target.checked;
         const force = (config.user.level === 'S');
         const vendorId = config.client.vendorId;
         setState((state) => produce(state, (draft) => {
            draft.selected.all = value;
            draft.tabs.forEach((t) => t.products.forEach((p) => {
               draft.selected[p.id] = (force || p.vendorId === vendorId) ? value : false;
            }))
         }));
      };

      const handleSetCheckbox = (id, value) => setState((state) => produce(state, (draft) => {
         draft.selected[id] = value;
      }));

      response.forEach((t) => {
         handleEdit[t.id]   = () => handleEditTab(t.id);
         handleDelete[t.id] = () => handleDeleteTab(t.id, t.version);
         t.products.forEach((p) => {
            handleCheckbox[p.id] = (e) => handleSetCheckbox(p.id, e.target.checked);
            handleEdit[p.id]     = ()  => handleEditProduct(p.id);
            handleDelete[p.id]   = ()  => handleDeleteProduct(p.id, p.version);
            handleSupplier[p.id] = ()  => handleChangeSupplier(p.id);
            handleStatus[p.id]   = ()  => handleEnableDisableProduct(p.id, p.version, !p.enabled);
            handleLocked[p.id]   = ()  => handleLockedDetails(p.id);
            handleView[p.id]     = ()  => handleViewProduct(p.id);
            p.filterName = p.name.toLowerCase();
            selected[p.id] = false;
            Object.freeze(p);
         });
         Object.freeze(t);
      });
      Object.freeze(response);

      setState((state) => produce(state, (draft) => {
         draft.master    = response;
         draft.handlers  = {
            filter         : handleFilter,
            checkbox       : handleCheckbox,
            edit           : handleEdit,
            delete         : handleDelete,
            supplier       : handleSupplier,
            status         : handleStatus,
            locked         : handleLocked,
            view           : handleView,
            openSpeedDial  : () => setState((state) => produce(state, (draft) => { draft.speedDial = true; })),
            closeSpeedDial : () => setState((state) => produce(state, (draft) => { draft.speedDial = false; })),
            createTab      : handleCreateTab,
            createProduct  : handleCreateProduct,
            cloneProducts  : handleCloneProducts
         };
         draft.filter    = '';
         draft.tabs      = response;
         draft.selected  = selected;
         draft.speedDial = false;
         draft.dialog    = false;
      }));

   }).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------



