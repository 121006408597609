import React from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function FlowLinkForm(props) {

   const { state, setState } = props;

   const fieldHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields.flowLink[field] = value;
      switch ( field ) {

         case 'hostname' :
            draft.errors.flowLink.hostname = Utils.isBlank(value);
            break;

         case 'database' :
            draft.errors.flowLink.database = Utils.isBlank(value);
            break;

         case 'username' :
            draft.errors.flowLink.username = Utils.isBlank(value);
            break;

         case 'password' :
            draft.errors.flowLink.password1 = validationActual(state.orderRoutingChanged, value);
            draft.errors.flowLink.password2 = validationConfirmation(state.orderRoutingChanged, draft.confirm.flowLink.password, value);
            break;

         case 'apikey' :
            draft.errors.flowLink.apikey1 = validationActual(state.orderRoutingChanged, value);
            draft.errors.flowLink.apikey2 = validationConfirmation(state.orderRoutingChanged, draft.confirm.flowLink.apikey, value);
            break;

         default :
            break;

      };
      mergeErrors(draft.errors.flowLink);
   }));

   const confirmPasswordHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.confirm.flowLink.password = value;
      draft.errors.flowLink.password2 = validationConfirmation(state.orderRoutingChanged, value, draft.fields.flowLink.password);
      mergeErrors(draft.errors.flowLink);
   }));

   const confirmAPIKeyHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.confirm.flowLink.apikey = value;
      draft.errors.flowLink.apikey2 = validationConfirmation(state.orderRoutingChanged, value, draft.fields.flowLink.apikey);
      mergeErrors(draft.errors.flowLink);
   }));

   const fields  = state.fields.flowLink;
   const confirm = state.confirm.flowLink;
   const errors  = state.errors.flowLink;

   return (
      <Grid container spacing={2}>
         <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
               <TextField id="hostname" label="Hostname" value={fields.hostname} onChange={(e) => fieldHandler('hostname', e.target.value)} error={errors.hostname} fullWidth required/>
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextField id="database" label="Database" value={fields.database} onChange={(e) => fieldHandler('database', e.target.value)} error={errors.database} fullWidth required/>
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextField id="username" label="Username" value={fields.username} onChange={(e) => fieldHandler('username', e.target.value)} error={errors.username} fullWidth required/>
            </Grid>
         </Grid>
         {
            state.orderRoutingChanged ? (
               <>
                  <Grid item container spacing={2} xs={12}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password1" type="password" label="Password" value={fields.password} onChange={(e) => fieldHandler('password', e.target.value)} error={errors.password1}
                                   fullWidth required/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password2" type="password" label="Confirm Password" value={confirm.password} onChange={(e) => confirmPasswordHandler(e.target.value)} error={errors.password2}
                                   fullWidth required/>
                     </Grid>
                  </Grid>
                  <Grid item container spacing={2} xs={12}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="apikey1" type="password" label="API Key" value={fields.apikey} onChange={(e) => fieldHandler('apikey', e.target.value)} error={errors.apikey1}
                                   fullWidth required/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="apikey2" type="password" label="Confirm API Key" value={confirm.apikey} onChange={(e) => confirmAPIKeyHandler(e.target.value)} error={errors.apikey2}
                                   fullWidth required/>
                     </Grid>
                  </Grid>
               </>
            ) : (
               <>
                  <Grid item container spacing={2} xs={12}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password1" type="password" label="Change Password" value={fields.password} onChange={(e) => fieldHandler('password', e.target.value)} error={errors.password1}
                                   placeholder="--- Keep Current ---" InputLabelProps={{ shrink: true }} fullWidth/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password2" type="password" label="Confirm Change Password" value={confirm.password} onChange={(e) => confirmPasswordHandler(e.target.value)} error={errors.password2}
                                   fullWidth required={fields.password !== ''} disabled={fields.password === ''}/>
                     </Grid>
                  </Grid>
                  <Grid item container spacing={2} xs={12}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="apikey1" type="password" label="Change API Key" value={fields.apikey} onChange={(e) => fieldHandler('apikey', e.target.value)} error={errors.apikey1}
                                   placeholder="--- Keep Current ---" InputLabelProps={{ shrink: true }} fullWidth/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="apikey2" type="password" label="Confirm Change API Key" value={confirm.apikey} onChange={(e) => confirmAPIKeyHandler(e.target.value)} error={errors.apikey2}
                                   fullWidth required={fields.apikey !== ''} disabled={fields.apikey === ''}/>
                     </Grid>
                  </Grid>
               </>
            )
         }
      </Grid>
   );

}

//  --------------------------------------------------------------------------------------------------

function validationActual(changed, entered) {
   return (changed && Utils.isBlank(entered));
}

function validationConfirmation(changed, entered, actual) {
   return ((changed && Utils.isBlank(entered)) || (entered !== actual));
}

function mergeErrors(draft) {
   draft.errors = (draft.hostname || draft.database || draft.username || draft.password1 || draft.password2 || draft.apikey1 || draft.apikey2);
}

//  --------------------------------------------------------------------------------------------------

export function newFlowLinkFields() {
   return {
      hostname : '',
      database : '',
      username : '',
      password : '',
      apikey   : ''
   };
}

//  --------------------------------------------------------------------------------------------------

export function newFlowLinkConfirm() {
   return {
      password : '',
      apikey   : ''
   };
}

//  --------------------------------------------------------------------------------------------------

export function newFlowLinkErrors(fields, confirm, changed) {
   const hostname  = Utils.isBlank(fields.hostname);
   const database  = Utils.isBlank(fields.database);
   const username  = Utils.isBlank(fields.username);
   const password1 = validationActual(changed, fields.password);
   const password2 = validationConfirmation(changed, confirm.password, fields.password);
   const apikey1   = validationActual(changed, fields.apikey);
   const apikey2   = validationConfirmation(changed, confirm.apikey, fields.apikey);
   const errors    = (hostname || database || username || password1 || password2 || apikey1 || apikey2);
   return { hostname, database, username, password1, password2, apikey1, apikey2, errors };
}

//  --------------------------------------------------------------------------------------------------

export function resetFlowLink(draft) {
   draft.fields.flowLink.password  = '';
   draft.confirm.flowLink.password = '';
   draft.fields.flowLink.apikey    = '';
   draft.confirm.flowLink.apikey   = '';
}

//  --------------------------------------------------------------------------------------------------



