import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import MainSelectors from '../../components/MainSelectors';
import DateRangeSelector from '../../components/DateRangeSelector';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';

import OrderList from './OrderList';

//  --------------------------------------------------------------------------------------------------

export default function BranchTab(props) {

   const { config, tab, selected } = props;

   return (selected === tab) && (
      <MainSelectors config={config} allowAll={true} render={(c, b, u) =>
         <DateRangeSelector render={(d) =>
            <Wrapper config={config} client={c} branch={b} user={u} dates={d}/>
         }/>
      }/>
   );

}

//  --------------------------------------------------------------------------------------------------

function Wrapper(props) {

   const { config, client, branch, user, dates } = props;

   const [ orders, setOrders ] = useState(false);

   const [ reload, reloadOrders ] = useToggle();

   useEffect(() => {
      setOrders(false);
      PortalAPI.getOrders({ client, branch, user, dates }).then((response) => {
         setOrders(response);
      }).catch(config.reportAPIError);
   }, [ config, user, branch, client, dates, reload ]);

   return orders ? (
      <OrderList config={config} orders={orders} client={client === '*'} branch={branch === '*'} user={user === '*'} vendor={true} view="client" onReload={reloadOrders}/>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------


