import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PortalAPI from '../../PortalAPI';
import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function GroupDialog(props) {

   const config = useContext(ConfigurationContext);

   const [ state, setState ] = useState(() => initState(props.details));

   const fieldHandler = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const saveButtonHandler = () => saveDetails(config, props, state.fields);

   const cancelButtonHandler = () => props.onClose(false);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.groupId ? "Attribute Group Details" : "Create Attribute Group"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField label="Name" value={state.fields.name} onChange={fieldHandler.name} error={state.errors.name} fullWidth required/>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={state.errors.name}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(details) {
   return {
      fields  : {
      ...details
      },
      errors  : {
         name : Utils.isBlank(details.name)
      }
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {
   const handler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name' : draft.errors.name = Utils.isBlank(value); break;
         default     : break;
      }
   }));
   return {
      name : (e) => handler('name', e.target.value),
   };
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields) {
   if ( props.groupId ) {
      config.showMessage('Updating Attribute Group');
      PortalAPI.putAttributeGroup(props.groupId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Attribute Group');
      PortalAPI.postAttributeGroup(fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

export function newGroupDetails() {
   return {
      version : 0,
      name    : ''
   };
}

//  --------------------------------------------------------------------------------------------------


