import React, { useState, useCallback } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FilesList from './FilesList';

//  --------------------------------------------------------------------------------------------------

export default function FilesDialog(props) {

   const [ state, setState ] = useState(props.files);

   const listHandler = useCallback((idx1, idx2, ref) => setState((state) => produce(state, (draft) => {
      if ( ref ) {
         draft[idx1] = ref;
      } else if ( idx2 > -1 ) {
         const [ removed ] = draft.splice(idx1, 1);
         draft.splice(idx2, 0, removed);
      } else {
         draft.splice(idx1, 1);
      }
   })), [ setState ]);

   const saveButtonHandler = () => props.onClose(state);

   const cancelButtonHandler = () => props.onClose(props.files);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs" fullWidth>
         <DialogTitle>Product Files</DialogTitle>
         <DialogContent dividers={true}>
            <FilesList files={state} onChange={listHandler}/>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary">Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


