import React, { useState } from 'react';

import { produce } from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import PortalAPI from './PortalAPI';
import Utils from './Utils';

//  --------------------------------------------------------------------------------------------------

export default function BillingDialog(props) {

   const { config, details, onClose } = props;

   const [ state, setState ] = useState({
      fields : {
         ...details
      },
      errors : {
         contact : false,
      }
   });

   const fieldHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields.details[field] = value;
      switch ( field ) {
         case 'contact' : draft.errors.name = Utils.isBlank(value); break;
         default        :                                           break;
      }
   }));

   const updateHandler = () => {
      config.showMessage('Updating');
      PortalAPI.putUserBilling(state.fields).then((response) => {
         config.hideMessage();
         onClose();
      }).catch(config.reportAPIError);
   };

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onClose} fullWidth>
         <DialogTitle>Billing Details</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Grid item xs={12} style={{ paddingBottom : '12px' }}>
                     <Typography>
                        Should a vendor of any item in your catalogue require payment in advance, you can use the fields below to preset the billing details associated with the
                        credit / debit card you will be using, this will save you time when making your purchase.  Please note that this portal does <b>NOT</b> hold your actual
                        card details, you will only ever be asked to provide these on a secure payment site at the time of purchase.
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="contact" label="Name" value={fields.details.contact} onChange={(e) => fieldHandler('contact', e.target.value)} error={errors.contact} required fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="phone" label="Phone" value={fields.details.phone} onChange={(e) => fieldHandler('phone', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="email" label="Email" value={fields.details.email} onChange={(e) => fieldHandler('email', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="name" label="Company Name" value={fields.details.name} onChange={(e) => fieldHandler('name', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="street" label="Street" value={fields.details.street} onChange={(e) => fieldHandler('street', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="area" label="Area" value={fields.details.area} onChange={(e) => fieldHandler('area', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="town" label="Town" value={fields.details.town} onChange={(e) => fieldHandler('town', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="county" label="County" value={fields.details.county} onChange={(e) => fieldHandler('county', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField id="postcode" label="Postcode" value={fields.details.postcode} onChange={(e) => fieldHandler('postcode', e.target.value)} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <InputLabel>Country</InputLabel>
                        <Select variant="standard" value={fields.details.country} onChange={(e) => fieldHandler('country', e.target.value)}>
                           { config.countries.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>) }
                        </Select>
                     </FormControl>
                  </Grid>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={updateHandler} color="primary" disabled={state.errors.contact}>Update</Button>
            <Button onClick={onClose} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------
