import React, { useContext, useState, useMemo } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import PortalAPI from '../../PortalAPI';
import { ConfigurationContext } from '../../ConfigurationContext';

import AttributeDialog, { newAttributeDetails } from './AttributeDialog';
import TemplateDialog from './TemplateDialog';

//  --------------------------------------------------------------------------------------------------

export default function AttributesList(props) {

   const config = useContext(ConfigurationContext);

   const { templates, groups, selected, locked, onChange } = props;

   const [ dialog, setDialog ] = useState(false);

   //  Some attribute groups are special cases with special rules
   const tppp   = (selected !== -1 && groups[selected].name === 'TPPP');
   const wizard = (locked && selected !== -1 && groups[selected].name === 'Wizard');

   const attributes = useMemo(() => ((selected === -1) ? [ ] : groups[selected].attributes), [ groups, selected ] );

   const handlers = useMemo(() => createHandlers(config, locked, tppp, wizard, templates, attributes, onChange, setDialog),
                                               [ config, locked, tppp, wizard, templates, attributes, onChange, setDialog ]);

   //  Reparenting workaround as the drag & drop component doesn't play well with the swipable views component (I think it doesn't like the transitions)
   const getItem = (provided, snapshot, rubric) => {
      const idx = rubric.source.index;
      return (
         <ListItem ContainerComponent="li" ContainerProps={{ ref: provided.innerRef }}
                         {...provided.draggableProps} {...provided.dragHandleProps}
                         style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
            <ListItemText><Link color="inherit" href="#" onClick={handlers.editAttribute[idx]}>{attributes[idx].name}</Link></ListItemText>
            {
               !snapshot.isDragging && (
                  <ListItemSecondaryAction>
                     <IconButton onClick={handlers.deleteAttribute[idx]} disabled={wizard}><DeleteIcon/></IconButton>
                  </ListItemSecondaryAction>
               )
            }
         </ListItem>
      );
   };

   return (
      <div>
         <DragDropContext onDragEnd={handlers.dragEnd}>
            <Droppable droppableId="attributesDroppable" renderClone={getItem}>{(provided, snapshot) => (
               <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>
                     { attributes.map((a, i) => <Draggable key={i} draggableId={'e'+i} index={i}>{getItem}</Draggable>) }
                     { provided.placeholder }
                  </List>
               </div>
            )}</Droppable>
         </DragDropContext>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={handlers.addTemplate} disabled={wizard}><PlaylistAddIcon/></IconButton>
            <IconButton color="primary" onClick={handlers.addAttribute} disabled={wizard}><AddCircleIcon/></IconButton>
         </div>
         { dialog }
      </div>
   );

}

//  --------------------------------------------------------------------------------------------------

function createHandlers(config, locked, tppp, wizard, templates, attributes, onChange, setDialog) {

   const dragEndHandler = (result) => {
      if ( result.destination ) {
         onChange(result.source.index, result.destination.index, null);
      }
   };

   const templateHandler = () => {
      const closeHandler = (id) => {
         setDialog(false);
         if ( id ) {
            config.showMessage('Loading');
            PortalAPI.getAttribute(id).then((response) => {
               config.hideMessage();
               response.id         = '';
               response.value      = '';
               response.required   = tppp;
               response.readOnly   = tppp;
               response.visibility = (locked || tppp) ? 'PRIVATE' : 'PUBLIC';
               const closeHandler2 = (attribute) => {
                  setDialog(false);
                  if ( attribute ) {
                     onChange(attributes.length, -1, attribute);
                  }
               };
               setDialog(<AttributeDialog locked={locked} tppp={tppp} wizard={wizard} details={response}
                                          excluding={new Set(attributes.map((a) => a.name))} onClose={closeHandler2}/>);
            }).catch(config.reportAPIError);
         }
      };
      setDialog(<TemplateDialog templates={templates} onClose={closeHandler}/>);
   };

   const addHandler = () => {
      const closeHandler = (attribute) => {
         setDialog(false);
         if ( attribute ) {
            onChange(attributes.length, -1, attribute);
         }
      };
      setDialog(<AttributeDialog locked={locked} tppp={tppp} wizard={wizard} details={newAttributeDetails(locked, tppp)}
                                 excluding={new Set(attributes.map((a) => a.name))} onClose={closeHandler}/>);
   };

   const editHandler = (idx) => {
      const closeHandler = (attribute) => {
         setDialog(false);
         if ( attribute ) {
            onChange(idx, -1, attribute);
         }
      };
      setDialog(<AttributeDialog locked={locked} tppp={tppp} wizard={wizard} details={attributes[idx]}
                                 excluding={new Set(attributes.filter((a, i) => (i !== idx)).map((a) => a.name))} onClose={closeHandler}/>);
   };

   const deleteHandler = (idx) => {
      const confirmationHandler = () => {
         setDialog(false);
         onChange(idx, -1, null);
      };
      const rejectionHandler = () => {
         setDialog(false);
      };
      setDialog(<ConfirmationDialog message="Delete this attribute ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={rejectionHandler}/>);
   };

   return {
      dragEnd         : dragEndHandler,
      addTemplate     : templateHandler,
      addAttribute    : addHandler,
      editAttribute   : attributes.map((o, i) => () => editHandler(i)),
      deleteAttribute : attributes.map((o, i) => () => deleteHandler(i))
   };

}

//  --------------------------------------------------------------------------------------------------

function getItemStyle(isDragging, draggableStyle) {
   const style = { ...draggableStyle };
   if ( isDragging ) {
      style.border     = 'solid 1px black';
      style.background = 'rgb(245, 245, 245)';
   }
   return style;
}

//  --------------------------------------------------------------------------------------------------


