import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';

import PortalAPI from '../PortalAPI';

import Selector, { findOption } from './Selector';

//  --------------------------------------------------------------------------------------------------

export default function MainSelectors(props) {

   const { config, users, allowAll, render } = props;

   const [ state, setState ] = useState({
      clients  : null,
      client   : null,
      clientId : config.client.id,
      branches : null,
      branch   : null,
      branchId : config.branch.id,
      users    : null,
      user     : null,
      userId   : '*'
   });

   useEffect(() => {
      if ( config.user.level === 'S' ) {
         PortalAPI.getClients().then((response) => {
            if ( allowAll && response.length > 1 ) {
               response.unshift({ id : '*', name : '--- All Clients ---' });
            }
            setState((state) => produce(state, (draft) => {
               draft.clients  = response;
               draft.client   = findOption(response, draft.clientId);
               draft.clientId = (draft.client === null) ? '' : draft.client.id;
            }));
         }).catch(config.reportAPIError);
      }
   }, [ config, allowAll, setState ]);

   useEffect(() => {
      if ( (config.user.level === 'C' || state.clients) && state.clientId && state.clientId !== '*' ) {
         PortalAPI.getBranches(state.clientId).then((response) => {
            if ( allowAll && response.length > 1 ) {
               response.unshift({ id : '*', name : '--- All Branches ---' });
            }
            setState((state) => produce(state, (draft) => {
               draft.branches = response;
               draft.branch   = (draft.branchId === '' && response.length === 1) ? response[0] : findOption(response, draft.branchId);
               draft.branchId = (draft.branch === null) ? '' : draft.branch.id;
            }));
         }).catch(config.reportAPIError);
      }
   }, [ config, allowAll, state.clients, state.clientId, setState ]);

   useEffect(() => {
      if ( users && ((config.user.level === 'S' && state.clients && state.clientId && (state.clientId === '*' || (state.branches && state.branchId))) ||
                     (config.user.level === 'C' && state.branches && state.branchId) ||
                     config.user.level === 'B') ) {
         PortalAPI.getUsers(state.clientId, state.branchId, true).then((response) => {
            if ( allowAll && response.length > 1 ) {
               response.unshift({ id : '*', name : '--- All Users ---' });
            }
            setState((state) => produce(state, (draft) => {
               draft.users  = response;
               draft.user   = (response.length === 1) ? response[0] : findOption(response, draft.userId);
               draft.userId = (draft.user === null) ? '' : draft.user.id;
            }));
         }).catch(config.reportAPIError);
      }
   }, [ config, users, allowAll, state.clients, state.clientId, state.branches, state.branchId, setState ]);

   const handleClientSelect = (client) => {
      setState((state) => produce(state, (draft) => {
         draft.client   = client;
         draft.clientId = (client === null) ? '' : client.id;
         draft.branches = null;
         draft.branch   = null;
         draft.branchId = (draft.clientId === '*') ? '*' : '';
         draft.users    = null;
         draft.user     = null;
         draft.userId   = '*';
      }));
   };

   const handleBranchSelect = (branch) => {
      setState((state) => produce(state, (draft) => {
         draft.branch   = branch;
         draft.branchId = (branch === null) ? '' : branch.id;
         draft.users    = null;
         draft.user     = null;
         draft.userId   = '*';
      }));
   }

   const handleUserSelect = (user) => {
      setState((state) => produce(state, (draft) => {
         draft.user   = user;
         draft.userId = (user === null) ? '' : user.id;
      }));
   }

   return (
      <>
         <Grid container spacing={2}>
            {
               (config.user.level === 'S') && (
                  <Grid item xs={12} sm={6}>
                     <Selector options={state.clients} value={state.client} onChange={(event, client) => handleClientSelect(client)} label="Select Client" />
                  </Grid>
               )
            }
            {
               (config.user.level === 'C' || (state.client && state.clientId !== '*')) && (
                  <Grid item xs={12} sm={6}>
                     <Selector options={state.branches} value={state.branch} onChange={(event, branch) => handleBranchSelect(branch)} label="Select Branch" />
                  </Grid>
               )
            }
            {
               (users && ((config.user.level === 'S' && state.client && (state.clientId === '*' || state.branch)) ||
                          (config.user.level === 'C' && state.branch) ||
                          config.user.level === 'B')) && (
                  <Grid item xs={12} sm={6}>
                     <Selector options={state.users} value={state.user} onChange={(event, user) => handleUserSelect(user)} label="Select User" />
                  </Grid>
               )
            }
         </Grid>
         <br/>
         {
            (state.clientId && state.branchId && state.userId && (!users || state.user) && ((config.user.level === 'S' && state.client && (state.clientId === '*' || state.branch)) ||
                                                                                            (config.user.level === 'C' && state.branch) ||
                                                                                            config.user.level === 'B')) && (
               render(state.clientId, state.branchId, state.userId)
            )
         }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------


