import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import QuantityList from './QuantityList';
import PricingADialog, { newPricingADetails } from './PricingADialog';

//  --------------------------------------------------------------------------------------------------

export default function PricingABranchDialog(props) {

   const { vatRates, branches, locked, value, onSave, onCancel } = props;

   const [ state, setState ] = useState(() => initState(value));

   const handlers = useMemo(() => createHandlers(vatRates, locked, setState), [ vatRates, locked, setState ]);

   const saveHandler = () => onSave(state.fields);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Prices</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <InputLabel shrink required={!locked} error={errors.branch}>Branch</InputLabel>
                  <Select value={fields.branch} onChange={handlers.branch} disabled={locked || value.branch !== ''} fullWidth>{
                     branches.map((b) => <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>)
                  }</Select>
               </Grid>
               <Grid item xs={12}>
                  <QuantityList values={state.fields.details} locked={locked}
                                onAdd={handlers.add} onEdit={handlers.edit} onDelete={handlers.delete}
                                description={(p) => `${p.quantity} @ £${p.price} (Cost: £${p.cost})`}/>
               </Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>{
            locked ? (
               <Button onClick={onCancel} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveHandler} color="primary" disabled={errors.branch || errors.details}>Save</Button>
                  <Button onClick={onCancel} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(value) {
   return {
      fields : {
         ...value
      },
      errors : {
         branch  : value.branch === '',
         details : testPricingABranchDetails(value.details)
      },
      dialog : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(vatRates, locked, setState) {

   const branchHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.branch = value;
         draft.errors.branch = value === '';
      }));
   };

   const dialogCloseHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));

   const addHandler = () => {
      const saveHandler = (price) => setState((state) => produce(state, (draft) => {
         draft.fields.details.push(price);
         draft.fields.details.sort((a, b) => (a.quantity - b.quantity));
         draft.errors.details = testPricingABranchDetails(draft.fields.details);
         draft.dialog         = false;
      }));
      setState((state) => {
         const quantities = state.fields.details.map((p) => p.quantity.toString());
         const price = newPricingADetails();
         return produce(state, (draft) => {
            draft.dialog = <PricingADialog quantities={quantities} value={price} locked={locked} onSave={saveHandler} onCancel={dialogCloseHandler}/>
         });
      });
   };

   const editHandler = (idx) => {
      const saveHandler = (price) => setState((state) => produce(state, (draft) => {
         if ( draft.fields.details[idx].quantity === price.quantity ) {
            draft.fields.details[idx] = price;
         } else {
            draft.fields.details[idx] = price;
            draft.fields.details.sort((a, b) => (a.quantity - b.quantity));
         }
         draft.errors.details = testPricingABranchDetails(draft.fields.details);
         draft.dialog         = false;
      }));
      setState((state) => {
         const quantities = state.fields.details.filter((p, i) => (i !== idx)).map((p) => p.quantity.toString());
         const price = state.fields.details[idx];
         return produce(state, (draft) => {
            draft.dialog = <PricingADialog quantities={quantities} value={price} locked={locked} onSave={saveHandler} onCancel={dialogCloseHandler}/>
         });
      });
   };

   const deleteHandler = (idx) => {
      const confirmationHandler = () => setState((state) => produce(state, (draft) => {
         draft.fields.details.splice(idx, 1);
         draft.errors.details = testPricingABranchDetails(draft.fields.details);
         draft.dialog         = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <ConfirmationDialog message="Delete this quantity ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={dialogCloseHandler}/>;
      }));
   };

   return {
      branch : branchHandler,
      add    : addHandler,
      edit   : editHandler,
      delete : deleteHandler
   };

}

//  --------------------------------------------------------------------------------------------------

export function newPricingABranchDetails() {
   return [ ];
}

//  --------------------------------------------------------------------------------------------------

export function testPricingABranchDetails(details) {
   return details.length === 0;
}

//  --------------------------------------------------------------------------------------------------


