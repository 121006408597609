import React, { useState, useReducer, useEffect } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { useIdleTimer } from 'react-idle-timer';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Bar from './Bar';
import EPDQ from './EPDQ';
import Navigation, { NAVIGATION_WIDTH } from './Navigation';
import PortalAPI from './PortalAPI';
import Utils from './Utils';
import { ConfigurationContext } from './ConfigurationContext';

import BasketDialog  from './basket/BasketDialog';

import NoticeBoard   from './pages/noticeboard/NoticeBoard';
import Attributes    from './pages/attributes/Attributes';
import Vendors       from './pages/vendors/Vendors';
import Suppliers     from './pages/suppliers/Suppliers';
import Clients       from './pages/clients/Clients';
import Products      from './pages/products/Products';
import Branches      from './pages/branches/Branches';
import Users         from './pages/users/Users';
import Catalog       from './pages/catalog/Catalog';
import BranchOrders  from './pages/orders/BranchOrders';
import VendorOrders  from './pages/orders/VendorOrders';
import Authorisation from './pages/authorisation/Authorisation';
import Bespoke       from './pages/bespoke/Bespoke';
import Integrations  from './pages/integrations/Integrations';

//  --------------------------------------------------------------------------------------------------

export default function PortalRoot(props) {

   const history = useHistory();

   const theme = useTheme();

   const classes = useStyles(theme);

   const { cognitoID } = props;

   const [ config, setConfig ] = useState(false);

   const [ navState, setNavState ] = useNavigation(theme);

   const [ dialog, setDialog ] = useState(false);

   const [ decoration, setDecoration ] = useState(false);

   const [ basket, setBasket ] = useState(0);

   useIdleTimer({
      timeout  : (15 * 60000),
      onIdle   : () => Utils.signOut(),
      debounce : 500
   });

   useEffect(() => {
      const apiErrorHandler = (error) => {
         if ( error.response ) {
            console.log(error.response);
            const closeHandler = (error.response.status === 401 || error.response.status === 403) ? () => Utils.signOut() : () => setDecoration(false);
            setDecoration({
               render   : () => (<APIErrorDialog title={error.response.statusText + ' (' + error.response.status + ')'} message={error.response.data} onClose={closeHandler}/>),
               progress : 0
            });
         } else if ( error.request ) {
            console.log(error.request);
            setDecoration({
               render   : () => (<APIErrorDialog title="Request error" message="???" onClose={() => setDecoration(false)}/>),
               progress : 0
            });
         } else {
            console.log(error);
            setDecoration({
               render   : () => (<APIErrorDialog title="Error" message={error.message} onClose={() => setDecoration(false)}/>),
               progress : 0
            });
         }
      }
      PortalAPI.getConfig(cognitoID).then((response) => {
         Object.freeze(response.user.name);
         Object.freeze(response.user);
         Object.freeze(response.client);
         Object.freeze(response.branch);
         response.countryIndex = new Map();
         Object.freeze(response.countries.map(c => {
            response.countryIndex.set(c.id, c);
            c.postcode = new RegExp(('^(?:' + c.postcode + ')$'), 'i');
            return Object.freeze(c)
         }));
         Object.freeze(response.countryIndex);
         const filter = (o) => (!o.perm || (o.perm === 'A' && response.user.aAdmin) || (o.perm === 'V' && response.user.vAdmin) || (o.perm === 'S' && response.user.sAdmin)  ||
                                           (o.perm === 'C' && response.user.cAdmin) || (o.perm === 'B' && response.user.bAdmin) || (o.perm === 'U' && response.user.uAdmin)  ||
                                           (o.perm === 'P' && response.user.pAdmin) || (o.perm === 'O' && response.user.order)  || (o.perm === 'R' && response.user.bespoke) ||
                                           (o.perm === '*' && (response.user.aAdmin || response.user.vAdmin || response.user.sAdmin || response.user.cAdmin ||
                                                               response.user.bAdmin || response.user.uAdmin || response.user.pAdmin)) ||
                                           (o.perm === 'VO' && (response.user.level === 'S' || (response.user.level === 'C' && response.client.vendorId))) ||
                                           (o.perm === 'AU' && (response.user.authorise.length > 0)) ||
                                           (o.perm === 'VI' && response.user.iAdmin && (response.user.level === 'S' || (response.user.level === 'C' && response.client.vendorId))));
         response.navigation = Object.freeze(NAVDATA.filter(filter).map((o) => Object.freeze(o)));
         response.theme = createMuiTheme(createPortalTheme(response.client.primary, response.client.secondary));
         response.reportAPIError = apiErrorHandler;
         response.showMessage = (message, determinate) => {
            setDecoration({
               render   : (progress) => (<Message message={message} determinate={determinate} progress={progress}/>),
               progress : 0
            });
            return (progress) => setDecoration((old) => ({
               render   : old.render,
               progress : progress
            }));
         };
         response.hideMessage = () => setDecoration(false);
         response.setBasket = setBasket;
         setConfig(Object.freeze(response));
         setBasket(response.basket);
      }).catch(apiErrorHandler);
   }, [ cognitoID ]);

   const handleSignOutOrContinue = (signOut, authorise) => {
      history.push('/');
      if ( signOut ) {
         Utils.signOut();
      } else if ( authorise ) {
         const idx = config.navigation.findIndex((o) => (o.path === 'authorisation'));
         if ( idx >= 0 ) {
            setNavState(idx);
         }
      } else {
         setDialog(<BasketDialog config={config} onClose={() => setDialog(false)}/>);
      }
   }

   function dispatchPage(path) {
      switch ( path ) {
         case 'noticeboard'        : return <NoticeBoard/>;
         case 'catalog'            : return <Catalog/>;
         case 'authorisation'      : return <Authorisation/>;
         case 'attributes'         : return <Attributes/>;
         case 'vendors'            : return <Vendors/>;
         case 'suppliers'          : return <Suppliers/>;
         case 'clients'            : return <Clients/>;
         case 'products'           : return <Products/>;
         case 'branches'           : return <Branches/>;
         case 'users'              : return <Users/>;
         case 'branchOrders'       : return <BranchOrders/>;
         case 'vendorOrders'       : return <VendorOrders/>;
         case 'bespoke'            : return <Bespoke/>;
         case 'vendorIntegrations' : return <Integrations/>;
         default                   : return 'Unknown Page';
      }
   }

   return (
      config ? (
         <ConfigurationContext.Provider value={config}>
            <MuiThemeProvider theme={config.theme}>
               <div className={classes.root}>
                  <Switch>

                     {/* All the main portal pages */}
                     <Route exact path="/">
                        <Bar title={config.navigation[navState.selected].label} basket={basket} onMenuButtonPressed={() => setNavState('temporary')}/>
                        <Navigation visibility={navState.visibility} selected={navState.selected} onSelection={setNavState} onClose={() => setNavState('hidden')}/>
                        <main className={classes.content}>
                           <Toolbar/>
                           { config.user.message && <SnackbarContent className={classes.snackBar} aria-describedby="client-snackbar" message={config.user.message}/> }
                           { dispatchPage(config.navigation[navState.selected].path) }
                        </main>
                     </Route>

                     {/* All ePDQ responses for end users are handled by this route */}
                     <Route exact path="/epdq">
                        <EPDQ config={config} onClose={(signOut) => handleSignOutOrContinue(signOut, false)}/>
                     </Route>

                     {/* All ePDQ responses for authorisers are handled by this route */}
                     <Route exact path="/epdqauth">
                        <EPDQ config={config} onClose={(signOut) => handleSignOutOrContinue(signOut, true)}/>
                     </Route>

                     {/* --------------------------------------------------------------------------------------------------------
                         Insert more exact routes here to handle redirects from other external sites
                         Use history.push('/')  ( the useHistory() hook ) to return to the main portal pages and normal operation
                         Use location.search    ( the useLocation() hook )  to access all the query parameters from the URL
                         -------------------------------------------------------------------------------------------------------- */}

                     {/* Default route for all unhandled URLs */}
                     <Route path="/">
                        <main className={classes.loading}>
                           <h1>[ 404 ]</h1>
                           <h2>Move along please, nothing to see here.</h2>
                        </main>
                     </Route>

                  </Switch>
                  { dialog }
                  { decoration && decoration.render(decoration.progress) }
               </div>
            </MuiThemeProvider>
         </ConfigurationContext.Provider>
      ) : (
         <main className={classes.loading}>
            <Typography variant="h3" color="primary" align="center">Initialising<br/>Your Portal</Typography>
            <p/>
            <CircularProgress color="secondary"/>
            { decoration && decoration.render(decoration.progress) }
         </main>
      )
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   loading            : {
      minHeight       : '100vh',
      display         : 'flex',
      flexDirection   : 'column',
      justifyContent  : 'center',
      alignItems      : 'center'
   },
   root               : {
      display         : 'flex',
      maxWidth        : 1600,
      flex            : 1
   },
   content            : (theme) => ({
      flexGrow        : 1,
      flex            : 1,
      flexDirection   : 'column',
      padding         : theme.spacing(3),
      [theme.breakpoints.up('md')] : {
         marginLeft   : NAVIGATION_WIDTH
      }
   }),
   snackBar           : {
      backgroundColor : '#ffa000',
      marginBottom    : 15,
      width           : '100%',
      maxWidth        : '100%',
      padding         : '7px 15px'
   },
   other              : (theme) => ({
      flexGrow        : 1,
      flex            : 1,
      flexDirection   : 'column',
      padding         : theme.spacing(3)
   })
});

//  --------------------------------------------------------------------------------------------------

const NAVDATA = [
   // --- Hidden as it was not being used and Flow wanted to go straight to the catalog ---
   // { label : 'Notice Board',    path : 'noticeboard',        perm : false },
   // { label : false,             path : false,                perm : false },
   // -------------------------------------------------------------------------------------
   { label : 'Catalogue',       path : 'catalog',            perm : 'O'   },
   { label : false,             path : false,                perm : 'O'   },
   // -------------------------------------------------------------------------------------
   { label : 'Authorisation',   path : 'authorisation',      perm : 'AU'  },
   { label : false,             path : false,                perm : 'AU'  },
   // -------------------------------------------------------------------------------------
   { label : 'Attributes',      path : 'attributes',         perm : 'A'   },
   { label : 'Vendors',         path : 'vendors',            perm : 'V'   },
   { label : 'Suppliers',       path : 'suppliers',          perm : 'S'   },
   { label : 'Clients',         path : 'clients',            perm : 'C'   },
   { label : 'Branches',        path : 'branches',           perm : 'B'   },
   { label : 'Users',           path : 'users',              perm : 'U'   },
   { label : 'Products',        path : 'products',           perm : 'P'   },
   { label : false,             path : false,                perm : '*'   },
   // -------------------------------------------------------------------------------------
   { label : 'Orders Placed',   path : 'branchOrders',       perm : false },
   { label : 'Orders Received', path : 'vendorOrders',       perm : 'VO'  },
   // -------------------------------------------------------------------------------------
   { label : false,             path : false,                perm : 'R'   },
   { label : 'Request Product', path : 'bespoke',            perm : 'R'   },
   // -------------------------------------------------------------------------------------
   { label : false,             path : false,                perm : 'VI'  },
   { label : 'Integrations',    path : 'vendorIntegrations', perm : 'VI'  }
];

//  --------------------------------------------------------------------------------------------------

function useNavigation(theme) {
   const wideEnough = useMediaQuery(theme.breakpoints.up('md'));
   const [ state, dispatch ] = useReducer(navigationReducer, {
      visibility : wideEnough ? 'permanent' : 'hidden',
      selected   : 0
   });
   useEffect(() => dispatch(wideEnough ? 'permanent' : 'hidden'), [ wideEnough ]);
   return [ state, dispatch ];
}

function navigationReducer(state, action) {
   return (action === 'hidden' || action === 'temporary' || action === 'permanent') ? {
      visibility : action,
      selected   : state.selected
   } : {
      visibility : (state.visibility === 'temporary') ? 'hidden' : state.visibility,
      selected   : action
   };
}

//  --------------------------------------------------------------------------------------------------

function createPortalTheme(primary, secondary) {

   const format = (c) => ('rgba(' + c.r  + ',' + c.g  + ',' + c.b  + ',1)');

   const shade = (c, a) => ({
      r : Math.min(Math.floor(c.r * a), 255),
      g : Math.min(Math.floor(c.g * a), 255),
      b : Math.min(Math.floor(c.b * a), 255)
   });

   const getColours = (hex) => {
      const main = {
         r : parseInt(hex.substring(1, 3), 16),
         g : parseInt(hex.substring(3, 5), 16),
         b : parseInt(hex.substring(5, 7), 16)
      };
      return {
         main         : format(main),
         light        : format(shade(main, 1.20)),
         dark         : format(shade(main, 0.80)),
         contrastText : '#fff'
      };
   };

   return {
      typography         : {
         useNextVariants : true,
         fontFamily      : [ 'Nunito', 'sans-serif' ]
      },
      palette            : {
         common          : {
            black        : '#000',
            white        : '#fff'
         },
         background      : {
            paper        : '#fff',
            default      : '#fafafa'
         },
         primary         : getColours(primary),
         secondary       : getColours(secondary),
         error           : {
            main         : '#f44336',
            light        : '#e57373',
            dark         : '#d32f2f',
            contrastText : '#fff'
         },
         text            : {
            primary      : 'rgba(0, 0, 0, 0.87)',
            secondary    : 'rgba(0, 0, 0, 0.54)',
            disabled     : 'rgba(0, 0, 0, 0.38)',
            hint         : 'rgba(0, 0, 0, 0.38)'
         }
      },
      logos              : {
         default         : '',
         inverted        : ''
      }
   }
}

//  --------------------------------------------------------------------------------------------------

function APIErrorDialog(props) {

   const { title, message, onClose } = props;

   return (
      <Dialog open disableBackdropClick onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
         <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
         <DialogContent dividers={true}>
            <DialogContentText id="alert-dialog-description">{
               message.split('\n').map((l, i) => (i === 0) ? <React.Fragment key={`l${i}`}>{l}</React.Fragment> : <React.Fragment key={`l${i}`}><br/>{l}</React.Fragment>)
            }</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>OK</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function Message(props) {

   const { message, determinate, progress } = props;

   const theme = useTheme();

   const classes = useMessageStyles(theme);

   return (
      <Backdrop className={classes.backdrop} open={true}>
         <Paper className={classes.paper} elevation={1}>
            <div>
               <Typography variant="h4" color="primary">{message}</Typography>
               <div className={classes.progress}>{
                  determinate ? (
                     <CircularProgress color="primary" variant="static" value={progress}/>
                  ) : (
                     <CircularProgress color="secondary"/>
                  )
               }</div>
            </div>
         </Paper>
      </Backdrop>
   );
}

const useMessageStyles = makeStyles({
   backdrop     : {
      zIndex    : 9999,
      color     : '#fff'
   },
   paper        : {
      textAlign : 'center',
      padding   : '24px'
   },
   progress    : {
      marginTop : '24px'
   }
});

//  --------------------------------------------------------------------------------------------------






