import React, { useState } from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import MarkupDialog from './MarkupDialog';

//  --------------------------------------------------------------------------------------------------

export default function WizardMarkups(props) {

   const { branches, value, onChange } = props;

   const [ dialog, setDialog ] = useState(false);

   const saveHandler = (data) => {
      setDialog(false);
      onChange(produce(value, (draft) => {
         draft.set(data.branch, data.markup);
      }));
   };

   const deleteHandler = (branch) => {
      setDialog(false);
      onChange(produce(value, (draft) => {
         draft.delete(branch);
      }));
   };

   const closeDialogHandler = () => setDialog(false);

   const addMarkupHandler = () => {
      const s = branches.filter((s) => !value.has(s.id));
      const v = { branch : '', markup : '0.0' };
      setDialog(<MarkupDialog branches={s} value={v} onSave={saveHandler} onCancel={closeDialogHandler}/>);
   };

   const editMarkupHandler = (branch) => {
      const s = (branch === 'DEFAULT') ? [ { id : branch, name : branch } ] : branches;
      const v = { branch : branch, markup : value.get(branch) };
      setDialog(<MarkupDialog branches={s} value={v} onSave={saveHandler} onCancel={closeDialogHandler}/>);
   };

   const deleteMarkupHandler = (branch) => {
      setDialog(<ConfirmationDialog message="Delete this branch markup ?" confirmationButton="Delete" onConfirmation={() => deleteHandler(branch)} onClose={closeDialogHandler}/>);
   };

   return (
      <>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <Typography>Please specify the % markup to be added to the suppliers prices for each branch.</Typography>
            </Grid>
            <Grid item xs={12}>
               <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>
                  <ListItem key="DEFAULT">
                     <ListItemText><Link color="inherit" href="#" onClick={() => editMarkupHandler('DEFAULT')}>DEFAULT ({value.get('DEFAULT')}%)</Link></ListItemText>
                  </ListItem>
                  {
                     branches.filter((s) => value.has(s.id)).map((s) => (
                        <ListItem key={s.id}>
                           <ListItemText><Link color="inherit" href="#" onClick={() => editMarkupHandler(s.id)}>{s.name} ({value.get(s.id)}%)</Link></ListItemText>
                           <ListItemSecondaryAction>
                              <IconButton onClick={() => deleteMarkupHandler(s.id)}><DeleteIcon/></IconButton>
                           </ListItemSecondaryAction>
                        </ListItem>
                     ))
                  }
               </List>
               <div style={{ textAlign : 'center' }}>
                  <IconButton color="primary" onClick={addMarkupHandler} disabled={value.size === (branches.length + 1)}><AddCircleIcon/></IconButton>
               </div>
            </Grid>
         </Grid>
         {dialog}
      </>
   );

}

//  --------------------------------------------------------------------------------------------------




