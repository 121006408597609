import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

//  --------------------------------------------------------------------------------------------------

export default function SupplierIntegrationsDialog(props) {

   const [ selected, setSelected ] = useState(() => new Set(props.selected));

   const selectionHandler = (id) => setSelected((state) => produce(state, (draft) => {
      if ( state.has(id) ) {
         draft.delete(id);
      } else {
         draft.add(id);
      }
   }));

   const saveButtonHandler = () => props.onClose(Array.from(selected));

   const cancelButtonHandler = () => props.onClose(props.selected);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs" fullWidth>
         <DialogTitle>Supplier Integrations</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
                     props.integrations.map((i) => {
                        const isSelected = selected.has(i.id);
                        return (
                           <CustomListItem key={i.id} style={ isSelected ? { background : 'rgb(255, 0, 0)' } : { } }>
                              <ListItemText>{i.description}</ListItemText>
                              <ListItemSecondaryAction><IconButton onClick={() => selectionHandler(i.id)}>{ isSelected ? <RemoveIcon/> : <AddIcon/> }</IconButton></ListItemSecondaryAction>
                           </CustomListItem>
                        );
                     })
                  }</List>
               </Grid>
               <Grid item xs={12}>
                  <Typography>
                     Please Note: This only controls whether a vendor can start using an integration,
                     once they have configured a supplier with one, disabling it here has no effect.
                  </Typography>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary">Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const CustomListItem = withStyles({
   secondaryAction : {
      paddingRight : 52
   }
})(ListItem);

//  --------------------------------------------------------------------------------------------------

