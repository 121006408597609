import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Utils from '../Utils';
import { ConfigurationContext } from '../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function ImageDialog(props) {

   const { role, markdown, details, onSave, onCancel } = props;

   const config = useContext(ConfigurationContext);

   const classes = useStyles();

   const [ state, setState ] = useState({
      fields   : {
         ...(details)
      },
      errors   : {
         title : Utils.isBlank(details.title),
         id    : Utils.isBlank(details.id),
      },
   });

   const handlers = useMemo(() => {

      const handleTextField = (field, value) => setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         draft.errors[field] = Utils.isBlank(value);
      }));

      const handleImage = (file) => {
         setState((state) => produce(state, (draft) => {
            draft.fields.id  = '';
            draft.fields.url = '';
            draft.errors.id  = true;
         }));
         Utils.uploadFile(config, role, file, (id, data) => setState((state) => produce(state, (draft) => {
            draft.fields.id  = id;
            draft.fields.url = data.url;
            draft.errors.id  = false;
         })));
      };

      return {
         title : (e) => handleTextField('title', e.target.value),
         alt   : (e) => handleTextField('alt',   e.target.value),
         image : (e) => handleImage(e.target.files[0])
      };

   }, [ role, config, setState ]);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel}>
         <DialogTitle>{details.id ? 'Image Details' : 'Add Image'}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <InputLabel error={errors.id} required shrink>Image</InputLabel>
                  {
                     fields.id ? (
                        <div className={classes.imageContainer}>
                           <img className={classes.image} src={fields.url} alt=""/>
                           { fields.urlRaw && <a href={fields.urlRaw} target="_blank" rel="noopener noreferrer"><Button variant="contained" color="primary">Original Image</Button></a> }
                        </div>
                     ) : (
                        <>
                           <div>
                              <input id="image" type="file" onChange={handlers.image}/>
                           </div>
                           <br/>
                           <Typography variant="body2">Supported formats:<br/>BMP, GIF, JPEG, PNG, &amp; TIFF.</Typography>
                        </>
                     )
                  }
               </Grid>
               <Grid item xs={12}>
                  <TextField id="title" label="Title" value={fields.title} error={errors.title} onChange={handlers.title} required fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="alt" label="Alt Text" value={fields.alt} onChange={handlers.alt} fullWidth/>
               </Grid>
               {
                  (markdown && fields.id !== '') && (
                     <Grid item xs={12}>
                        <br/>
                        <Typography variant="h6">Use the following markup to reference the image:</Typography>
                        <Typography variant="body1" color="secondary"><b>![{fields.id}]</b></Typography>
                     </Grid>
                  )
               }
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => onSave(fields)} color="primary" disabled={errors.title || errors.id}>save</Button>
            <Button onClick={() => onCancel(false)} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   imageContainer : {
      width       : 300,
      margin      : '0 0'
   },
   image          : {
      maxWidth    : '100%',
   }
});

//  --------------------------------------------------------------------------------------------------

export function newImageDetails() {
   return {
      title : '',
      alt   : '',
      id    : '',
      url   : ''
   };
}

//  --------------------------------------------------------------------------------------------------


