import { API } from 'aws-amplify';

import axios from 'axios';

import produce from 'immer';

import Utils from './Utils';

//  --------------------------------------------------------------------------------------------------

const PORTAL = 'Portal';

const api = 83;

//  --------------------------------------------------------------------------------------------------

export default class PortalAPI {

   //  -----

   static getConfig(id) {
      return API.get(PORTAL, 'config', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, id }
      }).then((response) => {
         response.user.name = deserialiseName(response.user.name);
         return response;
      });
   }

   //  -----

   static getVendors(shared) {
      return API.get(PORTAL, 'vendors', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api }
      });
   }

   static getVendor(vendor) {
      const params = { api };
      if ( vendor ) {
         params.vendor = vendor;
      }
      return API.get(PORTAL, 'vendor', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      }).then((response) => {
         response.main.country    = deserialiseCountry(response.main.country);
         response.main.contact    = deserialiseName(response.main.contact);
         response.billing.country = deserialiseCountry(response.billing.country);
         response.billing.contact = deserialiseName(response.billing.contact);
         return response;
      });
   }

   static postVendor(details) {
      return API.post(PORTAL, 'vendor', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : vendorSerialisation(details)
      });
   }

   static putVendor(vendor, details) {
      return API.put(PORTAL, 'vendor', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, vendor },
         body                     : vendorSerialisation(details)
      });
   }

   //  -----

   static getSuppliers(vendor, wizard) {
      const params = { api, vendor };
      if ( wizard ) {
         params.wizard = true;
      }
      return API.get(PORTAL, 'suppliers', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   static getSupplier(vendor, supplier) {
      const params = { api, vendor };
      if ( supplier ) {
         params.supplier = supplier;
      }
      return API.get(PORTAL, 'supplier', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      }).then((response) => {
         const zones = new Map();
         for ( const [ k, v ] of Object.entries(response.surcharges.zones) ) {
            zones.set(deserialiseCountry(k), new Map(Object.entries(v)));
         }
         response.surcharges.zones = zones;
         response.main.country     = deserialiseCountry(response.main.country);
         response.main.contact     = deserialiseName(response.main.contact);
         return response;
      });
   }

   static postSupplier(vendor, details) {
      return API.post(PORTAL, 'supplier', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, vendor },
         body                     : supplierSerialisation(details)
      });
   }

   static putSupplier(vendor, supplier, details) {
      return API.put(PORTAL, 'supplier', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, vendor, supplier },
         body                     : supplierSerialisation(details)
      });
   }

   //  -----

   static getClients(mode) {
      const params = { api };
      if ( mode ) {
         params.mode = mode;
      }
      return API.get(PORTAL, 'clients', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   static getClient(client) {
      return API.get(PORTAL, 'client', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client }
      });
   }

   static postClient(details) {
      return API.post(PORTAL, 'client', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : clientSerialisation(details)
      });
   }

   static putClient(client, details) {
      return API.put(PORTAL, 'client', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client },
         body                     : clientSerialisation(details)
      });
   }

   //  -----

   static getBranches(client) {
      return API.get(PORTAL, 'branches', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client }
      });
   }

   static getBranch(client, branch) {
      return API.get(PORTAL, 'branch', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, branch }
      }).then((response) => {
         response.main.country     = deserialiseCountry(response.main.country);
         response.main.contact     = deserialiseName(response.main.contact);
         response.billing.country  = deserialiseCountry(response.billing.country);
         response.billing.contact  = deserialiseName(response.billing.contact);
         response.shipping.country = deserialiseCountry(response.shipping.country);
         response.shipping.contact = deserialiseName(response.shipping.contact);
         return response;
      });
   }

   static postBranch(client, details) {
      return API.post(PORTAL, 'branch', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client },
         body                     : branchSerialisation(details)
      });
   }

   static putBranch(client, branch, details) {
      return API.put(PORTAL, 'branch', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, branch },
         body                     : branchSerialisation(details)
      });
   }

   //  -----

   static getUsers(client, branch, lookup) {
      const params = { api };
      if ( client && client !== '*' ) {
         params.client = client;
         if ( branch && branch !== '*' ) {
            params.branch = branch;
         }
      }
      if ( lookup ) {
         params.lookup = true;
      }
      return API.get(PORTAL, 'users', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   static getNewUser(client, branch) {
      return API.get(PORTAL, 'user', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, branch }
      }).then((response) => {
         response.name = deserialiseName(response.name);
         if ( response.clientId === '*' ) response.clientId = false;
         if ( response.branchId === '*' ) response.branchId = false;
         return response;
      });
   }

   static getUser(user) {
      return API.get(PORTAL, 'user', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, user }
      }).then((response) => {
         response.name = deserialiseName(response.name);
         return response;
      });
   }

   static postUser(details) {
      return API.post(PORTAL, 'user', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : userSerialisation(details)
      });
   }

   static putUser(user, details) {
      return API.put(PORTAL, 'user', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, user },
         body                     : userSerialisation(details)
      });
   }

   static putUserState(user, state) {
      return API.put(PORTAL, 'userState', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, user },
         body                     : { state }
      });
   }

   static putUserReset(user) {
      return API.put(PORTAL, 'userReset', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, user },
      });
   }

   static putUserBranch(branch) {
      return API.put(PORTAL, 'userBranch', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : { branch }
      });
   }

   static getUserBilling() {
      return API.get(PORTAL, 'userBilling', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api }
      }).then((response) => {
         //  [ In the users billing details the name is always a plain text field ]
         response.details.country = deserialiseCountry(response.details.country);
         return response;
      });
   }

   static putUserBilling(fields) {
      const details = produce(fields, (draft) => {
         //  [ In the users billing details the name is always a plain text field ]
         draft.details.country = serialiseCountry(draft.details.country);
      });
      return API.put(PORTAL, 'userBilling', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : details
      });
   }

   //  -----

   static getNotices(client, dates) {
      return API.get(PORTAL, 'notices', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : {
            api,
            client,
            start : Utils.formatStartDate(dates.start),
            end   : Utils.formatEndDate(dates.end)
         }
      });
   }

   static getNotice(client, notice) {
      return API.get(PORTAL, 'notice', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, notice }
      });
   }

   static postNotice(client, details) {
      return API.post(PORTAL, 'notice', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client },
         body                     : noticeSerialisation(details)
      });
   }

   static putNotice(client, notice, details) {
      return API.put(PORTAL, 'notice', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, notice },
         body                     : noticeSerialisation(details)
      });
   }

   static deleteNotice(client, notice, version) {
      return API.del(PORTAL, 'notice', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, notice, version }
      });
   }

   //  -----

   static getCatalogs(client, branch) {
      return API.get(PORTAL, 'catalogs', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, branch }
      });
   }

   static getCatalogTabs(client, branch, catalog) {
      return API.get(PORTAL, 'catalogTabs', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, branch, catalog }
      });
   }

   static getCatalogPage(client, branch, catalog, tab) {
      const params = { api, client, branch, catalog };
      if ( tab !== '*' ) {
         params.tab = tab;
      }
      return API.get(PORTAL, 'catalogPage', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   //  -----

   static getAttributeGroup(group) {
      return API.get(PORTAL, 'attributeGroup', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, group }
      });
   }

   static postAttributeGroup(details) {
      return API.post(PORTAL, 'attributeGroup', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : details
      });
   }

   static putAttributeGroup(group, details) {
      return API.put(PORTAL, 'attributeGroup', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, group },
         body                     : details
      });
   }

   static deleteAttributeGroup(group, version) {
      return API.del(PORTAL, 'attributeGroup', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, group, version }
      });
   }

   //  -----

   static getAttributes() {
      return API.get(PORTAL, 'attributes', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api }
      });
   }

   static getAttribute(attribute) {
      return API.get(PORTAL, 'attribute', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, attribute }
      });
   }

   static postAttribute(details) {
      return API.post(PORTAL, 'attribute', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : details
      });
   }

   static putAttribute(attribute, details) {
      return API.put(PORTAL, 'attribute', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, attribute },
         body                     : details
      });
   }

   static deleteAttribute(attribute, version) {
      return API.del(PORTAL, 'attribute', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, attribute, version }
      });
   }

   //  -----

   static getTab(client, tab) {
      return API.get(PORTAL, 'tab', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, tab }
      });
   }

   static postTab(client, details) {
      return API.post(PORTAL, 'tab', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client },
         body                     : details
      });
   }

   static putTab(client, tab, details) {
      return API.put(PORTAL, 'tab', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, tab },
         body                     : details
      });
   }

   static deleteTab(client, tab, version) {
      return API.del(PORTAL, 'tab', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, tab, version }
      });
   }

   //  -----

   static getProducts(client) {
      return API.get(PORTAL, 'products', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client }
      });
   }

   static getProduct(client, product) {
      return API.get(PORTAL, 'product', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product }
      }).then(preProcessProductInfo);
   }

   static postProduct(client, details) {
      return API.post(PORTAL, 'product', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client },
         body                     : productSerialisation(details)
      });
   }

   static putProduct(client, product, details) {
      return API.put(PORTAL, 'product', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product },
         body                     : productSerialisation(details)
      });
   }

   static deleteProduct(client, product, version) {
      return API.del(PORTAL, 'product', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product, version }
      });
   }

   //  -----

   static putProductStatus(client, product, version, enabled) {
      return API.put(PORTAL, 'productStatus', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product },
         body                     : { version, enabled }
      });
   }

   //  -----

   static getProductPricing(client, product) {
      return API.get(PORTAL, 'productPricing', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product }
      }).then(preProcessProductInfo);
   }

   static putProductPricing(client, product, details) {
      return API.put(PORTAL, 'productPricing', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product },
         body                     : details
      });
   }

   //  -----

   static getProductLock(client, product) {
      return API.get(PORTAL, 'productLock', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product }
      });
   }

   static putProductLock(client, product, details) {
      return API.put(PORTAL, 'productLock', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product },
         body                     : details
      });
   }

   static deleteProductLock(client, product, version) {
      return API.del(PORTAL, 'productLock', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product, version }
      });
   }

   //  -----

   static postProductClone(client, target, products) {
      return API.post(PORTAL, 'productClone', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, target },
         body                     : { products }
      });
   }

   //  -----

   static getWizardMigration(client, product) {
      return API.get(PORTAL, 'wizardMigration', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, product }
      });
   }

   static getWizardProducts(vendor, supplier) {
      return API.get(PORTAL, 'wizardProducts', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, vendor, supplier }
      });
   }

   static getWizardAttributes(vendor, supplier, tag, ref) {
      return API.get(PORTAL, 'wizardAttributes', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, vendor, supplier, tag, ref }
      });
   }

   static getWizardQuantities(vendor, supplier, tag, ref) {
      return API.get(PORTAL, 'wizardQuantities', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, vendor, supplier, tag, ref }
      });
   }

   //  Used by the wizard to get the edit details for non-catalog and enquire-only products
   static getWizardProduct(client, vendor, supplier, enquiry, name, product, version) {
      const params = { api, client, vendor, supplier, enquiry };
      if ( name ) {
         params.name = name;
      }
      if ( product ) {
         params.product = product;
         params.version = version;
      }
      return API.get(PORTAL, 'wizardProduct', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      }).then(preProcessProductInfo);
   }

   //  Used by the wizard to get the edit details for a full catalog based product
   static postWizardProduct(client, vendor, supplier, tag, ref, lock, quantities, markups, rounding, product, version) {
      const params = { api, client, vendor, supplier, tag, ref, lock };
      if ( product ) {
         params.product = product;
      }
      return API.post(PORTAL, 'wizardProduct', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params,
         body                     : {
            version    : version,
            quantities : quantities,
            markups    : markups,
            rounding   : rounding
         }
      }).then(preProcessProductInfo);;
   }

   //  -----

   static getBaskets(branch) {
      const params = { api };
      if ( branch ) {
         params.branch = branch;
      }
      return API.get(PORTAL, 'baskets', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   static getBasket(basket) {
      const params = { api };
      if ( basket ) {
         params.basket = basket;
      }
      return API.get(PORTAL, 'basket', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   static postBasket() {
      return API.post(PORTAL, 'basket', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api }
      });
   }

   static deleteBasket(basket) {
      const params = { api };
      if ( basket ) {
         params.basket = basket;
      }
      return API.del(PORTAL, 'basket', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      });
   }

   static getBasketHistory(basket) {
      return API.get(PORTAL, 'basketHistory', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, basket }
      });
   }

   static putBasketAuthorisation(basket, authorise, comment) {
      return API.put(PORTAL, 'basketAuthorisation', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, basket },
         body                     : { authorise, comment }
      });
   }

   //  -----

   static getBasketItemPricing(client, branch, product, country, postcode) {
      return API.get(PORTAL, 'basketItemPricing', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : {
            api                   : api,
            client                : client,
            branch                : branch,
            product               : product,
            country               : serialiseCountry(country),
            postcode              : postcode
         }
      });
   }

   static getBasketItemFor(client, branch, product) {
      return API.get(PORTAL, 'basketItemFor', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, client, branch, product }
      }).then((response) => {
         if ( response.addresses ) {
            Object.values(response.addresses).forEach((a) => {
               a.country = deserialiseCountry(a.country);
               a.contact = deserialiseName(a.contact);
            });
         }
         return response;
      });
   }

   static getBasketItem(item) {
      return API.get(PORTAL, 'basketItem', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, item }
      }).then((response) => {
         Object.values(response.addresses).forEach((a) => {
            a.country = deserialiseCountry(a.country);
            a.contact = deserialiseName(a.contact);
         });
         return response;
      });
   }

   static postBasketItem(details) {
      return API.post(PORTAL, 'basketItem', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : basketItemSerialisation(details)
      });
   }

   static putBasketItem(item, details) {
      return API.put(PORTAL, 'basketItem', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, item },
         body                     : basketItemSerialisation(details)
      });
   }

   static deleteBasketItem(item) {
      return API.del(PORTAL, 'basketItem', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, item }
      });
   }

   //  -----

   //  NOTE: The wire format used by this API call interns all the string values into an array
   //        to save bandwidth.  This function reverses that for ease of use within the code.
   static getOrders(details) {
      const params = { api };
      if ( details.query ) {
         params.query = details.query;
         params.view  = details.view;
      } else {
         params.start = Utils.formatStartDate(details.dates.start);
         params.end   = Utils.formatEndDate(details.dates.end);
         if ( details.user && details.user !== '*' ) {
            params.user = details.user;
         }
         if ( details.branch && details.branch !== '*' ) {
            params.branch = details.branch;
         } else if ( details.client && details.client !== '*' ) {
            params.client = details.client;
         }
         if ( details.vendor ) {
            params.vendor = details.vendor;
         }
      }
      const expand = (value) => {
         return {
            id   : Utils.takeUUIDPrefix(value),
            name : Utils.dropUUIDPrefix(value)
         };
      };
      return API.get(PORTAL, 'orders', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : params
      }).then((response) => {
         const { names, orders } = response;
         return orders.map((o) => {
            const order = {
               id        : o.id,
               timestamp : names[o.timestamp],
               vendor    : expand(names[o.vendor]),
               client    : expand(names[o.client]),
               branch    : expand(names[o.branch]),
               user      : expand(names[o.user]),
               items     : o.items,
               total     : o.total,
               status    : o.status.map((s) => { return { status : names[s.status], count : s.count }; }),
            };
            //  Paid is tri-state: undefined, false, and true.
            if ( o.hasOwnProperty('paid') ) {
               order.paid = o.paid;
            }
            return order;
         });
      });
   }

   static getOrder(order, view) {
      return API.get(PORTAL, 'order', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, order, view }
      });
   }

   static putOrder(order) {
      return API.put(PORTAL, 'order', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, order }
      });
   }

   static putOrderStatus(order, status) {
      return API.put(PORTAL, 'orderStatus', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, order },
         body                     : status
      });
   }

   static getOrderHistory(order) {
      return API.get(PORTAL, 'orderHistory', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, order }
      });
   }

   //  -----

   static postEnquiry(details) {
      return API.post(PORTAL, 'enquiry', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : details
      });
   }

   //  -----

   static getIntegrations() {
      return API.get(PORTAL, 'integrations', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api }
      });
   }

   static putIntegrations(details) {
      return API.put(PORTAL, 'integrations', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : details
      });
   }

   //  -----

   static postEPDQ(basket, details) {
      return API.post(PORTAL, 'epdq', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, basket },
         body                     : details
      });
   }

   static putEPDQ(params) {
      return API.put(PORTAL, 'epdq', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : { params }
      });
   }

   static deleteEPDQ(basket) {
      return API.del(PORTAL, 'epdq', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api, basket }
      });
   }

   //  -----

   static uploadStart(filename, role) {
      return API.post(PORTAL, 'uploadStart', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : { filename, role }
      });
   }

   static uploadFile(file, url, contentType, contentDisposition, setProgress) {
      return axios.put(url, file, {
         response                 : false,
         headers                  : {
            'Content-Type'        : contentType,
            'Content-Disposition' : contentDisposition
         },
         queryStringParameters    : { api },
         onUploadProgress         : (event) => setProgress(Math.round((event.loaded * 100) / event.total))
      });
   }

   static uploadComplete(id) {
      return API.post(PORTAL, 'uploadComplete', {
         response                 : false,
         headers                  : { },
         queryStringParameters    : { api },
         body                     : { id }
      });
   }

}

//  --------------------------------------------------------------------------------------------------

function vendorSerialisation(details) {
   return produce(details, (draft) => {
      draft.main.country    = serialiseCountry(draft.main.country);
      draft.main.contact    = serialiseName(draft.main.contact);
      draft.billing.country = serialiseCountry(draft.billing.country);
      draft.billing.contact = serialiseName(draft.billing.contact);
   });
}

function supplierSerialisation(details) {
   return produce(details, (draft) => {
      const zones = { };
      for ( const [ k, v ] of draft.surcharges.zones.entries() ) {
         zones[serialiseCountry(k)] = Object.fromEntries(v);
      }
      draft.surcharges.zones = zones;
      draft.main.country     = serialiseCountry(draft.main.country);
      draft.main.contact     = serialiseName(draft.main.contact);
   });
}

function clientSerialisation(client) {
   return produce(client, (draft) => {
      delete draft.logo.url;
   });
}

function branchSerialisation(details) {
   return produce(details, (draft) => {
      draft.main.country     = serialiseCountry(draft.main.country);
      draft.main.contact     = serialiseName(draft.main.contact);
      draft.billing.country  = serialiseCountry(draft.billing.country);
      draft.billing.contact  = serialiseName(draft.billing.contact);
      draft.shipping.country = serialiseCountry(draft.shipping.country);
      draft.shipping.contact = serialiseName(draft.shipping.contact);
   });
}

function userSerialisation(details) {
   return produce(details, (draft) => {
      draft.name = serialiseName(draft.name);
   });
}

function noticeSerialisation(notice) {
   return produce(notice, (draft) => {
      delete draft.banner.url;
      draft.images.forEach((i) => {
         delete i.url;
      });
   });
}

function productSerialisation(product) {
   return produce(product, (draft) => {
      draft.images.forEach((i) => {
         delete i.url;
         delete i.urlRaw;
      });
      draft.files.forEach((f) => {
         delete f.url;
      });
   });
}

function basketItemSerialisation(details) {
   return produce(details, (draft) => {
      if ( draft.address ) {
         draft.address.country = serialiseCountry(draft.address.country);
         draft.address.contact = serialiseName(draft.address.contact);
      }
   });
}

//  -----------------------------------------------------------------------------------------------

function deserialiseCountry(country) {
   //  This is somewhat problematic as all the forms use a combo box to select the appropriate value and therefore cannot display the old textual value in any way
   //  to correct, so we simply return an empty string which will force the user to select the appropriate value as all the forms where this can occur will have
   //  the country field marked as being required - and all the plain text values should either be empty or reference the UK in some form.
   return country.startsWith('\u001f') ? country.substring(1) : '';
}

function serialiseCountry(country) {
   return ('\u001f' + country);
}

//  -----------------------------------------------------------------------------------------------

function deserialiseName(name) {
   if ( name.startsWith('\u001f') ) {
      const parts = name.substring(1).split('\u001f');
      return {
         surname      : parts[0],
         forename     : parts[1],
         surnameFirst : (parts[2] === 'Y')
      };
   }
   return {
      surname      : '',
      forename     : name,
      surnameFirst : false
   };
}

function serialiseName(name) {
   return ('\u001f' + name.surname + '\u001f' + name.forename + '\u001f' + (name.surnameFirst ? 'Y' : 'N'));
}

//  --------------------------------------------------------------------------------------------------

function preProcessProductInfo(info) {
   return produce(info, (draft) => {
      if ( info.attributes ) {
         draft.attributes = info.attributes.flatMap((g) => g.attributes.map((a) => {
            return {
               group : g.name,
               id    : a.id,
               name  : a.name
            };
         }));
      }
   });
}

//  --------------------------------------------------------------------------------------------------

