import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

//  --------------------------------------------------------------------------------------------------

export default function Selector(props) {

   const { options, value, onChange, label, helperText, error, required, disableOption } = props;

   return (
      options ? (
         <Autocomplete options={options}
                       value={value}
                       onChange={onChange}
                       getOptionLabel={(option) => option.name}
                       getOptionDisabled={disableOption}
                       renderInput={(params) => <TextField label={label} helperText={helperText} error={error} required={required} fullWidth {...params} />}
                       filterOptions={selectorFilterOptions} />
      ) : (
         <CircularProgress color="secondary"/>
      )
   );
}

//  --------------------------------------------------------------------------------------------------

const selectorFilterOptions = createFilterOptions({
   stringify: (option) => option.name
});

//  --------------------------------------------------------------------------------------------------

export function findOption(options, id) {
   if ( id ) {
      const result = options.find((option) => (option.id === id));
      if ( result ) {
         return result;
      }
   }
   return null;
}

//  --------------------------------------------------------------------------------------------------



