import React, { useContext, useState, useMemo } from 'react';

import SwipeableViews from 'react-swipeable-views';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import InfoIcon from '@material-ui/icons/Info';

import PortalAPI from '../../PortalAPI';
import Utils, { TAB_STYLE } from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import { newContactDetails, initContactErrors } from '../../components/ContactDetails';
import ContactDetailsTab from '../../components/ContactDetailsTab';
import TextInputDialog from '../../components/TextInputDialog';

//  --------------------------------------------------------------------------------------------------

export default function BranchDialog(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(config, props.details));

   const fieldHandlers = useMemo(() => createFieldHandlers(config, setState), [ config, setState ]);

   const notesButtonHandler = () => editNotes(setState);

   const saveButtonHandler = () => saveDetails(config, props, state.fields);

   const cancelButtonHandler = () => props.onClose(false);

   const { fields, errors } = state;

   const billingRequired  = (fields.billTo === 'specify');
   const shippingRequired = (fields.shipTo === 'specify');

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.branchId ? "Branch Details" : "Create Branch"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               {
                  (fields.authorise.length > 0) && (
                     <Grid item xs={12}>
                        <table style={{ border : '6px solid red' }}>
                           <tbody>
                              <tr>
                                 <td style={{ padding : '6px' }}>
                                    <InfoIcon fontSize="large"/>
                                 </td>
                                 <td style={{ padding : '6px' }}>
                                    Baskets submitted for this branch need to be authorised by one of the following individuals:
                                    { fields.authorise.map((n, i) => <React.Fragment key={'auth' + i}><br/>{'\u2022 ' + n}</React.Fragment>) }
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </Grid>
                  )
               }
               <Grid item xs={12}>
                  <TextField id="name" label="Name" value={fields.name} onChange={fieldHandlers.name} error={errors.name} required fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField id="companyNo" label="Company No." value={fields.companyNo} onChange={fieldHandlers.companyNo} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField id="vatNo" label="VAT No." value={fields.vatNo} onChange={fieldHandlers.vatNo} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="body1">The following recipients will receive details of all orders placed by this branch:</Typography>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="email" label="Email(s)" helperText="Please enter only one email address per line"
                             value={fields.email} onChange={fieldHandlers.email} error={errors.email} fullWidth multiline rowsMax="6"/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="code" label="Branch Code" value={fields.code} onChange={fieldHandlers.code} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <Button className={classes.buttons} variant="contained" onClick={notesButtonHandler} color="primary">Notes</Button>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="h6">Contact details:</Typography>
                  <Typography>The details shown below form part of the orders, so the specified individuals must be able to respond to queries regarding those orders.</Typography>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel component="legend">Billing:</FormLabel>
                     <FormGroup row={true}>
                        <RadioGroup row aria-label="billing" value={fields.billTo} onChange={fieldHandlers.billTo}>
                           <FormControlLabel value="main"     control={<Radio color="primary"/>} label="Use Main"/>
                           <FormControlLabel value="specify"  control={<Radio color="primary"/>} label="Specify"/>
                        </RadioGroup>
                        <IconButton color="inherit" onClick={fieldHandlers.billToImport} disabled={billingRequired}><ImportContactsIcon/></IconButton>
                     </FormGroup>
                  </FormControl>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel component="legend">Shipping:</FormLabel>
                     <FormGroup row={true}>
                        <RadioGroup row aria-label="shipping" value={fields.shipTo} onChange={fieldHandlers.shipTo}>
                           <FormControlLabel value="main"    control={<Radio color="primary"/>} label="Use Main"/>
                           <FormControlLabel value="billing" control={<Radio color="primary"/>} label="Use Billing" disabled={fields.billTo === 'main'}/>
                           <FormControlLabel value="specify" control={<Radio color="primary"/>} label="Specify"/>
                        </RadioGroup>
                        <IconButton color="inherit" onClick={fieldHandlers.shipToImport} disabled={shippingRequired}><ImportContactsIcon/></IconButton>
                     </FormGroup>
                  </FormControl>
               </Grid>
               <Grid item xs={12}>
                  <Tabs value={state.contactTab} onChange={fieldHandlers.contactTab_1} aria-label="address tabs">
                     <Tab id="contact-details-tab-0" aria-controls="contact-details-0" style={TAB_STYLE} label="Main"/>
                     <Tab id="contact-details-tab-1" aria-controls="contact-details-1" style={TAB_STYLE} label="Billing"/>
                     <Tab id="contact-details-tab-2" aria-controls="contact-details-2" style={TAB_STYLE} label="Shipping"/>
                  </Tabs>
                  <SwipeableViews axis="x" index={state.contactTab} onChangeIndex={fieldHandlers.contactTab_2}>
                     <ContactDetailsTab config={config} tab={0} values={fields.main} errors={errors.main} onChange={fieldHandlers.details} required={true}/>
                     <ContactDetailsTab config={config} tab={1} values={fields.billing} errors={errors.billing} onChange={fieldHandlers.details} required={billingRequired}/>
                     <ContactDetailsTab config={config} tab={2} values={fields.shipping} errors={errors.shipping} onChange={fieldHandlers.details} required={shippingRequired}/>
                  </SwipeableViews>
               </Grid>
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={(errors.name || errors.main.combined || (billingRequired && errors.billing.combined) || (shippingRequired && errors.shipping.combined))}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   buttons   : (theme) => ({
      margin : theme.spacing(1),
   }),
});

//  --------------------------------------------------------------------------------------------------

function initState(config, details) {
   return {
      fields      : {
      ...details
      },
      errors      : {
         name     : Utils.isBlank(details.name),
         email    : Utils.isNotOptionalMLEmail(details.email),
         main     : initContactErrors(config, details.main),
         billing  : initContactErrors(config, details.billing),
         shipping : initContactErrors(config, details.shipping)
      },
      contactTab  : 0,
      dialog      : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(config, setState) {
   const handler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name'  : draft.errors.name  = Utils.isBlank(value);              break;
         case 'email' : draft.errors.email = Utils.isNotOptionalMLEmail(value); break;
         default      : break;
      }
   }));
   return {
      name         : (e) => handler('name',       e.target.value),
      companyNo    : (e) => handler('companyNo',  e.target.value),
      vatNo        : (e) => handler('vatNo',      e.target.value),
      email        : (e) => handler('email',      e.target.value),
      code         : (e) => handler('code',       e.target.value),
      billTo       : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            if ( value === 'specify' ) {
               draft.contactTab = 1;
            } else if ( draft.fields.billTo === 'specify' ) {
               if ( draft.fields.shipTo === 'billing' ) {
                  draft.fields.shipTo = 'specify';
                  draft.fields.shipping = draft.fields.billing;
                  draft.errors.shipping = draft.errors.billing;
                  draft.contactTab      = 2;
               }
               draft.fields.billing = newContactDetails();
               draft.errors.billing = initContactErrors(config, draft.fields.billing);
            }
            draft.fields.billTo = value;
         }));
      },
      shipTo       : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            if ( value === 'specify' ) {
               draft.contactTab = 2;
            } else if ( draft.fields.shipTo === 'specify' ) {
               draft.fields.shipping = newContactDetails();
               draft.errors.shipping = initContactErrors(config, draft.fields.shipping);
            }
            draft.fields.shipTo = value;
         }));
      },
      billToImport : (e) => setState((state) => produce(state, (draft) => {
         draft.fields.billing = { ...draft.fields.main };
         draft.errors.billing = { ...draft.errors.main };
         draft.fields.billTo  = 'specify';
         draft.contactTab     = 1;
      })),
      shipToImport : (e) => setState((state) => produce(state, (draft) => {
         if ( draft.fields.shipTo === 'main' ) {
            draft.fields.shipping = { ...draft.fields.main };
            draft.errors.shipping = { ...draft.errors.main };
         } else {
            draft.fields.shipping = { ...draft.fields.billing };
            draft.errors.shipping = { ...draft.errors.billing };
         }
         draft.fields.shipTo      = 'specify';
         draft.contactTab         = 2;
      })),
      contactTab_1 : (e, v) => setState((state) => produce(state, (draft) => {
         draft.contactTab = v;
      })),
      contactTab_2 : (v) => setState((state) => produce(state, (draft) => {
         draft.contactTab = v;
      })),
      details      : (tab, fields, errors) => setState((state) => produce(state, (draft) => {
         switch ( tab ) {
            case 0 :
               draft.fields.main     = fields;
               draft.errors.main     = errors;
               break;
            case 1 :
               draft.fields.billing  = fields;
               draft.errors.billing  = errors;
               break;
            case 2 :
               draft.fields.shipping = fields;
               draft.errors.shipping = errors;
               break;
            default :
               break;
         }
      }))
   };
}

//  --------------------------------------------------------------------------------------------------

function editNotes(setState) {
   const closeHandler = (notes) => setState((state) => produce(state, (draft) => {
      draft.fields.notes = notes;
      draft.dialog       = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <TextInputDialog title="Notes" required={false} multiline={true} value={state.fields.notes} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields) {
   if ( props.branchId ) {
      config.showMessage('Updating Branch');
      PortalAPI.putBranch(props.clientId, props.branchId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Branch');
      PortalAPI.postBranch(props.clientId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

export function newBranchDetails() {
   return {
      version    : 0,
      name       : '',
      companyNo  : '',
      vatNo      : '',
      notes      : '',
      email      : '',
      billTo     : 'main',
      shipTo     : 'main',
      main       : newContactDetails(),
      billing    : newContactDetails(),
      shipping   : newContactDetails(),
      code       : '',
      authorise  : [ ]
   };
}

//  --------------------------------------------------------------------------------------------------

