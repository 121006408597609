import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import PortalAPI from '../../PortalAPI';
import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import NameField, { getNameErrors } from '../../components/NameField';

import UserAuthoriseDialog from './UserAuthoriseDialog';
import UserBranchDialog from './UserBranchDialog';

//  --------------------------------------------------------------------------------------------------

export default function UserDialog(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(props.details));

   const fieldHandlers = useMemo(() => createFieldHandlers(config, setState), [ config, setState ]);

   const saveButtonHandler = () => saveDetails(config, props, state.fields, setState);

   const cancelButtonHandler = () => props.onClose(false);

   const perms = useMemo(() => getPermissions(config.user, state.fields.access.level), [ config.user, state.fields.access.level ]);

   const getCheckbox = (code) => {
      switch ( code ) {
         case 'A'  : return <FormControlLabel label="Attribute Administration"
                                              control={(<Checkbox checked={state.fields.access.aAdmin} onChange={fieldHandlers.aAdmin}/>)}/>;
         case 'V'  : return <FormControlLabel label="Vendor Administration"
                                              control={(<Checkbox checked={state.fields.access.vAdmin} onChange={fieldHandlers.vAdmin}/>)}/>;
         case 'C'  : return <FormControlLabel label="Client Administration"
                                              control={(<Checkbox checked={state.fields.access.cAdmin} onChange={fieldHandlers.cAdmin}/>)}/>;
         case 'S'  : return <FormControlLabel label="Supplier Administration"
                                              control={(<Checkbox checked={state.fields.access.sAdmin} onChange={fieldHandlers.sAdmin}/>)}/>;
         case 'P'  : return <FormControlLabel label="Product Administration"
                                              control={(<Checkbox checked={state.fields.access.pAdmin} onChange={fieldHandlers.pAdmin}/>)}/>;
         case 'PF' : return <FormControlLabel label="Product Full Administration" disabled={!state.fields.access.pAdmin}
                                              control={(<Checkbox checked={state.fields.access.pFull} onChange={fieldHandlers.pFull}/>)}/>;
         case 'B'  : return <FormControlLabel label="Branch Administration"
                                              control={(<Checkbox checked={state.fields.access.bAdmin} onChange={fieldHandlers.bAdmin}/>)}/>;
         case 'N'  : return <FormControlLabel label="Notice Administration"
                                              control={(<Checkbox checked={state.fields.access.nAdmin} onChange={fieldHandlers.nAdmin}/>)}/>;
         case 'U'  : return <FormControlLabel label="User Administration"
                                              control={(<Checkbox checked={state.fields.access.uAdmin} onChange={fieldHandlers.uAdmin}/>)}/>;
         case 'UF' : return <FormControlLabel label="User Full Administration" disabled={!state.fields.access.uAdmin || state.fields.access.level === 'B'}
                                              control={(<Checkbox checked={state.fields.access.uFull} onChange={fieldHandlers.uFull}/>)}/>;
         case 'I'  : return <FormControlLabel label="Integration Administration"
                                              control={(<Checkbox checked={state.fields.access.iAdmin} onChange={fieldHandlers.iAdmin}/>)}/>;
         case 'O'  : return <FormControlLabel label="Can Place Orders"
                                              control={(<Checkbox checked={state.fields.access.order}  onChange={fieldHandlers.order}/>)}/>;
         case 'OO' : return <FormControlLabel label="Can Order for Others" disabled={!state.fields.access.order}
                                              control={(<Checkbox checked={state.fields.access.others} onChange={fieldHandlers.others}/>)}/>;
         case 'BE' : return <FormControlLabel label="Can Request Products"
                                              control={(<Checkbox checked={state.fields.access.bespoke} onChange={fieldHandlers.bespoke}/>)}/>;
         default   : return false;
      }
   };

   const showAccessLevel         = (config.user.level === 'S' || (config.user.level === 'C' && config.user.uFull));
   const showBranchesButton      = (config.user.level === 'S' || config.user.level === 'C' || config.user.branches.length > 1);
   const showAuthorisationButton = state.fields.authorisation;

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.userId ? "User Details" : "Create User"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <NameField values={state.fields.name} errors={state.errors.name} onChange={fieldHandlers.name} required={true} disabled={false}/>
               <Grid item xs={12}>
                  <TextField id="email" label="Email" value={state.fields.email} onChange={fieldHandlers.email} error={state.errors.email} fullWidth required/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="phone" label="Phone" value={state.fields.phone} onChange={fieldHandlers.phone} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="discount" label="Discount Code" value={state.fields.discount} onChange={fieldHandlers.discount} fullWidth/>
               </Grid>
               {
                  showAccessLevel && (
                     <Grid item xs={12}>
                        <InputLabel shrink>Access Level</InputLabel>
                        <Select id="level" label="Level" value={state.fields.access.level} onChange={fieldHandlers.level} fullWidth required>
                           { config.user.level === 'S' && config.user.uFull && <MenuItem key="S" value="S">Site</MenuItem> }
                           <MenuItem key="C" value="C">Client</MenuItem>
                           <MenuItem key="B" value="B">Branch</MenuItem>
                        </Select>
                     </Grid>
                  )
               }
               {
                  (showBranchesButton || showAuthorisationButton) && (
                     <Grid item xs={12}>
                        {
                           showBranchesButton && (
                              <Button className={classes.buttons} variant="contained" color="primary" onClick={fieldHandlers.branch}>{
                                 (config.user.level === 'S') ? 'Client & Branch Selection' : 'Branch Selection'
                              }</Button>
                           )
                        }
                        {
                           showAuthorisationButton && (
                              <Button className={classes.buttons} variant="contained" color="primary" onClick={fieldHandlers.authorise}>Basket Authorisation</Button>
                           )
                        }
                     </Grid>
                  )
               }
               <Grid item xs={12}>
                  <InputLabel shrink>Permissions</InputLabel>
               </Grid>
               {
                  perms.map((g, gi) => (
                     <Grid item xs={12} sm={6} key={'group' + gi}>
                        <Grid container spacing={1}>{g.map((p, pi) => (<Grid item xs={12} key={'perm' + pi}>{getCheckbox(p)}</Grid>))}</Grid>
                     </Grid>
                  ))
               }
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={(state.errors.name.combined || state.errors.email || state.errors.clientId || state.errors.branchId)}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   buttons   : (theme) => ({
      margin : theme.spacing(1),
   }),
});

//  --------------------------------------------------------------------------------------------------

function initState(details) {
   return {
      fields : {
         ...details
      },
      errors : {
         name     : getNameErrors(details.name, true),
         email    : Utils.isNotEmail(details.email),
         clientId : (details.clientId === false),
         branchId : (details.branchId === false)
      },
      dialog : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(config, setState) {
   const nameHandler = (values, errors) => setState((state) => produce(state, (draft) => {
      draft.fields.name = values;
      draft.errors.name = errors;
   }));
   const textHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'email' : draft.errors.email = Utils.isNotEmail(value);  break;
         default      : break;
      }
   }));
   const levelHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.access.level = value;
      switch ( value ) {

         case 'S' :
            draft.fields.branches = draft.fields.branchId ? [ draft.fields.branchId ] : [ ];
            break;

         case 'C' :
            draft.fields.branches = draft.fields.branchId ? [ draft.fields.branchId ] : [ ];
            draft.fields.access.aAdmin = false;
            draft.fields.access.vAdmin = false;
            draft.fields.access.cAdmin = false;
            break;

         default :
            const temp = new Set(state.fields.branches);
            draft.fields.authorise = state.fields.authorise.filter((b) => temp.has(b));
            draft.fields.access.aAdmin = false;
            draft.fields.access.vAdmin = false;
            draft.fields.access.cAdmin = false;
            draft.fields.access.sAdmin = false;
            draft.fields.access.pAdmin = false;
            draft.fields.access.pFull  = false;
            draft.fields.access.bAdmin = false;
            draft.fields.access.nAdmin = false;
            draft.fields.access.uFull  = draft.fields.access.uAdmin;
            draft.fields.access.iAdmin = false;
            draft.fields.access.others = false;
            break;

      }
   }));
   const branchHandler = () => {
      const closeHandler = (details) => setState((state) => produce(state, (draft) => {
         if ( details.clientId !== draft.fields.clientId ) {
            draft.fields.authorise = [ ];
         }
         if ( state.fields.access.level === 'B' ) {
            const temp = new Set(details.branches);
            draft.fields.authorise = state.fields.authorise.filter((b) => temp.has(b));
         }
         draft.fields.clientId      = details.clientId;
         draft.fields.branchId      = details.branchId;
         draft.fields.branches      = details.branches;
         draft.fields.authorisation = details.authorisation;
         draft.errors.clientId      = (details.clientId === false);
         draft.errors.branchId      = (details.branchId === false);
         draft.dialog               = false;
      }));
      setState((state) => {
         const details = {
            clientId      : state.fields.clientId,
            branchId      : state.fields.branchId,
            branches      : state.fields.branches,
            authorisation : state.fields.authorisation
         };
         return produce(state, (draft) => {
            draft.dialog = <UserBranchDialog config={config} level={state.fields.access.level} details={details} onClose={closeHandler}/>;
         })
      });
   };
   const authoriseHandler = () => {
      const closeHandler = (details) => setState((state) => produce(state, (draft) => {
         draft.fields.authorise = details;
         draft.dialog           = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <UserAuthoriseDialog config={config} client={state.fields.clientId} level={state.fields.access.level} branches={state.fields.branches}
                                             details={state.fields.authorise} onClose={closeHandler}/>;
      }));
   };
   const accessHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields.access[field] = value;
      switch ( field ) {
         case 'pAdmin' : draft.fields.access.pFull  = draft.fields.access.pFull && value;                                                break;
         case 'uAdmin' : draft.fields.access.uFull  = (draft.fields.access.level === 'B') ? value : draft.fields.access.uFull && value;  break;
         case 'order'  : draft.fields.access.others = draft.fields.access.others && value;                                               break;
         default       : break;
      }
   }));
   return {
      name       : nameHandler,
      email      : (e) => textHandler('email',      e.target.value),
      phone      : (e) => textHandler('phone',      e.target.value),
      discount   : (e) => textHandler('discount',   e.target.value),
      level      : (e) => levelHandler(e.target.value),
      branch     : branchHandler,
      authorise  : authoriseHandler,
      aAdmin     : (e) => accessHandler('aAdmin',  e.target.checked),
      vAdmin     : (e) => accessHandler('vAdmin',  e.target.checked),
      cAdmin     : (e) => accessHandler('cAdmin',  e.target.checked),
      sAdmin     : (e) => accessHandler('sAdmin',  e.target.checked),
      pAdmin     : (e) => accessHandler('pAdmin',  e.target.checked),
      pFull      : (e) => accessHandler('pFull',   e.target.checked),
      bAdmin     : (e) => accessHandler('bAdmin',  e.target.checked),
      nAdmin     : (e) => accessHandler('nAdmin',  e.target.checked),
      uAdmin     : (e) => accessHandler('uAdmin',  e.target.checked),
      uFull      : (e) => accessHandler('uFull',   e.target.checked),
      iAdmin     : (e) => accessHandler('iAdmin',  e.target.checked),
      order      : (e) => accessHandler('order',   e.target.checked),
      others     : (e) => accessHandler('others',  e.target.checked),
      bespoke    : (e) => accessHandler('bespoke', e.target.checked)
   };
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields, setState) {
   if ( props.userId ) {
      const performUpdate = () => {
         config.showMessage('Updating User');
         PortalAPI.putUser(props.userId, fields).then((response) => {
            config.hideMessage();
            if ( props.userId === config.user.id ) {
               Utils.signOut();
            } else {
               props.onClose(true);
            }
         }).catch(config.reportAPIError);
      };
      const authorise = new Set(props.details.authorise);
      fields.authorise.forEach(b => authorise.delete(b));
      if ( authorise.size === 0 ) {
         performUpdate();
      } else {
         const handleResponse = (update) => {
            setState((state) => produce(state, (draft) => {
               draft.dialog = false;
            }));
            if ( update ) {
               performUpdate();
            }
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message={<>Update user ?<br/><br/>IMPORTANT: This will stop this user from authorising baskets for one or more branches.
                                                          Please ensure that either someone else can perform this task for the affected branches, or have the users
                                                          of those branches recall any baskets currently sat in the authorisation queue, and resubmit them.</>}
                                               confirmationButton='Continue' onConfirmation={() => handleResponse(true)} onClose={() => handleResponse(false)}/>;
         }));
      }
   } else {
      config.showMessage('Creating User');
      PortalAPI.postUser(fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

function getPermissions(user, level) {
   const permsForLevel = () => {
      switch ( level ) {
         case 'S' : return [ 'A', 'V', 'C', 'S', 'P', 'PF', 'B', 'N', 'U', 'UF', 'I', 'O', 'OO', 'BE' ];
         case 'C' : return [ 'S', 'P', 'PF', 'B', 'N', 'U', 'UF', 'I', 'O', 'OO', 'BE' ];
         default  : return (user.level === 'S' || (user.level === 'C' && user.uFull)) ? [ 'U', 'UF', 'O', 'BE' ] : [ 'U', 'O', 'BE' ];
      }
   };
   const permFilter = (p) => {
      switch ( p ) {
         case 'A'  : return user.aAdmin;
         case 'V'  : return user.vAdmin;
         case 'C'  : return user.cAdmin;
         case 'S'  : return user.sAdmin;
         case 'P'  : return user.pAdmin;
         case 'PF' : return user.pFull;
         case 'B'  : return user.bAdmin;
         case 'N'  : return user.nAdmin;
         case 'U'  : return user.uAdmin;
         case 'UF' : return true;
         case 'I'  : return user.iAdmin;
         case 'O'  : return user.order;
         case 'OO' : return user.others;
         case 'BE' : return user.bespoke;
         default   : return false;
      }
   };
   const perms = permsForLevel().filter(permFilter);
   const cut   = Math.ceil(perms.length / 2);
   return [ perms.slice(0, cut), perms.slice(cut) ];
}

//  --------------------------------------------------------------------------------------------------

