import React, { useContext, useState, useMemo } from 'react';

import SwipeableViews from 'react-swipeable-views';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import PortalAPI from '../../PortalAPI';
import Utils, { TAB_STYLE } from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import { newContactDetails, initContactErrors } from '../../components/ContactDetails';
import ContactDetailsTab from '../../components/ContactDetailsTab';
import TextInputDialog from '../../components/TextInputDialog';

import SupplierIntegrationsDialog from './SupplierIntegrationsDialog';

//  --------------------------------------------------------------------------------------------------

export default function VendorDialog(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(config, props.details));

   const fieldHandlers = useMemo(() => createFieldHandlers(config, setState), [ config, setState ]);

   const notesButtonHandler = () => editNotes(setState);

   const integrationsButtonHandler = () => editIntegrations(setState);

   const saveButtonHandler = () => saveDetails(config, props, fields);

   const cancelButtonHandler = () => props.onClose(false);

   const { fields, errors } = state;

   const billingRequired  = (fields.billTo === 'specify');

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.vendorId ? "Vendor Details" : "Create Vendor"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField label="Name" value={fields.name} onChange={fieldHandlers.name} error={errors.name} required fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Notification Email" helperText="Email address(es) to receive order notifications, product enquiries, etc., please enter only one address per line"
                             value={fields.notificationEmail} onChange={fieldHandlers.notificationEmail} error={errors.notificationEmail} required fullWidth multiline rowsMax="6"/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Support Email" helperText="Email address(es) added to order emails as the point of contact for any queries, please enter only one address per line"
                             value={fields.supportEmail} onChange={fieldHandlers.supportEmail} error={errors.supportEmail} required fullWidth multiline rowsMax="6"/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Company No." value={fields.companyNo} onChange={fieldHandlers.companyNo} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="VAT No." value={fields.vatNo} onChange={fieldHandlers.vatNo} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Purchase Order Number Format" value={fields.poFormat} onChange={fieldHandlers.poFormat} error={errors.poFormat} required fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <Button className={classes.buttons} variant="contained" onClick={notesButtonHandler} color="primary">Notes</Button>
                  <Button className={classes.buttons} variant="contained" color="primary" onClick={integrationsButtonHandler}>Supplier Integrations</Button>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="h6">Contact details:</Typography>
                  <Typography>The details shown below form part of the orders, so the specified individuals must be able to respond to queries regarding those orders.</Typography>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel component="legend">Billing:</FormLabel>
                     <FormGroup row={true}>
                        <RadioGroup row aria-label="billing" value={fields.billTo} onChange={fieldHandlers.billTo}>
                           <FormControlLabel value="main" control={<Radio color="primary"/>} label="Use Main"/>
                           <FormControlLabel value="specify" control={<Radio color="primary"/>} label="Specify"/>
                        </RadioGroup>
                        <IconButton color="inherit" onClick={fieldHandlers.billToImport} disabled={billingRequired}><ImportContactsIcon/></IconButton>
                     </FormGroup>
                  </FormControl>
               </Grid>
               <Grid item xs={12}>
                  <Tabs value={state.contactTab} onChange={fieldHandlers.contactTab_1} aria-label="address tabs">
                     <Tab id="contact-details-tab-0" aria-controls="contact-details-0" style={TAB_STYLE} label="Main"/>
                     <Tab id="contact-details-tab-1" aria-controls="contact-details-1" style={TAB_STYLE} label="Billing"/>
                  </Tabs>
                  <SwipeableViews axis="x" index={state.contactTab} onChangeIndex={fieldHandlers.contactTab_2}>
                     <ContactDetailsTab config={config} tab={0} values={fields.main} errors={errors.main} onChange={fieldHandlers.details} required={true}/>
                     <ContactDetailsTab config={config} tab={1} values={fields.billing} errors={errors.billing} onChange={fieldHandlers.details} required={billingRequired}/>
                  </SwipeableViews>
               </Grid>
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={errors.name || errors.notificationEmail || errors.supportEmail || errors.poFormat ||
                                                                          errors.main.combined || (billingRequired && errors.billing.combined)}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   buttons   : (theme) => ({
      margin : theme.spacing(1),
   }),
});

//  --------------------------------------------------------------------------------------------------

function initState(config, details) {
   return {
      integrations            : details.availableIntegrations,
      fields                  : {
         version              : details.version,
         name                 : details.name,
         notificationEmail    : details.notificationEmail,
         supportEmail         : details.supportEmail,
         companyNo            : details.companyNo,
         vatNo                : details.vatNo,
         notes                : details.notes,
         poFormat             : details.poFormat,
         billTo               : details.billTo,
         main                 : details.main,
         billing              : details.billing,
         supplierIntegrations : details.supplierIntegrations,
      },
      errors                  : {
         name                 : Utils.isBlank(details.name),
         notificationEmail    : Utils.isNotMLEmail(details.notificationEmail),
         supportEmail         : Utils.isNotMLEmail(details.supportEmail),
         poFormat             : isNotPOFormat(details.poFormat),
         main                 : initContactErrors(config, details.main),
         billing              : initContactErrors(config, details.billing)
      },
      contactTab              : 0,
      dialog                  : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(config, setState) {
   const handler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name'              : draft.errors.name              = Utils.isBlank(value);       break;
         case 'notificationEmail' : draft.errors.notificationEmail = Utils.isNotMLEmail(value);  break;
         case 'supportEmail'      : draft.errors.supportEmail      = Utils.isNotMLEmail(value);  break;
         case 'poFormat'          : draft.errors.poFormat          = isNotPOFormat(value);       break;
         default                  : break;
      }
   }));
   return {
      name              : (e) => handler('name',              e.target.value),
      notificationEmail : (e) => handler('notificationEmail', e.target.value),
      supportEmail      : (e) => handler('supportEmail',      e.target.value),
      companyNo         : (e) => handler('companyNo',         e.target.value),
      vatNo             : (e) => handler('vatNo',             e.target.value),
      poFormat          : (e) => handler('poFormat',          e.target.value),
      billTo            : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            if ( value === 'specify' ) {
               draft.contactTab = 1;
            } else if ( draft.fields.billTo === 'specify' ) {
               draft.fields.billing = newContactDetails();
               draft.errors.billing = initContactErrors(config, draft.fields.billing);
            }
            draft.fields.billTo = value;
         }));
      },
      billToImport      : (e) => setState((state) => produce(state, (draft) => {
         draft.fields.billing = { ...draft.fields.main };
         draft.errors.billing = { ...draft.errors.main };
         draft.fields.billTo  = 'specify';
         draft.contactTab     = 1;
      })),
      contactTab_1      : (e, v) => setState((state) => produce(state, (draft) => {
         draft.contactTab = v;
      })),
      contactTab_2      : (v) => setState((state) => produce(state, (draft) => {
         draft.contactTab = v;
      })),
      details           : (tab, fields, errors) => setState((state) => produce(state, (draft) => {
         switch ( tab ) {
            case 0 :
               draft.fields.main     = fields;
               draft.errors.main     = errors;
               break;
            case 1 :
               draft.fields.billing  = fields;
               draft.errors.billing  = errors;
               break;
            default :
               break;
         }
      }))
   };
}

//  --------------------------------------------------------------------------------------------------

function editNotes(setState) {
   const closeHandler = (notes) => setState((state) => produce(state, (draft) => {
      draft.fields.notes = notes;
      draft.dialog       = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <TextInputDialog title="Notes" required={false} multiline={true} value={state.fields.notes} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editIntegrations(setState) {
   const onCloseHandler = (s) => setState((state) => produce(state, (draft) => {
      draft.fields.supplierIntegrations = s;
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <SupplierIntegrationsDialog integrations={state.integrations} selected={state.fields.supplierIntegrations} onClose={onCloseHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

const PO_FORMAT_PATTERN = /^[^%]*%(?:0?(?:[4-9]|10))?d[^%]*$/;

function isNotPOFormat(value) {
   return !PO_FORMAT_PATTERN.test(value);
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields) {
   if ( props.vendorId ) {
      config.showMessage('Updating Vendor');
      PortalAPI.putVendor(props.vendorId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Vendor');
      PortalAPI.postVendor(fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

