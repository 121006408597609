import React, { useContext, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import BasketDialog from './basket/BasketDialog';

import BillingDialog from './BillingDialog';
import InfoWidget from './InfoWidget';
import PortalAPI from './PortalAPI';
import SwitchDialog from './SwitchDialog';
import { NAVIGATION_WIDTH } from './Navigation';
import { ConfigurationContext } from './ConfigurationContext';

import ConfirmationDialog from './components/ConfirmationDialog';

import Utils from './Utils';

//  --------------------------------------------------------------------------------------------------

export default function Bar(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ dialog, setDialog ] = useState(false);

   const closeDialog = () => setDialog(false);

   const handleBasket = () => {
      setDialog(<BasketDialog config={config} onClose={closeDialog}/>);
   };

   const handleBilling = () => {
      config.showMessage('Loading');
      PortalAPI.getUserBilling().then((response) => {
         config.hideMessage();
         setDialog(<BillingDialog config={config} details={response} onClose={closeDialog}/>);
      }).catch(config.reportAPIError);
   };

   const handleSwitch = () => {
      const switchBranch = (id) => {
         setDialog(false);
         config.showMessage('Processing');
         PortalAPI.putUserBranch(id).then((response) => {
            Utils.signOut();
         }).catch(config.reportAPIError);
      };
      const branches = config.user.branches.filter((b) => (b.id !== config.branch.id));
      if ( branches.length === 1 ) {
         setDialog(<ConfirmationDialog message={'Switch to ' + branches[0].name} confirmationButton="Switch" onConfirmation={() => switchBranch(branches[0].id)} onClose={closeDialog}/>);
      } else {
         setDialog(<SwitchDialog branches={branches} onSwitch={switchBranch} onClose={closeDialog}/>);
      }
   };

   const handleSignOut = () => setDialog(<ConfirmationDialog message="Really sign out ?" confirmationButton="Accept" onConfirmation={() => Utils.signOut()} onClose={closeDialog}/>);

   const { title } = props;

   return (
      <AppBar position="fixed"  className={classes.appBar}>
         <Toolbar>
            <IconButton edge="start" color="inherit" className={classes.menuButton} aria-label="Open navigation" onClick={() => props.onMenuButtonPressed()}><MenuIcon/></IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>{title}</Typography>
            <IconButton color="inherit" variant="contained" onClick={handleBasket}>
               <Badge badgeContent={props.basket} showZero={false} color="secondary"><ShoppingBasketIcon/></Badge>
            </IconButton>
            <IconButton color="inherit" variant="contained" onClick={handleBilling}><CreditCardIcon/></IconButton>
            <InfoWidget icon={(<AccountCircleIcon/>)} render={(
               <Paper className={classes.widgetContent} elevation={5}>
                  <div style={{ textAlign : 'right' }}>
                     <Typography>{Utils.formatName(config.user.name)}<br/>{config.client.name}<br/>{config.branch.name}</Typography>
                  </div>
                  <hr/>
                  {
                     (config.user.branches.length > 1) && (
                        <Button style={{ marginBottom : '8px' }} variant="contained" color="primary" onClick={handleSwitch} disabled={props.basket > 0} fullWidth>Switch</Button>
                     )
                  }
                  <Button variant="contained" color="primary" onClick={handleSignOut} fullWidth>Sign Out</Button>
               </Paper>
            )}/>
            <InfoWidget icon={(<ContactSupportIcon/>)} render={(
               <Paper className={classes.widgetContent} elevation={5}>
                  <div style={{ textAlign : 'right' }}>
                     <Typography>Need help ? You can contact us via<br/><Link href="mailto:support@thepersonalprintportal.co.uk">support@thepersonalprintportal.co.uk</Link></Typography>
                  </div>
               </Paper>
            )}/>
            {dialog}
         </Toolbar>
      </AppBar>
   );
}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   appBar                          : (theme) => ({
      [theme.breakpoints.up('md')] : {
         width                     : `calc(100% - ${NAVIGATION_WIDTH}px)`,
      },
   }),
   menuButton                      : (theme) => ({
      marginRight                  : 20,
      [theme.breakpoints.up('md')] : {
         display                   : 'none',
      }
   }),
   title                           : {
      flexGrow                     : 1,
   },
   widgetContent                   : {
      padding                      : 16
   }
});


//  --------------------------------------------------------------------------------------------------

