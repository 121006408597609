import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Utils from '../Utils';

//  --------------------------------------------------------------------------------------------------

export default function OptionDialog(props) {

   const [ state, setState ] = useState({
      fields   : {
         ...props.value
      },
      errors   : {
         id    : Utils.isNotVariantCode(props.value.id),
         value : Utils.isBlank(props.value.value)
      }
   });

   const handleIdChange = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.id = value;
         draft.errors.id = Utils.isNotVariantCode(value) || props.ids.includes(value);
      }));
   };

   const handleValueChange = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.value = value;
         draft.errors.value = Utils.isBlank(value);
      }));
   };

   const handleSave = () => props.onClose(state.fields);

   const handleCancel = () => props.onClose(props.value);

   return (
      <Dialog open disableBackdropClick onClose={handleCancel}>
         <DialogTitle>Option Details</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField label="Variant ID" value={state.fields.id} onChange={handleIdChange} error={state.errors.id} disabled={props.disabled} required={!props.disabled} fullWidth autoFocus/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Value" value={state.fields.value} onChange={handleValueChange} error={state.errors.value} disabled={props.disabled} required={!props.disabled} fullWidth/>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>{
            props.disabled ? (
               <Button onClick={handleCancel} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={handleSave} color="primary" disabled={state.errors.id || state.errors.value}>Save</Button>
                  <Button onClick={handleCancel} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

export function newOptionDetails() {
   return {
      id    : '',
      value : ''
   };
}

//  --------------------------------------------------------------------------------------------------



