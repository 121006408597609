import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FilterIcon from '@material-ui/icons/FilterList';

import ProductDialog from '../../basket/ProductDialog';

import PortalAPI from '../../PortalAPI';

//  --------------------------------------------------------------------------------------------------

export default function CatalogPage(props) {

   //  Client and branch are who the user is ordering for, catalog is the client catalog the user is ordering from, and page is the page within that catalog
   const { config, client, branch, catalog, page } = props;

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState({ products : false });

   useEffect(() => loadProducts(config, client, branch, catalog, page, setState), [ config, client, branch, catalog, page, setState ]);

   const filterHandler = (e) => {
      const value = e.target.value;
      const search = value.toLowerCase();
      setState((state) => produce(state, (draft) => {
         draft.filter = value;
         draft.filtered = (value === '') ? draft.products : draft.products.filter(p => p.name.toLowerCase().includes(search));
      }));
   };

   const { products, filter, filtered, dialog } = state

   return products ? (
      <>
         {
            (products.length > 0) ? (
               <>
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="filter" label="Filter" value={filter} onChange={filterHandler} fullWidth
                                   InputProps={{ startAdornment: <InputAdornment position="start"><FilterIcon/></InputAdornment> }}/>
                     </Grid>
                  </Grid>
                  <br/>
                  {
                     (filtered.length > 0) ? (
                        <Grid container spacing={1}>{
                           filtered.map((p) => (
                              <Grid item key={p.id} xs={true}>
                                 <Card className={classes.card}>
                                    <CardActionArea onClick={p.handler}>
                                       <CardMedia className={classes.image} image={p.image.url} title={p.image.title}/>
                                       <CardContent className={classes.content}>
                                          <Typography variant="h6">{p.name}</Typography>
                                       </CardContent>
                                    </CardActionArea>
                                 </Card>
                              </Grid>
                           ))
                        }</Grid>
                     ) : (
                        <Typography variant="h6">No matching products found.</Typography>
                     )
                  }
               </>
            ) : (
               <Typography variant="h6">No products found.</Typography>
            )
         }
         { dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   grid          : {
      alignItems : 'center'
   },
   card          : {
      margin     : '0px auto',
      height     : '100%',
      width      : 300
   },
   image         : {
      width      : 300,
      height     : 300
   }
});

//  --------------------------------------------------------------------------------------------------

function loadProducts(config, client, branch, catalog, page, setState) {
   setState((state) => produce(state, (draft) => {
      draft.products = false;
   }));
   PortalAPI.getCatalogPage(client, branch, catalog, page).then((response) => {

      const handler = (id) => {
         config.showMessage('Loading');
         PortalAPI.getBasketItemFor(client, branch, id).then((response) => {
            config.hideMessage();
            const closeHandler = () => setState((state) => produce(state, (draft) => {
               draft.dialog = false;
            }));
            setState((state) => produce(state, (draft) => {
               draft.dialog = <ProductDialog id={false} product={response} onClose={closeHandler}/>;
            }));
         }).catch(config.reportAPIError);
      };

      response.forEach((p) => { p.handler = () => handler(p.id); });

      setState((state) => produce(state, (draft) => {
         draft.products = response;
         draft.filtered = response;
         draft.filter   = '';
         draft.dialog   = false;
      }));

   }).catch(config.reportAPIError);
};

//  --------------------------------------------------------------------------------------------------

