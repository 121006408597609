import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Markdown from 'markdown-to-jsx';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import DownloadsDialog from '../../components/DownloadsDialog';
import ImageGallery from '../../components/ImageGallery';
import QuantitySelectors, { initQuantitySelectorsRaw } from '../../components/QuantitySelectors';

import { GLOBAL_ID } from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function PreviewDialog(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(props.productInfo));

   const product = props.productInfo.product;

   const handlers = useMemo(() => createFieldHandlers(product, setState), [ product, setState ]);

   const attributes = useMemo(() => getAttributes(product, state.showAllAttributes), [product, state.showAllAttributes]);

   const files = useMemo(() => getFiles(product, state.showAllFiles), [product, state.showAllFiles]);

   const [ dialog, setDialog ] = useState(false);

   const downloadsHandler = () => setDialog(<DownloadsDialog files={files.others} onClose={() => setDialog(false)}/>);

   return (
      <Dialog open disableBackdropClick onClose={props.onClose} scroll="paper">
         <DialogTitle>{product.name}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <ImageGallery images={product.images}/>
               </Grid>
               {
                  state.hasHiddenAttributes && (
                     <Grid item xs={6}>
                        <FormControlLabel control={(<Checkbox checked={state.showAllAttributes} onChange={handlers.allAttributes}/>)} label="Show hidden attributes"/>
                     </Grid>
                  )
               }
               {
                  state.hasHiddenFiles && (
                     <Grid item xs={6}>
                        <FormControlLabel control={(<Checkbox checked={state.showAllFiles} onChange={handlers.allFiles}/>)} label="Show hidden files"/>
                     </Grid>
                  )
               }
               <Grid item xs={12} className={classes.heading}>
                  <Typography variant="subtitle1">Description</Typography>
               </Grid>
               <Grid item xs={12}>
                  <Markdown>{product.description}</Markdown>
               </Grid>
               {
                  attributes.map((g, i) => (
                     <>
                        <Grid item key={`g$i`} xs={12} className={classes.heading}>
                           <Typography variant="subtitle1">{g.name}</Typography>
                        </Grid>
                        {
                           g.attributes.map((a) => (
                              <Grid item xs={12} sm={6} key={a.id}>{
                                 (a.field === 'TEXT') ? (
                                    <TextField id={a.id} label={a.name} defaultValue={a.value} required={a.required} InputProps={{ readOnly : a.readOnly }} fullWidth/>
                                 ) : (a.field === 'TEXTAREA') ? (
                                    <TextField id={a.id} label={a.name} defaultValue={a.value} required={a.required} InputProps={{ readOnly : a.readOnly }} fullWidth multiline/>
                                 ) : (
                                    <TextField id={a.id} label={a.name} defaultValue={a.value} required={a.required} InputProps={{ readOnly : a.readOnly }} fullWidth select>{
                                       a.options.map((o) => <MenuItem key={o.id} value={o.id}>{o.value}</MenuItem>)
                                    }</TextField>
                                 )
                              }</Grid>
                           ))
                        }
                     </>
                  ))
               }
               {
                  (files.artwork1.length > 0 || files.artwork2.length > 0) && (
                     <Grid item xs={12} className={classes.heading}>
                        <Typography variant="subtitle1">Artwork</Typography>
                     </Grid>
                  )
               }
               {
                  (files.artwork1.length > 0) && (
                     <Grid item xs={12}>
                        <InputLabel shrink>Artwork #1</InputLabel>
                        <Select fullWidth>{
                           files.artwork1.map((f) => <MenuItem key={f.id} value={f.id}>{f.description}</MenuItem>)
                        }</Select>
                     </Grid>
                  )
               }
               {
                  (files.artwork2.length > 0) && (
                     <Grid item xs={12}>
                        <InputLabel shrink>Artwork #2</InputLabel>
                        <Select fullWidth>{
                           files.artwork2.map((f) => <MenuItem key={f.id} value={f.id}>{f.description}</MenuItem>)
                        }</Select>
                     </Grid>
                  )
               }
               {
                  (!product.visibility.value && state.branches.length === 0) ? (
                     <Grid item xs={12} className={classes.heading}>
                        <Typography variant="subtitle1">Product Is Not Visible</Typography>
                     </Grid>
                  ) : product.enquiry ? (
                     <Grid item xs={12} className={classes.heading}>
                        <Typography variant="subtitle1">Product Is Enquire Only</Typography>
                     </Grid>
                  ) : (
                     <>
                        <Grid item xs={12} className={classes.heading}>
                           <Typography variant="subtitle1">Quantity</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <InputLabel shrink>Branch View</InputLabel>
                           <Select value={state.branch} onChange={handlers.branch} fullWidth>{
                              state.branches.map((b) => <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>)
                           }</Select>
                        </Grid>
                        <QuantitySelectors quantities={state.quantities} quantity={state.quantity} delivery={state.delivery} onChange={handlers.pricing}/>
                        <Grid item xs={12}>
                           <InputLabel shrink>VAT</InputLabel>
                           <Typography variant="body1">{state.vatRate}</Typography>
                        </Grid>
                     </>
                  )
               }
            </Grid>
            { dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={downloadsHandler} color="primary" disabled={files.others.length === 0}>Downloads</Button>
            <Button onClick={props.onClose} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   heading       : (theme) => ({
      background : theme.palette.primary.main,
      color      : theme.palette.primary.contrastText
   })
});

//  --------------------------------------------------------------------------------------------------

function initState(productInfo) {
   const product     = productInfo.product;
   const hiddenAttrs = (product.attributes.flatMap((g) => g.attributes).filter((a) => a.visibility !== 'PUBLIC').length > 0);
   const hiddenFiles = (product.files.filter((f) => !f.userDownload).length > 0);
   const state = {
      hasHiddenAttributes : hiddenAttrs,
      hasHiddenFiles      : hiddenFiles,
      showAllAttributes   : false,
      showAllFiles        : false
   };
   if ( !product.enquiry ) {
      const branches   = product.visibility.value ? productInfo.branches.filter((b) => !product.visibility.exceptions.includes(b.id)).concat([{ id : GLOBAL_ID, name : 'Other' }]) :
                                                    productInfo.branches.filter((b) => product.visibility.exceptions.includes(b.id));
      const branch     = (branches.length > 0) ? branches[0].id : '';
      const selectors  = selectorsForBranch(product, branch);
      const vatRate    = productInfo.vatRates.find((v) => v.id === product.pricing.vatId).name;
      state.branches   = branches;
      state.branch     = branch;
      state.quantities = selectors[0];
      state.quantity   = selectors[1];
      state.delivery   = selectors[2];
      state.vatRate    = vatRate;
   }
   return state;
}

//  --------------------------------------------------------------------------------------------------

function selectorsForBranch(product, branch) {
   if ( product.pricing.inclusive ) {
      const pricing = {
         inclusive : true,
         pricingA  : [ ],
         pricingB  : product.pricing.pricingB[branch] || product.pricing.pricingB['DEFAULT']
      };
      return initQuantitySelectorsRaw(pricing, product.quantity, product.delivery);
   } else {
      const pricing = {
         inclusive : false,
         pricingA  : product.pricing.pricingA[branch] || product.pricing.pricingA['DEFAULT'],
         pricingB  : product.pricing.pricingB[branch] || product.pricing.pricingB['DEFAULT']
      };
      return initQuantitySelectorsRaw(pricing, product.quantity, product.delivery);
   }
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(product, setState) {
   return {
      allAttributes : (e) => {
         const checked = e.target.checked;
         setState((state) => produce(state, (draft) => {
            draft.showAllAttributes = checked;
         }));
      },
      allFiles : (e) => {
         const checked = e.target.checked;
         setState((state) => produce(state, (draft) => {
            draft.showAllFiles = checked;
         }));
      },
      branch : (e) => {
         const branch = e.target.value;
         const selectors = selectorsForBranch(product, branch);
         setState((state) => produce(state, (draft) => {
            draft.branch     = branch;
            draft.quantities = selectors[0];
            draft.quantity   = selectors[1];
            draft.delivery   = selectors[2];
         }));
      },
      pricing : (q, d) => {
         setState((state) => produce(state, (draft) => {
            draft.quantity = q;
            draft.delivery = d;
         }));
      }
   };
}

//  --------------------------------------------------------------------------------------------------

function getAttributes(product, showAll) {
   if ( showAll ) {
      return product.attributes;
   }
   const attributes = [ ];
   product.attributes.forEach((g) => {
      const filtered = g.attributes.filter((a) => a.visibility === 'PUBLIC');
      if ( filtered.length ) {
         attributes.push({
            name       : g.name,
            attributes : filtered
         });
      }
   });
   return attributes;
}

//  --------------------------------------------------------------------------------------------------

function getFiles(product, showAll) {
   return {
      artwork1 : product.files.filter((f) => f.artwork1),
      artwork2 : product.files.filter((f) => f.artwork2),
      others   : showAll ? product.files : product.files.filter((f) => f.userDownload)
   };
}

//  --------------------------------------------------------------------------------------------------

