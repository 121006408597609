import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import PortalAPI from '../../PortalAPI';
import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import ContactDetails, { initContactErrors } from '../../components/ContactDetails';
import TextInputDialog from '../../components/TextInputDialog';

import IntegrationsDialog from './IntegrationsDialog';

import SurchargesDialog, { testSurchargesDetails } from './SurchargesDialog';

//  --------------------------------------------------------------------------------------------------

export default function SupplierDialog(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(config, props.details));

   const fieldHandlers = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const notesButtonHandler = () => editNotes(setState);

   const surchargesButtonHandler = () => editSurcharges(config, setState);

   const integrationsButtonHandler = () => editIntegration(setState);

   const saveButtonHandler = () => saveDetails(config, props, fields);

   const cancelButtonHandler = () => props.onClose(false);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.supplierId ? "Supplier Details" : "Create Supplier"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField id="name" label="Name" value={fields.name} onChange={fieldHandlers.name} error={errors.name} required fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel component="legend">Send when ordering:</FormLabel>
                     <FormGroup row={true}>
                        <RadioGroup row aria-label="send when ordering" value={fields.send} onChange={fieldHandlers.send}>
                           <FormControlLabel value="NONE"     control={<Radio color="primary"/>} label="Nothing"/>
                           <FormControlLabel value="SIMPLE"   control={<Radio color="primary"/>} label="Simple email"/>
                           <FormControlLabel value="PROFORMA" control={<Radio color="primary"/>} label="Pro forma email"/>
                        </RadioGroup>
                     </FormGroup>
                  </FormControl>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="email" label="Email" helperText="Email address(es) to which orders are sent, please enter only one address per line"
                             value={fields.email} onChange={fieldHandlers.email} error={errors.email} required={fields.send !== 'NONE'} fullWidth multiline rowsMax="6"/>
               </Grid>
               <Grid item xs={12}>
                  <Button className={classes.buttons} variant="contained" color="primary" onClick={notesButtonHandler}>Notes</Button>
                  <Button className={classes.buttons} variant="contained" color="primary" onClick={surchargesButtonHandler}>Delivery Surcharges</Button>
                  <Button className={classes.buttons} variant="contained" color="primary" onClick={integrationsButtonHandler}>Integrations</Button>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="h6">Contact details:</Typography>
               </Grid>
               <ContactDetails config={config} values={fields.main} errors={errors.main} onChange={fieldHandlers.details} required={true}/>
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={(errors.name || errors.email || errors.surcharges || errors.main.combined)}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   buttons   : (theme) => ({
      margin : theme.spacing(1),
   }),
});

//  --------------------------------------------------------------------------------------------------

function initState(config, details) {
   return {
      vatRates      : details.vatRates,
      integrations  : details.available,
      fields        : {
         version    : details.version,
         name       : details.name,
         send       : details.send,
         email      : details.email,
         notes      : details.notes,
         surcharges : details.surcharges,
         main       : details.main,
         routing    : details.routing
      },
      errors        : {
         name       : Utils.isBlank(details.name),
         email      : (details.send === 'NONE') ? Utils.isNotOptionalMLEmail(details.email) : Utils.isNotMLEmail(details.email),
         surcharges : testSurchargesDetails(details.surcharges),
         main       : initContactErrors(config, details.main)
      },
      dialog        : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {
   const handler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name'  : draft.errors.name  = Utils.isBlank(value);                                                                                          break;
         case 'send'  : draft.errors.email = (value === 'NONE') ? Utils.isNotOptionalMLEmail(draft.fields.email) : Utils.isNotMLEmail(draft.fields.email);  break;
         case 'email' : draft.errors.email = (draft.fields.send === 'NONE') ? Utils.isNotOptionalMLEmail(value) : Utils.isNotMLEmail(value);                break;
         default      : break;
      }
   }));
   return {
      name    : (e) => handler('name',  e.target.value),
      send    : (e) => handler('send',  e.target.value),
      email   : (e) => handler('email', e.target.value),
      notes   : (e) => handler('notes', e.target.value),
      details : (fields, errors) => setState((state) => produce(state, (draft) => {
         draft.fields.main = fields;
         draft.errors.main = errors;
      }))
   };
}

//  --------------------------------------------------------------------------------------------------

function editNotes(setState) {
   const closeHandler = (notes) => setState((state) => produce(state, (draft) => {
      draft.fields.notes = notes;
      draft.dialog       = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <TextInputDialog title="Notes" required={false} multiline={true} value={state.fields.notes} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editIntegration(setState) {
   const closeHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.routing = value;
      draft.dialog         = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <IntegrationsDialog integrations={state.integrations} value={state.fields.routing} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editSurcharges(config, setState) {
   const closeHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.surcharges = value;
      draft.errors.surcharges = testSurchargesDetails(value);
      draft.dialog            = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <SurchargesDialog config={config} vatRates={state.vatRates} value={state.fields.surcharges} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields) {
   if ( props.supplierId ) {
      config.showMessage('Updating Supplier');
      PortalAPI.putSupplier(props.vendorId, props.supplierId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Supplier');
      PortalAPI.postSupplier(props.vendorId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

