import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import ImageList from '../../components/ImageList';
import Selector, { findOption } from '../../components/Selector';
import TextInputDialog from '../../components/TextInputDialog';
import VisibilityDialog from '../../components/VisibilityDialog';

import PortalAPI from '../../PortalAPI';
import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import AttributesDialog from './AttributesDialog';
import FilesDialog from './FilesDialog';
import PreviewDialog from './PreviewDialog';
import PricingDialog, { testPricingDetails } from './PricingDialog';
import SerialDialog from './SerialDialog';
import SupplierDialog from './SupplierDialog';

//  --------------------------------------------------------------------------------------------------

export default function ProductDialog(props) {

   const editing = (props.productId ? true : false);

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(props.tabs, props.productInfo.product));

   const fieldHandlers = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const visibilityButtonHandler = () => editVisibity(props, setState);

   const attributesButtonHandler = () => editAttributes(props, setState);

   const filesButtonHandler = () => editFiles(setState);

   const pricingButtonHandler = () => editPricing(props, setState);

   const notesButtonHandler = () => editNotes(setState);

   const serialButtonHandler = () => editSerial(setState);

   const supplierButtonHandler = () => editSupplier(props, setState);

   const previewButtonHandler = () => preview(props, setState);

   const saveButtonHandler = () => saveDetails(editing, config, props, state.fields)

   const cancelButtonHandler = () => props.onClose(false);

   const { fields, errors, dialog } = state;

   const errorsBase = (errors.name || errors.description || errors.images || errors.pricing || errors.supplierProduct);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{(editing ? 'Product Details' : 'Create Product') + (fields.enquiry ? ' (Enquire Only)' : '')}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Selector options={props.tabs} value={state.tab} onChange={fieldHandlers.tab} label="Section" error={errors.tabId} required={true}/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="name" label="Name" value={fields.name} onChange={fieldHandlers.name} error={errors.name} fullWidth required/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="description" label="Description" value={fields.description} onChange={fieldHandlers.description} error={errors.description} rowsMax={4} multiline fullWidth required/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="sku" label="SKU" helperText="Only use this field for YOUR product identifier, for the suppliers product identifier use the 'Supplier Details' button below"
                             value={fields.sku} onChange={fieldHandlers.sku} error={errors.sku} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel shrink required error={errors.images}>Images</InputLabel>
                  <ImageList role="PI" reorder={true} markdown={false} images={fields.images} onChange={fieldHandlers.images}/>
               </Grid>
               <Grid item xs={12}>
                  <Button className={classes.buttons} variant="contained" onClick={supplierButtonHandler} color="primary">Supplier Details</Button>
                  <Button className={classes.buttons} variant="contained" onClick={visibilityButtonHandler} color="primary">Visibility</Button>
                  <Button className={classes.buttons} variant="contained" onClick={attributesButtonHandler} color="primary">Attributes ({state.attributeCount})</Button>
                  <Button className={classes.buttons} variant="contained" onClick={filesButtonHandler} color="primary">Files ({state.fileCount})</Button>
                  {
                     !fields.enquiry && (
                        <>
                           <Button className={classes.buttons} variant="contained" onClick={pricingButtonHandler} color="primary">Pricing</Button>
                           <Button className={classes.buttons} variant="contained" onClick={serialButtonHandler} color="primary">Serial Numbers</Button>
                        </>
                     )
                  }
                  <Button className={classes.buttons} variant="contained" onClick={notesButtonHandler} color="primary">Notes</Button>
               </Grid>
               <Grid item xs={12}>
                  <FormControlLabel control={(<Checkbox checked={state.fields.enabled} onChange={fieldHandlers.enabled}/>)} label="Enabled"/>
               </Grid>
            </Grid>
            { dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={previewButtonHandler} color="primary" disabled={errorsBase}>Preview</Button>
            <Button onClick={saveButtonHandler} color="primary" disabled={errorsBase || errors.tabId}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   buttons   : (theme) => ({
      margin : theme.spacing(1),
   }),
});

//  --------------------------------------------------------------------------------------------------

function initState(tabs, details) {
   return {
      fields             : {
         ...details
      },
      errors             : {
         tabId           : Utils.isBlank(details.tabId),
         name            : Utils.isBlank(details.name),
         description     : Utils.isBlank(details.description),
         images          : (details.images.length === 0),
         pricing         : !details.enquiry && testPricingDetails(details.pricing),
         supplierProduct : Utils.isBlank(details.supplierProduct.name)
      },
      tab                : findOption(tabs, details.tabId),
      attributeCount     : countAttributes(details.attributes),
      fileCount          : details.files.length,
      dialog             : false
   }
}

//  --------------------------------------------------------------------------------------------------

function countAttributes(groups) {
   return groups.reduce((t, g) => (t + g.attributes.length), 0);
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {

   const handleTab = (tab) => setState((state) => produce(state, (draft) => {
      draft.fields.tabId = (tab == null) ? '' : tab.id;
      draft.errors.tabId = (tab == null);
      draft.tab          = tab;
   }));

   const handler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name'        : draft.errors.name = Utils.isBlank(value); break;
         case 'description' : draft.errors.description = Utils.isBlank(value); break;
         default            : break;
      }
   }));

   const handleImages = (idx, details) => setState((state) => produce(state, (draft) => {
      if ( details === -1 ) {
         const [ removed ] = draft.fields.images.splice(idx, 1);
         draft.fields.images.splice((idx - 1), 0, removed);
      } else if ( details === 1 ) {
         const [ removed ] = draft.fields.images.splice(idx, 1);
         draft.fields.images.splice((idx + 1), 0, removed);
      } else if ( details === false ) {
         draft.fields.images.splice(idx, 1);
         draft.errors.images = (draft.fields.images.length === 0);
      } else {
         draft.fields.images[idx] = details;
         draft.errors.images = false;
      }
   }));

   const handleEnabled = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.enabled = value;
   }));

   return {
      tab         : (e, v) => handleTab(v),
      sku         : (e)    => handler('sku',         e.target.value),
      name        : (e)    => handler('name',        e.target.value),
      description : (e)    => handler('description', e.target.value),
      images      : handleImages,
      enabled     : (e)    => handleEnabled(e.target.checked)
   };

}

//  --------------------------------------------------------------------------------------------------

function editVisibity(props, setState) {
   const saveHandler = (vis) => setState((state) => produce(state, (draft) => {
      draft.fields.visibility = vis;
      draft.dialog = false;
   }));
   const cancelHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <VisibilityDialog title="Product Visibility" client={false} branches={props.productInfo.branches} value={state.fields.visibility}
                                       onSave={saveHandler} onCancel={cancelHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editAttributes(props, setState) {
   const closeHandler = (attrs) => setState((state) => produce(state, (draft) => {
      draft.fields.attributes = attrs;
      draft.attributeCount    = countAttributes(attrs);
      draft.dialog            = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <AttributesDialog templates={props.productInfo.attributes} enquiry={state.fields.enquiry} locked={props.productInfo.locked}
                                       groups={state.fields.attributes} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editFiles(setState) {
   const closeHandler = (files) => setState((state) => produce(state, (draft) => {
      draft.fields.files = files;
      draft.fileCount    = files.length;
      draft.dialog       = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <FilesDialog files={state.fields.files} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editPricing(props, setState) {
   const saveHandler = (pricing) => setState((state) => produce(state, (draft) => {
      draft.fields.pricing = pricing;
      draft.errors.pricing = testPricingDetails(pricing);
      draft.dialog         = false;
   }));
   const cancelHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <PricingDialog vatRates={props.productInfo.vatRates} branches={props.productInfo.branches} value={state.fields.pricing}
                                    locked={props.productInfo.locked} onSave={saveHandler} onCancel={cancelHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editNotes(setState) {
   const closeHandler = (notes) => setState((state) => produce(state, (draft) => {
      draft.fields.notes = notes;
      draft.dialog       = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <TextInputDialog title="Notes" required={false} multiline={true} value={state.fields.notes} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editSerial(setState) {
   const closeHandler = (details) => setState((state) => produce(state, (draft) => {
      draft.fields.serialType   = details.serialType;
      draft.fields.serialFormat = details.serialFormat;
      draft.dialog              = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <SerialDialog details={{ serialType : state.fields.serialType, serialFormat : state.fields.serialFormat }} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function editSupplier(props, setState) {
   const closeHandler = (details) => setState((state) => produce(state, (draft) => {
      draft.fields.supplierProduct = details;
      draft.errors.supplierProduct = Utils.isBlank(details.name);
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <SupplierDialog vendorName={props.productInfo.vendorName} supplierName={props.productInfo.supplierName}
                                     details={state.fields.supplierProduct} locked={props.productInfo.locked} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function preview(props, setState) {
   const closeHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => {
      const details = produce(props.productInfo, (draft) => {
         draft.product = state.fields;
      });
      return produce(state, (draft) => {
         draft.dialog = <PreviewDialog productInfo={details} onClose={closeHandler}/>;
      });
   });
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(editing, config, props, fields) {
   if ( editing ) {
      config.showMessage('Updating Product');
      PortalAPI.putProduct(props.clientId, props.productId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Product');
      PortalAPI.postProduct(props.clientId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

