import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PortalAPI from '../../PortalAPI';

import OrderList from './OrderList';

//  --------------------------------------------------------------------------------------------------

const QUERY_PATTERN = /^[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}(?:-[0-9]{3})?$/;

//  --------------------------------------------------------------------------------------------------

export default function SearchTab(props) {

   const { config, tab, selected, view } = props;

   const [ state, setState ] = useState(false);

   if ( selected === tab ) {
      if ( !state ) {
         setState({
            id     : '',
            error  : true,
            orders : false
         });
      }
   } else if ( state ) {
      setState(false);
   }

   const idFieldHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.id     = value;
         draft.error  = !QUERY_PATTERN.test(value);
         draft.orders = false;
      }));
   };

   const searchButtonHandler = () => {
      setState((state) => produce(state, (draft) => {
         draft.orders = null;
      }));
      PortalAPI.getOrders({ query : state.id, view }).then((response) => setState((state) => produce(state, (draft) => {
         draft.orders = response;
      }))).catch(config.reportAPIError);
   };

   return state && (
      <>
         <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
               <TextField label="Order / Basket ID" value={state.id} onChange={idFieldHandler} error={state.error} fullWidth required/>
            </Grid>
            <Grid item xs={12} sm={6}>
               <Button variant="contained" onClick={searchButtonHandler} color="primary" disabled={state.error}>Search</Button>
            </Grid>
         </Grid>
         <br/>
         {
            state.orders ? (
               <OrderList config={config} orders={state.orders} user={true} branch={true} client={true} vendor={true} view={view} onReload={searchButtonHandler}/>
            ) : (state.orders === null) && (
               <CircularProgress color="secondary"/>
            )
         }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

