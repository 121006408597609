import React, { useContext, useState, useMemo, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ColorPicker } from 'material-ui-color';

import ImageField from '../../components/ImageField';
import Selector, { findOption } from '../../components/Selector';
import TextInputDialog from '../../components/TextInputDialog';

import PortalAPI from '../../PortalAPI';
import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import GuestsDialog from './GuestsDialog';

//  --------------------------------------------------------------------------------------------------

export default function ClientDialog(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(props.details));

   useEffect(() => loadVendors(config, setState), [ config, setState ]);

   const fieldHandlers = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const guestsHandler = () => manageGuests(props.clientId, setState);

   const notesButtonHandler = () => editNotes(setState);

   const saveButtonHandler = () => saveDetails(config, props, state.fields);

   const cancelButtonHandler = () => props.onClose(false);

   const { fields, errors, dialog } = state;

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{props.clientId ? "Client Details" : "Create Client"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField id="name" label="Client Name" value={fields.name} onChange={fieldHandlers.name} error={errors.name} fullWidth required/>
               </Grid>
               <Grid item xs={12}>
                  <Selector options={state.vendors} value={state.vendor} onChange={fieldHandlers.vendor} label="Vendor"
                            helperText={<><b>IMPORTANT</b>: This field <b>MUST</b> be left blank unless the client is selling their own products and has a vendor set up specifically
                                          for them.  Do <b>NOT</b> under any circumstances whatsoever, select any other vendor in this field.</>}/>
               </Grid>
               <Grid item xs={12}>
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="primary" label="Primary Colour" value={fields.primary} onChange={fieldHandlers.primary_1} error={errors.primary} fullWidth required
                                   InputProps={{ endAdornment : (<ColorPicker value={fields.primary} onChange={fieldHandlers.primary_2} hideTextfield disableAlpha deferred/>) }}/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="secondary" label="Secondary Colour" value={fields.secondary} onChange={fieldHandlers.secondary_1} error={errors.secondary} fullWidth required
                                   InputProps={{ endAdornment : (<ColorPicker value={fields.secondary} onChange={fieldHandlers.secondary_2} hideTextfield disableAlpha deferred/>) }}/>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel error={errors.logo} required shrink>Logo</InputLabel>
                  <ImageField role="GI" value={fields.logo} onChange={fieldHandlers.logo}/>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="body1">The following recipients will receive details of all orders placed through this print portal:</Typography>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="email" label="Email" helperText="Please enter only one email address per line"
                             value={fields.email} onChange={fieldHandlers.email} error={errors.email} fullWidth multiline rowsMax="6"/>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="code" label="Client Code" value={fields.code} onChange={fieldHandlers.code} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormControlLabel className={classes.buttons} control={<Checkbox checked={fields.authorisation} onChange={fieldHandlers.authorisation}/>} label="Basket Authorisation"/>
                     <FormHelperText>
                        Please Note: This setting only controls whether a client can configure a user to start authorising baskets,
                        once a user is so configured, disabling this option affects neither them, nor the branches they are authorising for.
                     </FormHelperText>
                  </FormControl>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Button className={classes.buttons} variant="contained" color="primary" onClick={guestsHandler}>Guest Catalogues ({fields.guests.length})</Button>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <FormControlLabel className={classes.buttons} control={<Checkbox checked={fields.shared} onChange={fieldHandlers.shared}/>} label="Share catalogue"/>
               </Grid>
               <Grid item xs={12}>
                  <Button className={classes.buttons} variant="contained" onClick={notesButtonHandler} color="primary">Notes</Button>
               </Grid>
            </Grid>
            { dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={(errors.name || errors.primary || errors.secondary || errors.logo)}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   buttons   : (theme) => ({
      margin : theme.spacing(1),
   }),
});

//  --------------------------------------------------------------------------------------------------

function initState(details) {
   return {
      vendors       : false,
      vendor        : false,
      fields        : {
      ...details
      },
      errors        : {
         name       : Utils.isBlank(details.name),
         primary    : Utils.isNotColour(details.primary),
         secondary  : Utils.isNotColour(details.secondary),
         logo       : (details.logo === false),
         email      : Utils.isNotOptionalMLEmail(details.email)
      },
      dialog        : false
   };
}


//  --------------------------------------------------------------------------------------------------

function loadVendors(config, setState) {
   PortalAPI.getVendors().then((response) => setState((state) => produce(state, (draft) => {
      draft.vendors = response;
      draft.vendor  = findOption(response, draft.fields.vendorId);
      if ( draft.vendor === null ) {
         delete draft.fields.vendorId;
      } else {
         draft.fields.vendorId = draft.vendor.id;
      }
   }))).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {

   const handler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      switch ( field ) {
         case 'name'      : draft.errors.name      = Utils.isBlank(value);              break;
         case 'primary'   : draft.errors.primary   = Utils.isNotColour(value);          break;
         case 'secondary' : draft.errors.secondary = Utils.isNotColour(value);          break;
         case 'email'     : draft.errors.email     = Utils.isNotOptionalMLEmail(value); break;
         default          : break;
      }
   }));

   const vendorHandler = (e, value) => setState((state) => produce(state, (draft) => {
      draft.vendor = value;
      if ( value === null ) {
         delete draft.fields.vendorId;
      } else {
         draft.fields.vendorId = value.id;
      }
   }));

   const logoHandler = (image) => setState((state) => produce(state, (draft) => {
      draft.fields.logo = image;
      draft.errors.logo = (image === false);
   }));

   return {
      name          : (e) => handler('name',          e.target.value),
      vendor        : vendorHandler,
      primary_1     : (e) => handler('primary',       e.target.value),
      primary_2     : (e) => handler('primary',       ('#' + e.hex)),
      secondary_1   : (e) => handler('secondary',     e.target.value),
      secondary_2   : (e) => handler('secondary',     ('#' + e.hex)),
      logo          : logoHandler,
      notes         : (e) => handler('notes',         e.target.value),
      email         : (e) => handler('email',         e.target.value),
      shared        : (e) => handler('shared',        e.target.checked),
      code          : (e) => handler('code',          e.target.value),
      authorisation : (e) => handler('authorisation', e.target.checked)
   };
}

//  --------------------------------------------------------------------------------------------------

function editNotes(setState) {
   const closeHandler = (notes) => setState((state) => produce(state, (draft) => {
      draft.fields.notes = notes;
      draft.dialog       = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <TextInputDialog title="Notes" required={false} multiline={true} value={state.fields.notes} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function manageGuests(clientId, setState) {
   const saveHandler = (v) => setState((state) => produce(state, (draft) => {
      draft.fields.guests = v;
      draft.dialog        = false;
   }));
   const cancelHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <GuestsDialog client={clientId} guests={state.fields.guests} onSave={saveHandler} onCancel={cancelHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function saveDetails(config, props, fields) {
   if ( props.clientId ) {
      config.showMessage('Updating Client');
      PortalAPI.putClient(props.clientId, fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   } else {
      config.showMessage('Creating Client');
      PortalAPI.postClient(fields).then((response) => {
         config.hideMessage();
         props.onClose(true);
      }).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

export function newClientDetails() {
   return {
      version       : 0,
      name          : '',
      primary       : '#254158',
      secondary     : '#f3364c',
      logo          : false,
      notes         : '',
      email         : '',
      shared        : false,
      guests        : [ ],
      code          : '',
      authorisation : false
   };
}

//  --------------------------------------------------------------------------------------------------


