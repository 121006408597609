import React, { useState, useEffect } from 'react';

import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import UserDialog from './UserDialog';

//  --------------------------------------------------------------------------------------------------

export default function UserList(props) {

   const { config, client, branch } = props;

   const classes = useStyles(config.theme);

   const [ users, setUsers ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   const [ refresh, forceRefresh ] = useToggle();

   useEffect(() => {
      setUsers(false);
      PortalAPI.getUsers(client, branch).then((response) => {
         setUsers(response);
      }).catch(config.reportAPIError);
   }, [ config, client, branch, refresh ]);

   function handleDialogClose(saved) {
      setDialog(false);
      if ( saved ) {
         forceRefresh();
      }
   }

   function handleCreateUser() {
      config.showMessage('Loading');
      PortalAPI.getNewUser(client, branch).then((response) => {
         config.hideMessage();
         setDialog((<UserDialog userId={false} details={response} onClose={handleDialogClose}/>));
      }).catch(config.reportAPIError);
   }

   function handleEditUser(id) {
      config.showMessage('Loading');
      PortalAPI.getUser(id).then((response) => {
         config.hideMessage();
         setDialog((<UserDialog userId={id} details={response} onClose={handleDialogClose}/>));
      }).catch(config.reportAPIError);
   }

   function handleSetUserState(id, enable, authoriser) {
      setDialog(<ConfirmationDialog message={
                                       enable     ? <>Enable this user ?</> :
                                       authoriser ? <>Disable this user ?<br/><br/>IMPORTANT: This user authorises baskets for one or more branches.
                                                      Please ensure that someone else can perform this task for the affected branches, otherwise orders cannot be placed.</> :
                                                    <>Disable this user ?</>
                                    }
                                    confirmationButton={enable ? "Enable" : "Disable"}
                                    onConfirmation={() => handleSetUserStateConfirmed(id, enable)} onClose={() => handleDialogClose(false)}/>);
   }

   function handleSetUserStateConfirmed(id, enable) {
      handleDialogClose(false);
      config.showMessage('Updating');
      PortalAPI.putUserState(id, enable).then((response) => {
         config.hideMessage();
         forceRefresh();
      }).catch(config.reportAPIError);
   }

   function handleResetUserPassword(id) {
      setDialog(<ConfirmationDialog message="Reset this user's password ?" confirmationButton="Reset"
                                    onConfirmation={() => handleResetUserPasswordConfirmed(id)} onClose={() => handleDialogClose(false)}/>);
   }

   function handleResetUserPasswordConfirmed(id) {
      handleDialogClose(false);
      config.showMessage('Resetting Password');
      PortalAPI.putUserReset(id).then((response) => {
         config.hideMessage();
      }).catch(config.reportAPIError);
   }

   const smUp     = useMediaQuery(config.theme.breakpoints.up('sm'));
   const mdUp     = useMediaQuery(config.theme.breakpoints.up('md'));

   const btnState = (u) => <IconButton onClick={() => handleSetUserState(u.id, !u.enabled, u.authoriser)} aria-label={u.enabled ? 'enabled' : 'disabled'}>{u.enabled ? <CheckIcon/> : <BlockIcon/>}</IconButton>;
   const btnReset = (u) => <IconButton onClick={() => handleResetUserPassword(u.id)} aria-label="reset password"><VpnKeyIcon/></IconButton>;

   const cell1    = (u) => <>{(u.level === 'B') ? (u.branches === 1) ? <PeopleIcon/> : <Badge badgeContent={u.branches}><SupervisorAccountIcon/></Badge> :
                              (u.level === 'C') ? <PersonIcon/> : <VerifiedUserIcon/>}{u.locked && <LockIcon/>}</>;
   const cell2    = (u) => !u.locked ? <><Link color="inherit" href="#" onClick={() => handleEditUser(u.id)}>{u.name}<br/>{u.email}</Link></> : <>{u.name}<br/>{u.email}</>;
   const cell3    = (u) => <>{u.client && (<>{u.client}<br/></>)}{u.branch}</>;
   const cell4    = (u) => !u.locked && <>{btnState(u)}{u.enabled && btnReset(u)}</>;

   return users ? (
      <>
         {
            (users.length > 0) ? (
               <Paper className={classes.root} elevation={1}>
                  <Table>
                     <TableHead>
                        <TableRow>{
                           mdUp ? (
                              <>
                                 <TableCell/>
                                 <TableCell>Name / Email</TableCell>
                                 { users[0].branch && (<TableCell>{users[0].client && 'Client / '}Branch</TableCell>) }
                                 <TableCell>Actions</TableCell>
                              </>
                           ) : smUp ? (
                              <>
                                 <TableCell/>
                                 <TableCell>Name / Email{users[0].branch && (<>{users[0].client && ' / Client'} / Branch</>)}</TableCell>
                                 <TableCell>Actions</TableCell>
                              </>
                           ) : (
                              <TableCell>Name / Email{users[0].branch && (<>{users[0].client && ' / Client'} / Branch</>)} / Actions</TableCell>
                           )
                        }</TableRow>
                     </TableHead>
                     <TableBody>{
                        users.map(user => (
                           <TableRow key={user.id}>
                              {
                                 mdUp ? (
                                    <>
                                       <TableCell align="center">{cell1(user)}</TableCell>
                                       <TableCell>{cell2(user)}</TableCell>
                                       { user.branch && (<TableCell>{cell3(user)}</TableCell>) }
                                       <TableCell>{cell4(user)}</TableCell>
                                    </>
                                 ) : smUp ? (
                                    <>
                                       <TableCell align="center">{cell1(user)}</TableCell>
                                       <TableCell>{cell2(user)}{user.branch && (<><br/>{cell3(user)}</>)}</TableCell>
                                       <TableCell>{cell4(user)}</TableCell>
                                    </>
                                 ) : (
                                    <TableCell>{cell1(user)}<br/>{cell2(user)}{user.branch && (<><br/>{cell3(user)}</>)}<br/>{cell4(user)}</TableCell>
                                 )
                              }
                           </TableRow>
                        ))
                     }</TableBody>
                  </Table>
               </Paper>
            ) : (
               <Typography variant="h6">No users found.</Typography>
            )
         }
         <Fab className={classes.fab} color="primary" aria-label="Create User" onClick={handleCreateUser}><AddIcon/></Fab>
         { dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   }),
   fab              : (theme) => ({
      position      : 'fixed',
      bottom        : theme.spacing(2),
      right         : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

