import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Markdown from 'markdown-to-jsx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ImageGallery from '../components/ImageGallery';
import MessageDialog from '../components/MessageDialog';
import QuantitySelectors, { initQuantitySelectors } from '../components/QuantitySelectors';
import VariantSelectors, { initVariants, areVariantsPresent, isVariantSelected, getVariantAttributes } from '../components/VariantSelectors';

import DownloadsDialog from '../components/DownloadsDialog';
import TextInputDialog from '../components/TextInputDialog';

import PortalAPI from '../PortalAPI';
import Utils from '../Utils';
import { ConfigurationContext } from '../ConfigurationContext';

import AddressDialog from './AddressDialog';

//  --------------------------------------------------------------------------------------------------

export default function ProductDialog(props) {

   const { id, product, onClose } = props;

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(() => initState(product));

   const handlers = useMemo(() => createFieldHandlers(product, setState), [ product, setState ]);

   const enquireButtonHandler = () => {
      if ( product.enquiry ) {
         enquireOnlyItem(config, product, state, onClose);
      } else {
         enquireNormalItem(config, product, state, setState, onClose);
      }
   };

   const downloadsButtonHandler = () => {
      const closeHandler = () => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <DownloadsDialog files={product.files} onClose={closeHandler}/>;
      }));
   }

   const saveButtonHandler = () => orderItem(config, id, product, state, setState, onClose);

   const cancelButtonHandler = () => onClose(false);

   const { fields, errors, variants } = state;

   const ignore = product.enquiry ? 0 : ((errors.has('artwork1') ? 1 : 0) + (errors.has('artwork2') ? 1 : 0) + (errors.has('quantity') ? 1 : 0) + (errors.has('delivery') ? 1 : 0));

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>{product.name}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <ImageGallery images={product.images}/>
               </Grid>
               <Grid item xs={12} className={classes.heading}>
                  <Typography variant="subtitle1">Description</Typography>
               </Grid>
               <Grid item xs={12}>
                  <Markdown>{product.description}</Markdown>
               </Grid>
               {
                  variants ? (
                     areVariantsPresent(variants) && (
                        <>
                           <Grid item xs={12} className={classes.heading}>
                              <Typography variant="subtitle1">Options</Typography>
                           </Grid>
                           <VariantSelectors state={variants} onChange={handlers.variants} shrink/>
                        </>
                     )
                  ) : (
                     product.attributes.map((g, i) => (
                        <>
                           <Grid item key={`g$i`} xs={12} className={classes.heading}>
                              <Typography variant="subtitle1">{g.name}</Typography>
                           </Grid>
                           {
                              g.attributes.map((a) => (
                                 <Grid item xs={12} sm={6} key={a.id}>{
                                    (a.field === 'TEXT') ? (
                                       <TextField id={a.id} label={a.name} value={fields.attributes[a.id]} onChange={handlers[a.id]} required={a.required} error={errors.has(a.id)} InputProps={{ readOnly : a.readOnly }} fullWidth/>
                                    ) : (a.field === 'TEXTAREA') ? (
                                       <TextField id={a.id} label={a.name} value={fields.attributes[a.id]} onChange={handlers[a.id]} required={a.required} error={errors.has(a.id)} InputProps={{ readOnly : a.readOnly }} fullWidth multiline/>
                                    ) : (
                                       <TextField id={a.id} label={a.name} value={fields.attributes[a.id]} onChange={handlers[a.id]} required={a.required} error={errors.has(a.id)} InputProps={{ readOnly : a.readOnly }} fullWidth select>{
                                          a.options.map((o) => <MenuItem key={o.id} value={o.id}>{o.value}</MenuItem>)
                                       }</TextField>
                                    )
                                 }</Grid>
                              ))
                           }
                        </>
                     ))
                  )
               }
               {
                  (state.artwork1.length > 0 || state.artwork2.length > 0) && (
                     <Grid item xs={12} className={classes.heading}>
                        <Typography variant="subtitle1">Artwork</Typography>
                     </Grid>
                  )
               }
               {
                  (state.artwork1.length > 0) && (
                     <Grid item xs={12}>
                        <InputLabel error={errors.has('artwork1')}  shrink required>Artwork #1</InputLabel>
                        <Select value={fields.artwork1} onChange={handlers.artwork1} error={errors.has('artwork1')} fullWidth>{
                           state.artwork1.map((f) => <MenuItem key={f.id} value={f.id}>{f.description}</MenuItem>)
                        }</Select>
                     </Grid>
                  )
               }
               {
                  (state.artwork2.length > 0) && (
                     <Grid item xs={12}>
                        <InputLabel error={errors.has('artwork2')}  shrink required>Artwork #2</InputLabel>
                        <Select value={fields.artwork2} onChange={handlers.artwork2} error={errors.has('artwork2')} fullWidth>{
                           state.artwork2.map((f) => <MenuItem key={f.id} value={f.id}>{f.description}</MenuItem>)
                        }</Select>
                     </Grid>
                  )
               }
               {
                  product.enquiry ? (
                     <>
                        <Grid item xs={12} className={classes.heading}>
                           <Typography variant="subtitle1">Additional Information</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <TextField placeholder="Please add any additional requirements, comments, or questions, that you may have, here..."
                                      value={fields.additional} onChange={handlers.additional} fullWidth multiline rows={6}/>
                        </Grid>
                     </>
                  ) : (
                     <>
                        <Grid item xs={12} className={classes.heading}>
                           <Typography variant="subtitle1">Quantity &amp; Delivery</Typography>
                        </Grid>
                        {
                           (state.quantities.length > 0) ? (
                              <React.Fragment key="canDeliver">
                                 <QuantitySelectors quantities={state.quantities} quantity={fields.quantity} delivery={fields.delivery} onChange={handlers.pricing}/>
                                 <Grid item xs={12}>
                                    <Typography variant="body1">All prices shown are exclusive of VAT which will be added at the applicable rates once placed in your basket.</Typography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Typography variant="body1">
                                       If this is not the quantity you need, or you're wanting something slightly different, then you can use the 'Enquire' button below to contact the vendor.
                                    </Typography>
                                 </Grid>
                              </React.Fragment>
                           ) : (
                              <React.Fragment key="cannotDeliver">
                                 <Grid item xs={12}>
                                    <Typography variant="body1">There are no delivery options available for the specified address.</Typography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Typography variant="body1">If you wish to contact the vendor regarding this, then please use the 'Enquire' button below.</Typography>
                                 </Grid>
                              </React.Fragment>
                           )
                        }
                        <Grid item xs={12} className={classes.heading}>
                           <Typography variant="subtitle1">Delivery Address and Instruction</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body1">
                              { fields.address.name }, { fields.address.street }
                              { fields.address.area && (', ' + fields.address.area) }
                              { fields.address.town && (', ' + fields.address.town) }
                              { fields.address.county && (', ' + fields.address.county) }
                              { fields.address.postcode && (', ' + fields.address.postcode) }
                              { fields.address.country && (', ' + countryLookup(config, fields.address.country)) }
                              { fields.address.contact.forename && (', ' + (fields.address.contact.surnameFirst ? (fields.address.contact.surname + ' ' + fields.address.contact.forename) :
                                                                                                                  (fields.address.contact.forename + ' ' + fields.address.contact.surname))) }
                              { fields.address.phone && (', ' + fields.address.phone) }
                              { fields.address.email && (', ' + fields.address.email) }
                           </Typography>
                           {
                              fields.instruction && (
                                 <>
                                    <hr/>
                                    <Typography variant="body1">{fields.instruction}</Typography>
                                 </>
                              )
                           }
                        </Grid>
                        <Grid item xs={12}>
                           <Button onClick={() => changeDeliveryAddress(config, product, setState)} color="primary" fullWidth>Change</Button>
                        </Grid>
                     </>
                  )
               }
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={enquireButtonHandler} color="primary" disabled={errors.size > ignore}>Enquire</Button>
            { !product.enquiry && <Button onClick={saveButtonHandler} color="primary" disabled={errors.size > 0}>{id ? 'Update Basket' : 'Add to Basket'}</Button> }
            <Button onClick={downloadsButtonHandler} color="primary" disabled={product.files.length === 0}>Downloads</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   heading       : (theme) => ({
      background : theme.palette.primary.main,
      color      : theme.palette.primary.contrastText
   })
});

//  --------------------------------------------------------------------------------------------------

function initState(product) {

   const state = {
      artwork1 : product.artworkFiles.filter((f) => f.artwork1),
      artwork2 : product.artworkFiles.filter((f) => f.artwork2),
      fields   : {
         attributes : { },
         artwork1   : product.artwork1,
         artwork2   : product.artwork2
      },
      errors   : new Set(),
      dialog   : false
   };

   if ( state.artwork1.length > 0 && Utils.isBlank(product.artwork1) ) {
      state.errors.add('artwork1');
   }
   if ( state.artwork2.length > 0 && Utils.isBlank(product.artwork2) ) {
      state.errors.add('artwork2');
   }

   if ( product.variants ) {
      state.variants = initVariants(product.variants);
      if ( areVariantsPresent(product.variants) ) {
         state.errors.add('variants');
      }
   } else {
      state.variants = null;
      product.attributes.forEach((g) => g.attributes.forEach((a) => {
         state.fields.attributes[a.id] = a.value;
         if ( a.required && a.value === '' ) {
            state.errors.add(a.id);
         }
      }));
   }

   if ( product.enquiry ) {
      state.fields.quantity   = '';
      state.fields.additional = '';
   } else {
      const selectors          = initQuantitySelectors(product.pricing, product.quantity, product.delivery);
      state.quantities         = selectors[0];
      state.fields.quantity    = selectors[1];
      state.fields.delivery    = selectors[2];
      state.fields.addressId   = product.addressId;
      state.fields.address     = product.addresses[product.addressId];
      state.fields.instruction = product.instructions[product.addressId];
      if ( selectors[1] === 0  ) state.errors.add('quantity');
      if ( selectors[2] === '' ) state.errors.add('delivery');
   }

   return state;

}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(product, setState) {
   const handlers = { };
   const setError = (draft, id, value) => {
      if ( value ) {
         draft.errors.add(id);
      } else {
         draft.errors.delete(id);
      }
   };
   handlers.variants = (value) => setState((state) => produce(state, (draft) => {
      draft.variants = value;
      if ( isVariantSelected(value) ) {
         draft.errors.delete('variants');
      } else {
         draft.errors.add('variants');
      }
   }));
   product.attributes.forEach((g) => g.attributes.forEach((a) => {
      if ( a.field.startsWith('TEXT') ) {
         handlers[a.id] = (e) => {
            const value = e.target.value;
            setState((state) => produce(state, (draft) => {
               draft.fields.attributes[a.id] = value;
               setError(draft, a.id, (a.required && Utils.isBlank(value)));
            }));
         };
      } else {
         handlers[a.id] = (e) => {
            const value = e.target.value;
            setState((state) => produce(state, (draft) => {
               draft.fields.attributes[a.id] = value;
               setError(draft, a.id, (Utils.isBlank(value) ? a.required : (a.options.findIndex(o => o.id === value) === -1)));
            }));
         };
      }
   }));
   const artworkHandler = (field, value) => {
      setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         if ( Utils.isBlank(value) ) {
            draft.errors.add(field);
         } else {
            draft.errors.delete(field);
         }
      }));
   };
   handlers.artwork1 = (e) => artworkHandler('artwork1', e.target.value);
   handlers.artwork2 = (e) => artworkHandler('artwork2', e.target.value);
   handlers.pricing = (q, d) => {
      const closeDialog = () => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         if ( d === 'COL' && draft.fields.delivery !== 'COL' ) {
            draft.dialog = <MessageDialog title="Important"
                                          message={ 'You have chosen the collection option for this item, please ensure that your contact details ' +
                                                    'are in the delivery address field so the supplier can notify you when it is ready to collect' }
                                          onClose={closeDialog}/>
         }
         draft.fields.quantity = q;
         draft.fields.delivery = d;
         if ( q === 0 ) {
            draft.errors.add('quantity');
         } else {
            draft.errors.delete('quantity');
         }
         if ( d === '' ) {
            draft.errors.add('delivery');
         } else {
            draft.errors.delete('delivery');
         }
      }));
   };
   handlers.additional = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.additional = value;
      }));
   };
   return handlers;
}

//  --------------------------------------------------------------------------------------------------

function countryLookup(config, country) {
   const entry = config.countries.find(c => c.id === country);
   return entry ? entry.name : '???';
}

//  --------------------------------------------------------------------------------------------------

function changeDeliveryAddress(config, product, setState) {
   const handleClose = (id, address, instruction) => {
      config.showMessage('Refreshing Prices');
      PortalAPI.getBasketItemPricing(product.clientId, product.branchId, product.productId, address.country, address.postcode).then((response) => {
         config.hideMessage();
         setState((state) => produce(state, (draft) => {
            const selectors          = initQuantitySelectors(response, state.fields.quantity, state.fields.delivery);
            draft.quantities         = selectors[0];
            draft.fields.quantity    = selectors[1];
            draft.fields.delivery    = selectors[2];
            draft.fields.addressId   = id;
            draft.fields.address     = address;
            draft.fields.instruction = instruction;
            if ( selectors[1] === 0  ) draft.errors.add('quantity');
            if ( selectors[2] === '' ) draft.errors.add('delivery');
            draft.dialog             = false;
         }));
      }).catch(config.reportAPIError);
   };
   setState((state) => produce(state, (draft) => {
      draft.dialog = <AddressDialog config={config} onClose={handleClose}
                                    addresses={product.addresses} instructions={product.instructions}
                                    addressId={state.fields.addressId} address={state.fields.address} instruction={state.fields.instruction}/>
   }));
}

//  --------------------------------------------------------------------------------------------------

function enquireOnlyItem(config, product, state, onClose) {
   submitEnquiry(config, product, state, state.fields.additional, onClose);
}

//  --------------------------------------------------------------------------------------------------

function enquireNormalItem(config, product, state, setState, onClose) {
   const closeHandler = (text) => {
      setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      if ( text !== '' ) {
         submitEnquiry(config, product, state, text, onClose);
      }
   };
   setState((state) => produce(state, (draft) => {
      draft.dialog = <TextInputDialog title="Product Enquiry" label="Please describe your requirements:" value="" multiline required onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function submitEnquiry(config, product, state, additional, onClose) {
   const details = {
      clientId       : product.clientId,
      branchId       : product.branchId,
      productId      : product.productId,
      productVersion : product.productVersion,
      additional     : additional
   };
   if ( state.variants ) {
      details.attributes = getVariantAttributes(state.variants);
   } else {
      details.attributes = [ ];
      product.attributes.forEach((g) => g.attributes.forEach((a) => {
         const value = state.fields.attributes[a.id];
         if ( a.field === 'SELECT' ) {
            const option = a.options.find((o) => (o.id === value));
            details.attributes.push({
               name  : a.name,
               value : option ? option.value : 'ERROR'
            });
         } else {
            details.attributes.push({
               name  : a.name,
               value : value
            });
         }
      }));
   }
   config.showMessage('Submitting');
   PortalAPI.postEnquiry(details).then((response) => {
      config.hideMessage();
      onClose(true);
   }).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------

function orderItem(config, id, product, state, setState, onClose) {
   const details = {
      basketVersion  : product.basketVersion,
      clientId       : product.clientId,
      branchId       : product.branchId,
      productId      : product.productId,
      productVersion : product.productVersion,
      attributes     : state.fields.attributes,
      artwork1       : state.fields.artwork1,
      artwork2       : state.fields.artwork2,
      quantity       : parseInt(state.fields.quantity),
      delivery       : state.fields.delivery,
      addressId      : state.fields.addressId,
      address        : state.fields.addressId === 'Specify' ? state.fields.address : null,
      instruction    : state.fields.instruction
   };
   config.showMessage('Saving');
   (id ? PortalAPI.putBasketItem(id, details) : PortalAPI.postBasketItem(details)).then((response) => {
      config.hideMessage();
      config.setBasket(response);
      onClose(true);
   }).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------


