import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import SR001, { newSR001Fields, newSR001Confirm, newSR001Errors } from './SR001';

//  --------------------------------------------------------------------------------------------------

export default function IntegrationsPage(props) {

   const [ state, setState ] = useState(() => initState(props.integrations, props.value));

   const routingHandler = (e) => selectRouting(e.target.value, setState);

   const saveButtonHandler = () => props.onClose(state.fields);

   const cancelButtonHandler = () => props.onClose(props.value);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" fullWidth>
         <DialogTitle>Integrations</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                     <Select variant="outlined" value={state.fields.selected} onChange={routingHandler} fullWidth>{
                        props.integrations.map((i) => (<MenuItem key={i.id} value={i.id}>{i.description}</MenuItem>))
                     }</Select>
                  </Grid>
               </Grid>
               {
                  state.guides.has(state.fields.selected) && (
                     <Grid item xs={12}>
                        <table>
                           <tbody>
                              <tr>
                                 <td style={{ width : '48px' }}><img src="/icons/icon_pdf.svg" alt="PDF Icon" height={48}/></td>
                                 <td>
                                    <Typography>Read our <Link href={state.guides.get(state.fields.selected)}>integration guide</Link> for further information.</Typography>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </Grid>
                  )
               }
               {
                  (state.fields.selected !== 'NONE') && (
                     <Grid item xs={12}>{
                        (state.fields.selected === 'SR001') ? (
                           <SR001 state={state} setState={setState}/>
                        ) : (
                           <Typography>No form defined for this integration !</Typography>
                        )
                     }</Grid>
                  )
               }
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={
               (state.fields.selected === 'SR001' && state.errors.sr001.errors)
            }>Update</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(integrations, value) {
   const state = {
      guides         : integrations.reduce((s, i) => (i.guide ? s.set(i.id, i.guide) : s), new Map()),
      fields         : { ...value },
      confirm        : { },
      errors         : { },
      routingChanged : false
   };
   switch ( value.selected ) {

      case 'SR001' :
         state.confirm.sr001 = newSR001Confirm();
         state.errors.sr001  = newSR001Errors(value.sr001, state.confirm.sr001, false);
         break;

      default :
         break;

   }
   return produce(state, (draft) => { });
}

//  --------------------------------------------------------------------------------------------------

function selectRouting(value, setState) {
   setState((state) => produce(state, (draft) => {
      draft.routingChanged = true;
      draft.fields.selected = value;
      //  Clear the parameters for the deselected integration
      switch ( state.fields.selected ) {

         case 'SR001' :
            delete draft.fields.sr001;
            delete draft.confirm.sr001;
            delete draft.errors.sr001;
            break;

         default :
            break;

      }
      //  Initialize the parameters for the selected integration
      switch ( value ) {

         case 'SR001' :
            draft.fields.sr001  = newSR001Fields();
            draft.confirm.sr001 = newSR001Confirm();
            draft.errors.sr001  = newSR001Errors(draft.fields.sr001, draft.confirm.sr001, true);
            break;

         default :
            break;

      }
   }));
}

//  --------------------------------------------------------------------------------------------------




