import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';

//  --------------------------------------------------------------------------------------------------

export default function InfoWidget(props) {

   const [ anchor, setAnchor ] = useState(null);

   return (
      <>
         <IconButton color="inherit" variant="contained" onClick={(event) => setAnchor(event.currentTarget)}>{props.icon}</IconButton>
         <Popper id={anchor ? 'popper' : null} open={anchor !== null} anchorEl={anchor} style={{ zIndex : 9000 }} transition modifiers={MODIFIERS}>{ ({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setAnchor(null)}>
               <Fade {...TransitionProps} timeout={500}>{props.render}</Fade>
            </ClickAwayListener>
         )}</Popper>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

const MODIFIERS = {
   flip: {
      enabled           : true,
   },
   preventOverflow: {
      enabled           : true,
      boundariesElement : 'scrollParent',
   },
   arrow: {
      enabled           : true,
      element           : 'basket-popover'
   }
};

//  --------------------------------------------------------------------------------------------------


