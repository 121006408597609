import React from 'react';

import produce from 'immer';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function SR001(props) {

   const { state, setState } = props;

   const fieldHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields.sr001[field] = value;
      switch ( field ) {

         case 'username' :
            draft.errors.sr001.username = Utils.isBlank(value);
            break;

         case 'password' :
            draft.errors.sr001.password1 = validationActual(state.routingChanged, value);
            draft.errors.sr001.password2 = validationConfirmation(state.routingChanged, draft.confirm.sr001.password, value);
            break;

         default :
            break;

      };
      mergeErrors(draft.errors.sr001);
   }));

   const confirmPasswordHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.confirm.sr001.password = value;
      draft.errors.sr001.password2 = validationConfirmation(state.routingChanged, value, draft.fields.sr001.password);
      mergeErrors(draft.errors.sr001);
   }));

   const poNumberHandler = (e) => {
      const checked = e.target.checked;
      setState((state) => produce(state, (draft) => {
         draft.fields.sr001.poNumber = checked;
      }));
   };

   const fields  = state.fields.sr001;
   const confirm = state.confirm.sr001;
   const errors  = state.errors.sr001;

   return (
      <Grid container spacing={2}>
         <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
               <TextField id="username" label="Username" value={fields.username} onChange={(e) => fieldHandler('username', e.target.value)} error={errors.username} fullWidth required/>
            </Grid>
         </Grid>
         {
            state.routingChanged ? (
               <>
                  <Grid item container spacing={2} xs={12}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password1" type="password" label="Password" value={fields.password} onChange={(e) => fieldHandler('password', e.target.value)} error={errors.password1}
                                   fullWidth required/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password2" type="password" label="Confirm Password" value={confirm.password} onChange={(e) => confirmPasswordHandler(e.target.value)} error={errors.password2}
                                   fullWidth required/>
                     </Grid>
                  </Grid>
               </>
            ) : (
               <>
                  <Grid item container spacing={2} xs={12}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password1" type="password" label="Change Password" value={fields.password} onChange={(e) => fieldHandler('password', e.target.value)} error={errors.password1}
                                   placeholder="--- Keep Current ---" InputLabelProps={{ shrink: true }} fullWidth/>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField id="password2" type="password" label="Confirm Change Password" value={confirm.password} onChange={(e) => confirmPasswordHandler(e.target.value)} error={errors.password2}
                                   fullWidth required={fields.password !== ''} disabled={fields.password === ''}/>
                     </Grid>
                  </Grid>
               </>
            )
         }
         <Grid item xs={12}>
            <FormControlLabel control={(<Checkbox checked={fields.poNumber} onChange={poNumberHandler}/>)} label="Send purchase order number"/>
         </Grid>
      </Grid>
   );

}

//  --------------------------------------------------------------------------------------------------

function validationActual(changed, entered) {
   return (changed && Utils.isBlank(entered));
}

function validationConfirmation(changed, entered, actual) {
   return ((changed && Utils.isBlank(entered)) || (entered !== actual));
}

function mergeErrors(draft) {
   draft.errors = (draft.username || draft.password1 || draft.password2);
}

//  --------------------------------------------------------------------------------------------------

export function newSR001Fields() {
   return {
      username : '',
      password : '',
      poNumber : false
   };
}

//  --------------------------------------------------------------------------------------------------

export function newSR001Confirm() {
   return {
      password : ''
   };
}

//  --------------------------------------------------------------------------------------------------

export function newSR001Errors(fields, confirm, changed) {
   const username  = Utils.isBlank(fields.username);
   const password1 = validationActual(changed, fields.password);
   const password2 = validationConfirmation(changed, confirm.password, fields.password);
   const errors    = (username || password1 || password2);
   return { username, password1, password2, errors };
}

//  --------------------------------------------------------------------------------------------------

