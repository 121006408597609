import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function PricingADialog(props) {

   const { quantities, value, locked, onSave, onCancel } = props;

   const [ state, setState ] = useState(() => initState(value));

   const handlers = useMemo(() => createHandlers(quantities, setState), [ quantities, setState ]);

   const saveHandler = () => onSave({
      quantity : parseInt(state.fields.quantity),
      cost     : state.fields.cost,
      price    : state.fields.price
   });

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Quantity Price</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField label="Quantity" value={state.fields.quantity} error={state.errors.quantity} onChange={handlers.quantity}
                             disabled={locked} required={!locked} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Cost Price" value={state.fields.cost} error={state.errors.cost} onChange={handlers.cost}
                             disabled={locked} required={!locked} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Selling Price" value={state.fields.price} error={state.errors.price} onChange={handlers.price}
                             disabled={locked} required={!locked} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>{
            locked ? (
               <Button onClick={onCancel} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveHandler} disabled={(state.errors.quantity || state.errors.cost || state.errors.price)} color="primary">Save</Button>
                  <Button onClick={onCancel} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(value) {
   return {
      fields : {
         quantity : value.quantity.toString(),
         cost     : value.cost,
         price    : value.price
      },
      errors : {
         quantity : Utils.isNotOnePlusInteger(value.quantity.toString()),
         cost     : Utils.isNotPrice(value.cost),
         price    : Utils.isNotPrice(value.price)
      }
   };
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(quantities, setState) {

   const handler = (f, v) => setState((state) => produce(state, (draft) => {
      draft.fields[f] = v;
      switch ( f ) {
         case 'quantity' : draft.errors[f] = Utils.isNotOnePlusInteger(v) || quantities.indexOf(v) !== -1; break;
         case 'cost'     : draft.errors[f] = Utils.isNotPrice(v); break;
         case 'price'    : draft.errors[f] = Utils.isNotPrice(v); break;
         default         : break;
      }
   }));

   return {
      quantity : (e) => handler('quantity', e.target.value),
      cost     : (e) => handler('cost', e.target.value),
      price    : (e) => handler('price', e.target.value)
   };

}

//  --------------------------------------------------------------------------------------------------

export function newPricingADetails() {
   return {
      quantity : 0,
      cost     : '0.00',
      price    : '0.00'
   };
}

//  --------------------------------------------------------------------------------------------------
