import React from 'react';
import ReactDOM from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import { enableAllPlugins, setAutoFreeze } from 'immer';

import Application from './Application';

//  --------------------------------------------------------------------------------------------------

enableAllPlugins();
setAutoFreeze(true);

//  --------------------------------------------------------------------------------------------------

ReactDOM.render((
   <React.StrictMode>
      <CssBaseline>
         <Application/>
      </CssBaseline>
   </React.StrictMode>
), document.getElementById('root'));

//  --------------------------------------------------------------------------------------------------

