import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ConfirmationDialog from '../components/ConfirmationDialog';

import PortalAPI from '../PortalAPI';

import Basket from './Basket';
import EPDQDetailsDialog from './EPDQDetailsDialog';
import RejectDialog from './RejectDialog';

//  --------------------------------------------------------------------------------------------------

export default function BasketViewerDialog(props) {

   const { config, authorisation, basketId, basket, payment, canLock, onClose } = props;

   const [ dialog, setDialog ] = useState(false);

   const closeButtonHandler = () => onClose(false);

   const closeDialog = (refresh) => {
      if ( refresh ) {
         onClose(true);
      } else {
         setDialog(false);
      }
   }

   const authoriseButtonHandler = () => {
      switch ( payment ) {

         case 'VENDOR' :
            const confirmationHandler = () => {
               setDialog(false);
               config.showMessage('Authorising');
               PortalAPI.putBasketAuthorisation(basketId, true, '').then((response) => {
                  config.hideMessage();
                  onClose(true);
               }).catch(config.reportAPIError);
            };
            setDialog(<ConfirmationDialog message="Authorise this basket ?" confirmationButton="Authorise" onConfirmation={confirmationHandler} onClose={() => setDialog(false)}/>);
            break;

         case 'EPDQ' :
            config.showMessage("Loading");
            PortalAPI.getUserBilling().then((response) => {
               config.hideMessage();
               setDialog(<EPDQDetailsDialog config={config} authorise={true} basket={basketId} details={response} onClose={closeDialog}/>);
            }).catch(config.reportAPIError);
            break;

         default :
            break;

      }
   };

   const rejectButtonHandler = () => {
      const confirmationHandler = (reason) => {
         setDialog(false);
         config.showMessage('Rejecting');
         PortalAPI.putBasketAuthorisation(basketId, false, reason).then((response) => {
            config.hideMessage();
            onClose(true);
         }).catch(config.reportAPIError);
      };
      setDialog(<RejectDialog onConfirmation={confirmationHandler} onClose={() => setDialog(false)}/>);
   }

   return (
      <Dialog open disableBackdropClick onClose={closeButtonHandler} scroll="paper" maxWidth="md" fullWidth>
         <DialogTitle>Basket: {basketId}</DialogTitle>
         <DialogContent dividers={true}>
            <Basket config={config} basket={basket} edit={false}/>
            { dialog }
         </DialogContent>
         <DialogActions>
            {
               (authorisation && canLock) && (
                  <>
                     <Button color="primary" onClick={authoriseButtonHandler}>Authorise</Button>
                     <Button color="primary" onClick={rejectButtonHandler}>Reject</Button>
                  </>
               )
            }
            <Button onClick={closeButtonHandler} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------



