import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function MarkupDialog(props) {

   const { branches, value, onSave, onCancel } = props;

   const [ state, setState ] = useState(() => initState(value));

   const handlers = useMemo(() => createHandlers(setState), [ setState ]);

   const saveHandler = () => onSave(state.fields);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Branch Markup</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <InputLabel shrink required error={errors.branch}>Branch</InputLabel>
                  <Select value={fields.branch} onChange={handlers.branch} disabled={value.branch !== ''} fullWidth>{
                     branches.map((b) => <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>)
                  }</Select>
               </Grid>
               <Grid item xs={12}>
                  <TextField id="% markup" label="% Markup" value={fields.markup} onChange={handlers.markup} error={errors.markup} fullWidth required/>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveHandler} color="primary" disabled={errors.branch}>Save</Button>
            <Button onClick={onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(value) {
   return {
      fields : {
         ...value
      },
      errors : {
         branch : value.branch === '',
         markup : Utils.isNotZeroPlusNumber(value.markup)
      }
   };
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(setState) {
   return {
      branch : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.fields.branch = value;
            draft.errors.branch = value === '';
         }));
      },
      markup : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.fields.markup = value;
            draft.errors.markup = Utils.isNotZeroPlusNumber(value);
         }));
      }
   };

}

//  --------------------------------------------------------------------------------------------------


