import React, {useMemo} from 'react';

import produce from 'immer';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Utils from '../Utils';

import NameField, { newNameDetails, getNameErrors } from './NameField';

//  --------------------------------------------------------------------------------------------------

export default function ContactDetails(props) {

   const { config, values, errors, required, onChange } = props;

   const handlers = useMemo(() => createFieldHandlers(config, values, errors, onChange), [ config, values, errors, onChange ]);

   const disabled = !required;

   return (
      <Grid container spacing={1}>
         <Grid item xs={12}>
            <TextField id="name" label="Company Name" value={values.name} error={required && errors.name} onChange={handlers.name} required={required} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <TextField id="street" label="Street" value={values.street} error={required && errors.street} onChange={handlers.street} required={required} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <TextField id="area" label="Area" value={values.area} onChange={handlers.area} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <TextField id="town" label="Town" value={values.town} error={required && errors.town} onChange={handlers.town} required={required} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <TextField id="county" label="County" value={values.county} error={required && errors.county} onChange={handlers.county} required={required} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <TextField id="postcode" label="Postcode" value={values.postcode} error={required && errors.postcode} onChange={handlers.postcode} required={required} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <FormControl error={required && errors.country} required={required} disabled={disabled} fullWidth>
               <InputLabel>Country</InputLabel>
               <Select label="Country" variant="standard" value={values.country} onChange={handlers.country}>
                  { config.countries.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>) }
               </Select>
            </FormControl>
         </Grid>
         <NameField values={values.contact} errors={errors.contact} onChange={handlers.contact} required={required} disabled={disabled}/>
         <Grid item xs={12}>
            <TextField id="phone" label="Phone" value={values.phone} error={required && errors.phone} onChange={handlers.phone} required={required} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <TextField id="email" label="Email" value={values.email} error={required && errors.email} onChange={handlers.email} required={required} disabled={disabled} fullWidth/>
         </Grid>
      </Grid>
   );
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(config, values, errors, onChange) {

   const combineErrors = (draft) => {
      draft.combined = (draft.name || draft.street || draft.town || draft.county || draft.postcode || draft.country || draft.contact.combined || draft.phone || draft.email);
   };

   const defaultHandler = (field, value) => {
      const newValues = produce(values, (draft) => {
         draft[field] = value;
      });
      const newErrors = produce(errors, (draft) => {
         switch ( field ) {
            case 'name'   : draft.name   = Utils.isBlank(value);     break;
            case 'street' : draft.street = Utils.isBlank(value);     break;
            //  [ Area is optional ]
            case 'town'   : draft.town   = Utils.isBlank(value);     break;
            case 'county' : draft.county = Utils.isBlank(value);     break;
            //  [ Postcode, Country, & Contact are handled separately ]
            case 'phone'  : draft.phone  = Utils.isBlank(value);     break;
            case 'email'  : draft.email  = Utils.isNotEmail(value);  break;
            default       : break;
         }
         combineErrors(draft);
      });
      onChange(newValues, newErrors);
   };

   const postcodeAndCountryHandler = (field, value) => {
      const newValues = produce(values, (draft) => {
         draft[field] = value;
      });
      const [ postcode, country ] = postcodeAndCountryErrors(config, newValues.postcode, newValues.country);
      const newErrors = produce(errors, (draft) => {
         draft.postcode = postcode;
         draft.country  = country;
         combineErrors(draft);
      });
      onChange(newValues, newErrors);
   };

   const contactHandler = (cv, ce) => {
      const newValues = produce(values, (draft) => {
         draft.contact = cv;
      });
      const newErrors = produce(errors, (draft) => {
         draft.contact = ce;
         combineErrors(draft);
      });
      onChange(newValues, newErrors);
   };

   return {
      name     : (e) => defaultHandler('name',   e.target.value),
      street   : (e) => defaultHandler('street', e.target.value),
      area     : (e) => defaultHandler('area',   e.target.value),
      town     : (e) => defaultHandler('town',   e.target.value),
      county   : (e) => defaultHandler('county', e.target.value),
      postcode : (e) => postcodeAndCountryHandler('postcode', e.target.value),
      country  : (e) => postcodeAndCountryHandler('country',  e.target.value),
      contact  : contactHandler,
      phone    : (e) => defaultHandler('phone', e.target.value),
      email    : (e) => defaultHandler('email', e.target.value)
   };
}

//  --------------------------------------------------------------------------------------------------

//  IMPORTANT: New contact detail objects are always fully structured
export function newContactDetails() {
   return {
      name     : '',
      street   : '',
      area     : '',
      town     : '',
      county   : '',
      postcode : '',
      country  : '',
      contact  : newNameDetails(),
      phone    : '',
      email    : ''
   };
}

//  --------------------------------------------------------------------------------------------------

export function initContactErrors(config, group) {
   const name     = Utils.isBlank(group.name);
   const street   = Utils.isBlank(group.street);
   //  [ Area is optional ]
   const town     = Utils.isBlank(group.town);
   const county   = Utils.isBlank(group.county);
   const [ postcode, country ] = postcodeAndCountryErrors(config, group.postcode, group.country);
   const contact  = getNameErrors(group.contact, true);
   const phone    = Utils.isBlank(group.phone);
   const email    = Utils.isNotEmail(group.email);
   return {
      name, street, town, county, postcode, country, contact, phone, email,
      combined : (name || street || town || county || postcode || country || contact.combined || phone || email)
   };
}

//  --------------------------------------------------------------------------------------------------

function postcodeAndCountryErrors(config, postcode, country) {
   let postcodeError = Utils.isBlank(postcode)
   let countryError  = Utils.isBlank(country);
   if ( !countryError ) {
      const ref = config.countryIndex.get(country)
      if ( ref ) {
         postcodeError = !ref.postcode.test(postcode);
      } else {
         countryError = true;
      }
   }
   return [ postcodeError, countryError ];
}

//  --------------------------------------------------------------------------------------------------



