import React, { useContext, useState, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import { ConfigurationContext } from '../ConfigurationContext';
import PortalAPI from '../PortalAPI';

//  --------------------------------------------------------------------------------------------------

export default function VisibilityDialog(props) {

   const config = useContext(ConfigurationContext);

   const [ state, setState ] = useState(() => {
      const selection = props.client ? [ false, 0 ] : applyExceptions(props.branches, props.value.exceptions);
      return {
         fields    : { ...props.value },
         branches  : selection[0],
         available : selection[1],
         dialog    : false
      };
   });

   useEffect(() => {
      if ( props.client ) {
         loadBranches(config, props.client, setState);
      }
   }, [ config, props.client, setState ]);

   const valueHandler = (v) => setState((state) => produce(state, (draft) => {
      draft.fields.value = v;
   }));

   const selectionHandler = (i) => setState((state) => produce(state, (draft) => {
      if ( draft.branches[i].selected ) {
         //  When invoked from the delete button
         draft.branches[i].selected = false;
         draft.available = draft.available + 1;
      } else {
         //  When invoked from the add branch dialog
         draft.branches[i].selected = true;
         draft.available = draft.available - 1;
         draft.dialog = false;
      }
   }));

   const addBranchHandler = () => {
      const branches = state.branches.filter((b) => !b.selected);
      const cancelHandler = () => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <AddBranchDialog available={branches} onSave={selectionHandler} onCancel={cancelHandler}/>;
      }));
   };

   const saveButtonHandler = () => {
      props.onSave(produce(state.fields, (draft) => { draft.exceptions = state.branches.filter((b) => b.selected).map((b) => b.id); }));
   };

   return (
      <Dialog open disableBackdropClick onClose={props.onCancel} scroll="paper" fullWidth>
         <DialogTitle>{props.title}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <InputLabel shrink>Default</InputLabel>
                  <Select value={state.fields.value} onChange={(e) => valueHandler(e.target.value)} fullWidth>
                     <MenuItem value={true}>Visible</MenuItem>
                     <MenuItem value={false}>Hidden</MenuItem>
                  </Select>
               </Grid>
               <Grid item xs={12}>{
                  state.branches ? (
                     <>
                        <InputLabel shrink>Exceptions</InputLabel>
                        <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
                           state.branches.filter((b) => b.selected).map((b) => (
                              <ListItem key={b.id}>
                                 <ListItemText>{b.name}</ListItemText>
                                 <ListItemSecondaryAction><IconButton onClick={() => selectionHandler(b.idx)}><DeleteIcon/></IconButton></ListItemSecondaryAction>
                              </ListItem>
                           ))
                        }</List>
                        <div style={{ textAlign : 'center' }}>
                           <IconButton color="primary" onClick={addBranchHandler} disabled={state.available === 0}><AddCircleIcon/></IconButton>
                        </div>
                     </>
                  ) : (
                     <CircularProgress color="secondary"/>
                  )
               }</Grid>
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary">Save</Button>
            <Button onClick={props.onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function loadBranches(config, client, setState) {
   PortalAPI.getBranches(client).then((response) => setState((state) => {
      const [ branches, available ] = applyExceptions(response, state.fields.exceptions);
      return produce(state, (draft) => {
         draft.branches  = branches;
         draft.available = available;
      });
   })).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------

function applyExceptions(branches, exceptions) {
   const selected = new Map();
   exceptions.forEach((b) => { selected.set(b, true); });
   const updated = produce(branches, (draft) => draft.forEach((b, i) => {
      b.selected = selected.has(b.id);
      b.idx = i;
   }));
   return [ updated, (branches.length - selected.size) ];
}

//  --------------------------------------------------------------------------------------------------

function AddBranchDialog(props) {

   const [ selected, setSelected ] = useState(props.available[0].idx);

   return (
      <Dialog open disableBackdropClick onClose={props.onCancel}>
         <DialogTitle>Select Branch</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Select value={selected} onChange={(e) => setSelected(e.target.value)} fullWidth>{
                     props.available.map((b) => <MenuItem key={b.id} value={b.idx}>{b.name}</MenuItem>)
                  }</Select>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => props.onSave(selected)} color="primary">Add</Button>
            <Button onClick={props.onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

export function newVisibilityDetails() {
   return {
      value      : true,
      exceptions : [ ]
   };
}

//  --------------------------------------------------------------------------------------------------



