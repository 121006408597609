import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import PortalAPI from '../PortalAPI';

//  --------------------------------------------------------------------------------------------------

export default function EPDQPaymentDialog(props) {

   const { config, basket, details, onClose } = props;

   const cancelButtonHandler = () => {
      config.showMessage("Unlocking Basket");
      PortalAPI.deleteEPDQ(basket).then((response) => {
         config.hideMessage();
         onClose();
      }).catch(config.reportAPIError);
   }

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs">
         <DialogTitle>Payment</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>Click the button below to visit the secure payment site and pay the £{details.cost} due for this basket.</Grid>
               <Grid item xs={12}>
                  <form method="post" action={process.env.REACT_APP_EPDQ} id="form1" name="form1">
                     { details.params.map((p) => <input key={p[0]} type="hidden" name={p[0]} value={p[1]}/>) }
                     <p style={{ textAlign : 'center' }}><input style={{ padding : '8px' }} type="submit" id="submit2" name="submit2" value="Visit Payment Site"/></p>
                  </form>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

