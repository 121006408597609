import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import HistoryDialog from '../../components/HistoryDialog';

import PortalAPI from '../../PortalAPI';
import Utils, { ORDER_STATUS_OPTIONS } from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function OrderDialog(props) {

   const { config, order, reorder, onClose } = props;

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState({
      paid   : order.paid,
      status : order.status
   });

   const [ dialog, setDialog ] = useState(false);

   const paidHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.paid = value;
   }));

   const statusHandler = (idx, value) => setState((state) => produce(state, (draft) => {
      draft.status[idx] = value;
   }));

   const historyButtonHandler = () => {
      config.showMessage('Loading');
      PortalAPI.getOrderHistory(order.id).then((response) => {
         config.hideMessage();
         setDialog(<HistoryDialog title="Order History" history={response} onClose={() => setDialog(false)}/>);
      }).catch(config.reportAPIError);
   };

   const updateButtonHandler = () => {
      const details = produce(state, (draft) => {
         draft.paid = (draft.paid === 'true') ? true : (draft.paid === 'false') ? false : null;
      });
      config.showMessage('Updating');
      PortalAPI.putOrderStatus(order.id, details).then((response) => {
         config.hideMessage();
         onClose(true);
      }).catch(config.reportAPIError);
   };

   const canReorder = (reorder && config.user.order && (config.user.others || config.branch.id === order.branchId) &&
                       (config.user.level === 'S' || (config.user.level === 'C' && config.client.id === order.clientId) || (config.user.level === 'B' && config.branch.id === order.branchId)));

   const reorderButtonHandler = () => {
      config.showMessage('Processing');
      PortalAPI.putOrder(order.id).then((response) => {
         config.hideMessage();
         config.setBasket(response);
         onClose(false);
      }).catch(config.reportAPIError);
   };

   return (
      <Dialog open disableBackdropClick onClose={() => onClose(false)} scroll="paper" maxWidth="md" fullWidth>
         <DialogTitle>Order: {order.id}</DialogTitle>
         <DialogContent dividers={true}>
            <Paper className={classes.paper} elevation={1}>
               <table className={classes.headerTable}>
                  <tbody>
                     <tr>
                        <td className={classes.headerLabel}>Created:</td>
                        <td className={classes.headerValue}>{Utils.formatTimestamp(order.timestamp)}</td>
                     </tr>
                     <tr>
                        <td className={classes.headerLabel}>User:</td>
                        <td className={classes.headerValue}>{order.user}</td>
                     </tr>
                     <tr>
                        <td className={classes.headerLabel}>Vendor:</td>
                        <td className={classes.headerValue}>{order.vendor}</td>
                     </tr>
                     <tr>
                        <td className={classes.headerLabel}>For:</td>
                        <td className={classes.headerValue}>{order.recipient}</td>
                     </tr>
                     <tr>
                        <td className={classes.headerLabel}>Address:</td>
                        <td className={classes.headerValue}>{order.address}</td>
                     </tr>
                     <tr>
                        <td className={classes.headerLabel}>Delivery:</td>
                        <td className={classes.headerValue}>{order.how}</td>
                     </tr>
                     {
                        order.instruction && (
                           <tr>
                              <td className={classes.headerLabel}>Instruction:</td>
                              <td className={classes.headerValue}>{order.instruction}</td>
                           </tr>
                        )
                     }
                     <tr>
                        <td className={classes.headerLabel}>Status:</td>
                        {
                           reorder ? (
                              order.paid === 'true'  ? <td className={classes.headerPaid}>PAID</td>     :
                              order.paid === 'false' ? <td className={classes.headerUnpaid}>UNPAID</td> :
                                                       <td className={classes.headerValue}>-</td>
                           ) : (
                              <td>
                                 <Select variant="outlined" value={state.paid} onChange={(e) => paidHandler(e.target.value)}>
                                    <MenuItem value="false">UNPAID</MenuItem>
                                    <MenuItem value="true">PAID</MenuItem>
                                 </Select>
                              </td>
                           )
                        }
                     </tr>
                  </tbody>
               </table>
            </Paper>
            {
               order.sections.map((section, sectionIdx) => (
                  <Paper key={'section' + sectionIdx} className={classes.paper} elevation={1}>
                     {
                        section.supplier && (
                           <table className={classes.headerTable}>
                              <tbody>
                                 <tr>
                                    <td className={classes.headerLabel}>Supplier:</td>
                                    <td className={classes.headerValue}>{section.supplier}</td>
                                 </tr>
                                 {
                                    section.poNumber && (
                                       <tr>
                                          <td className={classes.headerLabel}>Order:</td>
                                          <td className={classes.headerValue}>{section.poNumber}</td>
                                       </tr>
                                    )
                                 }
                              </tbody>
                           </table>
                        )
                     }
                     <table className={classes.detailTable}>
                        <thead>
                           <tr>
                              <td className={classes.detailNameHeading}>Name</td>
                              <td className={classes.detailStatusHeading}>Status</td>
                              <td className={classes.detailOtherHeading}>Quantity</td>
                              <td className={classes.detailOtherHeading}>Price (£)</td>
                              <td className={classes.detailOtherHeading}>VAT (£)</td>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              section.items.map((item, itemIdx) => (
                                 <>
                                    <tr className={classes.detailRow} key={'item' + itemIdx}>
                                       <td className={item.id ? classes.detailItemNameFirst : classes.detailItemName}>
                                          <span className={classes.detailValue}>{item.name}</span>
                                          {
                                             (item.summary.length > 0) && (
                                                <>
                                                   <br/>
                                                   {
                                                      item.summary.map((summary, summaryIdx) => (
                                                         <>
                                                            {
                                                               (summaryIdx > 0) ? <span key={'name' + summaryIdx} className={classes.detailLabel}>; {summary.name}: </span> :
                                                                                  <span key={'name' + summaryIdx} className={classes.detailLabel}>{summary.name}: </span>
                                                            }
                                                            <span key={'value' + summaryIdx} className={classes.detailValue}>"{summary.value}"</span>
                                                         </>
                                                      ))
                                                   }
                                                </>
                                             )
                                          }
                                          {
                                             item.sku && (
                                                <>
                                                   <br/>
                                                   <span className={classes.detailLabel}>SKU: </span>
                                                   <span className={classes.detailValue}>"{item.sku}"</span>
                                                </>
                                             )
                                          }
                                          {
                                             item.serialNos && (
                                                <>
                                                   <br/>
                                                   <span className={classes.detailLabel}>Serial Numbers: </span>
                                                   <span className={classes.detailValue}>"{item.serialNos.first}"</span>
                                                   <span className={classes.detailLabel}> to </span>
                                                   <span className={classes.detailValue}>"{item.serialNos.last}"</span>
                                                   <span className={classes.detailLabel}> inclusive.</span>
                                                </>
                                             )
                                          }
                                          {
                                             item.suppliersName && (
                                                <>
                                                   <br/>
                                                   <span className={classes.detailLabel}>Supplier's Product: </span>
                                                   <span className={classes.detailValue}>{item.suppliersName}</span>
                                                   {
                                                      item.suppliersID && (
                                                         <>
                                                            <span className={classes.detailLabel}> (</span>
                                                            <span className={classes.detailValue}>{item.suppliersID}</span>
                                                            <span className={classes.detailLabel}>) </span>
                                                         </>
                                                      )
                                                   }
                                                </>
                                             )
                                          }
                                          {
                                             item.artwork1 && (
                                                <>
                                                   <br/>
                                                   <span className={classes.detailLabel}>Artwork #1: </span>
                                                   <span className={classes.detailValue}>{item.artwork1}</span>
                                                </>
                                             )
                                          }
                                          {
                                             item.artwork2 && (
                                                <>
                                                   <br/>
                                                   <span className={classes.detailLabel}>Artwork #2: </span>
                                                   <span className={classes.detailValue}>{item.artwork2}</span>
                                                </>
                                             )
                                          }
                                       </td>
                                       <td className={item.id ? classes.detailItemStatusFirst : classes.detailItemStatus}>{
                                          item.id ? reorder ? (
                                             <>{Utils.orderStatusText(state.status[item.idx])}</>
                                          ) : (
                                             <Select variant="outlined" value={state.status[item.idx]} onChange={(e) => statusHandler(item.idx, e.target.value)} fullWidth>{
                                                ORDER_STATUS_OPTIONS.map((o) => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)
                                             }</Select>
                                          ) : false
                                       }</td>
                                       <td className={item.id ? classes.detailItemValueFirst : classes.detailItemValue}>{item.quantity}</td>
                                       <td className={item.id ? classes.detailItemValueFirst : classes.detailItemValue}>{item.inclusive ? '\u2014' : item.price}</td>
                                       <td className={item.id ? classes.detailItemValueFirst : classes.detailItemValue}>{item.inclusive ? '\u2014' : item.vat}</td>
                                    </tr>
                                 </>
                              ))
                           }
                        </tbody>
                     </table>
                  </Paper>
               ))
            }
            <Paper className={classes.paper} elevation={1}>
               <table className={classes.totalsTable}>
                  <tbody>
                     <tr>
                        <td className={classes.totalsLabel}>Price</td>
                        <td className={classes.totalsPound}>£</td>
                        <td className={classes.totalsValue}>{order.price}</td>
                     </tr>
                     <tr>
                        <td className={classes.totalsLabel}>VAT</td>
                        <td className={classes.totalsPound}>£</td>
                        <td className={classes.totalsValue}>{order.vat}</td>
                     </tr>
                     <tr>
                        <td className={classes.totalsLabel}>Total</td>
                        <td className={classes.totalsPound}>£</td>
                        <td className={classes.totalsFinal}>{order.total}</td>
                     </tr>
                  </tbody>
               </table>
            </Paper>
            { dialog }
         </DialogContent>
         <DialogActions>
            {
               !reorder && (
                  <>
                     <Button onClick={historyButtonHandler} color="primary">History</Button>
                     <Button onClick={updateButtonHandler} color="primary"
                             disabled={state.paid === '' || (state.paid === order.paid && state.status.join('\n') === order.status.join('\n'))}>Update</Button>
                  </>
               )
            }
            { canReorder && <Button onClick={reorderButtonHandler} color="primary">Reorder</Button> }
            <Button onClick={() => onClose(false)} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   paper                     : (theme) => ({
      ...theme.mixins.gutters(),
      marginTop              : theme.spacing(1),
      marginBottom           : theme.spacing(1),
      paddingTop             : theme.spacing(1),
      paddingBottom          : theme.spacing(1)
   }),
   headerTable               : {
      width                  : '100%',
      borderSpacing          : '4px'
   },
   headerLabel               : {
      width                  : '70px'
   },
   headerValue               : {
      fontWeight             : 'bold'
   },
   headerUnpaid              : {
      color                  : 'red',
      fontWeight             : 'bold'
   },
   headerPaid                : {
      color                  : 'green',
      fontWeight             : 'bold'
   },
   detailTable               : {
      marginTop              : '8px',
      width                  : '100%',
      borderSpacing          : '4px'
   },
   detailRow                 : {
      verticalAlign          : 'top'
   },
   detailNameHeading         : {
      textAlign              : 'left'
   },
   detailStatusHeading       : {
      textAlign              : 'left'
   },
   detailOtherHeading        : {
      width                  : '80px',
      textAlign              : 'right'
   },
   detailItemNameFirst       : {
      borderTop              : '1px solid black',
      paddingTop             : '4px',
      textAlign              : 'left'
   },
   detailItemStatusFirst     : {
      borderTop              : '1px solid black',
      paddingTop             : '4px',
      textAlign              : 'left',
      fontWeight             : 'bold',
      whiteSpace             : 'nowrap'
   },
   detailItemValueFirst      : {
      borderTop              : '1px solid black',
      paddingTop             : '4px',
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
   detailItemName            : {
      textAlign              : 'left'
   },
   detailItemStatus          : {
      textAlign              : 'left',
      fontWeight             : 'bold',
      whiteSpace             : 'nowrap'
   },
   detailItemValue           : {
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
   detailLabel               : { },
   detailValue               : {
      fontWeight             : 'bold'
   },
   totalsTable               : {
      width                  : '100%',
      borderSpacing          : '4px'
   },
   totalsLabel               : {
      textAlign              : 'left'
   },
   totalsPound               : {
      width                  : '30px',
      textAlign              : 'right'
   },
   totalsValue               : {
      width                  : '80px',
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
   totalsFinal               : {
      width                  : '80px',
      borderTop              : '1px black solid',
      borderBottom           : '1px solid black',
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
});

//  --------------------------------------------------------------------------------------------------



