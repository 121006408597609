import React, { useMemo } from 'react';

import produce from 'immer';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Utils from '../Utils';

//  --------------------------------------------------------------------------------------------------

export default function NameField(props) {

   const { values, errors, onChange, required, disabled } = props;

   const handlers = useMemo(() => createFieldHandlers(values, errors, onChange, required), [ values, errors, onChange, required ]);

   return (
      <>
         <Grid item xs={12} sm={6}>
            <TextField id="forename" label="Forename" value={values.forename} onChange={handlers.forename}
                       error={errors.forename && !disabled} required={(errors.forename || required) && !disabled} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12} sm={6}>
            <TextField id="surname" label="Surname" value={values.surname} onChange={handlers.surname}
                       error={errors.surname && !disabled} required={(errors.surname || required) && !disabled} disabled={disabled} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <FormControlLabel label="This name is normally written with the surname first"
                              control={(<Checkbox checked={values.surnameFirst} onChange={handlers.surnameFirst} disabled={disabled}/>)}/>
         </Grid>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(values, errors, onChange, required) {
   const textHandler = (field, value) => {
      const newValues = produce(values, (draft) => {
         draft[field] = value;
      });
      const newErrors = getNameErrors(newValues, required);
      onChange(newValues, newErrors);
   };
   const checkHandler = (value) => {
      const newValues = produce(values, (draft) => {
         draft.surnameFirst = value;
      });
      onChange(newValues, errors);
   };
   return {
      surname      : (e) => textHandler('surname', e.target.value),
      forename     : (e) => textHandler('forename', e.target.value),
      surnameFirst : (e) => checkHandler(e.target.checked)
   };
}

//  --------------------------------------------------------------------------------------------------

export function newNameDetails() {
   return {
      surname      : '',
      forename     : '',
      surnameFirst : false
   };
}

//  --------------------------------------------------------------------------------------------------

export function getNameErrors(name, required) {
   const sBlank   = Utils.isBlank(name.surname);
   const fBlank   = Utils.isBlank(name.forename);
   const surname  = sBlank && (required || !fBlank);
   const forename = fBlank && (required || !sBlank);
   return {
      surname,
      forename,
      combined : (surname || forename)
   };
}

//  --------------------------------------------------------------------------------------------------




