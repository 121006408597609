import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import ReplayIcon from '@material-ui/icons/Replay';

import ConfirmationDialog from '../components/ConfirmationDialog';
import HistoryDialog from '../components/HistoryDialog';

import PortalAPI from '../PortalAPI';
import Utils, { useToggle } from '../Utils';

import Basket from './Basket';
import BasketStatusIcon from './BasketStatusIcon';
import BasketViewerDialog from './BasketViewerDialog';
import EPDQDetailsDialog from './EPDQDetailsDialog';
import ProductDialog from './ProductDialog';

//  --------------------------------------------------------------------------------------------------

export default function BasketDialog(props) {

   const { config, onClose } = props;

   const classes = useStyles(config.theme);

   const [ submitted, setSubmitted ] = useState(false);

   const [ basket, setBasket ] = useState(false);

   const [ artworkConfirmation, setArtworkConfirmation ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   const [ refresh, forceRefresh ] = useToggle();

   useEffect(() => {
      setSubmitted(false);
      setBasket(false);
      setArtworkConfirmation(false);
      PortalAPI.getBaskets().then((response) => {
         setSubmitted(splitBasketList(response));
         return PortalAPI.getBasket().then((response) => {
            setBasket(response);
         });
      }).catch(config.reportAPIError);
   }, [ config, refresh ]);

   const handleDialogClose = (refresh) => {
      setDialog(false);
      if ( refresh ) {
         forceRefresh();
      }
   };

   const handleView = (basket, payment, canLock) => {
      config.showMessage("Loading");
      PortalAPI.getBasket(basket).then((response) => {
         config.hideMessage();
         setDialog(<BasketViewerDialog config={config} authorisation={false} basketId={basket} basket={response} payment={payment} canLock={canLock} onClose={handleDialogClose}/>);
      }).catch(config.reportAPIError);
   };

   const handlePayment = (basket, payment) => {
      switch ( payment ) {

         case 'EPDQ' :
            config.showMessage("Loading");
            PortalAPI.getUserBilling().then((response) => {
               config.hideMessage();
               setDialog(<EPDQDetailsDialog config={config} authorise={false} basket={basket} details={response} onClose={handleDialogClose}/>);
            }).catch(config.reportAPIError);
            break;

         default :
            break;

      }
   };

   const handleHistory = (basketId) => {
      config.showMessage("Loading");
      PortalAPI.getBasketHistory(basketId).then((response) => {
         config.hideMessage();
         setDialog(<HistoryDialog title="Basket History" history={response} onClose={() => handleDialogClose(false)}/>);
      }).catch(config.reportAPIError);
   };

   const update = (response) => {
      config.setBasket(response);
      config.hideMessage();
      forceRefresh();
   };

   const handleRecall = (id) => {
      config.showMessage('Retrieving');
      PortalAPI.deleteBasket(id).then(update).catch(config.reportAPIError);
   };

   const handleRefreshBaskets = () => {
      config.showMessage("Refreshing");
      PortalAPI.getBaskets().then((response) => {
         config.hideMessage();
         setSubmitted(splitBasketList(response));
      }).catch(config.reportAPIError);
   };

   const handleSubmit = () => {
      config.showMessage('Submitting');
      PortalAPI.postBasket().then((response) => {
         update(0);
         const msg = (response === 1) ? 'Your basket has now entered the ordering process and is' :
                                        'Your basket has been split to facilitate the ordering process, and the resulting baskets are';
         setDialog(<SubmittedDialog message={msg} onClose={() => setDialog(false)}/>);
      }).catch(config.reportAPIError);
   };

   const handleDeleteAll = () => {
      const handleDelete = () => {
         setDialog(false);
         config.showMessage('Deleting');
         PortalAPI.deleteBasket().then(update).catch(config.reportAPIError);
      };
      setDialog(<ConfirmationDialog message="Delete all the items within your basket ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => setDialog(false)}/>);
   };

   const handleEditItem = (id) => {
      config.showMessage("Loading");
      PortalAPI.getBasketItem(id).then((response) => {
         config.hideMessage();
         setDialog(<ProductDialog id={id} product={response} onClose={handleDialogClose}/>);
      }).catch(config.reportAPIError);
   };

   const handleDeleteItem = (id) => {
      const handleDelete = () => {
         setDialog(false);
         config.showMessage('Deleting');
         PortalAPI.deleteBasketItem(id).then(update).catch(config.reportAPIError);
      };
      setDialog(<ConfirmationDialog message="Delete this item ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => setDialog(false)}/>);
   };

   const nonEmpty = (basket && basket.orders.length > 0);
   const artworkConfirmationRequired = basket && basket.orders.findIndex(o => o.items.findIndex(i => i.artworkConfirm) !== -1) !== -1

   return (
      <Dialog open disableBackdropClick onClose={onClose} scroll="paper" maxWidth="lg" fullWidth>
         <DialogTitle>Your Basket</DialogTitle>
         <DialogContent style={{ height : '90vh' }} dividers={true}>{
            basket ? (
               <>
                  <BasketList classes={classes} mode="P" title="Baskets Requiring Payment" baskets={submitted[0]} nonEmpty={nonEmpty}
                              onView={handleView} onPay={handlePayment} onHistory={handleHistory} onRecall={handleRecall}/>
                  <BasketList classes={classes} mode="V" title="Submitted Baskets" baskets={submitted[1]} nonEmpty={nonEmpty}
                              onView={handleView} onPay={handlePayment} onHistory={handleHistory} onRecall={handleRecall}/>
                  <BasketList classes={classes} mode="A" title="Baskets Awaiting Authorisation" baskets={submitted[2]} nonEmpty={nonEmpty}
                              onView={handleView} onPay={handlePayment} onHistory={handleHistory} onRecall={handleRecall}/>
                  {
                     nonEmpty ? (
                        <>
                           <Basket config={config} basket={basket} edit={true} onEdit={handleEditItem} onDelete={handleDeleteItem}/>
                           {
                              artworkConfirmationRequired && (
                                 <Paper key="confirmation" className={classes.submittedPaper} elevation={1}>
                                    <FormControlLabel label="I confirm that I have checked the artwork for this print order, and that I am happy to proceed to print with the artwork associated with the product(s)."
                                                      control={(<Checkbox checked={artworkConfirmation} onChange={(e) => setArtworkConfirmation(e.target.checked)} />)}/>
                                 </Paper>
                              )
                           }
                        </>
                     ) : (
                        <Typography variant="h6">Your current basket is empty.</Typography>
                     )
                  }
                  { dialog }
               </>
            ) : (
               <CircularProgress color="secondary"/>
            )
         }</DialogContent>
         <DialogActions>
            <Button onClick={handleSubmit}         color="primary" disabled={!nonEmpty || (artworkConfirmationRequired && !artworkConfirmation) || basket.issues > 0}>Submit</Button>
            <Button onClick={handleDeleteAll}      color="primary" disabled={!nonEmpty}>Empty</Button>
            <Button onClick={handleRefreshBaskets} color="primary">Refresh</Button>
            <Button onClick={onClose}              color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function BasketList(props) {

   const { classes, mode, title, baskets, nonEmpty, onView, onPay, onHistory, onRecall } = props;

   const payments = (mode === 'P');

   return (baskets.length > 0) && (
      <Paper key={mode + 'List'} className={classes.submittedPaper} elevation={1}>
         <Typography variant="h6">{title}</Typography>
         <table className={classes.submittedTable}>
            <thead>
               <tr>
                  <td className={classes.submittedTimestampHeading}>Submitted</td>
                  <td className={classes.submittedIdHeading}>ID</td>
                  { payments && <td className={classes.submittedPayHeading}>Payment</td> }
                  <td className={classes.submittedStatusHeading}>Status</td>
                  <td className={classes.submittedItemsHeading}>Items</td>
                  <td className={classes.submittedValueHeading}>Value</td>
                  <td className={classes.submittedRecallHeading}/>
               </tr>
            </thead>
            <tbody>{
               baskets.map((basket, basketIdx) => (
                  <tr key={'basket' + basketIdx}>
                     <td className={classes.submittedTimestamp}>{Utils.formatTimestamp(basket.timestamp)}</td>
                     <td className={classes.submittedId}>{
                        basket.canView ? (
                           <Link color="inherit" href="#" onClick={() => onView(basket.id, basket.paymentType, basket.canLock)}>{basket.id}</Link>
                        ) : (
                           basket.id
                        )
                     }</td>
                     {
                        payments && (
                           <td className={classes.submittedPay}>
                              <IconButton size="small" disabled={!basket.canLock} onClick={() => onPay(basket.id, basket.paymentType)}><CreditCardIcon/></IconButton>
                           </td>
                        )
                     }
                     <td className={classes.submittedStatus}>
                        <IconButton size="small" onClick={() => onHistory(basket.id)}><BasketStatusIcon mode={mode} status={basket.status}/></IconButton>
                     </td>
                     <td className={classes.submittedItems}>{basket.items}</td>
                     <td className={classes.submittedValue}>{basket.total}</td>
                     <td className={classes.submittedRecall}>
                        <IconButton size="small" onClick={() => onRecall(basket.id)} disabled={nonEmpty || !basket.canRecall}><ReplayIcon/></IconButton>
                     </td>
                  </tr>
               ))
            }</tbody>
         </table>
      </Paper>
   );
}

//  --------------------------------------------------------------------------------------------------

function SubmittedDialog(props) {
   return (
      <Dialog open disableBackdropClick onClose={props.onClose} aria-labelledby="sumbitted-dialog-title" aria-describedby="sumbitted-dialog-description" style={{ zIndex : 9999 }}>
         <DialogTitle id="sumbitted-dialog-title">Thank you for your order</DialogTitle>
         <DialogContent dividers={true}>
            <div id="sumbitted-dialog-description">
               <p>{props.message} shown, along with any others you've recently submitted, in one or more queues at the top of your basket page.</p>
               <ul>
                  <li>To view the content of any of basket, simply click on its name.</li>
                  <li>If a basket requires payment, please use its <CreditCardIcon size="small"/> button to start the payment process.</li>
                  <li>If a basket requires authorisation, you will be notified once this has occurred.</li>
                  <li>If a basket requires neither payment nor authorisation, then you have approximately 15 minutes to make any changes before it becomes final.</li>
                  <li>To amend or delete a basket, you must first recall it using its <ReplayIcon size="small"/> button, however this can only be done if your current basket is empty.</li>
               </ul>
            </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.onClose} color="primary">OK</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   submittedPaper            : (theme) => ({
      ...theme.mixins.gutters(),
      marginBottom           : theme.spacing(2),
      paddingTop             : theme.spacing(2),
      paddingBottom          : theme.spacing(2)
   }),
   submittedTable            : (theme) => ({
      marginTop              : theme.spacing(1),
      marginBottom           : theme.spacing(1),
      width                  : '100%',
      borderSpacing          : '4px'
   }),
   submittedTimestampHeading : {
      width                  : '180px',
      borderBottom           : '1px solid black',
      textAlign              : 'left'
   },
   submittedIdHeading : {
      borderBottom           : '1px solid black',
      textAlign              : 'left'
   },
   submittedPayHeading       : {
      borderBottom           : '1px solid black',
      width                  : '80px',
      textAlign              : 'center'
   },
   submittedStatusHeading    : {
      borderBottom           : '1px solid black',
      width                  : '30px',
      textAlign              : 'center'
   },
   submittedItemsHeading     : {
      borderBottom           : '1px solid black',
      width                  : '30px',
      textAlign              : 'right'
   },
   submittedValueHeading     : {
      borderBottom           : '1px solid black',
      width                  : '80px',
      textAlign              : 'right'
   },
   submittedRecallHeading    : {
      width                  : '30px'
   },
   submittedTimestamp        : {
      textAlign              : 'left'
   },
   submittedId               : {
      textAlign              : 'left'
   },
   submittedPay              : {
      textAlign              : 'center'
   },
   submittedStatus           : {
      textAlign              : 'center'
   },
   submittedItems            : {
      textAlign              : 'right'
   },
   submittedValue            : {
      textAlign              : 'right'
   },
   submittedRecall           : {
      textAlign              : 'center'
   }
});

//  --------------------------------------------------------------------------------------------------

function splitBasketList(baskets) {
   return [
      baskets.filter((b) => !b.authorise && b.paymentType !== 'VENDOR'),
      baskets.filter((b) => !b.authorise && b.paymentType === 'VENDOR'),
      baskets.filter((b) => b.authorise)
   ];
}

//  --------------------------------------------------------------------------------------------------


