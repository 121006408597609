import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import PortalAPI from '../../PortalAPI';

import SelectionDialog from '../../components/SelectionDialog';

//  --------------------------------------------------------------------------------------------------

export default function UserAuthoriseDialog(props) {

   const { config, client, level, branches, details, onClose } = props;

   const [ state, setState ] = useState(() => initState(details));

   useEffect(() => loadBranches(config, setState, client, level, branches), [ config, setState, client, level, branches ]);

   const addHandler = (id) => {
      const saveHandler = (id) => setState((state) => produce(state, (draft) => {
         draft.selected.add(id);
         draft.dialog = false;
      }));
      const cancelHandler = (id) => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         const branches = state.branches.filter((b) => !state.selected.has(b.id));
         draft.dialog = <SelectionDialog title="Add Branch" label="Branch" button="Add" options={branches} value={branches[0].id} onSave={saveHandler} onCancel={cancelHandler}/>;
      }));
   };

   const removeHandler = (id) => setState((state) => produce(state, (draft) => {
      draft.selected.delete(id);
   }));

   const saveButtonHandler = () => onClose(Array.from(state.selected));

   const cancelButtonHandler = () => onClose(details);

   const disableAdd = state.branches && (state.selected.size === state.branches.length);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs" fullWidth>
         <DialogTitle>Basket Authorisation</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>Selecting one or more branches here, allows this user to authorise or reject baskets submitted by other users ordering for those branches.</Grid>
               {
                  state.branches && (
                     <Grid item xs={12}>
                        <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
                           state.branches.filter((b) => state.selected.has(b.id)).map((b) => (
                              <CustomListItem key={b.id}>
                                 <ListItemText>{b.name}</ListItemText>
                                 <ListItemSecondaryAction><IconButton onClick={() => removeHandler(b.id)}><DeleteIcon/></IconButton></ListItemSecondaryAction>
                              </CustomListItem>
                           ))
                        }</List>
                        <div style={{ textAlign : 'center' }}>
                           <IconButton color="primary" onClick={() => addHandler()} disabled={disableAdd}><AddCircleIcon/></IconButton>
                        </div>
                     </Grid>
                  )
               }
            </Grid>
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary">Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const CustomListItem = withStyles({
   secondaryAction: {
      paddingRight: 52
   }
})(ListItem);

//  --------------------------------------------------------------------------------------------------

function initState(details) {
   return {
      selected : new Set(details),
      branches : null,
      dialog   : false
   };
}

//  --------------------------------------------------------------------------------------------------

function loadBranches(config, setState, client, level, branches) {
   const temp = new Set(branches);
   if ( config.user.level === 'B' ) {
      setState((state) => produce(state, (draft) => {
         draft.branches = config.user.branches.filter((b) => temp.has(b.id));
      }));
   } else {
      PortalAPI.getBranches(client).then((response) => setState((state) => produce(state, (draft) => {
         draft.branches = (level === 'B') ? response.filter((b) => temp.has(b.id)) : response;
      }))).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

