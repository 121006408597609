import React, { useState, useMemo, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import Selector, { findOption } from '../../components/Selector';
import VariantSelectors, { initVariants, areVariantsPresent, isVariantSelected, getVariantRef } from '../../components/VariantSelectors';

import PortalAPI from '../../PortalAPI';

import ProductDialog from './ProductDialog';

import WizardMarkups from './WizardMarkups';
import WizardRounding from './WizardRounding';
import WizardQuantities from './WizardQuantities';

//  --------------------------------------------------------------------------------------------------

export default function WizardDialog(props) {

   const { config, migration } = props;

   const [ state, setState ] = useState(() => initState(config, migration));

   useEffect(() => loadVendors(config, setState), [ config, setState ]);

   useEffect(() => loadSuppliers(config, setState, state.vendors, state.vendorId), [ config, setState, state.vendors, state.vendorId ]);

   const handlers = useMemo(() => createHandlers(props, state, setState), [ props, state, setState ]);

   const errors = evalErrors(state);

   const lastPage = (state.supplier && state.supplier.wizard) ? state.enquiry ? 1 : 5 : 0;

   return (
      <Dialog open disableBackdropClick onClose={handlers.cancelButton} scroll="paper" fullWidth>
         <DialogTitle>{ migration ? 'Change Supplier Wizard' : 'New Product Wizard' }</DialogTitle>
         <DialogContent dividers={true}>
            {
               state.enquiry ? (
                  <Stepper key="stepEnquiry" activeStep={state.page} alternativeLabel>
                     <Step><StepLabel>Supplier</StepLabel></Step>
                     <Step><StepLabel>Product</StepLabel></Step>
                  </Stepper>
               ) : (
                  <Stepper key="stepNormal" activeStep={state.page} alternativeLabel>
                     <Step><StepLabel>Supplier</StepLabel></Step>
                     <Step><StepLabel>Product</StepLabel></Step>
                     <Step><StepLabel>Attributes</StepLabel></Step>
                     <Step><StepLabel>Quantities</StepLabel></Step>
                     <Step><StepLabel>Markup</StepLabel></Step>
                     <Step><StepLabel>Updates</StepLabel></Step>
                  </Stepper>
               )
            }
            {
               (state.page === 0) ? (
                  <Grid container key="pg0" spacing={2}>
                     <Grid item xs={12}>{ migration ? state.enquiry ? (
                           <Typography>
                              If the new suppliers catalog is known to the portal, the 'NEXT' button will allow you to select the corresponding
                              product from that catalog.  If their catalog is not known, the 'FINISH' button will take you straight to the product
                              dialog so that you can make all the necessary changes.
                           </Typography>
                        ) : (
                           <Typography>
                              If the new suppliers catalog is known to the portal, the 'NEXT' button will step you through the process of selecting
                              the corresponding product from that catalog, this will replace ALL the attribute and pricing details within the current
                              product definition.  If their catalog is not known, the 'FINISH' button will take you straight to the product dialog so
                              that you can make all the necessary changes.
                           </Typography>
                        ) : (
                           <Typography>
                              If the suppliers catalog is known to the portal, the 'NEXT' button will step you through the process of selecting one
                              of their products to use as the basis of the one you wish to create.  If their catalog is not known, the 'FINISH' button
                              will take you straight to the product creation dialog where you can set up everything from scratch.
                           </Typography>
                        )
                     }</Grid>
                     <Grid item xs={12}>
                        <Grid container spacing={1}>
                           {
                              (config.user.level === 'S') && (
                                 <Grid item xs={12} sm={6}>
                                    <Selector options={state.vendors} value={state.vendor} onChange={handlers.vendor} label="Vendor" required={true} error={state.vendorError}/>
                                 </Grid>
                              )
                           }
                           {
                              (config.user.level === 'C' || state.vendor) && (
                                 <Grid item xs={12} sm={6}>
                                    <Selector options={state.suppliers} value={state.supplier} onChange={handlers.supplier} label="Supplier" required={true} error={state.supplierError}/>
                                 </Grid>
                              )
                           }
                        </Grid>
                     </Grid>
                     <Grid item xs={12}>
                        <FormControlLabel control={(<Checkbox checked={state.enquiry} onChange={handlers.enquiry}/>)} label="Enquire only" disabled={migration !== false}/>
                     </Grid>
                  </Grid>
               ) : (state.page === 1) ? (
                  <Grid container key="pg1" spacing={2}>
                     <Grid item xs={12}>
                        <Typography>Choose from one of the products that this supplier offers.</Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Selector options={state.products} value={state.product} onChange={handlers.product} label="Products" required={true} error={state.productErrors}/>
                     </Grid>
                  </Grid>
               ) : (state.page === 2) ? (
                  <Grid container key="pg2" spacing={2}>{
                     areVariantsPresent(state.variants) ? (
                        <>
                           <Grid item xs={12}>
                              <Typography>Not every combination of attribute value shown below may be available from this supplier.  Each selection you make will narrow the
                                          available options.  To expand the available options you must cancel one of your selections.  When you have zeroed in on a specific
                                          product, use the 'NEXT' button to continue.</Typography>
                           </Grid>
                           <VariantSelectors state={state.variants} onChange={handlers.variants}/>
                        </>
                     ) : (
                        <Typography>There is only one version of this product available, use the 'NEXT' button to continue.</Typography>
                     )
                  }</Grid>
               ) : (state.page === 3) ? (
                  <WizardQuantities quantities={state.quantities} value={state.quantity} onChange={handlers.quantity}/>
               ) : (state.page === 4) ? (
                  <>
                     <WizardMarkups branches={state.branches} value={state.markup} onChange={handlers.markup}/>
                     <WizardRounding value={state.rounding} onChange={handlers.rounding}/>
                  </>
               ) : (
                  <Grid container key="pg5" spacing={2}>
                     <Grid item xs={12}>
                        <Typography>
                           When this option is turned <i>on</i>, the product pricing will be automatically updated when the portal receives new
                           information about the suppliers catalog, but the changes you will be able to make to the product will be restricted.
                           Turning this option <i>off</i> gives you full control over the product, but you also have the complete responsibility
                           of keeping all the product details accurate and up-to-date.
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <FormControlLabel control={(<Checkbox checked={state.lock} onChange={handlers.lock}/>)} label="Automatic updates"/>
                     </Grid>
                  </Grid>
               )
            }
            {state.dialog}
         </DialogContent>
         <DialogActions>
            <Button onClick={handlers.prevButton} color="primary" disabled={state.page === 0}>Prev</Button>
            <Button onClick={handlers.nextButton} color="primary" disabled={errors || state.page === lastPage}>Next</Button>
            <Button onClick={handlers.finishButton} color="primary" disabled={errors || state.page < lastPage}>Finish</Button>
            <Button onClick={handlers.cancelButton} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(config, migration) {
   return {
      dialog         : false,
      page           : 0,
      //  --- Page 0 ---
      vendors        : null,
      vendor         : null,
      vendorId       : migration ? migration.vendorId : config.client.vendorId,
      vendorError    : false,
      suppliers      : null,
      supplier       : null,
      supplierId     : migration ? migration.supplierId : false,
      supplierError  : migration ? false : true,
      enquiry        : migration ? migration.enquiry : false,
      //  --- Page 1 ---
      tag            : '',
      products       : null,
      product        : null,
      productError   : false,
      //  --- Page 2 ---
      variants       : null,
      //  --- Page 3 ---
      quantities     : null,
      quantity       : null,
      //  --- Page 4 ---
      branches       : null,
      markup         : null,
      rounding       : false,
      //  --- Page 5 ---
      lock           : true
   }
}

//  --------------------------------------------------------------------------------------------------

function loadVendors(config, setState) {
   if ( config.user.level === 'S' ) {
      PortalAPI.getVendors().then((response) => setState((state) => produce(state, (draft) => {
         draft.vendors     = response;
         draft.vendor      = findOption(response, draft.vendorId);
         draft.vendorId    = (draft.vendor === null) ? false : draft.vendor.id;
         draft.vendorError = (draft.vendor === null);
      }))).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

function loadSuppliers(config, setState, vendors, vendorId) {
   if ( vendorId && (config.user.level !== 'S' || vendors) ) {
      PortalAPI.getSuppliers(vendorId, true).then((response) => setState((state) => produce(state, (draft) => {
         draft.suppliers     = response;
         draft.supplier      = findOption(response, draft.supplierId);
         draft.supplierId    = (draft.supplier === null) ? false : draft.supplier.id;
         draft.supplierError = (draft.supplier === null);
      }))).catch(config.reportAPIError);
   }
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(props, state, setState) {
   return {
      vendor : (e, value) => setState((state) => produce(state, (draft) => {
         draft.vendor         = value;
         draft.vendorId       = (value === null) ? false : value.id;
         draft.vendorError    = (value === null);
         draft.suppliers      = null;
         draft.supplier       = null;
         draft.supplierId     = false;
         draft.supplierError  = true;
      })),
      supplier : (e, value) => setState((state) => produce(state, (draft) => {
         draft.supplier       = value;
         draft.supplierId     = (value === null) ? false : value.id;
         draft.supplierError  = (value === null);
      })),
      enquiry : (e) => {
         const value = e.target.checked;
         setState((state) => produce(state, (draft) => {
            draft.enquiry     = value;
         }));
      },
      product : (e, value) => setState((state) => produce(state, (draft) => {
         draft.product        = value;
         draft.productError   = (value === null);
      })),
      variants : (value) => setState((state) => produce(state, (draft) => {
         draft.variants       = value;
      })),
      quantity : (value) => setState((state) => produce(state, (draft) => {
         draft.quantity       = value;
      })),
      markup : (value) => setState((state) => produce(state, (draft) => {
         draft.markup         = value;
      })),
      rounding : (value) => setState((state) => produce(state, (draft) => {
         draft.rounding       = value;
      })),
      lock : (e) => {
         const value = e.target.checked;
         setState((state) => produce(state, (draft) => {
            draft.lock = value;
         }));
      },
      prevButton   : () => prevPage(props.config, state, setState),
      nextButton   : () => nextPage(props.config, props.clientId, props.migration, state, setState),
      finishButton : () => finish(props, state, setState),
      cancelButton : () => props.onClose(false)
   };
}

//  --------------------------------------------------------------------------------------------------

function evalErrors(state) {
   switch ( state.page ) {
      case 0  : return (state.vendorError || state.supplierError);
      case 1  : return state.productError;
      case 2  : return !isVariantSelected(state.variants);
      case 3  : return (state.quantity.size === 0);
      case 4  : return false;
      case 5  : return false;
      default : return true;
   }
}

//  --------------------------------------------------------------------------------------------------

function prevPage(config, state, setState) {
   switch ( state.page ) {

      case 5 :
         //  Transition from automatic update selection to markup selection
         setState((state) => produce(state, (draft) => {
            draft.lock       = true;
            draft.page       = 4;
         }));
         break;

      case 4 :
         //  Transition from markup selection to quantity selection
         setState((state) => produce(state, (draft) => {
            draft.branches   = null;
            draft.markup     = null;
            draft.rounding   = false;
            draft.page       = 3;
         }));
         break;

      case 3 :
         //  Transition from quantity selection to variant selection
         setState((state) => produce(state, (draft) => {
            draft.quantities = null;
            draft.quantity   = null;
            draft.page       = 2;
         }));
         break;

      case 2 :
         //  Transition from variant selection to product selection
         setState((state) => produce(state, (draft) => {
            draft.variants   = null;
            draft.page       = 1;
         }));
         break;

      case 1 :
         //  Transition from product selection to supplier selection
         setState((state) => produce(state, (draft) => {
            draft.tag        = '';
            draft.products   = null;
            draft.product    = null;
            draft.page       = 0;
         }));
         break;

      default :
         break;

   }
}

//  --------------------------------------------------------------------------------------------------

function nextPage(config, clientId, migration, state, setState) {
   switch ( state.page ) {

      case 0 :
         //  Transition from supplier selection to product selection
         config.showMessage("Loading");
         PortalAPI.getWizardProducts(state.vendorId, state.supplierId).then((response) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.tag          = response.tag;
               draft.products     = response.products;
               draft.product      = null;
               draft.productError = true;
               draft.page         = 1;
            }));
         }).catch(config.reportAPIError);
         break;

      case 1 :
         //  Transition from product selection to variant selection
         config.showMessage("Loading");
         PortalAPI.getWizardAttributes(state.vendorId, state.supplierId, state.tag, state.product.ref).then((response) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.variants     = initVariants(response);
               draft.page         = 2;
            }));
         }).catch(config.reportAPIError);
         break;

      case 2 :
         //  Transition from variant selection to quantity selection
         config.showMessage("Loading");
         PortalAPI.getWizardQuantities(state.vendorId, state.supplierId, state.tag, getVariantRef(state.variants)).then((response) => {
            config.hideMessage();
            const selected = new Set();
            if ( migration ) {
               const available = new Set(response);
               migration.quantities.filter((q) => available.has(q)).forEach((q) => selected.add(q));
            }
            setState((state) => produce(state, (draft) => {
               draft.quantities   = response;
               draft.quantity     = selected;
               draft.page         = 3;
            }));
         }).catch(config.reportAPIError);
         break;

      case 3 :
         //  Transition from quantity selection to markup selection
         config.showMessage("Loading");
         PortalAPI.getBranches(clientId).then((response) => {
            config.hideMessage();
            const markup = new Map();
            if ( migration ) {
               Object.entries(migration.markups).forEach((e) => markup.set(e[0], e[1]));
            } else {
               markup.set('DEFAULT', '0.0');
            }
            setState((state) => produce(state, (draft) => {
               draft.branches     = response;
               draft.markup       = markup;
               draft.rounding     = migration ? migration.rounding : false;
               draft.page         = 4;
            }));
         }).catch(config.reportAPIError);
         break;

      case 4 :
         //  Transition from markup selection to automatic update selection
         setState((state) => produce(state, (draft) => {
            draft.lock            = true;
            draft.page            = 5;
         }));
         break;

      default :
         break;

   }
}

//  --------------------------------------------------------------------------------------------------

function finish(props, state, setState) {
   const closeHandler = (refresh) => {
      if ( refresh ) {
         props.onClose(true);
      } else {
         setState((state) => produce(state, (draft) => {
            draft.dialog = false;
         }));
      }
   };
   if ( state.page === 5 ) {
      productDialogFromCatalog(props, state, setState, closeHandler);
   } else {
      productDialogFromScratch(props, state, setState, closeHandler);
   }
}

//  --------------------------------------------------------------------------------------------------

function productDialogFromScratch(props, state, setState, closeHandler) {
   const { config, clientId, tabs } = props;
   const productId      = props.migration ? props.migration.id      : false;
   const productVersion = props.migration ? props.migration.version : false;  //  Yes, false is correct in this context
   config.showMessage('Loading');
   PortalAPI.getWizardProduct(clientId, state.vendorId, state.supplierId, state.enquiry,
                              (state.page === 0) ? false : state.product.name, productId, productVersion).then((productInfo) => {
      config.hideMessage();
      setState((state) => produce(state, (draft) => {
         draft.dialog = <ProductDialog clientId={clientId} tabs={tabs} productId={productId} productInfo={productInfo} onClose={closeHandler}/>;
      }));
   }).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------

function productDialogFromCatalog(props, state, setState, closeHandler) {
   const { config, clientId, tabs } = props;
   const productId      = props.migration ? props.migration.id      : false;
   const productVersion = props.migration ? props.migration.version : 0;      //  Yes, 0 is correct in this context
   config.showMessage('Loading');
   PortalAPI.postWizardProduct(clientId, state.vendorId, state.supplierId, state.tag, getVariantRef(state.variants), state.lock,
                               Array.from(state.quantity), Object.fromEntries(state.markup), state.rounding, productId, productVersion).then((productInfo) => {
      config.hideMessage();
      setState((state) => produce(state, (draft) => {
         draft.dialog = <ProductDialog clientId={clientId} tabs={tabs} productId={productId} productInfo={productInfo} onClose={closeHandler}/>;
      }));
   }).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------

