import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';

import { ConfigurationContext } from '../../ConfigurationContext';

import IntegrationsPage from './IntegrationsPage';

//  --------------------------------------------------------------------------------------------------

export default function Integrations(props) {
   const config = useContext(ConfigurationContext);
   return config.client.vendorId ? (
      <IntegrationsPage config={config}/>
   ) : (
      <Typography variant="h6">Your company is not configured as a vendor.</Typography>
   );
}

//  --------------------------------------------------------------------------------------------------

