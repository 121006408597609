import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Utils from '../Utils';

//  --------------------------------------------------------------------------------------------------

export default function TextInputDialog(props) {

   const [ state, setState ] = useState({
      value : props.value,
      error : props.required && Utils.isBlank(props.value)
   });

   const handleChange = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.value = value;
         draft.error = ((props.required && Utils.isBlank(value)) || (props.excluding && props.excluding.has(value)));
      }));
   }

   const handleSave = () => props.onClose(state.value);

   const handleCancel = () => props.onClose(props.value);

   return (
      <Dialog open disableBackdropClick onClose={handleCancel} fullWidth>
         <DialogTitle>{props.title}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>{
                  props.multiline ? (
                     <TextField label={props.label} value={state.value} onChange={handleChange} required={props.required} error={state.error} rows={6} multiline fullWidth autoFocus/>
                  ) : (
                     <TextField label={props.label} value={state.value} onChange={handleChange} required={props.required} error={state.error} fullWidth autoFocus/>
                  )
               }</Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleSave} color="primary" disabled={state.error}>Save</Button>
            <Button onClick={handleCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


