import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//  --------------------------------------------------------------------------------------------------

export default function MessageDialog(props) {

   return (
      <Dialog open disableBackdropClick onClose={props.onClose} aria-labelledby="message-dialog-title" aria-describedby="message-dialog-description" style={{ zIndex : 9999 }}>
         <DialogTitle id="message-dialog-title">{props.title}</DialogTitle>
         <DialogContent dividers={true}>
            <DialogContentText id="message-dialog-description">{props.message}</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.onClose} color="primary">OK</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


