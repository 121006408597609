import React from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';

import Selector from './Selector';

//  --------------------------------------------------------------------------------------------------

export default function VariantSelectors(props) {

   const { state, onChange } = props;

   const sm = props.shrink ? 6 : 12;

   const handler = (idx, value) => onChange(produce(state, (draft) => {
      draft.attribute[idx] = value;
      evaluateVariants(draft);
   }));

   return (
      <>{
         state.attributes.map((a, i) => (
            <Grid item key={'a' + i} xs={12} sm={sm}>
               <Selector label={a.name} options={a.values} value={state.attribute[i]} onChange={(e, v) => handler(i, v)}
                         disableOption={(v) => !state.available[i].has(v.id)} fullWidth select/>
            </Grid>
         ))
      }</>
   );

}

//  --------------------------------------------------------------------------------------------------

function evaluateVariants(draft) {
   //  Find all the variants that match the selection and determine if we have zeroed in on a single one
   const filtered = draft.variants.filter(draft.attribute.reduce((s, a, i) => (a === null) ? s : (v) => s(v) && v.key[i] === a.id, (v) => true));
   if ( filtered.length === 1 ) {
      const variant = filtered[0];
      draft.variant = draft.attribute.every((a, i) => (a == null) ? (variant.key[i] === 0) : (variant.key[i] === a.id)) ? variant : null;
   } else {
      draft.variant = null;
   }
   //  Determine the new set of available options for each of the selectors
   const available = draft.attributes.map(a => new Set());
   draft.attribute.forEach((av, ai) => {
      if ( av === null ) {
         filtered.forEach(v => available[ai].add(v.key[ai]));
      } else {
         draft.variants.filter(draft.attribute.reduce((s, a, i) => (a === null || i === ai) ? s : (v) => s(v) && v.key[i] === a.id, (v) => true)).forEach(v => available[ai].add(v.key[ai]));
      }
   });
   draft.available = available;
}

//  --------------------------------------------------------------------------------------------------

export function initVariants(details) {
   return produce({ }, (draft) => {
      draft.attributes = details.attributes.map((a) => {
         return {
            name   : a.name,
            values : a.values.map((v, i) => { return { id : (1 + i), name : v }; })
         }
      });
      draft.attribute  = details.attributes.map((a) => null);
      draft.variants   = details.products;
      evaluateVariants(draft);
   });
}

//  --------------------------------------------------------------------------------------------------

//  NOTE: This works on both the raw variant data and the working variant state
export function areVariantsPresent(state) {
   return (state.attributes.length > 0);
}

//  --------------------------------------------------------------------------------------------------

export function isVariantSelected(state) {
   return (state.variant !== null);
}

//  --------------------------------------------------------------------------------------------------

export function getVariantRef(state) {
   return state.variant.ref;
}

//  --------------------------------------------------------------------------------------------------

export function getVariantAttributes(state) {
   const attributes = [ ];
   if ( state.variant ) {
      state.attributes.forEach((a, i) => {
         if ( state.attribute[i] != null ) {
            attributes.push({
               name  : a.name,
               value : state.attribute[i].name
            });
         }
      });
   }
   return attributes;
}

//  --------------------------------------------------------------------------------------------------



