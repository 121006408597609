import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';

import VendorSelector from '../../components/VendorSelector';

import { ConfigurationContext } from '../../ConfigurationContext';

import SupplierList from './SupplierList';

//  --------------------------------------------------------------------------------------------------

export default function Suppliers(props) {
   const config = useContext(ConfigurationContext);
   return (config.user.level === 'S') ? (
      <VendorSelector config={config} global={true} render={(v) => (<SupplierList config={config} vendor={v}/>)}/>
   ) : config.client.vendorId ? (
      <SupplierList config={config} vendor={config.client.vendorId}/>
   ) : (
      <Typography variant="h6">Your company is not configured as a vendor.</Typography>
   );
}

//  --------------------------------------------------------------------------------------------------

