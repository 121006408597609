import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

//  --------------------------------------------------------------------------------------------------

export default function QuantitySelectors(props) {

   const { quantities, quantity, delivery, onChange } = props;

   const quantityHandler = (e) => {
      const qty = parseInt(e.target.value);
      const deliveries = quantities[qty].deliveries;
      onChange(qty, (quantity === 0 && deliveries.length === 1) ? deliveries[0].id : (deliveries.findIndex((d) => d.id === delivery) === -1) ? '' : delivery);
   };

   const deliveryHandler = (e) => onChange(quantity, e.target.value);

   const quantityError = quantity === 0;
   const deliveryError = !quantityError && delivery === '';

   return (
      <>
         <Grid item xs={12} sm={6}>
            <InputLabel error={quantityError} required shrink>Quantity</InputLabel>
            <Select value={quantity === 0 ? '' : quantity.toString()} error={quantityError} onChange={quantityHandler} fullWidth>{
               quantities.map((v) => <MenuItem key={`Q${v.quantity}`} value={v.quantity}>{v.quantity}</MenuItem>)
            }</Select>
         </Grid>
         <Grid item xs={12} sm={6}>
            <InputLabel error={deliveryError} disabled={quantityError} required={!quantityError} shrink>Delivery</InputLabel>
            <Select value={delivery} onChange={deliveryHandler} error={deliveryError} disabled={quantityError} fullWidth>{
               quantities[quantity] && quantities[quantity].deliveries.map((d) => <MenuItem key={`${quantity}${d.id}`} value={d.id}>{d.name}</MenuItem>)
            }</Select>
         </Grid>
         <Grid item xs={12}>
            <InputLabel shrink>Price</InputLabel>
            <Typography>{getText(quantities, quantity, delivery)}</Typography>
         </Grid>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function getText(quantities, quantity, delivery) {
   if ( delivery === '' ) {
      return ( quantity === 0 ) ? 'Please select a quantity' : 'Please select a delivery option';
   }
   const text = quantities[quantity].deliveries.find(d => d.id === delivery).text.split('\n');
   const first = text.shift();
   return (
      <>
         { first }
         { text.map(t => <><br/>{t}</>) }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

export function initQuantitySelectors(pricing, quantity, delivery) {
   const quantities = [ ];
   pricing.forEach((p) => {
      quantities[p.quantity] = {
         quantity   : p.quantity.toString(),
         deliveries : p.deliveries
      };
   });
   return initComplete(quantities, quantity, delivery);
}

export function initQuantitySelectorsRaw(pricing, quantity, delivery) {
   const quantities = [ ];
   if ( pricing.inclusive ) {
      pricing.pricingB.forEach((p) => {
         quantities[p.quantity] = {
            quantity   : p.quantity.toString(),
            deliveries : summariseDeliveries(p, false)
         };
      });
   } else {
      const bands = [ ...pricing.pricingB ].reverse();
      [...pricing.pricingA].reverse().forEach((p) => {
         while ( bands[0].quantity > p.quantity ) {
            bands.shift();
         }
         quantities[p.quantity] = {
            quantity   : p.quantity.toString(),
            deliveries : summariseDeliveries(bands[0], p.price)
         };
      });
   }
   return initComplete(quantities, quantity, delivery);
}

function summariseDeliveries(band, price) {
   const deliveries = [ ];
   if ( band.saver    ) deliveries.push({ id : 'SVR', name : 'Saver',              text : summariseDelivery(price, true,  band.saver)    });
   if ( band.standard ) deliveries.push({ id : 'STD', name : 'Standard',           text : summariseDelivery(price, true,  band.standard) });
   if ( band.express  ) deliveries.push({ id : 'EXP', name : 'Express',            text : summariseDelivery(price, true,  band.express)  });
   if ( band.nextDay  ) deliveries.push({ id : 'NXT', name : 'Next Working Day',   text : summariseDelivery(price, false, band.nextDay)  });
   if ( band.sameDay  ) deliveries.push({ id : 'SAM', name : 'Same Working Day',   text : summariseDelivery(price, false, band.sameDay)  });
   if ( band.collect  ) deliveries.push({ id : 'COL', name : 'Recipient Collects', text : summariseDelivery(price, false, band.collect)  });
   return deliveries;
}

function summariseDelivery(price, days, delivery) {
   return ((price ? `£${price} + £${delivery.price} delivery` : `£${delivery.price}`) + (days ? ` - ${summariseDays(delivery)}` : '') + (delivery.comment ? `\n${delivery.comment}` : ''));
}

function summariseDays(delivery) {
   return (delivery.daysMin === delivery.daysMax) ? (delivery.daysMin === 1) ? '1 working day' : `${delivery.daysMin} working days` : `${delivery.daysMin} to ${delivery.daysMax} working days`;
}

function initComplete(quantities, quantity, delivery) {
   return quantities[quantity] ? [ quantities, quantity, quantities[quantity].deliveries.findIndex((d) => d.id === delivery) === -1 ? '' : delivery ] :
                                 [ quantities, 0, '' ];
}

//  --------------------------------------------------------------------------------------------------



