import React, { useState, useEffect, useCallback } from 'react';

import produce from 'immer';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PortalAPI from '../../PortalAPI';
import { TAB_STYLE } from '../../Utils';

import CatalogPage from './CatalogPage';

//  --------------------------------------------------------------------------------------------------

export default function CatalogTabs(props) {

   const [ state, setState ] = useState({
      client   : false,
      branch   : false,
      catalogs : false
   });

   useEffect(() => {
      setState((state) => produce(state, (draft) => {
         draft.catalogs = false;
      }));
      PortalAPI.getCatalogs(props.client, props.branch).then((response) => setState((state) => produce(state, (draft) => {
         draft.client     = props.client;   //  <- MUST be cached here in state to ensure correct behaviour
         draft.branch     = props.branch;   //  <- MUST be cached here in state to ensure correct behaviour
         draft.catalogs   = response;
         draft.catalog    = (response.length > 0) ? response[0] : false;
         draft.catalogTab = 0;
      }))).catch(props.config.reportAPIError);
   }, [ props.config, props.client, props.branch, setState ]);

   const catalogHandler = useCallback((e, v) => setState((state) => produce(state, (draft) => {
      draft.catalog    = draft.catalogs[v];
      draft.catalogTab = v;
   })), [ setState ]);

   return (state.catalogs === false) ? (
      <CircularProgress color="secondary"/>
   ) : (state.catalog === false) ? (
      <Typography variant="h6">No products found.</Typography>
   ) : (
      <>
         { (state.catalogs.length > 1) && <Tabset tabs={state.catalogs} selected={state.catalogTab} onChange={catalogHandler} label="catalogue tabs"/> }
         <CatalogPageTabs config={props.config} client={state.client} branch={state.branch} catalog={state.catalog.id}/>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function CatalogPageTabs(props) {

   const [ state, setState ] = useState({
      client  : false,
      branch  : false,
      catalog : false,
      pages   : false
   });

   useEffect(() => {
      setState((state) => produce(state, (draft) => {
         draft.pages = false;
      }));
      PortalAPI.getCatalogTabs(props.client, props.branch, props.catalog).then((response) => {
         response.unshift({ id : '*', name : 'All Products' });
         setState((state) => produce(state, (draft) => {
            draft.client  = props.client;      //  <- MUST be cached here in state to ensure correct behaviour
            draft.branch  = props.branch;      //  <- MUST be cached here in state to ensure correct behaviour
            draft.catalog = props.catalog;     //  <- MUST be cached here in state to ensure correct behaviour
            draft.pages   = response;
            draft.page    = (response.length > 1) ? response[1] : false;
            draft.pageTab = 1;
         }));
      }).catch(props.config.reportAPIError);
   }, [ props.config, props.client, props.branch, props.catalog, setState ]);

   const pageHandler = useCallback((e, v) => setState((state) => produce(state, (draft) => {
      draft.page    = draft.pages[v];
      draft.pageTab = v;
   })), [ setState ]);

   return (state.pages === false) ? (
      <CircularProgress color="secondary"/>
   ) : (state.page === false) ? (
      <Typography variant="h6">No products found.</Typography>
   ) : (
      <>
         { (state.pages.length > 2) && <Tabset tabs={state.pages} selected={state.pageTab} onChange={pageHandler} label="catalogue page tabs"/> }
         <CatalogPage config={props.config} client={state.client} branch={state.branch} catalog={state.catalog} page={state.page.id}/>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function Tabset(props) {
   const { tabs, selected, onChange, label } = props;
   return (
      <>
         <Tabs variant="scrollable" scrollButtons="off" value={selected} onChange={onChange} aria-label={label}>{
            tabs.map((tab, idx) => (<Tab key={tab.id} id={`tab-${idx}`} label={(tab.id === '*') ? (<span style={{ color : 'red' }}>{tab.name}</span>) : tab.name} style={TAB_STYLE}/>))
         }</Tabs>
         <br/>
      </>
   );
}

//  --------------------------------------------------------------------------------------------------

