import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Utils from '../Utils';

//  --------------------------------------------------------------------------------------------------

export default function OrderDialog(props) {

   const { title, history, onClose } = props;

   return (
      <Dialog open disableBackdropClick onClose={onClose} scroll="paper" maxWidth="md">
         <DialogTitle>{title}</DialogTitle>
         <DialogContent dividers={true}>{
            (history.length > 0) ? (
               <Table>
                  <TableBody>{
                     history.map((row, idx) => (
                        <TableRow key={'r' + idx}>
                           <TableCell>{Utils.formatTimestamp(row.timestamp)}</TableCell>
                           <TableCell>{row.details.split(/(\n)/).map(s => s === '\n' ? <br/> : s)}</TableCell>
                        </TableRow>
                     ))
                  }</TableBody>
               </Table>
            ) : (
               <p style={{ textAlign : 'center' }}>No history available</p>
            )
         }</DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

