import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import PortalAPI from '../../PortalAPI';

import EPDQForm, { newEPDQFields, newEPDQConfirm, newEPDQErrors, resetEPDQ } from './EPDQForm';
import FlowLinkForm, { newFlowLinkFields, newFlowLinkConfirm, newFlowLinkErrors, resetFlowLink } from './FlowLinkForm';

//  --------------------------------------------------------------------------------------------------

export default function IntegrationsPage(props) {

   const { config } = props;

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(false);

   useEffect(() => {
      PortalAPI.getIntegrations().then((response) => {
         const paymentIntegrations = response.paymentIntegrations;
         const paymentGuides       = paymentIntegrations.reduce((s, i) => (i.guide ? s.set(i.id, i.guide) : s), new Map());
         const orderIntegrations   = response.orderIntegrations;
         const orderGuides         = orderIntegrations.reduce((s, i) => (i.guide ? s.set(i.id, i.guide) : s), new Map());
         delete response.paymentIntegrations;
         delete response.orderIntegrations;
         const s = {
            paymentIntegrations,
            paymentGuides,
            orderIntegrations,
            orderGuides,
            fields              : { ...response },
            confirm             : { },
            errors              : { },
            paymentTypeChanged  : false,
            orderRoutingChanged : false
         };
         switch ( response.paymentType ) {

            case 'EPDQ' :
               s.confirm.ePDQ = newEPDQConfirm();
               s.errors.ePDQ  = newEPDQErrors(response.ePDQ, s.confirm.ePDQ, false);
               break;

            default :
               break;

         }
         switch ( response.orderRouting ) {

            case 'FLOWLINK' :
               s.confirm.flowLink = newFlowLinkConfirm();
               s.errors.flowLink  = newFlowLinkErrors(response.flowLink, s.confirm.flowLink, false);
               break;

            default :
               break;

         }
         setState(produce(s, (draft) => { }));
      }).catch((error) => {
         config.reportAPIError(error);
      });
   }, [ config ]);

   const paymentTypeHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.paymentTypeChanged = true;
         draft.fields.paymentType = value;
         //  Clear the parameters for the deselected integration
         switch ( state.fields.paymentType ) {

            case 'EPDQ' :
               delete draft.fields.ePDQ;
               delete draft.confirm.ePDQ;
               delete draft.errors.ePDQ;
               break;

            default :
               break;

         }
         //  Initialize the parameters for the selected integration
         switch ( value ) {

            case 'EPDQ' :
               draft.fields.ePDQ  = newEPDQFields();
               draft.confirm.ePDQ = newEPDQConfirm();
               draft.errors.ePDQ  = newEPDQErrors(draft.fields.ePDQ, draft.confirm.ePDQ, true);
               break;

            default :
               break;

         }
      }));
   };

   const orderRoutingHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.orderRoutingChanged = true;
         draft.fields.orderRouting = value;
         //  Clear the parameters for the deselected integration
         switch ( state.fields.orderRouting ) {

            case 'FLOWLINK' :
               delete draft.fields.flowLink;
               delete draft.confirm.flowLink;
               delete draft.errors.flowLink;
               break;

            default :
               break;

         }
         //  Initialize the parameters for the selected integration
         switch ( value ) {

            case 'FLOWLINK' :
               draft.fields.flowLink  = newFlowLinkFields();
               draft.confirm.flowLink = newFlowLinkConfirm();
               draft.errors.flowLink  = newFlowLinkErrors(draft.fields.flowLink, draft.confirm.flowLink, true);
               break;

            default :
               break;

         }
      }));
   };

   const emailXMLHandler = (e) => {
      const value = e.target.checked;
      setState((state) => produce(state, (draft) => {
         draft.fields.emailXML = value;
      }));
   };

   const updateButtonHandler = () => {
      config.showMessage("Updating");
      PortalAPI.putIntegrations(state.fields).then((response) => {
         config.hideMessage();
         setState((state) => produce(state, (draft) => {
            draft.fields.version += 1;
            draft.paymentTypeChanged  = false;
            draft.orderRoutingChanged = false;
            //  Reset the payment type form
            switch ( draft.fields.paymentType ) {

               case 'EPDQ' :
                  resetEPDQ(draft);
                  break;

               default :
                  break;

            }
            //  Reset the order routing form
            switch ( draft.fields.orderRouting ) {

               case 'FLOWLINK' :
                  resetFlowLink(draft);
                  break;

               default :
                  break;

            }
         }));
      }).catch((error) => {
         config.reportAPIError(error);
      });
   };

   return state ? (
      <>
         <Paper className={classes.root} elevation={1}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Typography variant="h6">Payments</Typography>
                  <hr/>
               </Grid>
               <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                     <Select variant="outlined" value={state.fields.paymentType} onChange={paymentTypeHandler} fullWidth>{
                        state.paymentIntegrations.map((i) => (<MenuItem key={i.id} value={i.id}>{i.description}</MenuItem>))
                     }</Select>
                  </Grid>
               </Grid>
               {
                  state.paymentGuides.has(state.fields.paymentType) && (
                     <Grid item xs={12}>
                        <table>
                           <tbody>
                              <tr>
                                 <td style={{ width : '48px' }}><img src="/icons/icon_pdf.svg" alt="PDF Icon" height={48}/></td>
                                 <td>
                                    <Typography>Read our <Link href={state.paymentGuides.get(state.fields.paymentType)}>integration guide</Link> for further information.</Typography>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </Grid>
                  )
               }
               <Grid item xs={12}>{
                  (state.fields.paymentType === 'VENDOR') ? (
                     <Typography>No additional details are required for this option.</Typography>
                  ) : (state.fields.paymentType === 'EPDQ') ? (
                     <EPDQForm state={state} setState={setState}/>
                  ) : (
                     <Typography>No form defined for this payment integration !</Typography>
                  )
               }</Grid>
               <Grid item xs={12}>
                  <br/>
                  <Typography variant="h6">Orders</Typography>
                  <hr/>
               </Grid>
               <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                     <Select variant="outlined" value={state.fields.orderRouting} onChange={orderRoutingHandler} fullWidth>{
                        state.orderIntegrations.map((i) => (<MenuItem key={i.id} value={i.id}>{i.description}</MenuItem>))
                     }</Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <FormControlLabel control={(<Checkbox checked={state.fields.emailXML} onChange={emailXMLHandler}/>)}
                                       label="Add an attachment to your order emails containing the order details in XML format."/>
                  </Grid>
               </Grid>
               {
                  state.orderGuides.has(state.fields.orderRouting) && (
                     <Grid item xs={12}>
                        <table>
                           <tbody>
                              <tr>
                                 <td style={{ width : '48px' }}><img src="/icons/icon_pdf.svg" alt="PDF Icon" height={48}/></td>
                                 <td>
                                    <Typography>Read our <Link href={state.orderGuides.get(state.fields.orderRouting)}>integration guide</Link> for further information.</Typography>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </Grid>
                  )
               }
               {
                  (state.fields.orderRouting !== 'EMAIL') && (
                     <Grid item xs={12}>{
                        (state.fields.orderRouting === 'FLOWLINK') ? (
                           <FlowLinkForm state={state} setState={setState}/>
                        ) : (
                           <Typography>No form defined for this order integration !</Typography>
                        )
                     }</Grid>
                  )
               }
               <Grid item xs={12}>
                  <br/>
                  <hr/>
               </Grid>
               <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={updateButtonHandler} disabled={
                     (state.fields.paymentType === 'EPDQ' && state.errors.ePDQ.errors) ||
                     (state.fields.orderRouting === 'FLOWLINK' && state.errors.flowLink.errors)
                  }>Update</Button>
               </Grid>
            </Grid>
         </Paper>
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

