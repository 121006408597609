import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';

import PortalAPI from '../PortalAPI';
import { GLOBAL_ID } from '../Utils';

import Selector, { findOption } from './Selector';

//  --------------------------------------------------------------------------------------------------

export default function ClientSelector(props) {

   const { config, global, render } = props;

   const [ state, setState ] = useState({
      clients  : null,
      client   : null,
      clientId : config.client.id
   });

   useEffect(() => {
      PortalAPI.getClients().then((response) => {
         if ( global ) {
            response.unshift({ id : GLOBAL_ID, name : '--- Global ---' });
         }
         setState((state) => produce(state, (draft) => {
            draft.clients  = response;
            draft.client   = findOption(response, draft.clientId);
            draft.clientId = (draft.client === null) ? false : draft.client.id;
         }));
      }).catch(config.reportAPIError);
   }, [ config, global, setState ]);

   function handleClientSelect(client) {
      setState((state) => produce(state, (draft) => {
         draft.client   = client;
         draft.clientId = (client === null) ? false : client.id;
      }));
   }

   return (
      <>
         <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
               <Selector options={state.clients} value={state.client} onChange={(event, client) => handleClientSelect(client)} label="Select Client"/>
            </Grid>
         </Grid>
         <br/>
         { state.client && render(state.clientId) }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------


