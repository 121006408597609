import React, { useContext } from 'react';

import { ConfigurationContext } from '../../ConfigurationContext';

import MainSelectors from '../../components/MainSelectors';

import UserList from './UserList';

//  --------------------------------------------------------------------------------------------------

export default function Users(props) {
   const config = useContext(ConfigurationContext);
   return (config.user.level === 'S' || config.user.level === 'C') ? (
      <MainSelectors config={config} allowAll={true} render={(c, b) => (<UserList config={config} client={c} branch={b}/>)}/>
   ) : (
      <UserList config={config} client={config.client.id} branch={config.branch.id}/>
   );
}

//  --------------------------------------------------------------------------------------------------

