import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ConfirmationDialog from './components/ConfirmationDialog';

import PortalAPI from './PortalAPI';

//  --------------------------------------------------------------------------------------------------

export default function EPDQ(props) {

   const { config, onClose } = props;

   const location = useLocation();

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   useEffect(() => {
      PortalAPI.putEPDQ(location.search).then((response) => setState(response)).catch(config.reportAPIError);
   }, [ config, location ]);

   const handleSignOutButton = () => {
      setDialog(<ConfirmationDialog message="Really sign out ?" confirmationButton="Accept" onConfirmation={() => onClose(true)} onClose={() => setDialog(false)}/>);
   };

   return state ? (
      <main className={classes.wrapper}>
         <Typography variant="h3" color="primary" align="center">{ getTitle(state[0]) }</Typography>
         <p/>
         <Typography align="center">{ getEPDQText(state[0]) }</Typography>
         <p/>
         <Typography align="center">{ getBasketText(state[0], state[1]) }</Typography>
         <p/>
         <div>
            <Button variant="contained" onClick={() => onClose(false)} color="primary" autoFocus>Continue</Button>
            &nbsp;
            <Button variant="contained" onClick={handleSignOutButton} color="primary" autoFocus>Sign Out</Button>
         </div>
         { dialog }
      </main>
   ) : (
      <main className={classes.wrapper}>
         <Typography variant="h3" color="primary" align="center">Updating Basket</Typography>
         <p/>
         <CircularProgress color="secondary"/>
      </main>
   );
}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   wrapper            : {
      margin          : 'auto',
      minHeight       : '100vh',
      display         : 'flex',
      flexDirection   : 'column',
      justifyContent  : 'center',
      alignItems      : 'center'
   }
});

//  --------------------------------------------------------------------------------------------------

function getTitle(status) {
   switch ( status ) {
      case 'PENDING'    : return '[ Uncertain ]';
      case 'PAID'       : return '[ Paid ]';
      case 'REFUSED'    : return '[ Refused ]';
      case 'DECLINED'   : return '[ Declined ]';
      case 'CANCELLED'  : return '[ Cancelled ]';
      default           : return 'Unexpected Status !';
   }
}

function getEPDQText(status) {
   switch ( status ) {
      case 'PENDING'    : return 'This response this can indicate a temporary problem in contacting your card provider or bank';
      case 'PAID'       : return 'Your payment was successful';
      case 'REFUSED'    : return 'Your payment was refused';
      case 'DECLINED'   : return 'Your payment was declined';
      case 'CANCELLED'  : return 'You chose to cancel the payment';
      default           : return 'Unexpected Status !';
   }
}

function getBasketText(epdqStatus, basketStatus) {
   switch ( basketStatus ) {
      case 'LOCKED'     : return <>Your basket is currently in the <b>LOCKED</b> state waiting for a response from the payment site</>;
      case 'UNLOCKED'   : return <>Your basket is currently in the <b>UNLOCKED BY ADMINISTRATOR</b> state, please contact support !</>;
      case 'PENDING'    : return <>Your basket is currently in the <b>PENDING</b> state, this will change when we receive further updates from the payment site</>;
      case 'PAID'       : return <>Your basket is currently in the <b>PAID</b> state and is being processed through into order(s)</>;
      case 'REFUSED'    : return <>Your basket is currently in the <b>PAYMENT REFUSED</b> state, either contact the vendor for more information, or recall the basket and decide on how you wish to proceed</>;
      case 'DECLINED'   : return <>Your basket is currently in the <b>PAYMENT DECLINED</b> state, you will need to recall it and decide on how you wish to proceed</>;
      case 'CANCELLED'  : return <>Your basket is currently in the <b>PAYMENT CANCELLED</b> state, you will need to recall it and decide on how you wish to proceed</>;
      case 'INHUMED'    : return <>{(epdqStatus === 'PAID') ? 'Your basket has already been processed through into order(s)' : 'Your basket has already been recalled'}</>;
      default           : return <>Unexpected Status !</>;
   }
}

//  --------------------------------------------------------------------------------------------------

