import React, { useContext, useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Utils from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function FileDialog(props) {

   const { details, onSave, onCancel } = props;

   const config = useContext(ConfigurationContext);

   const classes = useStyles();

   const [ state, setState ] = useState({
      fields : {
         ...(details)
      },
      errors : {
         id          : Utils.isBlank(details.id),
         description : (details.artwork1 || details.artwork2) && Utils.isBlank(details.description)
      }
   });

   const handlers = useMemo(() => {

      const handleFile = (file) => {
         setState((state) => produce(state, (draft) => {
            draft.fields.id   = '';
            draft.fields.icon = '';
            draft.fields.url  = '';
            draft.errors.id   = true;
         }));
         Utils.uploadFile(config, 'GF', file, (id, data) => setState((state) => produce(state, (draft) => {
            draft.fields.id   = id;
            draft.fields.icon = data.icon;
            draft.fields.url  = data.url;
            draft.fields.name = file.name;
            draft.errors.id   = false;
         })));
      };

      const handleField = (field, value) => setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         // eslint-disable-next-line
         switch ( field ) {

            case 'artwork1' :
            case 'artwork2' :
               if ( value ) {
                  draft.fields.vendorDownload   = false;
                  draft.fields.supplierDownload = false;
               }

            // This fall-through is intentional
            // eslint-disable-next-line
            case 'description' :
               draft.errors.description = ((draft.fields.artwork1 || draft.fields.artwork2) && Utils.isBlank(draft.fields.description));
               break;

         }
      }));

      return {
         file             : (e) => handleFile(e.target.files[0]),
         description      : (e) => handleField('description',      e.target.value),
         artwork1         : (e) => handleField('artwork1',         e.target.checked),
         artwork2         : (e) => handleField('artwork2',         e.target.checked),
         vendorDownload   : (e) => handleField('vendorDownload',   e.target.checked),
         supplierDownload : (e) => handleField('supplierDownload', e.target.checked),
         userDownload     : (e) => handleField('userDownload',     e.target.checked),
         userPreview      : (e) => handleField('userPreview',      e.target.checked)
      };

   }, [ config, setState ]);

   const { fields, errors } = state;

   const artwork = (fields.artwork1 || fields.artwork2);

   return (
      <Dialog open disableBackdropClick onClose={onCancel}>
         <DialogTitle>{details.id ? 'File Details' : 'Add File'}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Typography>
                     IMPORTANT: All artwork files must be press-ready and conform to the suppliers requirements.
                     If you have any doubts or questions regarding this, please discuss them with the supplier.
                  </Typography>
                  <hr/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel error={errors.id} required shrink>File</InputLabel>
                  {
                     fields.id ? (
                        <div className={classes.fileContainer}>
                           <img className={classes.file} src={'/icons/' + fields.icon + '.svg'} height={64} alt="File Icon"/>
                           <Typography variant="body1"><b><Link href={fields.url}>{fields.name}</Link></b></Typography>
                        </div>
                     ) : (
                        <>
                           <div>
                              <input id="file" type="file" onChange={handlers.file}/>
                           </div>
                        </>
                     )
                  }
               </Grid>
               <Grid item xs={12}>
                  <TextField id="description" label="Description"
                             helperText="If this file is to be used in a selector, please restrict the description to a single concise line."
                             value={fields.description} onChange={handlers.description} error={errors.description} required={artwork} multiline fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <FormControlLabel control={(<Checkbox checked={fields.artwork1} onChange={handlers.artwork1}/>)} label="Artwork selector #1"/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <FormControlLabel control={(<Checkbox checked={fields.artwork2} onChange={handlers.artwork2}/>)} label="Artwork selector #2"/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <FormControlLabel control={(<Checkbox checked={fields.vendorDownload} onChange={handlers.vendorDownload}/>)} label="Download by vendor" disabled={artwork}/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <FormControlLabel control={(<Checkbox checked={fields.supplierDownload} onChange={handlers.supplierDownload}/>)} label="Download by supplier" disabled={artwork}/>
               </Grid>
               <Grid item xs={12}>
                  <FormControlLabel control={(<Checkbox checked={fields.userDownload} onChange={handlers.userDownload}/>)} label="Download in catalogue"/>
               </Grid>
{/*
               <Grid item xs={12} sm={6}>
                  <FormControlLabel control={(<Checkbox checked={fields.userPreview} onChange={handlers.userPreview}/>)} label="Preview in catalogue" disabled={!fields.userDownload}/>
               </Grid>
*/}
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => onSave(fields)} color="primary" disabled={errors.id || errors.description}>save</Button>
            <Button onClick={() => onCancel(false)} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   fileContainer : {
      width      : 300,
      margin     : '0 0'
   },
   file          : {
      maxWidth   : '100%',
   }
});

//  --------------------------------------------------------------------------------------------------

export function newFileDetails() {
   return {
      id               : '',
      icon             : '',
      url              : '',
      name             : '',
      description      : '',
      artwork1         : false,
      artwork2         : false,
      vendorDownload   : false,
      supplierDownload : false,
      userDownload     : false,
      userPreview      : false,
   };
}

//  --------------------------------------------------------------------------------------------------


