import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';

import SupplierDialog from './SupplierDialog';

//  --------------------------------------------------------------------------------------------------

export default function SupplierList(props) {

   const { config, vendor } = props;

   const classes = useStyles(config.theme);

   const [ suppliers, setSuppliers ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   const [ refresh, forceRefresh ] = useToggle();

   useEffect(() => {
      setSuppliers(false);
      PortalAPI.getSuppliers(vendor).then((response) => {
         setSuppliers(response);
      }).catch((error) => {
         config.reportAPIError(error);
      });
   }, [ config, vendor, refresh ]);

   function handleDialogClose(saved) {
      setDialog(false);
      if ( saved ) {
         forceRefresh();
      }
   }

   function handleEditSupplier(id) {
      config.showMessage('Loading');
      PortalAPI.getSupplier(vendor, id).then((response) => {
         config.hideMessage();
         setDialog((<SupplierDialog vendorId={vendor} supplierId={id} details={response} onClose={handleDialogClose}/>));
      }).catch((error) => {
         config.reportAPIError(error);
      });
   }

   return suppliers ? (
      <>
         {
            (suppliers.length > 0) ? (
               <Paper className={classes.root} elevation={1}>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>{
                        suppliers.map((supplier) => (
                           <TableRow key={supplier.id}>
                              <TableCell><Link color="inherit" href="#" onClick={() => handleEditSupplier(supplier.id)}>{supplier.name}</Link></TableCell>
                           </TableRow>
                        ))
                     }</TableBody>
                  </Table>
               </Paper>
            ) : (
               <Typography variant="h6">No suppliers found.</Typography>
            )
         }
         <Fab className={classes.fab} color="primary" aria-label="Create Supplier" onClick={() => handleEditSupplier()}><AddIcon/></Fab>
         { dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   }),
   fab              : (theme) => ({
      position      : 'fixed',
      bottom        : theme.spacing(2),
      right         : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

