import React, { useContext, useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import PortalAPI from '../../PortalAPI';
import { ConfigurationContext } from '../../ConfigurationContext';
import { useToggle } from '../../Utils.js';

import ClientDialog, { newClientDetails } from './ClientDialog';

//  --------------------------------------------------------------------------------------------------

export default function Clients(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ clients, setClients ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   const [ refresh, forceRefresh ] = useToggle();

   useEffect(() => {
      setClients(false);
      PortalAPI.getClients().then((response) => {
         setClients(response);
      }).catch(config.reportAPIError);
   }, [ config, refresh ]);

   function handleDialogClose(saved) {
      setDialog(false);
      if ( saved ) {
         forceRefresh();
      }
   }

   function handleCreateClient() {
      setDialog((<ClientDialog clientId={false} details={newClientDetails()} onClose={handleDialogClose}/>));
   }

   function handleEditClient(id) {
      config.showMessage('Loading');
      PortalAPI.getClient(id).then((response) => {
         config.hideMessage();
         setDialog((<ClientDialog clientId={id} details={response} onClose={handleDialogClose}/>));
      }).catch(config.reportAPIError);
   }

   return (
      <>
         {
            clients ? (
               <>
                  {
                     (clients.length > 0) ? (
                        <Paper className={classes.root} elevation={1}>
                           <Table>
                              <TableHead>
                                 <TableRow>
                                    <TableCell>Name</TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>{
                                 clients.map((client) => {
                                    const editHandler = () => handleEditClient(client.id);
                                    return (
                                       <TableRow key={client.id}>
                                          <TableCell><Link color="inherit" href="#" onClick={editHandler}>{client.name}</Link></TableCell>
                                       </TableRow>
                                    );
                                 })
                              }</TableBody>
                           </Table>
                        </Paper>
                     ) : (
                        <Typography variant="h6">No clients found.</Typography>
                     )
                  }
                  <Fab className={classes.fab} color="primary" aria-label="Create User" onClick={handleCreateClient}><AddIcon/></Fab>
               </>
            ) : (
               <CircularProgress color="secondary"/>
            )
         }
         { dialog }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   }),
   fab              : (theme) => ({
      position      : 'fixed',
      bottom        : theme.spacing(2),
      right         : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

