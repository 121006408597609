import React, { useContext } from 'react';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ConfigurationContext } from './ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export const NAVIGATION_WIDTH = 240;

//  --------------------------------------------------------------------------------------------------

export default function Navigation(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles();

   const { visibility, selected } = props;

   const drawerContent = (
      <>
         { config.client.logo && (<div className={classes.logoContainer}><img className={classes.logo} src={config.client.logo.url} title={config.client.logo.title}
                                                                              alt={config.client.logo.alt === '' ? config.client.logo.title : config.client.logo.alt}/></div>) }
         <List>
            <Divider/>
            {
               config.navigation.map((option, idx) => (option.label && option.path) ? (
                  <ListItem button key={idx} to={option.path} selected={selected === idx} onClick={() => props.onSelection(idx)}>
                     <ListItemText>{option.label}</ListItemText>
                  </ListItem>
               ) : (
                  <Divider key={idx}/>
               ))
            }
            <Divider/>
         </List>
         <div className={classes.foot}>
            <Typography variant="body2">&copy; { (new Date()).getFullYear() } The Flow Group</Typography>
         </div>
      </>
   );

   return (
      <>
         <Hidden xsUp={visibility !== 'permanent'}>
            <Drawer variant="permanent" classes={{ paper : classes.paper }} anchor="left">{drawerContent}</Drawer>
         </Hidden>
         <Hidden xsUp={visibility === 'permanent'}>
            <Drawer variant="temporary" classes={{ paper : classes.paper }} anchor="left" open={visibility === 'temporary'} onClose={props.onClose}>{drawerContent}</Drawer>
         </Hidden>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   paper         : {
      width      : NAVIGATION_WIDTH,
   },
   logoContainer : {
      width      : 150,
      margin     : '0 auto'
   },
   logo          : {
      maxWidth   : '100%',
   },
   foot          : {
      position   : 'fixed',
      bottom     : 0,
      width      : NAVIGATION_WIDTH,
      zIndex     : 9999,
      background : '#efefef',
      padding    : 4
   }
});

//  --------------------------------------------------------------------------------------------------

