import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import Amplify, { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';

import { makeStyles } from '@material-ui/core/styles';

import PortalRoot from './PortalRoot';

//  --------------------------------------------------------------------------------------------------

Amplify.configure({
   Auth : {
      region              : process.env.REACT_APP_AWS_REGION,
      userPoolId          : process.env.REACT_APP_AWS_USER_POOL,
      userPoolWebClientId : process.env.REACT_APP_AWS_WEB_CLIENT
   },
   API : {
      endpoints           : [{
         name             : 'Portal',
         endpoint         : process.env.REACT_APP_AWS_API,
         custom_header    : async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
         // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
         }
      }]
   }
});

//  --------------------------------------------------------------------------------------------------

export default function Application(props) {

   const classes = useStyles();

   const [ authState, setAuthState ] = useState();

   const [ user, setUser ] = React.useState();

   useEffect(() => onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
   }), [ ]);

   return (authState === AuthState.SignedIn && user) ? (
      <Router>
         <PortalRoot cognitoID={user.username} />
      </Router>
   ) : (
      <div className={classes.login}>
         <AmplifyAuthContainer>
            <AmplifyAuthenticator usernameAlias="email">
               <AmplifySignIn slot="sign-in" headerText={process.env.REACT_APP_GREETING} hideSignUp={true}/>
            </AmplifyAuthenticator>
         </AmplifyAuthContainer>
      </div>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   login : {
      minHeight                    : '100vh',
      display                      : 'flex',
      flexDirection                : 'column',
      justifyContent               : 'center',
      alignItems                   : 'center',
      '--amplify-primary-color'    : '#254158',
      '--amplify-primary-tint'     : '#254158',
      '--amplify-primary-shade'    : '#254158',
      '--amplify-primary-contrast' : '#FFFFFF'
   }
});

//  --------------------------------------------------------------------------------------------------

