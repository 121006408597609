import React, { useMemo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

//  --------------------------------------------------------------------------------------------------

export default function QuantityList(props) {

   const { values, onAdd, onEdit, onDelete, description, locked } = props;

   const listHandlers = useMemo(() => {
      const editHandlers = [ ];
      const deleteHandlers = [ ];
      values.forEach((p, i) => {
         editHandlers[i]   = () => onEdit(i);
         deleteHandlers[i] = () => onDelete(i);
      });
      return {
         edit   : editHandlers,
         delete : deleteHandlers
      };
   }, [ values, onEdit, onDelete ]);

   return (
      <>
         <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
            values.map((v, i) => (
               <ListItem key={i}>
                  <ListItemText><Link color="inherit" href="#" onClick={listHandlers.edit[i]}>{description(v)}</Link></ListItemText>
                  <ListItemSecondaryAction><IconButton onClick={listHandlers.delete[i]} disabled={locked}><DeleteIcon/></IconButton></ListItemSecondaryAction>
               </ListItem>
            ))
         }</List>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={onAdd} disabled={locked}><AddCircleIcon/></IconButton>
         </div>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

