import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

//  --------------------------------------------------------------------------------------------------

export default function SerialDialog(props) {

   const { details, onClose } = props;

   const [ state, setState ] = useState({
      fields : {
         ...details
      },
      errors : {
         serialFormat : false
      }
   });

   const serialTypeHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.serialType = value;
         switch ( value ) {
            case 'NONE'   : draft.fields.serialFormat = '';                       break;
            case 'GLOBAL' : draft.fields.serialFormat = '%06d';                   break;
            case 'CLIENT' : draft.fields.serialFormat = '{CLIENT}-%06d';          break;
            case 'BRANCH' : draft.fields.serialFormat = '{CLIENT}-{BRANCH}-%06d'; break;
            default       : draft.fields.serialFormat = '*** ERROR ***';          break;
         }
         draft.errors.serialFormat = false;
      }));
   };

   const serialFormatHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.serialFormat = value;
         draft.errors.serialFormat = isNotSerialFormat(value);
      }));
   }

   const saveHandler = () => onClose(state.fields);

   const closeHandler = () => onClose(details);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={closeHandler} scroll="paper" fullWidth>
         <DialogTitle>Serial Number Generation</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <FormControl>
                     <RadioGroup row value={fields.serialType} onChange={serialTypeHandler}>
                        <FormControlLabel value="NONE"   control={<Radio color="primary"/>} label="None"/>
                        <FormControlLabel value="GLOBAL" control={<Radio color="primary"/>} label="Global"/>
                        <FormControlLabel value="CLIENT" control={<Radio color="primary"/>} label="Client"/>
                        <FormControlLabel value="BRANCH" control={<Radio color="primary"/>} label="Branch"/>
                     </RadioGroup>
                  </FormControl>
               </Grid>
               {
                  (fields.serialType !== 'NONE') && (
                     <Grid item xs={12}>
                        <TextField label="Format" value={fields.serialFormat} onChange={serialFormatHandler} error={errors.serialFormat} fullWidth required/>
                     </Grid>
                  )
               }
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveHandler} color="primary" disabled={errors.serialFormat}>Save</Button>
            <Button onClick={closeHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const SERIAL_FORMAT_PATTERN = /^(?:[^%{]*(?:\{(?:CLIENT|BRANCH)\})?)*%(?:0?(?:[4-9]|10))?d(?:[^%{]*(?:\{(?:CLIENT|BRANCH)\})?)*$/;

function isNotSerialFormat(value) {
   return !SERIAL_FORMAT_PATTERN.test(value);
}

//  --------------------------------------------------------------------------------------------------

