import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function SupplierDialog(props) {

   const { vendorName, supplierName, details, locked, onClose } = props;

   const [ state, setState ] = useState({
      fields : {
         ...details
      },
      errors : {
         name : Utils.isBlank(details.name),
         id   : false
      }
   });

   const fieldHandler = (name, value) => {
      setState((state) => produce(state, (draft) => {
         draft.fields[name] = value;
         if ( name === 'name' ) {
            draft.errors.name = Utils.isBlank(value);
         }
      }));
   };

   const saveHandler = () => onClose(state.fields);

   const cancelHandler = () => onClose(props.details);

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={cancelHandler} scroll="paper" fullWidth>
         <DialogTitle>Supplier Details</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12} sm={6}>
                  <TextField label="Vendor" value={vendorName} disabled={true} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Supplier" value={supplierName} disabled={true} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Product Name" value={fields.name} onChange={(e) => fieldHandler('name', e.target.value)}
                             error={errors.name} disabled={locked} required={!locked} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Product ID" value={fields.id} onChange={(e) => fieldHandler('id', e.target.value)}
                             error={errors.id} disabled={locked} fullWidth/>
               </Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>{
            locked ? (
               <Button onClick={cancelHandler} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveHandler} color="primary" disabled={errors.name || errors.id}>Save</Button>
                  <Button onClick={cancelHandler} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

