import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

//  --------------------------------------------------------------------------------------------------

export default function TemplateDialog(props) {

   const [ state, setState ] = useState(null);

   const handleSave = () => props.onClose(state.id);

   const handleCancel = () => props.onClose(false);

   return (
      <Dialog open disableBackdropClick onClose={handleCancel} maxWidth="xs" fullWidth>
         <DialogTitle>Attribute Template Selection</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Autocomplete options={props.templates}
                                value={state}
                                onChange={(event, option) => setState(option)}
                                groupBy={(option) => (option ? option.group : '')}
                                getOptionLabel={(option) => (option ? option.name : '')}
                                renderInput={(params) => <TextField label="Select" error={state === null} required fullWidth autoFocus {...params}/>}
                                filterOptions={selectorFilterOptions}
                                fullWidth/>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleSave} color="primary" disabled={state === null}>Add</Button>
            <Button onClick={handleCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const selectorFilterOptions = createFilterOptions({
   stringify: (option) => option.name
});

//  --------------------------------------------------------------------------------------------------


