import React, { useState, useEffect } from 'react';

import produce from 'immer';

import Grid from '@material-ui/core/Grid';

import PortalAPI from '../PortalAPI';

import Selector, { findOption } from './Selector';

//  --------------------------------------------------------------------------------------------------

export default function VendorSelector(props) {

   const { config, render } = props;

   const [ state, setState ] = useState({
      vendors  : null,
      vendor   : null,
      vendorId : config.client.vendorId || false
   });

   useEffect(() => {
      PortalAPI.getVendors().then((response) => setState((state) => produce(state, (draft) => {
         draft.vendors  = response;
         draft.vendor   = findOption(response, draft.vendorId);
         draft.vendorId = (draft.vendor === null) ? false : draft.vendor.id;
      }))).catch(config.reportAPIError);
   }, [ config, setState ]);

   function handleVendorSelect(vendor) {
      setState((state) => produce(state, (draft) => {
         draft.vendor   = vendor;
         draft.vendorId = (vendor === null) ? false : vendor.id;
      }));
   }

   return (
      <>
         <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
               <Selector options={state.vendors} value={state.vendor} onChange={(event, vendor) => handleVendorSelect(vendor)} label="Select Vendor"/>
            </Grid>
         </Grid>
         <br/>
         { state.vendor && render(state.vendorId) }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------


