import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//  --------------------------------------------------------------------------------------------------

export default function SelectionDialog(props) {

   const [ state, setState ] = useState(props.value);

   return (
      <Dialog open disableBackdropClick onClose={props.onCancel}>
         <DialogTitle>{props.title}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <InputLabel shrink>{props.label}</InputLabel>
                  <Select value={state} onChange={(e) => setState(e.target.value)} fullWidth>{
                     props.options.map((o) => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)
                  }</Select>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => props.onSave(state)} color="primary" disabled={state === ''}>{props.button}</Button>
            <Button onClick={props.onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


