import React, { useContext, useState, useEffect } from 'react';

import produce from 'immer';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';
import { ConfigurationContext } from '../../ConfigurationContext';

import ClientSelector from '../../components/ClientSelector';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import DateRangeSelector from '../../components/DateRangeSelector';

import NoticeEditorDialog, { newNoticeDetails } from './NoticeEditorDialog';
import NoticeViewerDialog from './NoticeViewerDialog';

//  --------------------------------------------------------------------------------------------------

export default function NoticeBoard(props) {
   const config = useContext(ConfigurationContext);
   return (config.user.level === 'S') ? (
      <ClientSelector config={config} render={(c) => <DateRangeSelector render={(d) => <Notices config={config} client={c} dates={d}/>}/>}/>
   ) : (
      <DateRangeSelector render={(d) => <Notices config={config} client={config.client.id} dates={d}/>}/>
   );
}

//  --------------------------------------------------------------------------------------------------

function Notices(props) {

   const { config, client, dates } = props;

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState({ posts : false });

   const [ refreshToggle, refresh ] = useToggle();

   useEffect(() => loadNotices(config, client, dates, setState, refresh), [ config, client, dates, setState, refresh, refreshToggle ]);

   return state.posts ? (
      <>
         {
            (state.posts.length > 0) ? (
               <Grid container spacing={1}>{
                  state.posts.map((p) => (
                     <Grid item key={p.id} xs={true}>
                        <Card className={classes.card}>
                           <CardActionArea onClick={state.handlers.view[p.id]}>
                              <CardMedia className={classes.image} image={p.banner.url} title={p.banner.title}>{
                                 (p.clientId !== client) && <NotificationImportantIcon color="secondary"/>
                              }{
                                 p.viewed ? <CheckCircleIcon color="secondary"/> : (p.posted > config.lastLogin) && <NewReleasesIcon color="secondary"/>
                              }</CardMedia>
                              <CardContent className={classes.content}>
                                 <Typography variant="h6">{p.title}</Typography>
                              </CardContent>
                           </CardActionArea>
                           {
                              config.user.nAdmin && (config.user.level === 'S' || config.client.id === p.clientId) && (
                                 <CardActions>
                                    <IconButton onClick={state.handlers.edit[p.id]}><EditIcon/></IconButton>
                                    <IconButton onClick={state.handlers.delete[p.id]}><DeleteIcon/></IconButton>
                                 </CardActions>
                              )
                           }
                        </Card>
                     </Grid>
                  ))
               }</Grid>
            ) : (
               <Typography variant="h6">No notices were found for this timeframe.</Typography>
            )
         }
         { config.user.nAdmin && <Fab className={classes.fab} color="primary" aria-label="Create Notice" onClick={state.handlers.create}><AddIcon/></Fab> }
         { state.dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   grid             : {
      alignItems    : 'center'
   },
   card             : {
      margin        : '0px auto',
      height        : '100%',
      width         : 300
   },
   image            : {
      width         : 300,
      height        : 169
   },
   fab              : (theme) => ({
      position      : 'fixed',
      bottom        : theme.spacing(2),
      right         : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

function loadNotices(config, client, dates, setState, refresh) {
   setState((state) => produce(state, (draft) => {
      draft.posts = false;
   }));
   PortalAPI.getNotices(client, dates).then((response) => {

      const handleDialogClose = (updated) => {
         setState((state) => produce(state, (draft) => {
            draft.dialog = false;
         }));
         if ( updated ) {
            refresh();
         }
      };

      const handleViewNotice = (c, n) => {
         config.showMessage('Loading');
         PortalAPI.getNotice(c, n).then((response) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.dialog = <NoticeViewerDialog config={config} notice={response} onClose={() => handleDialogClose(false)}/>;
            }));
         }).catch(config.reportAPIError);
      };

      const handleEditNotice = (c, n) => {
         config.showMessage('Loading');
         PortalAPI.getNotice(c, n).then((response) => {
            config.hideMessage();
            setState((state) => produce(state, (draft) => {
               draft.dialog = <NoticeEditorDialog config={config} client={c} notice={n} details={response} onClose={handleDialogClose}/>;
            }));
         }).catch(config.reportAPIError);
      };

      const handleCreateNotice = () => setState((state) => produce(state, (draft) => {
         draft.dialog = <NoticeEditorDialog config={config} client={client} details={newNoticeDetails()} onClose={handleDialogClose}/>;
      }));

      const handleDeleteNotice = (c, n, v) => {
         const handleDelete = () => {
            handleDialogClose(false);
            config.showMessage('Deleting');
            PortalAPI.deleteNotice(c, n, v).then((response) => {
               config.hideMessage();
               refresh();
            }).catch(config.reportAPIError);
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message="Delete this notice ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => handleDialogClose(false)}/>
         }));
      };

      const viewHandlers = { };
      const editHandlers = { };
      const deleteHandlers = { }
      response.forEach((n) => {
         viewHandlers[n.id] = () => handleViewNotice(n.clientId, n.id);
         editHandlers[n.id] = () => handleEditNotice(n.clientId, n.id);
         deleteHandlers[n.id] = () => handleDeleteNotice(n.clientId, n.id, n.version);
      });

      setState((state) => produce(state, (draft) => {
         draft.posts    = response;
         draft.dialog   = false;
         draft.handlers = {
            view   : viewHandlers,
            edit   : editHandlers,
            delete : deleteHandlers,
            create : handleCreateNotice,
         };
      }));

   }).catch(config.reportAPIError);

}

//  --------------------------------------------------------------------------------------------------

