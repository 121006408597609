import React from 'react';

import produce from 'immer';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function EPDQForm(props) {

   const { state, setState } = props;

   const fieldHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields.ePDQ[field] = value;
      switch ( field ) {

         case 'pspid' :
            draft.errors.ePDQ.pspid = Utils.isBlank(value);
            break;

         case 'shaIn' :
            draft.errors.ePDQ.shaIn1 = validateActualSHA(state.paymentTypeChanged, value);
            draft.errors.ePDQ.shaIn2 = validateConfirmationSHA(state.paymentTypeChanged, draft.confirm.ePDQ.shaIn, value);
            break;

         case 'shaOut' :
            draft.errors.ePDQ.shaOut1 = validateActualSHA(state.paymentTypeChanged, value);
            draft.errors.ePDQ.shaOut2 = validateConfirmationSHA(state.paymentTypeChanged, draft.confirm.ePDQ.shaOut, value);
            break;

         default :
            break;

      };
      mergeErrors(draft.errors.ePDQ);
   }));

   const confirmHandler = (field, value) => setState((state) => produce(state, (draft) => {
      draft.confirm.ePDQ[field] = value;
      switch ( field ) {

         case 'shaIn' :
            draft.errors.ePDQ.shaIn2 = validateConfirmationSHA(state.paymentTypeChanged, value, draft.fields.ePDQ.shaIn);
            break;

         case 'shaOut' :
            draft.errors.ePDQ.shaOut2 = validateConfirmationSHA(state.paymentTypeChanged, value, draft.fields.ePDQ.shaOut);
            break;

         default :
            break;

      };
      mergeErrors(draft.errors.ePDQ);
   }));

   const fields  = state.fields.ePDQ;
   const confirm = state.confirm.ePDQ;
   const errors  = state.errors.ePDQ;

   return (
      <Grid container spacing={2}>
         <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={6}>
               <TextField id="pspid" label="PSPID" value={fields.pspid} onChange={(e) => fieldHandler('pspid', e.target.value)} error={errors.pspid} fullWidth required/>
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormControl fullWidth>
                  <InputLabel>Signature Strength</InputLabel>
                  <Select value={fields.signature} onChange={(e) => fieldHandler('signature', e.target.value)}>
                     <MenuItem value="SHA1">SHA-1</MenuItem>
                     <MenuItem value="SHA256">SHA-256</MenuItem>
                     <MenuItem value="SHA512">SHA-512</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
         </Grid>
         {
            state.paymentTypeChanged ? (
               <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaIn1" type="password" label="SHA-IN" value={fields.shaIn} onChange={(e) => fieldHandler('shaIn', e.target.value)} error={errors.shaIn1}
                                fullWidth required/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaIn2" type="password" label="Confirm SHA-IN" value={confirm.shaIn} onChange={(e) => confirmHandler('shaIn', e.target.value)} error={errors.shaIn2}
                                fullWidth required/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaOut1" type="password" label="SHA-OUT" value={fields.shaOut} onChange={(e) => fieldHandler('shaOut', e.target.value)} error={errors.shaOut1}
                                fullWidth required/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaOut2" type="password" label="Confirm SHA-OUT" value={confirm.shaOut} onChange={(e) => confirmHandler('shaOut', e.target.value)} error={errors.shaOut2}
                                fullWidth required/>
                  </Grid>
               </Grid>
            ) : (
               <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaIn1" type="password" label="Change SHA-IN" value={fields.shaIn} onChange={(e) => fieldHandler('shaIn', e.target.value)} error={errors.shaIn1}
                                placeholder="--- Keep Current ---" InputLabelProps={{ shrink: true }} fullWidth/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaIn2" type="password" label="Confirm SHA-IN" value={confirm.shaIn} onChange={(e) => confirmHandler('shaIn', e.target.value)} error={errors.shaIn2}
                                fullWidth required={fields.shaIn !== ''} disabled={fields.shaIn === ''}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaOut1" type="password" label="Change SHA-OUT" value={fields.shaOut} onChange={(e) => fieldHandler('shaOut', e.target.value)} error={errors.shaOut1}
                                placeholder="--- Keep Current ---" InputLabelProps={{ shrink: true }} fullWidth/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField id="shaOut2" type="password" label="Confirm SHA-OUT" value={confirm.shaOut} onChange={(e) => confirmHandler('shaOut', e.target.value)} error={errors.shaOut2}
                                fullWidth required={fields.shaOut !== ''} disabled={fields.shaOut === ''}/>
                  </Grid>
               </Grid>
            )
         }
      </Grid>
   );

}

//  --------------------------------------------------------------------------------------------------

function validateActualSHA(changed, entered) {
   return (changed && Utils.isBlank(entered));
}

function validateConfirmationSHA(changed, entered, actual) {
   return ((changed && Utils.isBlank(entered)) || (entered !== actual));
}

function mergeErrors(draft) {
   draft.errors = (draft.pspid || draft.shaIn1 || draft.shaIn2 || draft.shaOut1 || draft.shaOut2);
}

//  --------------------------------------------------------------------------------------------------

export function newEPDQFields() {
   return {
      pspid     : '',
      signature : 'SHA512',
      shaIn     : '',
      shaOut    : ''
   };
}

//  --------------------------------------------------------------------------------------------------

export function newEPDQConfirm() {
   return {
      shaIn  : '',
      shaOut : ''
   };
}

//  --------------------------------------------------------------------------------------------------

export function newEPDQErrors(fields, confirm, changed) {
   const pspid   = Utils.isBlank(fields.pspid);
   const shaIn1  = validateActualSHA(changed, fields.shaIn);
   const shaIn2  = validateConfirmationSHA(changed, confirm.shaIn, fields.shaIn);
   const shaOut1 = validateActualSHA(changed, fields.shaOut);
   const shaOut2 = validateConfirmationSHA(changed, confirm.shaOut, fields.shaOut);
   const errors  = (pspid || shaIn1 || shaIn2 || shaOut1 || shaOut2);
   return { pspid, shaIn1, shaOut1, shaIn2, shaOut2, errors };
}


//  --------------------------------------------------------------------------------------------------

export function resetEPDQ(draft) {
   draft.fields.ePDQ.shaIn = '';
   draft.fields.ePDQ.shaOut = '';
   draft.confirm.ePDQ.shaIn = '';
   draft.confirm.ePDQ.shaOut = '';
}

//  --------------------------------------------------------------------------------------------------



