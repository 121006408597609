import React, { useContext, useState, useEffect } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import VisibilityDialog, { newVisibilityDetails } from '../../components/VisibilityDialog';

import PortalAPI from '../../PortalAPI';
import { ConfigurationContext } from '../../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function GuestsDialog(props) {

   const config = useContext(ConfigurationContext);

   const [ state, setState ] = useState({
      clients   : false,
      available : 0,
      dialog    : false
   });

   useEffect(() => loadSharedClients(config, props.client, props.guests, setState), [ config, props.client, props.guests, setState ]);

   const visibilityHandler = (i) => {
      const saveHandler = (visibility) => setState((state) => produce(state, (draft) => {
         draft.clients[i].visibility = visibility;
         draft.dialog = false;
      }));
      const cancelHandler = () => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <VisibilityDialog title="Catalogue Visibility" client={props.client} value={state.clients[i].visibility} onSave={saveHandler} onCancel={cancelHandler}/>;
      }));
   };

   const selectionHandler = (i) => setState((state) => produce(state, (draft) => {
      if ( draft.clients[i].selected ) {
         //  When invoked from the delete button
         draft.clients[i].selected = false;
         draft.clients[i].visibility = newVisibilityDetails();
         draft.available = draft.available + 1;
      } else {
         //  When invoked from the add client dialog
         draft.clients[i].selected = true;
         draft.available = draft.available - 1;
         draft.dialog = false;
      }
   }));

   const addGuestHandler = () => {
      const available = state.clients.filter((g) => !g.selected);
      const cancelHandler = () => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <AddGuestDialog available={available} onSave={selectionHandler} onCancel={cancelHandler}/>;
      }));
   };

   const saveButtonHandler = () => {
      props.onSave(state.clients.filter((c) => c.selected).map((c) => ({
         id         : c.id,
         visibility : c.visibility
      })));
   };

   return (
      <Dialog open disableBackdropClick onClose={props.onCancel} scroll="paper" fullWidth>
         <DialogTitle>Guest Catalogues</DialogTitle>
         <DialogContent dividers={true}>
            {
               state.clients ? (
                  <>
                     <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
                        state.clients.filter((c) => c.selected).map((c) => (
                           <CustomListItem key={c.id}>
                              <ListItemText>{c.name}</ListItemText>
                              <ListItemSecondaryAction>
                                 { props.client && <IconButton onClick={() => visibilityHandler(c.idx)}><VisibilityIcon/></IconButton> }
                                 <IconButton onClick={() => selectionHandler(c.idx)}><DeleteIcon/></IconButton>
                              </ListItemSecondaryAction>
                           </CustomListItem>
                        ))
                     }</List>
                     <div style={{ textAlign : 'center' }}>
                        <IconButton color="primary" onClick={addGuestHandler} disabled={state.available === 0}><AddCircleIcon/></IconButton>
                     </div>
                  </>
               ) : (
                  <CircularProgress color="secondary"/>
               )
            }
            { state.dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary">Save</Button>
            <Button onClick={props.onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

const CustomListItem = withStyles({
  secondaryAction: {
    paddingRight: 104
  }
})(ListItem);

//  --------------------------------------------------------------------------------------------------

function loadSharedClients(config, client, guests, setState) {
   PortalAPI.getClients('shared').then((response) => {
      const clients = response.filter((c) => (c.id !== client));
      const index = { };
      guests.forEach((g) => { index[g.id] = g; });
      clients.forEach((c, i) => {
         const entry = index[c.id];
         if ( entry ) {
            c.selected   = true;
            c.visibility = entry.visibility;
         } else {
            c.selected   = false;
            c.visibility = newVisibilityDetails();
         }
         c.idx = i;
      });
      setState((state) => produce(state, (draft) => {
         draft.clients   = clients;
         draft.available = clients.filter((c) => (c.selected === false)).length;
      }));
   }).catch(config.reportAPIError);
}

//  --------------------------------------------------------------------------------------------------

function AddGuestDialog(props) {

   const [ selected, setSelected ] = useState(props.available[0].idx);

   return (
      <Dialog open disableBackdropClick onClose={props.onCancel}>
         <DialogTitle>Select Guest Catalogue</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Select value={selected} onChange={(e) => setSelected(e.target.value)} fullWidth>{
                     props.available.map((c) => <MenuItem key={c.id} value={c.idx}>{c.name}</MenuItem>)
                  }</Select>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => props.onSave(selected)} color="primary">Add</Button>
            <Button onClick={props.onCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

