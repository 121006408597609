import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

//  --------------------------------------------------------------------------------------------------

export default function ImageGallery(props) {

   const { images } = props;

   const [ state, setState ] = useState(0);

   return (
      <Grid container spacing={2}>
         <Grid item xs={12} style={{ textAlign : 'center' }}>
            <img height={300} width={300} src={images[state].url} title={images[state].title} alt={images[state].alt}/>
         </Grid>
         <Grid item xs={12}>
            <MobileStepper variant="dots" steps={images.length} position="static" activeStep={state}
                           backButton={<IconButton size="small" onClick={() => setState(state - 1)} disabled={state === 0}><ArrowLeftIcon/></IconButton>}
                           nextButton={<IconButton size="small" onClick={() => setState(state + 1)} disabled={state === (images.length - 1)}><ArrowRightIcon/></IconButton>}/>
         </Grid>
      </Grid>
   );

}

//  --------------------------------------------------------------------------------------------------


