import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import PortalAPI from '../../PortalAPI';
import { TAB_STYLE } from '../../Utils';

import WizardMarkups from './WizardMarkups';
import WizardRounding from './WizardRounding';
import WizardQuantities from './WizardQuantities';

//  --------------------------------------------------------------------------------------------------

export default function LockedDialog(props) {

   const { config, clientId, productId, productInfo, onClose } = props;

   const [ state, setState ] = useState({
      tab        : 0,
      quantities : new Set(productInfo.lock.quantities),
      markups    : new Map(Object.entries(productInfo.lock.markups)),
      rounding   : productInfo.lock.rounding,
      dialog     : false
   });

   const tabHandler = (e, v) => {
      setState((state) => produce(state, (draft) => {
         draft.tab = v;
      }));
   };

   const quantityHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.quantities = value;
   }));

   const markupHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.markups = value;
   }));

   const roundingHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.rounding = value;
   }));

   const unlockButtonHandler = () => {
      const message = (
         <div style={{ textAlign : 'center' }}>
            <Typography variant="h5" style={{ color : 'red' }}>[ WARNING ]</Typography>
            <br/>
            <Typography>
               Unlocking a product removes it from the automatic price update mechanism.  By doing so, you assume full responsibility for
               keeping all the product details accurate and up-to-date.  There is no way to re-instate the lock except by deleting the
               product, and re-creating it via the wizard.
            </Typography>
         </div>
      );
      const unlockHandler = () => {
         closeHandler();
         config.showMessage("Unlocking");
         PortalAPI.deleteProductLock(clientId, productId, productInfo.lock.version).then((response) => {
            config.hideMessage();
            onClose(true);
         }).catch(config.reportAPIError);
      };
      const closeHandler = () => setState((state) => produce(state, (draft) => {
         draft.dialog = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <ConfirmationDialog message={message} confirmationButton="Unlock" onConfirmation={unlockHandler} onClose={closeHandler}/>;
      }));
   };

   const saveButtonHandler = () => {
      const details = {
         version    : productInfo.lock.version,
         quantities : Array.from(state.quantities),
         markups    : Object.fromEntries(state.markups),
         rounding   : state.rounding
      };
      config.showMessage("Updating");
      PortalAPI.putProductLock(clientId, productId, details).then((response) => {
         config.hideMessage();
         onClose(true);
      }).catch(config.reportAPIError);
   };

   const cancelButtonHandler = () => onClose(false);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" fullWidth>
         <DialogTitle>Locked Product Settings</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Tabs value={state.tab} onChange={tabHandler} aria-label="tabs">
                     <Tab style={TAB_STYLE} label="Quantities"/>
                     <Tab style={TAB_STYLE} label="Markups"/>
                  </Tabs>
               </Grid>
               <Grid item xs={12}>{
                  (state.tab === 0) ? (
                     <WizardQuantities quantities={productInfo.quantities} value={state.quantities} onChange={quantityHandler}/>
                  ) : (
                     <>
                        <WizardMarkups branches={productInfo.branches} value={state.markups} onChange={markupHandler}/>
                        <WizardRounding value={state.rounding} onChange={roundingHandler}/>
                     </>
                  )
               }</Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>
            <Button color="primary" onClick={unlockButtonHandler}>Unlock</Button>
            <Button color="primary" onClick={saveButtonHandler}>Save</Button>
            <Button color="primary" onClick={cancelButtonHandler}>Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


