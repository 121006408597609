import React, { useContext, useState, useEffect } from 'react';

import produce from 'immer';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import PortalAPI from '../../PortalAPI';
import { ConfigurationContext } from '../../ConfigurationContext';
import { useToggle } from '../../Utils';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import GroupDialog, { newGroupDetails } from './GroupDialog';
import AttributeDialog, { newAttributeDetails } from './AttributeDialog';

//  --------------------------------------------------------------------------------------------------

export default function Attributes(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ refresh, forceRefresh ] = useToggle();

   const [ state, setState ] = useState({ master : false });

   // ----------------------------------

   useEffect(() => {
      setState((state) => produce(state, (draft) => {
         draft.master = false;
      }));
      PortalAPI.getAttributes().then((response) => {

         const handleDialogClose = (saved) => {
            setState((state) => produce(state, (draft) => {
               draft.dialog = false;
            }));
            if ( saved ) {
               forceRefresh();
            }
         };

         const handleCreateGroup = () => setState((state) => produce(state, (draft) => {
            draft.speedDial = false;
            draft.dialog    = (<GroupDialog groupId={false} details={newGroupDetails()} onClose={handleDialogClose}/>);
         }));

         const handleEditGroup = (id) => {
            config.showMessage('Loading');
            PortalAPI.getAttributeGroup(id).then((response) => {
               config.hideMessage();
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <GroupDialog groupId={id} details={response} onClose={handleDialogClose}/>;
               }));
            }).catch(config.reportAPIError);
         };

         const handleDeleteGroup = (id, version) => {
            const handleDelete = () => {
               handleDialogClose(false);
               config.showMessage('Deleting');
               PortalAPI.deleteAttributeGroup(id, version).then((response) => {
                  config.hideMessage();
                  forceRefresh();
               }).catch(config.reportAPIError);
            };
            setState((state) => produce(state, (draft) => {
               draft.dialog = <ConfirmationDialog message="Delete this attribute group ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => handleDialogClose(false)}/>
            }));
         };

         const getGroupList = (master) => master.map((g) => ({ id : g.id, name : g.name }));

         const handleCreateAttribute = () => setState((state) => produce(state, (draft) => {
            draft.speedDial = false;
            draft.dialog    = <AttributeDialog groups={getGroupList(draft.master)} attributeId={false} details={newAttributeDetails()} onClose={handleDialogClose}/>;
         }));

         const handleEditAttribute = (id) => {
            config.showMessage('Loading');
            PortalAPI.getAttribute(id).then((response) => {
               config.hideMessage();
               setState((state) => produce(state, (draft) => {
                  draft.dialog = <AttributeDialog groups={getGroupList(draft.master)} attributeId={id} details={response} onClose={handleDialogClose}/>;
               }));
            }).catch(config.reportAPIError);
         };

         const handleDeleteAttribute = (id, version) => {
            const handleDelete = () => {
               handleDialogClose(false);
               config.showMessage('Deleting');
               PortalAPI.deleteAttribute(id, version).then((response) => {
                  config.hideMessage();
                  forceRefresh();
               }).catch(config.reportAPIError);
            };
            setState((state) => produce(state, (draft) => {
               draft.dialog = <ConfirmationDialog message="Delete this attribute ?" confirmationButton="Delete" onConfirmation={handleDelete} onClose={() => handleDialogClose(false)}/>
            }));
         };

         const handleFilter = (e) => {
            const value = e.target.value;
            const search = value.toLowerCase();
            setState((state) => produce(state, (draft) => {
               draft.filter = value;
               if ( value === '' ) {
                  draft.groups = draft.master;
               } else {
                  const filtered = [ ];
                  draft.master.forEach((g) => {
                     const matching = g.attributes.filter((a) => a.filterName.includes(search));
                     if ( matching.length > 0 ) {
                        filtered.push({
                           id         : g.id,
                           name       : g.name,
                           attributes : matching
                        });
                     }
                  });
                  draft.groups = filtered;
               }
            }));
         };

         const handleEdit   = { };
         const handleDelete = { };

         response.forEach((g) => {
            handleEdit[g.id]   = () => handleEditGroup(g.id);
            handleDelete[g.id] = () => handleDeleteGroup(g.id, g.version);
            g.attributes.forEach((a) => {
               handleEdit[a.id]   = () => handleEditAttribute(a.id);
               handleDelete[a.id] = () => handleDeleteAttribute(a.id, a.version);
               a.filterName = a.name.toLowerCase();
               Object.freeze(a);
            });
            Object.freeze(g);
         });
         Object.freeze(response);

         setState((state) => produce(state, (draft) => {
            draft.master    = response;
            draft.handlers  = {
               filter          : handleFilter,
               edit            : handleEdit,
               delete          : handleDelete,
               openSpeedDial   : () => setState((state) => produce(state, (draft) => { draft.speedDial = true; })),
               closeSpeedDial  : () => setState((state) => produce(state, (draft) => { draft.speedDial = false; })),
               createGroup     : handleCreateGroup,
               createAttribute : handleCreateAttribute
            };
            draft.filter    = '';
            draft.groups    = response;
            draft.speedDial = false;
            draft.dialog    = false;
         }));

      }).catch(config.reportAPIError);
   }, [ config, setState, refresh, forceRefresh ]);

   // ----------------------------------

   const { master, handlers, filter, groups, speedDial, dialog } = state

   return master ? (
      <>
         {
            (master.length > 0) ? (
               <Paper className={classes.root} elevation={1}>
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="filter" label="Attribute Name Filter" value={filter} onChange={handlers.filter} fullWidth/>
                     </Grid>
                  </Grid>
                  <br/>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                           <TableCell>Actions</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>{
                        groups.map((g) => (
                           <React.Fragment key={g.id}>
                              <TableRow className={classes.groupRow}>{
                                 (g.id === 'ORPHANED') ? (
                                    <>
                                       <TableCell className={classes.groupCell}><Typography variant="h6">{g.name}</Typography></TableCell>
                                       <TableCell className={classes.groupCell}></TableCell>
                                    </>
                                 ) : (
                                    <>
                                       <TableCell className={classes.groupCell}>
                                          <Typography variant="h6"><Link color="inherit" href="#" onClick={handlers.edit[g.id]}>{g.name}</Link></Typography>
                                       </TableCell>
                                       <TableCell className={classes.groupCell}>{
                                          (g.attributes.length === 0) && (
                                             <IconButton className={classes.groupIcons} onClick={handlers.delete[g.id]} aria-label="Delete Attribute Group"><DeleteIcon/></IconButton>
                                          )
                                       }</TableCell>
                                    </>
                                 )
                              }</TableRow>
                              {
                                 (g.attributes.length > 0) ? (
                                    g.attributes.map((a) => (
                                       <TableRow key={a.id}>
                                          <TableCell>
                                             <Link color="inherit" href="#" onClick={handlers.edit[a.id]}>{a.name}</Link>
                                          </TableCell>
                                          <TableCell>
                                             <IconButton onClick={handlers.delete[a.id]} aria-label="Delete Attribute"><DeleteIcon/></IconButton>
                                          </TableCell>
                                       </TableRow>
                                    ))
                                 ) : (
                                    <TableRow key={g.id + '_empty'}>
                                       <TableCell colSpan="2"><Typography variant="h6">No attributes found.</Typography></TableCell>
                                    </TableRow>
                                 )
                              }
                           </React.Fragment>
                        ))
                     }</TableBody>
                  </Table>
               </Paper>
            ) : (
               <Typography variant="h6">No attributes found.</Typography>
            )
         }
         <SpeedDial direction="up" className={classes.fab} icon={<SpeedDialIcon/>} open={speedDial} onOpen={handlers.openSpeedDial} onClose={handlers.closeSpeedDial} ariaLabel="SpeedDial">
            <SpeedDialAction icon={<CreateNewFolderIcon/>} tooltipTitle="New Attribute Group" onClick={handlers.createGroup}/>
            <SpeedDialAction icon={<NoteAddIcon/>}         tooltipTitle="New Attribute"       onClick={handlers.createAttribute}/>
         </SpeedDial>
         { dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root              : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop     : theme.spacing(2),
      paddingBottom  : theme.spacing(2)
   }),
   groupRow          : (theme) => ({
      background     : theme.palette.primary.main
   }),
   groupCell         : (theme) => ({
      color          : theme.palette.primary.contrastText,
      paddingTop     : 0,
      paddingBottom  : 0,
      paddingLeft    : theme.spacing(2),
      paddingRight   : 0,
   }),
   groupIcons        : (theme) => ({
      color          : theme.palette.primary.contrastText,
   }),
   fab               : (theme) => ({
      position       : 'fixed',
      bottom         : theme.spacing(2),
      right          : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

