import React, { useContext } from 'react';

import { ConfigurationContext } from '../../ConfigurationContext';

import ClientSelector from '../../components/ClientSelector';

import BranchList from './BranchList';

//  --------------------------------------------------------------------------------------------------

export default function Branches(props) {
   const config = useContext(ConfigurationContext);
   return (config.user.level === 'S') ? (
      <ClientSelector config={config} render={(c) => (<BranchList config={config} client={c}/>)}/>
   ) : (
      <BranchList config={config} client={config.client.id}/>
   );
}

//  --------------------------------------------------------------------------------------------------

