import React, { useState, useMemo } from 'react';

import produce from 'immer';

import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';

import Grid from '@material-ui/core/Grid';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

//  --------------------------------------------------------------------------------------------------

export default function DateRangeSelector(props) {

   const [ state, setState ] = useState(initDates());

   const handlers = useMemo(() => ({
      start : (date) => setState((state) => produce(state, (draft) => {
         draft.start = date;
         if ( draft.end.getTime() < date.getTime() ) {
            draft.end = date;
         }
      })),
      end   : (date) => setState((state) => produce(state, (draft) => {
         if ( draft.start.getTime() > date.getTime() ) {
            draft.start = date;
         }
         draft.end = date;
      }))
   }), [ setState ]);

   return (
      <>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={1}>
               <Grid item xs={6}>
                  <DatePicker id="start-date" label="Start Date" format="yyyy-MM-dd" value={state.start} onChange={handlers.start} disableFuture fullWidth/>
               </Grid>
               <Grid item xs={6}>
                  <DatePicker id="end-date" label="End Date" format="yyyy-MM-dd" value={state.end} onChange={handlers.end} disableFuture fullWidth/>
               </Grid>
           </Grid>
         </MuiPickersUtilsProvider>
         <br/>
         { props.render(state) }
      </>
   );
}

//  --------------------------------------------------------------------------------------------------

function initDates() {
   const now = new Date();
   return {
      start : new Date(now.getFullYear(), (now.getMonth() - 1), now.getDate()),
      end   : new Date(now.getFullYear(), now.getMonth(), now.getDate())
   };
}

//  --------------------------------------------------------------------------------------------------



