import React, {useContext, useState} from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {ConfigurationContext} from '../ConfigurationContext';

import ImageDialog, {newImageDetails} from './ImageDialog';
import ConfirmationDialog from './ConfirmationDialog';

//  --------------------------------------------------------------------------------------------------

export default function ImageField(props) {

   const { role, value, onChange } = props;

   const config = useContext(ConfigurationContext);

   const smUp = useMediaQuery(config.theme.breakpoints.up('sm'));

   const [ dialog, setDialog ] = useState(false);

   const handleAddImage = () => setDialog(<ImageDialog role={role} markdown={false} details={newImageDetails()} onSave={handleImageUpdate} onCancel={handleDialogClose}/>);

   const handleEditImage = () => setDialog(<ImageDialog role={role} markdown={false} details={value} onSave={handleImageUpdate} onCancel={handleDialogClose}/>);

   const handleDeleteImage = () => setDialog(<ConfirmationDialog message="Delete this image ?" confirmationButton="Delete" onConfirmation={() => handleImageUpdate(false)} onClose={handleDialogClose}/>);

   const handleImageUpdate = (i) => {
      setDialog(false);
      onChange(i);
   };

   const handleDialogClose = () => setDialog(false);

   return (
      <>
         <Grid container>{
            value ? (
               <>
                  <Grid item xs={12} sm={6}>
                     <img style={{ maxWidth : '100%' }} src={value.url} title={value.title} alt={value.alt === '' ? value.title : value.alt}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <IconButton onClick={handleEditImage}><EditIcon/></IconButton>
                     { smUp && <br/> }
                     <IconButton onClick={handleDeleteImage}><DeleteIcon/></IconButton>
                  </Grid>
               </>
            ) : (
               <Grid item xs={12}>
                  <IconButton color="primary" onClick={handleAddImage}><AddCircleIcon/></IconButton>
               </Grid>
            )
         }</Grid>
         { dialog }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

