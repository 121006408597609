import React, { useContext, useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ConfigurationContext } from '../../ConfigurationContext';

//  --------------------------------------------------------------------------------------------------

export default function Bespoke(props) {

   const config = useContext(ConfigurationContext);

   const classes = useStyles(config.theme);

   const [ state, setState ] = useState({
      fields          : {
         title        : '',
         requirements : ''
      },
      errors          : {
         title        : true,
         requirements : true
      }
    });


   function handleInputChange(field, value) {
      setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         draft.errors[field] = !value.trim();
      }));
   }

   function handleSubmit() {
      ///
      /// ...
      ///
   }

   return (
      <Paper className={classes.root} elevation={1}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <Typography variant="h5">Use this form to give us an idea of what you would like</Typography>
               <TextField id="title" label="Job Title" margin="normal" value={state.fields.title} onChange={(e) => handleInputChange('title', e.target.value)} error={state.errors.title} fullWidth required />
            </Grid>
            <Grid item xs={12}>
               <Typography variant="h6">Please describe the product</Typography>
               <Typography>eg. Business Cards, 300GSM, Gloss...</Typography>
               <br/>
               <TextField id="requirements" label="Requirements" value={state.fields.requirements} onChange={(e) => handleInputChange('requirements', e.target.value)} error={state.errors.requirements} fullWidth required multiline rows="8"/>
            </Grid>
            <Grid item xs={12}>
               <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={(state.errors.title || state.errors.requirements)}>Submit</Button>
            </Grid>
         </Grid>
      </Paper>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   }),
   container        : {
      display       : 'flex',
      flexWrap      : 'wrap'
   }
});

//  --------------------------------------------------------------------------------------------------



