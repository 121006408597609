import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import RepeatIcon from '@material-ui/icons/Repeat';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';

//  --------------------------------------------------------------------------------------------------

export default function BasketStatusIcon(props) {

   const { mode, status } = props;

   switch ( status ) {
      case 'SUBMITTED'  : return <Tooltip title={(mode === 'P') ? 'Waiting for payment' : (mode === 'V') ? 'In cooling off period' : 'Awaiting authorisation'}><ScheduleIcon/></Tooltip>;
      //  -----
      case 'AUTHORISED' : return <Tooltip title="Basket was authorised"><CheckCircleOutlineIcon/></Tooltip>;
      case 'REJECTED'   : return <Tooltip title="Basket was NOT authorised"><FeedbackIcon/></Tooltip>;
      //  -----
      case 'REROUTE'    : return <Tooltip title="The vendor has changed their payment requirements, please resubmit"><RepeatIcon/></Tooltip>;
      case 'ISSUES'     : return <Tooltip title="Issues were found, please resolve and resubmit"><WarningIcon/></Tooltip>;
      case 'LOCKED'     : return <Tooltip title="Locked for processing"><LockIcon/></Tooltip>;
      case 'UNLOCKED'   : return <Tooltip title="Unlocked by Administrator"><LockOpenIcon/></Tooltip>;
      //  -----
      case 'PENDING'    : return <Tooltip title="Waiting on response from payment site"><HelpOutlineIcon/></Tooltip>;
      case 'PAID'       : return <Tooltip title="Payment was accepted"><AttachMoneyIcon/></Tooltip>;
      case 'REFUSED'    : return <Tooltip title="Payment was refused"><MoneyOffIcon/></Tooltip>;
      case 'DECLINED'   : return <Tooltip title="Payment was declined"><HighlightOffIcon/></Tooltip>;
      case 'CANCELLED'  : return <Tooltip title="Payment was cancelled"><CancelIcon/></Tooltip>;
      //  -----
      default           : return <Tooltip title="Unknown, please contact support"><ErrorOutlineIcon/></Tooltip>;
   }
}

//  --------------------------------------------------------------------------------------------------


