import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import PortalAPI from '../../PortalAPI';
import { useToggle } from '../../Utils';

import BranchDialog, { newBranchDetails } from './BranchDialog';

//  --------------------------------------------------------------------------------------------------

export default function BranchList(props) {

   const { config, client } = props;

   const classes = useStyles(config.theme);

   const [ branches, setBranches ] = useState(false);

   const [ dialog, setDialog ] = useState(false);

   const [ refresh, forceRefresh ] = useToggle();

   useEffect(() => {
      setBranches(false);
      PortalAPI.getBranches(client).then((response) => {
         setBranches(response);
      }).catch(config.reportAPIError);
   }, [ config, client, refresh ]);

   function handleDialogClose(saved) {
      setDialog(false);
      if ( saved ) {
         forceRefresh();
      }
   }

   function handleCreateBranch() {
      setDialog((<BranchDialog clientId={client} branchId={false} details={newBranchDetails()} onClose={handleDialogClose}/>));
   }

   function handleEditBranch(id) {
      config.showMessage('Loading');
      PortalAPI.getBranch(client, id).then((response) => {
         config.hideMessage();
         setDialog((<BranchDialog clientId={client} branchId={id} details={response} onClose={handleDialogClose}/>));
      }).catch(config.reportAPIError);
   }

   return branches ? (
      <>
         {
            (branches.length > 0) ? (
               <Paper className={classes.root} elevation={1}>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>{
                        branches.map(branch => {
                           const editHandler = () => handleEditBranch(branch.id);
                           return (
                              <TableRow key={branch.id}>
                                 <TableCell><Link color="inherit" href="#" onClick={editHandler}>{branch.name}</Link></TableCell>
                              </TableRow>
                           );
                        })
                     }</TableBody>
                  </Table>
               </Paper>
            ) : (
               <Typography variant="h6">No branches found.</Typography>
            )
         }
         <Fab className={classes.fab} color="primary" aria-label="Create User" onClick={handleCreateBranch}><AddIcon/></Fab>
         { dialog }
      </>
   ) : (
      <CircularProgress color="secondary"/>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   root             : (theme) => ({
      ...theme.mixins.gutters(),
      paddingTop    : theme.spacing(2),
      paddingBottom : theme.spacing(2)
   }),
   fab              : (theme) => ({
      position      : 'fixed',
      bottom        : theme.spacing(2),
      right         : theme.spacing(2)
   })
});

//  --------------------------------------------------------------------------------------------------

