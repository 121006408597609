import React, { useMemo } from 'react';

import Markdown from 'markdown-to-jsx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function NoticeViewer(props) {

   const { config, notice, onClose } = props;

   const content = useMemo(() => initContent(config, notice), [ config, notice ]);

   return (
      <Dialog open disableBackdropClick onClose={onClose} maxWidth="md" scroll="paper">
         <DialogContent dividers={true}>
            <Typography variant="h4" component="h1">{notice.title}</Typography>
            {
               notice.firstAuthor ? (
                  <>
                     <Typography variant="subtitle1" component="h2">Posted by {notice.firstAuthor} on {Utils.formatTimestamp(notice.firstPosted)}</Typography>
                     {
                        (notice.firstAuthor === notice.author) ? (
                           <Typography variant="subtitle2" component="h3">Last updated on {Utils.formatTimestamp(notice.posted)}</Typography>
                        ) : (
                           <Typography variant="subtitle2" component="h3">Last updated by {notice.author} on {Utils.formatTimestamp(notice.posted)}</Typography>
                        )
                     }
                  </>
               ) : (
                  <Typography variant="subtitle1" component="h2">Posted by {notice.author} on {Utils.formatTimestamp(notice.posted)}</Typography>
               )
            }
            <hr/>
            <Markdown>{content}</Markdown>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">Close</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

function initContent(config, notice) {
   const idx = { };
   const refs = [ ];
   notice.images.forEach((i) => {
      idx[i.id] = `![${i.alt === '' ? i.title : i.alt}][${i.id}]`;
      refs.push(`[${i.id}]: ${i.url} "${i.title}"`);
   });
   return notice.content
                .replace(/!\[([0-9a-f]{8}-[[0-9a-f]{4}-[[0-9a-f]{4}-[[0-9a-f]{4}-[[0-9a-f]{12})\]/g, (m, g) => (idx[g] || '**(BAD IMAGE REFERENCE)**'))
                .replace(/\{\{([a-z]+)\}\}/g, (m, g) => {
                   switch ( g ) {
                      case 'client' : return escape(config.client.name);
                      case 'branch' : return escape(config.branch.name);
                      case 'user'   : return escape(config.user.name.forename);
                      default       : return '**(UNKNOWN VARIABLE)**';
                   }
                }) + '\n\n' + refs.join('\n\n');
}

function escape(text) {
   return text.replace(/[\\`*_{}[\]<>()#+\-.!|]/g, '\\$&');
}

//  --------------------------------------------------------------------------------------------------

