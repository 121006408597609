import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import Utils, { GLOBAL_ID } from '../../Utils';

import ImageField from '../../components/ImageField';
import ImageList from '../../components/ImageList';

import NoticeViewerDialog from './NoticeViewerDialog';
import PortalAPI from '../../PortalAPI';

import AudienceDialog from './AudienceDialog';

//  --------------------------------------------------------------------------------------------------

export default function NoticeEditorDialog(props) {

   const [ state, setState ] = useState(() => initState(props.details));

   const fieldHandlers = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const previewHandler = () => previewNotice(props.config, setState);

   const publishHandler = () => publishNotice(props, state.fields, setState);

   const handleCancel = () => props.onClose(false);

   const { fields, errors, dialog } = state;

   return (
      <Dialog open disableBackdropClick onClose={handleCancel} maxWidth="md" scroll="paper">
         <DialogTitle>{props.notice ? "Edit Notice" : "Create Notice"}</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField id="title" label="Title" value={fields.title} error={errors.title} onChange={fieldHandlers.title} required fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel error={errors.banner} required shrink>Banner</InputLabel>
                  <ImageField role="NB" value={fields.banner} onChange={fieldHandlers.banner}/>
               </Grid>
               <Grid item xs={12}>
                  <hr/>
                  <TextField id="content" label="Content" value={fields.content} error={errors.content} onChange={fieldHandlers.content} required fullWidth multiline rows="20"/>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel shrink>Images</InputLabel>
                  <ImageList role="GI" wide={true} markdown={true} images={fields.images} onChange={fieldHandlers.images}/>
               </Grid>
            </Grid>
            { dialog }
         </DialogContent>
         <DialogActions>
            <Button onClick={previewHandler}>Preview</Button>
            <Button onClick={publishHandler} disabled={errors.banner || errors.title || errors.content}>Publish</Button>
            <Button onClick={handleCancel}>Close</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

function initState(details) {
   return {
      fields     : {
         ...(details)
      },
      errors     : {
         banner  : (details.banner === false),
         title   : Utils.isBlank(details.title),
         content : Utils.isBlank(details.content)
      },
      dialog     : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {
   const handleBanner = (i) => setState((state) => produce(state, (draft) => {
      draft.fields.banner = i;
      draft.errors.banner = (i === false);
      draft.dialog = false;
   }));

   const handleText = (field, value) => setState((state) => produce(state, (draft) => {
      draft.fields[field] = value;
      draft.errors[field] = Utils.isBlank(value);
   }));

   const handleImages = (idx, details) => setState((state) => produce(state, (draft) => {
      if ( details ) {
         draft.fields.images[idx] = details;
      } else {
         draft.fields.images.splice(idx, 1);
      }
   }));

   return {
      banner  : handleBanner,
      title   : (e) => handleText('title', e.target.value),
      content : (e) => handleText('content', e.target.value),
      images  : handleImages
   };

}

//  --------------------------------------------------------------------------------------------------

function previewNotice(config, setState) {
   const handleDialogClose = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <NoticeViewerDialog config={config} notice={state.fields} onClose={handleDialogClose}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function publishNotice(props, fields, setState) {

   const { config, client, notice, onClose } = props;

   if ( notice ) {
      config.showMessage('Updating Notice');
      PortalAPI.putNotice(client, notice, fields).then((response) => {
         config.hideMessage();
         onClose(true);
      }).catch(config.reportAPIError);
   } else {
      const handleCreate = (c) => {
         config.showMessage('Publishing Notice');
         PortalAPI.postNotice(c, fields).then((response) => {
            config.hideMessage();
            onClose(true);
         }).catch(config.reportAPIError);
      };
      if ( config.user.level === 'S' ) {
         const handleClose = () => setState((state) => produce(state, (draft) => {
            draft.dialog = false;
         }));
         const handleGlobal = () => {
            handleClose();
            handleCreate(GLOBAL_ID);
         };
         const handleSelected = () => {
            handleClose();
            handleCreate(client);
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <AudienceDialog onSelected={handleSelected} onGlobal={handleGlobal} onClose={handleClose}/>;
         }));
      } else {
         handleCreate(client);
      }
   }
}

//  --------------------------------------------------------------------------------------------------

export function newNoticeDetails() {
   return {
      version : 0,
      author  : 'You',
      posted  : (new Date()).toUTCString(),
      banner  : false,
      title   : '',
      content : '',
      images  : [ ]
   };
}

//  --------------------------------------------------------------------------------------------------

