import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

//  --------------------------------------------------------------------------------------------------

export default function RejectDialog(props) {

   const [ state, setState ] = useState('');

   const handleSave = () => props.onConfirmation(state);

   const handleCancel = () => props.onClose();

   return (
      <Dialog open disableBackdropClick onClose={handleCancel} fullWidth>
         <DialogTitle>Confirmation Required</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <TextField label="Reason for rejecting this basket (optional)" value={state} onChange={(e) => setState(e.target.value)} rows={6} multiline fullWidth autoFocus/>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleSave} color="primary">Reject</Button>
            <Button onClick={handleCancel} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------


