import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';

import ContactDetails, { newContactDetails, initContactErrors } from '../components/ContactDetails';

import { TAB_STYLE } from '../Utils';

//  --------------------------------------------------------------------------------------------------

const SHIPPING = 'Shipping';
const BILLING  = 'Billing';
const MAIN     = 'Main';
const SPECIFY  = 'Specify';

//  --------------------------------------------------------------------------------------------------

export default function AddressDialog(props) {

   const [ state, setState ] = useState(() => initState(props.config, props.addresses, props.addressId, props.address, props.instruction));

   const handlers = useMemo(
      () => createFieldHandlers(props.config, props.addresses, props.instructions, setState),
      [ props.config, props.addresses, props.instructions, setState ]
   );

   const cancelButtonHandler = () => props.onClose(props.addressId, props.address, props.instruction);

   const saveButtonHandler = () => props.onClose(state.addressId, state.address, state.instruction);

   const enabled = (state.addressId === SPECIFY);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper">
         <DialogTitle>Delivery Address and Instruction</DialogTitle>
         <DialogContent style={{ height : '90vh' }} dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <InputLabel shrink>Select</InputLabel>
                  <Select value={state.addressId} onChange={handlers.address} fullWidth>
                     <MenuItem key={SHIPPING} value={SHIPPING}>Shipping address for branch</MenuItem>
                     <MenuItem key={BILLING} value={BILLING}>Billing address for branch</MenuItem>
                     <MenuItem key={MAIN} value={MAIN}>Main address for branch</MenuItem>
                     { state.addresses.map((a) => <MenuItem key={a} value={a}>{a}</MenuItem>) }
                     <MenuItem key={SPECIFY} value={SPECIFY}>Specify an alternate address...</MenuItem>
                  </Select>
               </Grid>
               <Grid item xs={12}>
                  <Tabs value={state.tab} onChange={handlers.tab} aria-label="tabs">
                     <Tab style={TAB_STYLE} label="Address"/>
                     <Tab style={TAB_STYLE} label="Instruction"/>
                  </Tabs>
               </Grid>
               <Grid item xs={12}>{
                  (state.tab === 0) ? (
                     <ContactDetails config={props.config} values={state.address} errors={state.errors} required={enabled} onChange={handlers.details}/>
                  ) : (
                     <TextField value={state.instruction} onChange={handlers.instruction} multiline fullWidth/>
                  )
               }</Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary" disabled={state.addressId === SPECIFY && state.errors.combined}>Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}

//  --------------------------------------------------------------------------------------------------

function initState(config, addresses, addressId, address, instruction) {
   return {
      addresses   : Object.keys(addresses).filter(n => !(n === SHIPPING || n === BILLING || n === MAIN || n === SPECIFY)).sort(),
      addressId   : addressId,
      tab         : 0,
      address     : address,
      instruction : instruction,
      errors      : initContactErrors(config, address)
   };
}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(config, addresses, instructions, setState) {
   return {

      address : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.addressId = value;
            if ( value !== SPECIFY ) {
               const address     = addresses[value];
               draft.address     = address;
               draft.instruction = instructions[value];
               draft.errors      = initContactErrors(config, address);
            } else {
               const address     = newContactDetails();
               draft.address     = address;
               draft.instruction = '';
               draft.errors      = initContactErrors(config, address);
            }
         }));
      },

      tab : (e, v) => setState((state) => produce(state, (draft) => {
         draft.tab = v;
      })),

      details : (cv, ce) => setState((state) => produce(state, (draft) => {
         draft.address = cv;
         draft.errors  = ce;
      })),

      instruction : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.instruction = value;
         }));
      }

   };
}

//  --------------------------------------------------------------------------------------------------


