import React, { useState, useMemo } from 'react';

import produce from 'immer';

import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import Utils, { TAB_STYLE } from '../../Utils';

import PricingABranchDialog, { newPricingABranchDetails, testPricingABranchDetails } from './PricingABranchDialog';
import PricingBBranchDialog, { newPricingBBranchDetails, testPricingBBranchDetails } from './PricingBBranchDialog';

//  --------------------------------------------------------------------------------------------------

export default function PricingDialog(props) {

   const { vatRates, branches, value, locked, onSave, onCancel } = props;

   const [ state, setState ] = useState(() => initState(branches, value));

   const handlers = useMemo(() => createHandlers(vatRates, branches, locked, setState), [ vatRates, branches, locked, setState ]);

   const saveHandler = () => onSave({
      review    : Utils.formatStartDate(state.fields.review),
      vatId     : state.fields.vatId,
      inclusive : state.fields.inclusive,
      pricingA  : Object.fromEntries(state.fields.pricingA),
      pricingB  : Object.fromEntries(state.fields.pricingB)
   });

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Product Pricing</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <DatePicker id="review-date" label="Next Pricing Review" format="yyyy-MM-dd" value={state.fields.review} onChange={handlers.review}
                                 minDateMessage={<>The pricing review date for this product has been passed.</>} disablePast disabled={locked} fullWidth/>
                  </MuiPickersUtilsProvider>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel shrink required={!locked} error={state.errors.vatId}>VAT Rate</InputLabel>
                  <Select value={state.fields.vatId} onChange={handlers.vat} disabled={locked} fullWidth>{
                     vatRates.map((v) => <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>)
                  }</Select>
               </Grid>
               <Grid item xs={12}>
                  <FormControlLabel control={(<Checkbox checked={state.fields.inclusive} onChange={handlers.inclusive}/>)} label="Prices are inclusive of delivery" disabled={locked}/>
               </Grid>
               <Grid item xs={12}>
                  <Tabs value={state.tab} onChange={handlers.tab} aria-label="tabs">
                     <Tab style={TAB_STYLE} label="Prices"/>
                     <Tab style={TAB_STYLE} label="Delivery Bands" disabled={state.fields.inclusive}/>
                  </Tabs>
               </Grid>
               <Grid item xs={12}>{
                  state.fields.inclusive ? (
                     <React.Fragment key="INC">
                        <BranchList tab={0} selected={state.tab} locked={locked}
                                    branches={branches} values={state.fields.pricingB}
                                    onAdd={handlers.addPricingBBranch} onEdit={handlers.editPricingBBranch} onDelete={handlers.deletePricingBBranch}/>
                     </React.Fragment>
                  ) : (
                     <React.Fragment key="EXC">
                        <BranchList tab={0} selected={state.tab} locked={locked}
                                    branches={branches} values={state.fields.pricingA}
                                    onAdd={handlers.addPricingABranch} onEdit={handlers.editPricingABranch} onDelete={handlers.deletePricingABranch}/>
                        <BranchList tab={1} selected={state.tab} locked={locked}
                                    branches={branches} values={state.fields.pricingB}
                                    onAdd={handlers.addPricingBBranch} onEdit={handlers.editPricingBBranch} onDelete={handlers.deletePricingBBranch}/>
                     </React.Fragment>
                  )
               }</Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>{
            locked ? (
               <Button onClick={onCancel} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveHandler} color="primary" disabled={state.errors.vatId || state.errors.pricingA || state.errors.pricingB || state.errors.pricingAB}>Save</Button>
                  <Button onClick={onCancel} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(info, value) {
   const review    = new Date(value.review);
   const vatId     = value.vatId;
   const inclusive = value.inclusive;
   const pricingA  = new Map(Object.entries(value.pricingA));
   const pricingB  = new Map(Object.entries(value.pricingB));
   return {
      fields : {
         review,
         vatId,
         inclusive,
         pricingA,
         pricingB
      },
      errors : {
         vatId     : vatId === '',
         pricingA  : testPricingA(pricingA),
         pricingB  : testPricingB(pricingB),
         pricingAB : testPricingAB(pricingA, pricingB)
      },
      tab    : 0,
      dialog : false
   };
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(vatRates, branches, locked, setState) {

   const saveAHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.pricingA.set(value.branch, value.details);
      draft.errors.pricingA  = testPricingA(draft.fields.pricingA);
      draft.errors.pricingAB = testPricingAB(draft.fields.pricingA, draft.fields.pricingB);
      draft.dialog           = false;
   }));

   const saveBHandler = (value) => setState((state) => produce(state, (draft) => {
      draft.fields.pricingB.set(value.branch, value.details);
      draft.errors.pricingB  = testPricingB(draft.fields.pricingB);
      draft.errors.pricingAB = testPricingAB(draft.fields.pricingA, draft.fields.pricingB);
      draft.dialog           = false;
   }));

   const closeDialogHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));

   return {

      review : (d) => {
         setState((state) => produce(state, (draft) => {
            draft.fields.review = d;
         }));
      },

      vat : (e) => {
         const value = e.target.value;
         setState((state) => produce(state, (draft) => {
            draft.fields.vatId = value;
            draft.errors.vatId = false;
         }));
      },

      inclusive : (e) => {
         const value = e.target.checked;
         const confirmationHandler = () => {
            setState((state) => produce(state, (draft) => {
               draft.fields.inclusive = value;
               draft.fields.pricingA  = new Map(value ? [ ] : [ [ 'DEFAULT', newPricingABranchDetails() ] ]);
               draft.fields.pricingB  = new Map([ [ 'DEFAULT', newPricingBBranchDetails() ] ]);
               draft.errors.pricingA  = testPricingA(draft.fields.pricingA);
               draft.errors.pricingB  = testPricingB(draft.fields.pricingB);
               draft.errors.pricingAB = testPricingAB(draft.fields.pricingA, draft.fields.pricingB);
               draft.tab              = 0;
               draft.dialog           = false;
            }));
         };
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message="Changing the pricing model of this product will reset the pricing tables
                                                        and discard any currently configured prices.  Are you sure you want to procede ?"
                                               confirmationButton="Change" onConfirmation={confirmationHandler} onClose={closeDialogHandler}/>;
         }));
      },

      tab : (e, v) => {
         setState((state) => produce(state, (draft) => {
            draft.tab = v;
         }));
      },

      addPricingABranch : () => setState((state) => {
         const s = branches.filter((s) => !state.fields.pricingA.has(s.id));
         const v = {
            branch  : '',
            details : newPricingABranchDetails()
         };
         return produce(state, (draft) => {
            draft.dialog = <PricingABranchDialog vatRates={vatRates} branches={s} locked={locked} value={v} onSave={saveAHandler} onCancel={closeDialogHandler}/>
         });
      }),

      editPricingABranch : (branch) => setState((state) => {
         const s = (branch === 'DEFAULT') ? [ { id : branch, name : branch } ] : branches;
         const v = {
            branch  : branch,
            details : state.fields.pricingA.get(branch)
         };
         return produce(state, (draft) => {
            draft.dialog = <PricingABranchDialog vatRates={vatRates} branches={s} locked={locked} value={v} onSave={saveAHandler} onCancel={closeDialogHandler}/>
         });
      }),

      deletePricingABranch : (branch) => {
         const confirmationHandler = () => setState((state) => produce(state, (draft) => {
            draft.fields.pricingA.delete(branch);
            draft.dialog = false;
         }));
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message="Delete the prices for this branch ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={closeDialogHandler}/>;
         }));
      },

      addPricingBBranch : () => setState((state) => {
         const s = branches.filter((s) => !state.fields.pricingB.has(s.id));
         const v = {
            branch  : '',
            details : newPricingBBranchDetails()
         };
         return produce(state, (draft) => {
            draft.dialog = <PricingBBranchDialog vatRates={vatRates} branches={s} locked={locked} inclusive={state.fields.inclusive} value={v} onSave={saveBHandler} onCancel={closeDialogHandler}/>
         });
      }),

      editPricingBBranch : (branch) => setState((state) => {
         const s = (branch === 'DEFAULT') ? [ { id : branch, name : branch } ] : branches;
         const v = {
            branch  : branch,
            details : state.fields.pricingB.get(branch)
         };
         return produce(state, (draft) => {
            draft.dialog = <PricingBBranchDialog vatRates={vatRates} branches={s} locked={locked} inclusive={state.fields.inclusive} value={v} onSave={saveBHandler} onCancel={closeDialogHandler}/>
         });
      }),

      deletePricingBBranch : (branch) => {
         const confirmationHandler = () => setState((state) => produce(state, (draft) => {
            draft.fields.pricingB.delete(branch);
            draft.dialog = false;
         }));
         setState((state) => produce(state, (draft) => {
            draft.dialog = <ConfirmationDialog message={state.fields.inclusive ? 'Delete the prices for this branch ?' : 'Delete the delivery bands for this branch ?'}
                                               confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={closeDialogHandler}/>;
         }));
      }

   };

}

//  --------------------------------------------------------------------------------------------------

function BranchList(props) {

   const { tab, selected, locked, branches, values, onAdd, onEdit, onDelete } = props;

   return (tab === selected) && (
      <>
         <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>
            <ListItem key="DEFAULT">
               <ListItemText><Link color="inherit" href="#" onClick={() => onEdit('DEFAULT')}>DEFAULT</Link></ListItemText>
            </ListItem>
            {
               branches.filter((s) => values.has(s.id)).map((s) => (
                  <ListItem key={s.id}>
                     <ListItemText><Link color="inherit" href="#" onClick={() => onEdit(s.id)}>{s.name}</Link></ListItemText>
                     <ListItemSecondaryAction>
                        <IconButton onClick={() => onDelete(s.id)} disabled={locked}><DeleteIcon/></IconButton>
                     </ListItemSecondaryAction>
                  </ListItem>
               ))
            }
         </List>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={onAdd} disabled={locked || values.size === (branches.length + 1)}><AddCircleIcon/></IconButton>
         </div>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

export function testPricingDetails(details) {
   const pricingA = new Map(Object.entries(details.pricingA));
   const pricingB = new Map(Object.entries(details.pricingB));
   return details.vatId === '' || testPricingA(pricingA) || testPricingB(pricingB) || testPricingAB(pricingA, pricingB);
}

//  --------------------------------------------------------------------------------------------------

function testPricingA(pricingA, pricingB) {
   let errors = false;
   pricingA.forEach((v, k) => {
      if ( !errors && testPricingABranchDetails(v) ) {
         errors = true;
      }
   });
   return errors;
}

//  --------------------------------------------------------------------------------------------------

function testPricingB(pricingB) {
   let errors = false;
   pricingB.forEach((v, k) => {
      if ( !errors && testPricingBBranchDetails(v) ) {
         errors = true;
      }
   });
   return errors;
}

//  --------------------------------------------------------------------------------------------------

function testPricingAB(pricingA, pricingB) {
   let errors = false;
   pricingA.forEach((v, k) => {
      if ( !errors ) {
         const bands = pricingB.get(k) || pricingB.get('DEFAULT');
         if ( v.length === 0 || !bands || bands.length === 0 || bands[0].quantity > v[0].quantity ) {
            errors = true;
         }
      }
   });
   return errors;
}

//  --------------------------------------------------------------------------------------------------


