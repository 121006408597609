import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Utils from '../../Utils';

//  --------------------------------------------------------------------------------------------------

export default function PricingBDeliveryDialog(props) {

   const { vatRates, style, locked, inclusive, details, onSave, onCancel } = props;

   const noDays = (locked || style === 'NXT' || style === 'SAM' || style === 'COL');

   const [ state, setState ] = useState({
      fields : {
         cost    : details.cost,
         price   : details.price,
         vatId   : details.vatId,
         daysMin : details.daysMin.toString(),
         daysMax : details.daysMax.toString(),
         comment : details.comment
      },
      errors : {
         cost    : inclusive && Utils.isNotPrice(details.cost),
         price   : Utils.isNotPrice(details.price),
         vatId   : !inclusive && details.vatId === '',
         daysMin : details.daysMin < 0 || details.daysMin > details.daysMax,
         daysMax : details.daysMax < 0 || details.daysMax < details.daysMin
      }
   });

   const vatHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.vatId = value;
         draft.errors.vatId = false;
      }));
   };

   const costHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.cost = value;
         draft.errors.cost = Utils.isNotPrice(value);
      }));
   };

   const priceHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.price = value;
         draft.errors.price = Utils.isNotPrice(value);
      }));
   };

   const daysMinHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.daysMin = value;
         checkMinMaxDays(draft);
      }));
   };

   const daysMaxHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.daysMax = value;
         checkMinMaxDays(draft);
      }));
   };

   const commentHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.comment = value;
      }));
   };

   const saveHandler = () => onSave({
      cost    : state.fields.cost,
      price   : state.fields.price,
      vatId   : state.fields.vatId,
      daysMin : parseInt(state.fields.daysMin),
      daysMax : parseInt(state.fields.daysMax),
      comment : state.fields.comment
   });

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Delivery Details</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12} sm={6}>{
                  inclusive ? (
                     <TextField label="Cost" value={fields.cost} error={errors.cost} onChange={costHandler} disabled={locked} required={!locked} fullWidth
                                InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
                  ) : (
                     <>
                        <InputLabel shrink required={!locked} error={errors.vatId}>VAT Rate</InputLabel>
                        <Select value={fields.vatId} onChange={vatHandler} disabled={locked} fullWidth>{
                           vatRates.map((v) => <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>)
                        }</Select>
                     </>
                  )
               }</Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Price" value={fields.price} error={errors.price} onChange={priceHandler} disabled={locked} required={!locked} fullWidth
                             InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Minimum Working Days" value={fields.daysMin} error={errors.daysMin} onChange={daysMinHandler} disabled={noDays} required={!noDays} fullWidth/>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField label="Maximum Working Days" value={fields.daysMax} error={errors.daysMax} onChange={daysMaxHandler} disabled={noDays} required={!noDays} fullWidth/>
               </Grid>
               <Grid item xs={12}>
                  <TextField label="Comment" value={fields.comment} onChange={commentHandler} disabled={locked} fullWidth/>
               </Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>{
            locked ? (
               <Button onClick={onCancel} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveHandler} color="primary" disabled={errors.cost || errors.price || errors.vatId || errors.daysMin || errors.daysMax}>Save</Button>
                  <Button onClick={onCancel} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function checkMinMaxDays(draft) {
   const errorMin = Utils.isNotInteger(draft.fields.daysMin);
   const errorMax = Utils.isNotInteger(draft.fields.daysMax);
   if ( errorMin || errorMax ) {
      draft.errors.daysMin = errorMin;
      draft.errors.daysMax = errorMax;
   } else {
      const daysMin = parseInt(draft.fields.daysMin);
      const daysMax = parseInt(draft.fields.daysMax);
      draft.errors.daysMin = daysMin < 0 || daysMin > daysMax;
      draft.errors.daysMax = daysMax < 0 || daysMax < daysMin;
   }
}

//  --------------------------------------------------------------------------------------------------

export function newPricingBDeliveryDetails(delivery, inclusive) {
   const details = {
      price   : '0.00',
      daysMin : (delivery === 'SVR') ?  9 : (delivery === 'STD') ? 6 : (delivery === 'EXP') ? 3 : (delivery === 'NXT') ? 1 : 0,
      daysMax : (delivery === 'SVR') ? 10 : (delivery === 'STD') ? 7 : (delivery === 'EXP') ? 4 : (delivery === 'NXT') ? 1 : 0,
      comment : ''
   };
   if ( inclusive ) {
      details.cost = '0.00';
   } else {
      details.vatId = '';
   }
   return details;
}

//  --------------------------------------------------------------------------------------------------

export function testPricingBDeliveryDetails(delivery) {
   return (delivery.vatId === '');
}

//  --------------------------------------------------------------------------------------------------

