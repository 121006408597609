import React, { useContext } from 'react';

import { ConfigurationContext } from '../../ConfigurationContext';

import MainSelectors from '../../components/MainSelectors';

import CatalogTabs from './CatalogTabs';

//  --------------------------------------------------------------------------------------------------

export default function Catalog(props) {
   const config = useContext(ConfigurationContext);
   return ((config.user.level === 'S' || config.user.level === 'C') && config.user.others) ? (
      <MainSelectors config={config} render={(c, b) => <CatalogTabs config={config} client={c} branch={b}/>}/>
   ) : (
      <CatalogTabs config={config} client={config.client.id} branch={config.branch.id}/>
   );
}

//  --------------------------------------------------------------------------------------------------

