import React, { useState } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import SurchargeZoneDialog, { newSurchargeZoneDetails, testSurchargeZoneDetails } from './SurchargeZoneDialog';

//  --------------------------------------------------------------------------------------------------

export default function SurchargesDialog(props) {

   const { config, vatRates, value, onClose } = props;

   const [ state, setState ] = useState(() => initState(config.countries, value));

   const saveHandler = () => onClose(produce(state, (draft) => zoneUpdate(draft)).surcharges);

   const cancelHandler = () => onClose(value);

   return (
      <Dialog open disableBackdropClick onClose={cancelHandler} scroll="paper" fullWidth>
         <DialogTitle>Delivery Surcharges</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <FormControl disabled={true} fullWidth>
                     <InputLabel>Country</InputLabel>
                     <Select label="Country" variant="standard" value={state.country} onChange={(e) => countryHandler(setState, e.target.value)}>
                        { state.countries.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>) }
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={12}>
                  <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>{
                     Array.from(state.zones.keys()).sort().map((p) => (
                        <ListItem key={`${state.country}:${p}`}>
                           <ListItemText><Link color="inherit" href="#" onClick={() => editHandler(setState, vatRates, p)}>{ format(p, state.zones.get(p)) }</Link></ListItemText>
                           <ListItemSecondaryAction><IconButton onClick={() => deleteHandler(setState, p)}><DeleteIcon/></IconButton></ListItemSecondaryAction>
                        </ListItem>
                     ))
                  }</List>
                  <div style={{ textAlign : 'center' }}>
                     <IconButton color="primary" onClick={() => addHandler(setState, vatRates)}><AddCircleIcon/></IconButton>
                  </div>
               </Grid>
            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>
            <Button onClick={saveHandler} color="primary">Save</Button>
            <Button onClick={cancelHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function initState(countries, surcharges) {
   return {
      countries  : countries.filter((c) => c.id !== ''),
      surcharges : surcharges,
      country    : 'GB',
      zones      : surcharges.zones.get('GB') || new Map(),
      dialog     : false
   };
}

//  --------------------------------------------------------------------------------------------------

function format(postcode, zone) {
   const surcharges = [
      zone.saver    && `Saver £${zone.saver.price}`,
      zone.standard && `Standard £${zone.standard.price}`,
      zone.express  && `Express £${zone.express.price}`,
      zone.nextDay  && `Next Day £${zone.nextDay.price}`,
      zone.sameDay  && `Same Day £${zone.sameDay.price}`
   ].filter(e => e).join(', ');
   return postcode + ' : ' + (surcharges === '' ? '-- No Deliveries --' : surcharges);
}

//  --------------------------------------------------------------------------------------------------

function countryHandler(setState, value) {
   setState((state) => produce(state, (draft) => {
      zoneUpdate(draft);
      draft.country = value;
      draft.zones   = draft.surcharges.zones.get(value) || new Map();
   }));
}

//  --------------------------------------------------------------------------------------------------

function addHandler(setState, vatRates) {
   const saveHandler = (p, v) => setState((state) => produce(state, (draft) => {
      draft.zones.set(p, v);
      draft.dialog = false;
   }));
   const closeHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <SurchargeZoneDialog vatRates={vatRates} postcodes={state.zones} postcode="" value={newSurchargeZoneDetails()} onSave={saveHandler} onCancel={closeHandler}/>
   }));
}

//  --------------------------------------------------------------------------------------------------

function editHandler(setState, vatRates, postcode) {
   const saveHandler = (p, v) => setState((state) => produce(state, (draft) => {
      draft.zones.delete(postcode);
      draft.zones.set(p, v);
      draft.dialog = false;
   }));
   const closeHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => {
      const zone      = state.zones.get(postcode);
      const postcodes = new Set(state.zones.keys());
      postcodes.delete(postcode);
      return produce(state, (draft) => {
         draft.dialog = <SurchargeZoneDialog vatRates={vatRates} postcodes={postcodes} postcode={postcode} value={zone} onSave={saveHandler} onCancel={closeHandler}/>
      });
   });
}

//  --------------------------------------------------------------------------------------------------

function deleteHandler(setState, postcode) {
   const confirmationHandler = () => setState((state) => produce(state, (draft) => {
      draft.zones.delete(postcode);
      draft.dialog = false;
   }));
   const closeHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));
   setState((state) => produce(state, (draft) => {
      draft.dialog = <ConfirmationDialog message="Delete this surcharge zone ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={closeHandler}/>;
   }));
}

//  --------------------------------------------------------------------------------------------------

function zoneUpdate(draft) {
   if ( draft.zones.size > 0 ) {
      draft.surcharges.zones.set(draft.country, draft.zones);
   } else {
      draft.surcharges.zones.delete(draft.country);
   }
}

//  --------------------------------------------------------------------------------------------------

export function testSurchargesDetails(details) {
   let errors = 0;
   details.zones.forEach((m) => m.forEach((z) => {
      if ( testSurchargeZoneDetails(z) ) {
         errors += 1;
      }
   }));
   return (errors > 0);
}

//  --------------------------------------------------------------------------------------------------


