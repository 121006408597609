import React, { useState, useMemo } from 'react';

import SwipeableViews from 'react-swipeable-views';

import produce from 'immer';

import { v4 as uuidv4 } from 'uuid';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import AttributeGroupsList from './AttributeGroupsList';
import AttributesList from './AttributesList';

//  --------------------------------------------------------------------------------------------------

export default function AttributeDialog(props) {

   const [ state, setState ] = useState({
      groups    : props.groups,
      selected  : -1,
      page      : 0
   });

   const handlers = useMemo(() => createFieldHandlers(setState), [ setState ]);

   const saveButtonHandler = () => props.onClose(allocateIDs(state.groups));

   const cancelButtonHandler = () => props.onClose(props.groups);

   return (
      <Dialog open disableBackdropClick onClose={cancelButtonHandler} scroll="paper" maxWidth="xs" fullWidth>
         <DialogTitle>Product Attributes</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>
               {
                  props.enquiry && (
                     <Grid item xs={12}>
                        <Typography>
                           Please Note: As this is an Enquire Only product, if details are available from the suppliers catalog when the
                                        customer views this product, the attributes from that will be used instead of any that you define here.
                        </Typography>
                        <hr/>
                     </Grid>
                  )
               }
               <Grid item xs={12}>{
                  (state.selected === -1) ? (
                     <Breadcrumbs aria-label="breadcrumbs">
                        <Typography color="textPrimary">Groups</Typography>
                     </Breadcrumbs>
                  ) : (
                     <Breadcrumbs aria-label="breadcrumbs">
                        <Link color="inherit" href="#" onClick={handlers.home}>Groups</Link>
                        <Typography color="textPrimary">{state.groups[state.selected].name}</Typography>
                     </Breadcrumbs>
                  )
               }</Grid>
               <Grid item xs={12}>
                  {/* IMPORTANT: This is only used programmatically to handle the page transitions. */}
                  <SwipeableViews axis="x" disabled={true} index={state.page}>
                     <AttributeGroupsList groups={state.groups} locked={props.locked} onChange={handlers.groups} onSelect={handlers.selection}/>
                     <AttributesList templates={props.templates} groups={state.groups} selected={state.selected} locked={props.locked} onChange={handlers.attributes}/>
                  </SwipeableViews>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={saveButtonHandler} color="primary">Save</Button>
            <Button onClick={cancelButtonHandler} color="primary">Cancel</Button>
         </DialogActions>
      </Dialog>
   );

}

//  --------------------------------------------------------------------------------------------------

function createFieldHandlers(setState) {

   const homeHandler = () => setState((state) => produce(state, (draft) => {
      draft.selected = -1;
      draft.page     = 0;
   }));

   const groupsHandler = (idx1, idx2, ref) => setState((state) => produce(state, (draft) => {
      if ( ref ) {
         draft.groups[idx1] = ref;
      } else if ( idx2 > -1 ) {
         const [ removed ] = draft.groups.splice(idx1, 1);
         draft.groups.splice(idx2, 0, removed);
      } else {
         draft.groups.splice(idx1, 1);
      }
   }));

   const attributesHandler = (idx1, idx2, ref) => setState((state) => produce(state, (draft) => {
      if ( ref ) {
         draft.groups[draft.selected].attributes[idx1] = ref;
      } else if ( idx2 > -1 ) {
         const [ removed ] = draft.groups[draft.selected].attributes.splice(idx1, 1);
         draft.groups[draft.selected].attributes.splice(idx2, 0, removed);
      } else {
         draft.groups[draft.selected].attributes.splice(idx1, 1);
      }
   }));

   const selectionHandler = (idx) => setState((state) => produce(state, (draft) => {
      draft.selected = idx;
      draft.page     = (idx === -1) ? 0 : 1;
   }));

   return {
      home       : homeHandler,
      groups     : groupsHandler,
      attributes : attributesHandler,
      selection  : selectionHandler
   };

}

//  --------------------------------------------------------------------------------------------------

//  Ensures that any new attributes receive a unique identifier (within this products domain)
function allocateIDs(groups) {
   let unallocated = [ ];
   const allocated = { };
   groups.forEach((g, gi) => g.attributes.forEach((a, ai) => {
      if ( a.id ) {
         allocated[a.id] = true;
      } else {
         unallocated.push([gi, ai]);
      }
   }));
   return (unallocated.length === 0) ? groups : produce(groups, (draft) => {
      unallocated.forEach((u) => {
         let id = uuidv4();
         while ( allocated[id] ) {
            id = uuidv4();
         }
         draft[u[0]].attributes[u[1]].id = id;
         allocated[id] = true;
      });
   });
}

//  --------------------------------------------------------------------------------------------------



