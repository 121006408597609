import React, { useState, useMemo } from 'react';

import produce from 'immer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import Utils from '../../Utils';

import PricingBDeliveryDialog, { newPricingBDeliveryDetails, testPricingBDeliveryDetails } from './PricingBDeliveryDialog';

//  --------------------------------------------------------------------------------------------------

export default function PricingBDialog(props) {

   const { vatRates, quantities, locked, inclusive, value, onSave, onCancel } = props;

   const [ state, setState ] = useState(() => initState(value));

   const handlers = useMemo(() => createHandlers(vatRates, quantities, locked, inclusive, setState), [ vatRates, quantities, locked, inclusive, setState ]);

   const saveHandler = () => {
      const fields = { };
      fields.quantity = parseInt(state.fields.quantity);
      if ( state.fields.saver ) {
         fields.saver = state.fields.saver;
      }
      if ( state.fields.standard ) {
         fields.standard = state.fields.standard;
      }
      if ( state.fields.express ) {
         fields.express = state.fields.express;
      }
      if ( state.fields.nextDay ) {
         fields.nextDay = state.fields.nextDay;
      }
      if ( state.fields.sameDay ) {
         fields.sameDay = state.fields.sameDay;
      }
      if ( state.fields.collect ) {
         fields.collect = state.fields.collect;
      }
      onSave(fields);
   };

   const { fields, errors } = state;

   return (
      <Dialog open disableBackdropClick onClose={onCancel} scroll="paper" fullWidth>
         <DialogTitle>Delivery Options</DialogTitle>
         <DialogContent dividers={true}>
            <Grid container spacing={1}>

               <Grid item xs={12}>
                  <TextField label="Quantity" value={state.fields.quantity} error={errors.quantity} onChange={handlers.quantity} disabled={locked} required={!locked} fullWidth/>
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Saver</InputLabel>
                  {
                     fields.saver ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editDeliverySaver}>{summarise(inclusive, 'SVR', fields.saver)}</Link>
                           <IconButton size="small" onClick={handlers.deleteDeliverySaver} disabled={locked}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Not Configured <IconButton size="small" onClick={handlers.addDeliverySaver} disabled={locked}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Standard</InputLabel>
                  {
                     fields.standard ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editDeliveryStandard}>{summarise(inclusive, 'STD', fields.standard)}</Link>
                           <IconButton size="small" onClick={handlers.deleteDeliveryStandard} disabled={locked}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Not Configured <IconButton size="small" onClick={handlers.addDeliveryStandard} disabled={locked}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Express</InputLabel>
                  {
                     fields.express ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editDeliveryExpress}>{summarise(inclusive, 'EXP', fields.express)}</Link>
                           <IconButton size="small" onClick={handlers.deleteDeliveryExpress} disabled={locked}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Not Configured <IconButton size="small" onClick={handlers.addDeliveryExpress} disabled={locked}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <InputLabel shrink>Next Working Day</InputLabel>
                  {
                     fields.nextDay ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editDeliveryNextDay}>{summarise(inclusive, 'NXT', fields.nextDay)}</Link>
                           <IconButton size="small" onClick={handlers.deleteDeliveryNextDay} disabled={locked}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Not Configured <IconButton size="small" onClick={handlers.addDeliveryNextDay} disabled={locked}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>
{/*
               <Grid item xs={12}>
 D   I     P      <InputLabel shrink>Same Working Day</InputLabel>
 I T S T   O E    {
 S H S H   T X       fields.sameDay ? (
 A E U I   E P          <Typography>
 B R E S A N E             <Link color="inherit" href="#" onClick={handlers.editDeliverySameDay}>{summarise(inclusive, 'SAM', fields.sameDay)}</Link>
 L E S   R T N             <IconButton size="small" onClick={handlers.deleteDeliverySameDay} disabled={locked}><DeleteIcon/></IconButton>
 E     T E I S          </Typography>
 D A W H   A I       ) : (
   R I A   L V           <Typography>Not Configured <IconButton size="small" onClick={handlers.addDeliverySameDay} disabled={locked}><AddCircleIcon/></IconButton></Typography>
 A E T T   L E       )
 S   H     Y      }
               </Grid>
*/}
               <Grid item xs={12}>
                  <InputLabel shrink>Recipient Collects</InputLabel>
                  {
                     fields.collect ? (
                        <Typography>
                           <Link color="inherit" href="#" onClick={handlers.editDeliveryCollect}>{summarise(inclusive, 'COL', fields.collect)}</Link>
                           <IconButton size="small" onClick={handlers.deleteDeliveryCollect} disabled={locked}><DeleteIcon/></IconButton>
                        </Typography>
                     ) : (
                        <Typography>Not Configured <IconButton size="small" onClick={handlers.addDeliveryCollect} disabled={locked}><AddCircleIcon/></IconButton></Typography>
                     )
                  }
               </Grid>

               <Grid item xs={12}>
                  <Typography>Please limit the 'Recipient Collects' option to just those specific branches that already know the product supplier and have requested it.</Typography>
               </Grid>

            </Grid>
            {state.dialog}
         </DialogContent>
         <DialogActions>{
            locked ? (
               <Button onClick={onCancel} color="primary">OK</Button>
            ) : (
               <>
                  <Button onClick={saveHandler} color="primary" disabled={errors.quantity || errors.saver   || errors.standard || errors.express ||
                                                                          errors.nextDay  || errors.sameDay || errors.collect  || errors.none}>Save</Button>
                  <Button onClick={onCancel} color="primary">Cancel</Button>
               </>
            )
         }</DialogActions>
      </Dialog>
   );

}


//  --------------------------------------------------------------------------------------------------

function summarise(inclusive, style, details) {
   return (inclusive ? `£${details.price} (Cost: £${details.cost})` : `£${details.price}`) + summariseDelivery(style, details)
}

function summariseDelivery(style, delivery) {
   return ((style === 'SVR' || style === 'STD' || style === 'EXP') ? (' - ' + summariseDays(delivery)) : '') + (delivery.comment ? (' - ' + delivery.comment) : '');
}

function summariseDays(delivery) {
   return (delivery.daysMin === delivery.daysMax) ? (delivery.daysMin === 1) ? '1 working day' : `${delivery.daysMin} working days` : `${delivery.daysMin} to ${delivery.daysMax} working days`;
}

//  --------------------------------------------------------------------------------------------------

function initState(value) {
   const state = {
      fields : {
         quantity : value.quantity.toString(),
      },
      errors : {
         quantity : Utils.isNotOnePlusInteger(value.quantity),
         saver    : value.saver    && testPricingBDeliveryDetails(value.saver),
         standard : value.standard && testPricingBDeliveryDetails(value.standard),
         express  : value.express  && testPricingBDeliveryDetails(value.express),
         nextDay  : value.nextDay  && testPricingBDeliveryDetails(value.nextDay),
         sameDay  : value.sameDay  && testPricingBDeliveryDetails(value.sameDay),
         collect  : value.collect  && testPricingBDeliveryDetails(value.collect),
         none     : testNone(value)
      },
      dialog : false
   };
   if ( value.saver ) {
      state.fields.saver = value.saver;
   }
   if ( value.standard ) {
      state.fields.standard = value.standard;
   }
   if ( value.express ) {
      state.fields.express = value.express;
   }
   if ( value.nextDay ) {
      state.fields.nextDay = value.nextDay;
   }
   if ( value.sameDay ) {
      state.fields.sameDay = value.sameDay;
   }
   if ( value.collect ) {
      state.fields.collect = value.collect;
   }
   return state;
}

//  --------------------------------------------------------------------------------------------------

function testNone(ref) {
   return (!ref.saver && !ref.standard && !ref.express && !ref.nextDay && !ref.sameDay && !ref.collect);
}

//  --------------------------------------------------------------------------------------------------

function createHandlers(vatRates, quantities, locked, inclusive, setState) {

   const quantityHandler = (e) => {
      const value = e.target.value;
      setState((state) => produce(state, (draft) => {
         draft.fields.quantity = value;
         draft.errors.quantity = Utils.isNotOnePlusInteger(value) || quantities.indexOf(value) !== -1;
      }));
   };

   const dialogCloseHandler = () => setState((state) => produce(state, (draft) => {
      draft.dialog = false;
   }));

   const addDeliveryHandler = (style, field) => {
      const saveHandler = (value) => setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         draft.errors[field] = testPricingBDeliveryDetails(value);
         draft.errors.none   = false;
         draft.dialog        = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <PricingBDeliveryDialog vatRates={vatRates} style={style} locked={locked} inclusive={inclusive}
                                                details={newPricingBDeliveryDetails(style, inclusive)} onSave={saveHandler} onCancel={dialogCloseHandler}/>
      }));
   };

   const editDeliveryHandler = (style, field) => {
      const saveHandler = (value) => setState((state) => produce(state, (draft) => {
         draft.fields[field] = value;
         draft.errors[field] = testPricingBDeliveryDetails(value);
         draft.dialog        = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <PricingBDeliveryDialog vatRates={vatRates} style={style} locked={locked} inclusive={inclusive}
                                                details={state.fields[field]} onSave={saveHandler} onCancel={dialogCloseHandler}/>
      }));
   };

   const deleteDeliveryHandler = (field) => {
      const confirmationHandler = (value) => setState((state) => produce(state, (draft) => {
         delete draft.fields[field];
         draft.errors.none = testNone(draft.fields);
         draft.dialog      = false;
      }));
      setState((state) => produce(state, (draft) => {
         draft.dialog = <ConfirmationDialog message="Delete this option ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={dialogCloseHandler}/>;
      }));
   };

   return {
      quantity               : quantityHandler,
      addDeliverySaver       : () => addDeliveryHandler('SVR', 'saver'),
      editDeliverySaver      : () => editDeliveryHandler('SVR', 'saver'),
      deleteDeliverySaver    : () => deleteDeliveryHandler('saver'),
      addDeliveryStandard    : () => addDeliveryHandler('STD', 'standard'),
      editDeliveryStandard   : () => editDeliveryHandler('STD', 'standard'),
      deleteDeliveryStandard : () => deleteDeliveryHandler('standard'),
      addDeliveryExpress     : () => addDeliveryHandler('EXP', 'express'),
      editDeliveryExpress    : () => editDeliveryHandler('EXP', 'express'),
      deleteDeliveryExpress  : () => deleteDeliveryHandler('express'),
      addDeliveryNextDay     : () => addDeliveryHandler('NXT', 'nextDay'),
      editDeliveryNextDay    : () => editDeliveryHandler('NXT', 'nextDay'),
      deleteDeliveryNextDay  : () => deleteDeliveryHandler('nextDay'),
      addDeliverySameDay     : () => addDeliveryHandler('SAM', 'sameDay'),
      editDeliverySameDay    : () => editDeliveryHandler('SAM', 'sameDay'),
      deleteDeliverySameDay  : () => deleteDeliveryHandler('sameDay'),
      addDeliveryCollect     : () => addDeliveryHandler('COL', 'collect'),
      editDeliveryCollect    : () => editDeliveryHandler('COL', 'collect'),
      deleteDeliveryCollect  : () => deleteDeliveryHandler('collect'),
   };

}

//  --------------------------------------------------------------------------------------------------

export function newPricingBDetails(quantity) {
   return { quantity };
}

//  --------------------------------------------------------------------------------------------------

export function testPricingBDetails(band) {
   return ((band.saver    && testPricingBDeliveryDetails(band.saver))    ||
           (band.standard && testPricingBDeliveryDetails(band.standard)) ||
           (band.express  && testPricingBDeliveryDetails(band.express))  ||
           (band.nextDay  && testPricingBDeliveryDetails(band.nextDay))  ||
           (band.sameDay  && testPricingBDeliveryDetails(band.sameDay))  ||
           (band.collect  && testPricingBDeliveryDetails(band.collect))  || testNone(band));
}

//  --------------------------------------------------------------------------------------------------


