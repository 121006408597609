import React from 'react';

import Box from '@material-ui/core/Box';

import ContactDetails from './ContactDetails';

//  --------------------------------------------------------------------------------------------------

export default function ContactDetailsTab(props) {

   const { config, tab, values, errors, required, onChange } = props;

   return (
      <div role="tabpanel" id={`contact-details-${tab}`} aria-labelledby={`contact-details-tab-${tab}`}>
         <Box p={2}>
            <ContactDetails config={config} values={values} errors={errors} required={required} onChange={(v, e) => onChange(tab, v, e)}/>
         </Box>
      </div>
   );
}

//  --------------------------------------------------------------------------------------------------

