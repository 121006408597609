import React, { useState, useMemo } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationDialog from '../../components/ConfirmationDialog';

import FileDialog, { newFileDetails } from './FileDialog';

//  --------------------------------------------------------------------------------------------------

export default function FilesList(props) {

   const { files, onChange } = props;

   const [ dialog, setDialog ] = useState(false);

   const handlers = useMemo(() => createHandlers(files, onChange, setDialog), [ files, onChange, setDialog ]);

   return (
      <>
         <DragDropContext onDragEnd={handlers.dragEnd}>
            <Droppable droppableId="droppable">{(provided, snapshot) => (
               <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List style={{ height : 240, position : 'relative', overflow : 'auto' }}>
                     {
                        files.map((f, i) => (
                           <Draggable key={i} draggableId={'f'+i} index={i}>{(provided, snapshot) => (
                              <ListItem ContainerComponent="li" ContainerProps={{ ref: provided.innerRef }}
                                              {...provided.draggableProps} {...provided.dragHandleProps}
                                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                 <img src={'/icons/' + f.icon + '.svg'} alt="File Icon" height={24} style={{ marginRight : 8 }}/>
                                 <ListItemText><Link color="inherit" href="#" onClick={handlers.editFile[i]}>{f.name}</Link></ListItemText>
                                 {
                                    !snapshot.isDragging && (
                                       <ListItemSecondaryAction>
                                          <IconButton onClick={handlers.deleteFile[i]}><DeleteIcon/></IconButton>
                                       </ListItemSecondaryAction>
                                    )
                                 }
                              </ListItem>
                           )}</Draggable>
                        ))
                     }
                     {provided.placeholder}
                  </List>
               </div>
            )}</Droppable>
         </DragDropContext>
         <div style={{ textAlign : 'center' }}>
            <IconButton color="primary" onClick={handlers.addFile}><AddCircleIcon/></IconButton>
         </div>
         { dialog }
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

function createHandlers(files, onChange, setDialog) {

   const dragEndHandler = (result) => {
      if ( result.destination ) {
         onChange(result.source.index, result.destination.index, null);
      }
   };

   const addHandler = () => {
      const saveHandler = (file) => {
         setDialog(false);
         onChange(files.length, -1, file);
      };
      const cancelHandler = () => {
         setDialog(false);
      };
      setDialog(<FileDialog details={newFileDetails()} onSave={saveHandler} onCancel={cancelHandler}/>);
   };

   const editHandler = (idx) => {
      const saveHandler = (file) => {
         setDialog(false);
         onChange(idx, -1, file);
      };
      const cancelHandler = () => {
         setDialog(false);
      };
      setDialog(<FileDialog details={files[idx]} onSave={saveHandler} onCancel={cancelHandler}/>);
   };

   const deleteHandler = (idx) => {
      const confirmationHandler = () => {
         setDialog(false);
         onChange(idx, -1, null);
      };
      const rejectionHandler = () => {
         setDialog(false);
      };
      setDialog(<ConfirmationDialog message="Delete this file ?" confirmationButton="Delete" onConfirmation={confirmationHandler} onClose={rejectionHandler}/>);
   };

   return {
      dragEnd    : dragEndHandler,
      addFile    : addHandler,
      editFile   : files.map((o, i) => () => editHandler(i)),
      deleteFile : files.map((o, i) => () => deleteHandler(i))
   };

}

//  --------------------------------------------------------------------------------------------------

function getItemStyle(isDragging, draggableStyle) {
   const style = { ...draggableStyle };
   if ( isDragging ) {
      style.border     = 'solid 1px black';
      style.background = 'rgb(245, 245, 245)';
   }
   return style;
}

//  --------------------------------------------------------------------------------------------------


